/*import angular */
import { createReducer, on } from '@ngrx/store';
import { DivisionState } from './divisions-data-store.interface';
import * as DivisionsDataStoreActions from './divisions-data-store.action';

/* state key */
export const featureKey = 'divisions';

/* initial state */
export const initialState: DivisionState = {
  isLoading: null,
  companyDivisionLoading: null,
  companyDivisions: [],
  locations: [],
  companyId: null,
  divisionAfterSearch: [],
  totalDivisionCount: 0
};

export const reducer = createReducer(
  initialState,
  on(DivisionsDataStoreActions.initDivisions, (state, action) => ({
    ...state
  })),

  on(DivisionsDataStoreActions.getAllCompanyDivisions, (state, action) => ({
    ...state,
    companyDivisionLoading: true
  })),

  on(DivisionsDataStoreActions.getAllCompanyDivisionsSuccessFully, (state, action) => ({
    ...state,
    companyDivisions: action.payload.divisionList,
    companyDivisionLoading: false
  })),

  // TODO: handle error
  on(DivisionsDataStoreActions.getAllCompanyDivisionsFailure, (state, action) => ({
    ...state,
    companyDivisionLoading: false
  })),

  on(DivisionsDataStoreActions.getDivisions, (state, action) => ({
    ...state,
    companyDivisions: [],
    isLoading: true,
  })),
  on(DivisionsDataStoreActions.getNestedDivisions, (state, action) => ({
    ...state,
    companyDivisions: [],
    isLoading: true,
  })),
  on(DivisionsDataStoreActions.getDivisionListSuccess, (state, action) => {
    return {
      ...state,
      isLoading: false,
      companyDivisions: [...action.payload.divisions],
      totalDivisionCount: action.payload.divisions.length,
      locations: [...action.payload.locations]
    };
  }),
  on(DivisionsDataStoreActions.getNestedDivisionListSuccess, (state, action) => {
    return {
      ...state,
      isLoading: false,
      companyDivisions: [...action.payload.divisions],
      totalDivisionCount: action.payload.divisions.length,
      locations: [...action.payload.locations]
    };
  }),
  on(DivisionsDataStoreActions.resetDivisions, (state, action) => (initialState)),
);
