import { Injectable } from '@angular/core';
import { EnumTranslateCategory, EnumTranslationPath } from '@app/core/consts/app.const';
import { AssetClass, AssetFieldCheckDup, AssetStatus, AssetType, IdentifierType, InspectionDevice, legacyType, TagType, VINProperties } from '@app/core/consts/asset.const';
import { IAssetParams } from '@app/core/models/asset.model';
import { ParseCSVParams } from '@app/core/models/parse-csv.model';
import { CoreAssetApiService } from '@app/core/services/api/core-asset-api.service';
import { AssetLogicService } from '@app/modules/asset/services/asset-logic.service';
import { get, cloneDeep } from 'lodash';
import { forkJoin, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import countryTimezone from 'countries-and-timezones';
import { ValidationService } from '@app/core/services/validation.service';
import { AssetValidationSchema } from '@app/core/consts/validation.const';
import { toTitleCase } from '@app/core/utils';
import { VINApiService } from '@app/core/services/api/vin-api.service';
import { BulkImportService } from './bulk-import.service';

@Injectable({
  providedIn: 'root'
})
export class BulkAssetImportService extends BulkImportService {
  errorMessagesTranslated: any = {};
  assetMapping: any = {};
  bulkImportTranslated: any = {};

  constructor(
    private coreAssetApiService: CoreAssetApiService,
    private assetLogicService: AssetLogicService,
    private validationService: ValidationService,
    private vinApiService: VINApiService,
  ) {
    super();
   }

  parseRecord(recordDataObject, params: ParseCSVParams): IAssetParams {
    return {
      name: recordDataObject[this.assetMapping.assetId],
      companyId: params.companyId,
      divisions: [this.getDivisionByName(params.divisions, recordDataObject[this.assetMapping.division])],
      assetType: AssetType.VEHICLE,
      typeInfo: {
        type: recordDataObject[this.assetMapping.type]?.toLowerCase(),
        subtype: recordDataObject[this.assetMapping.subtype]?.toLowerCase(),
        oemVin: recordDataObject[this.assetMapping.vin] ? recordDataObject[this.assetMapping.vin] : null,
        licenseNumber: recordDataObject[this.assetMapping.regPlate]
          ? recordDataObject[this.assetMapping.regPlate] : null,
        countryOfRegistration: recordDataObject[this.assetMapping.regCountry]
          ? recordDataObject[this.assetMapping.regCountry].toLowerCase() : null,
        licenseJurisdiction: recordDataObject[this.assetMapping.jurisdiction]
          ? recordDataObject[this.assetMapping.jurisdiction] : null,
        powerUnitNumber: recordDataObject[this.assetMapping.powerUnitNo]
          ? recordDataObject[this.assetMapping.powerUnitNo].toUpperCase() : null,
        class: recordDataObject[this.assetMapping.class]
          ? recordDataObject[this.assetMapping.class].toLowerCase() : null,
        fuelType: recordDataObject[this.assetMapping.fuelType]
          ? recordDataObject[this.assetMapping.fuelType].toLowerCase() : null
      },
      status: AssetStatus.ACTIVE,
      make: null,
      model: null,
      modelYear: null,
      inspectionInfo: {
        identifier: recordDataObject[this.assetMapping.cviTag] ? recordDataObject[this.assetMapping.cviTag] : null,
        tagType: TagType.ASSET,
        identifierType: IdentifierType.NFC,
        inspectionDevice: InspectionDevice.evirMobile,
      },
      isHazardous: this.parseBoolean(recordDataObject[this.assetMapping.hazmatLoads], false),
      legacyAttributes: {
        type: legacyType.STANDARD,
        subtype: recordDataObject[this.assetMapping.cviProfile]
          ? recordDataObject[this.assetMapping.cviProfile].toLowerCase() : null
      }
    };
  }

  deDupRecord(inputRecords, fieldsCheckDup) {
    const tmpInputRecords = cloneDeep(inputRecords);
    const mappingFields = {};
    Object.keys(fieldsCheckDup).forEach(key => {
      mappingFields[this.assetMapping[key]] = fieldsCheckDup[key];
    });
    tmpInputRecords?.forEach((record, index) => {
      this.checkRecordByFields(record, tmpInputRecords, index + 1, mappingFields);
    });
    return tmpInputRecords;
  }

  checkRecordByFields(recordSource, recordTargets, startIndex, mappingFields) {
    recordTargets.forEach((record, index) => {
      if (index < startIndex) {
        return;
      }

      Object.keys(mappingFields).forEach(key => {
        const value = get(recordSource, mappingFields[key]);
        if (
          value !== null &&
          value === get(record, mappingFields[key])
        ) {
          if (!record.hasOwnProperty('fieldDup')) {
            record.fieldDup = [];
          }
          if (!record.fieldDup.includes(key)) {
            record.fieldDup.push(key);
          }
        }
      });
    });
  }

  fetchingFieldOptions(data) {
    return forkJoin([
      this.coreAssetApiService.getTypeAssets(),
      this.coreAssetApiService.getFuelTypes(),
      this.assetLogicService.getCVIConfigurationProfile(data.companyId)
    ]).pipe(map(prePopulateDataList => {
      const assetType = prePopulateDataList[0].body;
      const assetSubType = {};
      Object.keys(assetType).forEach(key => {
        assetSubType[key] = new Map(assetType[key].map(type => {
          return [this.getTranslatedAssetEnumValue(type, EnumTranslateCategory.ASSET_SUBTYPE, key)?.toLowerCase(), type];
        }));
      });
      return {
        countryList: new Map(Object.values(countryTimezone.getAllCountries() || {}).map(country => {
          return [this.getTranslatedAssetEnumValue(country.name,
            EnumTranslateCategory.COUNTRY_OF_REGISTRATION)?.toLowerCase(), country.name];
        })),
        fuelType: new Map(prePopulateDataList[1].body.map(fuel => {
          return [this.getTranslatedAssetEnumValue(fuel, EnumTranslateCategory.ASSET_FUELTYPE)?.toLowerCase(), fuel];
        })),
        assetType: new Map(Object.keys(assetType).map(key => {
          return [this.getTranslatedAssetEnumValue(key, EnumTranslateCategory.ASSET_TYPE)?.toLowerCase(), key];
        })),
        assetSubType,
        evirConfProf: new Map(prePopulateDataList[2].map(evirconf => {
          return [evirconf?.toLowerCase(), evirconf];
        })),
        classList: new Map(AssetClass.map(assetClass => {
          return [this.getTranslatedAssetEnumValue(assetClass, EnumTranslateCategory.ASSET_CLASS)?.toLowerCase(), assetClass];
        }))
      };
    }));
  }

  /**
   * Function returns translated asset enum value
   * @param assetTranslationKey - Asset enum value that is being translated
   * @param assetCategory - Category key of asset.assetEnumValues in translation file
   * @param assetSubCategory - Optional sub category key of assetCategory in translation file
   * @returns Returns translated string of input asset enum value
   */
  getTranslatedAssetEnumValue(assetTranslationKey: string, assetCategory: string, assetSubCategory = ''): string {
    if (!assetSubCategory) {
      return this.bulkImportTranslated[EnumTranslationPath][assetCategory][assetTranslationKey];
    }
    return this.bulkImportTranslated[EnumTranslationPath][assetCategory][assetSubCategory][assetTranslationKey];
  }

  createRecord(record) {
    return this.coreAssetApiService.createAsset(record);
  }

  checkDupRecordAPI(record, companyId) {
    const stringQuery = [];
    const curRecAssetFieldCheck = Object.assign({}, AssetFieldCheckDup);
    Object.keys(AssetFieldCheckDup).forEach(key => {
      const field = AssetFieldCheckDup[key].split('.')[0];
      const assetFieldValue = get(record, AssetFieldCheckDup[key]);
      if (assetFieldValue) {
        stringQuery.push(`${field}:${assetFieldValue}`);
      } else {
        delete curRecAssetFieldCheck[key];
      }
    });
    return this.coreAssetApiService.getCompanyAssetsWithQuery(companyId, { q: stringQuery.join('+'), status: AssetStatus.ANY }).pipe(
      map((resList: any) => {
        const result: any = {
          isDuplicateRecord: false,
          fieldName: []
        };
        Object.keys(curRecAssetFieldCheck).forEach((key) => {
          const assetFieldValue = get(record, AssetFieldCheckDup[key]);
          if (resList.find(asset => assetFieldValue && get(asset, AssetFieldCheckDup[key]) === assetFieldValue)) {
            result.isDuplicateRecord = true;
            result.fieldName.push(this.assetMapping[key]);
          }
        });
        return result;
      })
    );
  }

  prePopulateAdditionalMetaData(record) {
    record = this.removeRedundantFields(record);
    if (record.typeInfo.oemVin) {
      return this.vinApiService.parsingVinAPI(record.typeInfo.oemVin)
        .pipe(map(response => {
          const vinParsedMap = response.Results.reduce((a, v) => ({ ...a, [v.Variable]: v }), {});
          record.make = vinParsedMap[VINProperties.MAKE]?.Value ?? null;
          record.model = vinParsedMap[VINProperties.MODEL]?.Value ?? null;
          record.modelYear = vinParsedMap[VINProperties.MODEL_YEAR]?.Value ?? null;
          return record;
        }), catchError(() => {
          // TODO: Skip this error for as this case is equivalent to parsing empty
          // This should not break the upload process.
          return of(record);
        }));
    }
  }

  validateRecord(record: IAssetParams, params) {
    const tmpRecord = cloneDeep(record);
    this.validationService.setRecordDataValidate(tmpRecord);

    return {
      isValid: this.validateAssetId(record.name)
        && this.validateType(record, params.assetType)
        && this.validateSubType(params.assetSubType)
        && this.validateVin(record.typeInfo.oemVin)
        && this.validatePowerUnitNo(record.typeInfo.powerUnitNumber)
        && this.validateRegCountry(params.countryList)
        && this.validateRegPlate(record.typeInfo.licenseNumber)
        && this.validateFuelType(params.fuelType)
        && this.validateDivision(record.divisions[0])
        && this.validateCviTag()
        && this.validateCviProfile(params.evirConfProf)
        && this.validateIsHazardous(record.isHazardous)
        && this.validateClass(params.classList)
        && this.validateJurisdiction(record.typeInfo.licenseJurisdiction),
      fieldName: this.validationService.getCurrentValidationField(),
      data: this.validationService.getRecordDataValidate() ? this.validationService.getRecordDataValidate() : record,
    };
  }

  validateAssetId(value): boolean {
    this.validationService.setCurrentValidationField(this.assetMapping.assetId);
    return this.validationService.validateRequiredField(value)
      && this.validationService.validateMinLength(value, AssetValidationSchema.name.minLength)
      && this.validationService.validateMaxLength(value, AssetValidationSchema.name.maxLength)
      && this.validationService.validateFormatPattern(value, AssetValidationSchema.name.pattern);
  }

  validateType(record, assetType): boolean {
    const tmpRecord = cloneDeep(record);
    this.validationService.setCurrentValidationField(this.assetMapping.type);
    if (this.validationService.validateRequiredField(tmpRecord.typeInfo.type)) {
      const enumValid = this.validationService.validateAndReviseEnumerated(tmpRecord.typeInfo.type, assetType);
      if (enumValid.validCheck) {
        tmpRecord.typeInfo.type = enumValid.optionRevised;
        this.validationService.setRecordDataValidate(tmpRecord.typeInfo.type, 'typeInfo.type');
        return true;
      }
    }
    return false;
  }

  validateSubType(assetSubType): boolean {
    const tmpRecord = cloneDeep(this.validationService.getRecordDataValidate());

    this.validationService.setCurrentValidationField(this.assetMapping.subtype);
    if (this.validationService.validateRequiredField(tmpRecord.typeInfo.type)) {
      const enumValid = this.validationService.validateAndReviseEnumerated(
        tmpRecord.typeInfo.subtype,
        assetSubType[toTitleCase(tmpRecord.typeInfo.type)]
      );
      if (enumValid.validCheck) {
        tmpRecord.typeInfo.subtype = enumValid.optionRevised;

        this.validationService.setRecordDataValidate(tmpRecord.typeInfo.subtype, 'typeInfo.subtype');
        return true;
      }
    }
    return false;
  }

  validateVin(value): boolean {
    this.validationService.setCurrentValidationField(this.assetMapping.vin);
    return value ? this.validationService.validateMinLength(value, AssetValidationSchema.vin.minLength)
      && this.validationService.validateMaxLength(value, AssetValidationSchema.vin.maxLength)
      && this.validationService.validateFormatPattern(value, AssetValidationSchema.vin.pattern) : true;
  }

  validatePowerUnitNo(value): boolean {
    this.validationService.setCurrentValidationField(this.assetMapping.powerUnitNo);
    return value ? this.validationService.validateMinLength(value, AssetValidationSchema.powerUnitNumber.minLength)
      && this.validationService.validateMaxLength(value, AssetValidationSchema.powerUnitNumber.maxLength)
      && this.validationService.validateFormatPattern(value, AssetValidationSchema.powerUnitNumber.pattern) : true;
  }

  validateRegCountry(countryList): boolean {
    const tmpRecord = cloneDeep(this.validationService.getRecordDataValidate());

    this.validationService.setCurrentValidationField(this.assetMapping.regCountry);
    if (tmpRecord.typeInfo.countryOfRegistration) {
      const enumValid = this.validationService.validateAndReviseEnumerated(tmpRecord.typeInfo.countryOfRegistration, countryList);
      if (enumValid.validCheck) {
        tmpRecord.typeInfo.countryOfRegistration = enumValid.optionRevised;
        this.validationService.setRecordDataValidate(tmpRecord.typeInfo.countryOfRegistration, 'typeInfo.countryOfRegistration');
      }
      return enumValid.validCheck;
    }
    return true;
  }

  validateRegPlate(value): boolean {
    this.validationService.setCurrentValidationField(this.assetMapping.regPlate);
    return value ? this.validationService.validateMinLength(value, AssetValidationSchema.plate.minLength)
      && this.validationService.validateMaxLength(value, AssetValidationSchema.plate.maxLength) : true;
  }

  validateFuelType(fuelType): boolean {
    const tmpRecord = cloneDeep(this.validationService.getRecordDataValidate());
    this.validationService.setCurrentValidationField(this.assetMapping.fuelType);
    if (tmpRecord.typeInfo.fuelType) {
      const enumValid = this.validationService.validateAndReviseEnumerated(tmpRecord.typeInfo.fuelType, fuelType);
      if (enumValid.validCheck) {
        tmpRecord.typeInfo.fuelType = enumValid.optionRevised;
        this.validationService.setRecordDataValidate(tmpRecord.typeInfo.fuelType, 'typeInfo.fuelType');
      }
      return enumValid.validCheck;
    }
    return true;
  }

  validateDivision(value) {
    this.validationService.setCurrentValidationField(this.assetMapping.division);
    return this.validationService.validateRequiredField(value);
  }

  validateCviTag() {
    const tmpRecord = cloneDeep(this.validationService.getRecordDataValidate());
    this.validationService.setCurrentValidationField(this.assetMapping.cviTag);
    if (tmpRecord.inspectionInfo.identifier === null) {
      return true;
    }

    if (this.validationService.validateFormatPattern(tmpRecord.inspectionInfo.identifier, AssetValidationSchema.cviID.pattern)) {
      tmpRecord.inspectionInfo.identifier = parseInt(tmpRecord.inspectionInfo.identifier, 10);
      this.validationService.setRecordDataValidate(tmpRecord.inspectionInfo.identifier, 'inspectionInfo.identifier');
      return true;
    }
    return false;
  }

  validateCviProfile(evirConfProf) {
    const tmpRecord = cloneDeep(this.validationService.getRecordDataValidate());
    this.validationService.setCurrentValidationField(this.assetMapping.cviProfile);
    if (tmpRecord.legacyAttributes.subtype) {
      const enumValid = this.validationService.validateAndReviseEnumerated(tmpRecord.legacyAttributes.subtype, evirConfProf);
      if (enumValid.validCheck) {
        tmpRecord.legacyAttributes.subtype = enumValid.optionRevised;
        this.validationService.setRecordDataValidate(tmpRecord.legacyAttributes.subtype, 'legacyAttributes.subtype');
      }
      return enumValid.validCheck;
    }
    return true;
  }

  validateIsHazardous(value) {
    this.validationService.setCurrentValidationField(this.assetMapping.hazmatLoads);
    return value !== undefined;
  }

  validateClass(classList) {
    const tmpRecord = cloneDeep(this.validationService.getRecordDataValidate());
    this.validationService.setCurrentValidationField(this.assetMapping.class);
    if (tmpRecord.typeInfo.class) {
      const enumValid = this.validationService.validateAndReviseEnumerated(tmpRecord.typeInfo.class, classList);
      if (enumValid.validCheck) {
        tmpRecord.typeInfo.class = enumValid.optionRevised;
        this.validationService.setRecordDataValidate(tmpRecord.typeInfo.class, 'typeInfo.class');
      }
      return enumValid.validCheck;
    }
    return true;
  }

  validateJurisdiction(value) {
    this.validationService.setCurrentValidationField(this.assetMapping.jurisdiction);
    return value ? this.validationService.validateMaxLength(value, AssetValidationSchema.jurisdiction.maxLength) : true;
  }
}
