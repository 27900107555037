import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse
} from '@angular/common/http';
import { AuthService } from '@auth0/auth0-angular';
import { Observable, of, throwError } from 'rxjs';
import { DialogService } from '@app/core/services/dialog.service';
import { catchError, delay, mergeMap, retryWhen } from 'rxjs/operators';
import { STATUSAPI } from '@app/core/consts/status-api.const';
import { environment } from '@environments/environment';

const DEFAULT_MAX_RETRIES = 3;
const MAX_BACKOFF = 60000;
const DELAY_MS = 500;
@Injectable()
export class HandleGlobalErrorInterceptor implements HttpInterceptor {
  compWindow: any = window;
  constructor(
    public auth: AuthService,
    private dialogService: DialogService
  ) { }
  retries = 0;

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const jitter = Math.floor(Math.random() * DELAY_MS);
    let httpRequest = request.clone({});
    // TODO: Temporary workaround for setting API to be able to get COMPANY setting instead of USERPROFILE one.
    if (request.urlWithParams.includes('v1beta2')) {
      httpRequest = httpRequest.clone({ headers: request.headers.delete('Zonar-Owner-ID') });
    }
    this.retries = 0;
    return next.handle(httpRequest).pipe(
      retryWhen(errors => {
        return errors
          .pipe(
            mergeMap((error) => {
              if (error.status >= 500 && this.retries < DEFAULT_MAX_RETRIES) {
                this.retries++;
                const backoffTime = jitter + Math.min(Math.pow(2, this.retries) * 1000, MAX_BACKOFF);
                return of(error).pipe(delay(backoffTime));
              }
              return throwError(error);
            }),
          );
      }),
      catchError((error: HttpErrorResponse) => {
        if (error.status >= 500) {
          this.dialogService.showAlertDialogComponent({}, '25rem', '25.313rem').subscribe(() => {
            this.compWindow.location.reload();
          });
          return throwError({} as any);
        }

        if (error.status === STATUSAPI.UNAUTHORIZED) {
          this.auth.logout({ returnTo: environment.appUrl });
        }
        return throwError(error);
      })
    );
  }
}
