import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';

/* reducer */
import * as fromRoleDataStore from './policy-data-store.reducer';

/* effects */
import { PolicyDataStoreEffects } from './policy-data-store.effects';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature(
      fromRoleDataStore.featureKey,
      fromRoleDataStore.reducer,
      { initialState: fromRoleDataStore.initialState }
    ),
    EffectsModule.forFeature([ PolicyDataStoreEffects ])
  ],
  exports: [
    StoreModule,
    EffectsModule
  ]
})
export class RoleDataStoreModule { }
