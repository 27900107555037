import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Translations } from '@app/core/services/i18n/translations.service';
import { RegionParserService } from '@app/core/services/region-parser.service';
import { TranslateService } from '@zonar-ui/i18n';

@Component({
  selector: 'app-bulk-user-preview-dialog',
  templateUrl: './bulk-user-preview-dialog.component.html',
  styleUrls: ['./bulk-user-preview-dialog.component.scss']
})
export class BulkUserPreviewDialogComponent implements OnInit {
  translatedText: any = {};
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<BulkUserPreviewDialogComponent>,
    public translateService: TranslateService,
    public translations: Translations,
    public regionParserService: RegionParserService,

  ) { }

  ngOnInit(): void {
    const translateString = 'bulkUserPreviewDialog';
    this.translateService.get(translateString).subscribe(translatedText => {
      this.translatedText = this.regionParserService.getParsedBucketKeyWords(
        translateString, Object.keys(translatedText));
    });
  }

  onCancel() {
    this.dialogRef.close();
  }

  onConfirm() {
    this.dialogRef.close(true);
  }

}
