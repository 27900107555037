<div class="migration-start-dialog" fxLayout="column" fxLayoutAlign="space-between start">
  <div fxLayout="row" fxLayoutAlign="start" class="title">
    <span>{{data?.title}}</span>
  </div>
  <div class="message" fxLayout="column">
    <span [innerHtml]="data?.message1"></span>
    <span *ngIf="data?.message2" [innerHtml]="data?.message2"></span>
    <span *ngIf="data?.message3" [innerHtml]="data?.message3"></span>
    <span *ngIf="data?.message4" [innerHtml]="data?.message4"></span>
  </div>
  <div class="dialog-button" fxLayout="row" fxLayoutAlign="end end">
    <button mat-raised-button type="submit" (click)="onProceed()">{{data?.button}}</button>
  </div>
</div>
