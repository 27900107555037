import { Component, OnInit } from '@angular/core';
import { I18nService, TranslateService } from '@zonar-ui/i18n';
import { Translations } from '@app/core/services/i18n/translations.service';
import { RegionParserService } from '@app/core/services/region-parser.service';
import { SupportService } from '@app/core/services/support.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-bulk-import',
  template: ''
})
export abstract class BulkImportRequirementsComponent implements OnInit {
  guideList = [];
  guideDesc = {};
  urlDownload = '';

  translationsNotLoaded = !!this.translateService.store.translations;
  private onDestroy$ = new Subject<void>();

  constructor(
    public i18nService: I18nService,
    public translateService: TranslateService,
    public translations: Translations,
    public regionParserService: RegionParserService,
    public supportService: SupportService,
  ) {
    if (this.translationsNotLoaded) {
      this.translateService
        .use(this.i18nService.getLanguageInUse()).pipe(takeUntil(this.onDestroy$)).subscribe(() => this.translationsNotLoaded = false);
    }
  }

  ngOnInit(): void {
    this.initGuide();
    this.getFilePathDownload();
  }

  abstract initGuide(): void;
  abstract getFilePathDownload();
}
