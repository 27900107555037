import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';

/* reducer */
import * as fromDriversDataStore from './drivers-data-store.reducer';

/* effects */
import { DriversDataStoreEffects } from './drivers-data-store.effects';

@NgModule({
    declarations: [],
    imports: [
        CommonModule,
        StoreModule.forFeature(
            fromDriversDataStore.featureKey,
            fromDriversDataStore.reducer,
            { initialState: fromDriversDataStore.initialState }
        ),
        EffectsModule.forFeature([DriversDataStoreEffects])

    ],
    exports: [
        StoreModule,
        EffectsModule
    ]
})
export class DriversDataStoreModule { }
