import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';

/* reducer */
import * as fromCompaniesDataStore from './companies-data-store.reducer';

/* effects */
import { CompaniesDataStoreEffects } from './companies-data-store.effects';


@NgModule({
    declarations: [],
    imports: [
        CommonModule,
        StoreModule.forFeature(
            fromCompaniesDataStore.featureKey,
            fromCompaniesDataStore.reducer,
            { initialState: fromCompaniesDataStore.initialState }
        ),
        EffectsModule.forFeature([ CompaniesDataStoreEffects ])

    ],
    exports: [
        StoreModule,
        EffectsModule
    ]
})
export class CompaniesDataStoreModule { }