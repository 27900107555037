/* angular */
import { createReducer, on } from '@ngrx/store';
import { initialState } from './app-data-store-state';

/* services */

/* actions */
import * as AppDataStoreActions from './app-data-store.actions';

/* state key */
export const featureKey = 'app';

export const reducer = createReducer(
  initialState,
  /* reducer functions for actions */
  on(AppDataStoreActions.initApp, (state, action) => ({
    ...state,
    initialized: true
  })),

  on(AppDataStoreActions.onCompanyModeSelected, (state, action) => ({
    ...state,
    isLoading: true,
    companyModeInfo: action.payload
  })),

  on(AppDataStoreActions.setCompanyId, (state, action) => ({
    ...state,
    isLoading: true,
    companyId: action.payload.id
  })),

);
