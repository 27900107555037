import { Injectable } from '@angular/core';
import { IDivision } from '@app/core/models/company.model';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as DivisionDataStoreActions from './divisions-data-store.action';
import { CoreCompanyApiService } from '@app/core/services/api/core-company-api.service';
import { forkJoin, of, Subject } from 'rxjs';
import { catchError, map, mergeMap, switchMap, takeUntil } from 'rxjs/operators';

import * as DivisionActions from './divisions-data-store.action';
import { CoreAssetApiService } from '@app/core/services/api/core-asset-api.service';
import { DivisionStatus, DivisionTablePropertyParams as propParams } from '@app/core/consts/division.const';
@Injectable()
export class DivisionsDataStoreEffects {
  private cancelSubject$ = new Subject<boolean>();

  constructor(
    private actions$: Actions,
    private companyApiService: CoreCompanyApiService,
    private assetApiService: CoreAssetApiService,
  ) { }

  getAllCompanyDivisions$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DivisionDataStoreActions.getAllCompanyDivisions),
      mergeMap((action) => {
        const { companyId, params } = action.payload;
        return this.companyApiService.getAllCompanyDivisions(companyId, params).pipe(
          mergeMap((divisionList: IDivision[]) => {
            return [DivisionDataStoreActions.getAllCompanyDivisionsSuccessFully(
              {
                payload: { divisionList }
              })];
          }),
          catchError((error) => {
            return [DivisionDataStoreActions.getAllCompanyDivisionsFailure({
              payload: { error }
            })];
          })
        );
      })
    )
  );

  getDivisions$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DivisionActions.getDivisions),
      mergeMap((action) => {
        const { companyId, params } = action.payload;

        const apiArray = [
          this.companyApiService.getCompanyLocations(companyId),
          this.companyApiService.getAllCompanyDivisions(companyId, {
            status: params[propParams.STATUS] ? params[propParams.STATUS] : DivisionStatus.ANY,
            sort: params[propParams.SORT] ? params[propParams.SORT] : '',
          })
        ];

        return forkJoin(apiArray).pipe(
          mergeMap(([locations, divisions]) => {
            if (divisions && divisions.length) {
              const queryList = [];
              divisions.forEach(div => {
                const locationExisted = locations.find(lo => lo.id === div.locationId);
                div.timeZone = locationExisted ? locationExisted.timezone : null;
                queryList.push(
                  this.assetApiService.getDivisionAssetsCount({ companyId, divisionId: div.id, allChildren: true })
                    .pipe(
                      map((result: any) => {
                        return {
                          assetCount: +result.headers.get('x-total-count'),
                          id: div.id
                        };
                      }),
                      catchError(() => of({
                        assetCount: null,
                        id: div.id
                      })),
                      takeUntil(this.cancelSubject$)
                    )
                );
              });
              return forkJoin(queryList).pipe(
                mergeMap((assetData: any) => {
                  divisions.forEach(div => {
                    div.assetCount = assetData.find(asset => asset.id === div.id)?.assetCount;
                  });
                  return [DivisionActions.getDivisionListSuccess({
                    payload: { divisions, locations }
                  })];
                })
              );
            } else {
              return [DivisionActions.getDivisionListSuccess({
                payload: { divisions: [], locations: [] }
              })];
            }
          }),
          catchError(error => {
            return [DivisionActions.getDivisionsFailure({
              payload: {
                error: error,
                message: error?.message
              }
            })];
          }),
          takeUntil(this.cancelSubject$)
        );
      })
    )
  );

  getNestedDivisions$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DivisionActions.getNestedDivisions),
      mergeMap((action) => {
        const { companyId, params, parentDivisionId } = action.payload;

        const apiArray = [
          this.companyApiService.getCompanyLocations(companyId),
          this.companyApiService.getAllCompanyDivisions(companyId, {
            status: params[propParams.STATUS] ? params[propParams.STATUS] : DivisionStatus.ANY,
            sort: params[propParams.SORT] ? params[propParams.SORT] : '',
          }),
          this.companyApiService.getCompanyChildrenDivisions(
            companyId, parentDivisionId, {
            status: params[propParams.STATUS] ? params[propParams.STATUS] : DivisionStatus.ANY,
            allChildren: true,
          }
          )
        ];

        return forkJoin(apiArray).pipe(
          mergeMap(([locations, divisions, nestedDivisions]) => {
            if (divisions && divisions.length
              && nestedDivisions.body && nestedDivisions.body.length) {
              const queryList = [];
              divisions.forEach(div => {
                const locationExisted = locations.find(lo => lo.id === div.locationId);
                div.timeZone = locationExisted ? locationExisted.timezone : null;
                queryList.push(
                  this.assetApiService.getDivisionAssetsCount({ companyId, divisionId: div.id, allChildren: true })
                    .pipe(
                      map((result: any) => {
                        return {
                          assetCount: +result.headers.get('x-total-count'),
                          id: div.id
                        };
                      }),
                      catchError(() => of({
                        assetCount: null,
                        id: div.id
                      })),
                      takeUntil(this.cancelSubject$)
                    )
                );
              });
              return forkJoin(queryList).pipe(
                mergeMap((assetData: any) => {
                  divisions.forEach(div => {
                    div.assetCount = assetData.find(asset => asset.id === div.id)?.assetCount;
                  });
                  return [DivisionActions.getNestedDivisionListSuccess({
                    payload: { divisions, locations }
                  })];
                })
              );
            } else {
              return [DivisionActions.getNestedDivisionListSuccess({
                payload: { divisions: [], locations: [] }
              })];
            }
          }),
          catchError(error => {
            return [DivisionActions.getDivisionsFailure({
              payload: {
                error: error,
                message: error?.message
              }
            })];
          }),
          takeUntil(this.cancelSubject$)
        );
      })
    )
  );

  cancelRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DivisionActions.cancelRequest),
      map(() => {
        this.cancelSubject$.next();
      })
    ), { dispatch: false }
  );
}
