/* angular */
import { IGroupPolicySetting } from '@app/core/models/group.model';
import { createAction, props } from '@ngrx/store';

/* action types */
export enum ActionTypes {
  InitSetting = '[SETTING] InitSetting',
  GetAllowManagedCompany = '[SETTING] GetAllowManagedCompany',
  GetAllowManagedCompanySuccessfully = '[SETTING] GetAllowManagedCompanySuccessfully',
  GetAllowManagedCompanyFailure = '[SETTING] GetAllowManagedCompanyFailure',
  ResetAllowManagedCompany = '[SETTING] ResetAllowManagedCompany',
  GetGroupPolicySetting = '[SETTING] GetGroupPolicySetting',
  GetGroupPolicySettingSuccess = '[SETTING] GetGroupPolicySettingSuccess',
  CreateGroupPolicySetting = '[SETTING] CreateGroupPolicySetting',
  CreateGroupPolicySettingSuccess = '[SETTING] CreateGroupPolicySettingSuccess',
  SetGroupPolicySetting = '[SETTING] SetGroupPolicySetting',

  GetLegacyOperatorSetting = '[SETTING] GetLegacyOperatorSetting',
  GetLegacyOperatorSettingSuccess = '[SETTING] GetLegacyOperatorSettingSuccess',
  GetLegacyOperatorSettingFail = '[SETTING] GetLegacyOperatorSettingFail',
};

/* actions */
export const initSetting = createAction(ActionTypes.InitSetting);
export const getAllowManagedCompany = createAction(ActionTypes.GetAllowManagedCompany, props<{ payload: { companyId: string } }>());
export const getAllowManagedCompanySuccessfully = createAction(ActionTypes.GetAllowManagedCompanySuccessfully, props<{ payload: { hasAllowManaged: boolean } }>());
export const getAllowManagedCompanyFailure = createAction(ActionTypes.GetAllowManagedCompanyFailure, props<{ payload: { error: any } }>());
export const resetAllowManagedCompany = createAction(ActionTypes.ResetAllowManagedCompany);

export const getGroupPolicySetting = createAction(ActionTypes.GetGroupPolicySetting, props<{ payload: { companyId: string } }>());
export const getGroupPolicySettingSuccess = createAction(ActionTypes.GetGroupPolicySettingSuccess, props<{ payload: { setting: string } }>());
export const createGroupPolicySetting = createAction(ActionTypes.CreateGroupPolicySetting, props<{ payload: { companyId: string } }>());
export const createGroupPolicySettingSuccess = createAction(ActionTypes.CreateGroupPolicySettingSuccess, props<{ payload: { setting: string } }>());
export const setGroupPolicySetting = createAction(ActionTypes.SetGroupPolicySetting, props<{ payload: { setting: string } }>());

// #region LEGACY
export const getLegacyOperatorSetting = createAction(ActionTypes.GetLegacyOperatorSetting, props<{ payload: { companyId: string } }>());
export const getLegacyOperatorSettingSuccess = createAction(ActionTypes.GetLegacyOperatorSettingSuccess, props<{ payload: { hasLegacyOperator: boolean } }>());
export const getLegacyOperatorSettingFail = createAction(ActionTypes.GetLegacyOperatorSettingFail, props<{ payload: { error: any } }>());
// #endregion LEGACY
