export enum DivisionStatus {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
  ANY = 'ANY'
}

export enum DivisionType {
  DIVISION = 'DIVISION',
  LEGACY = 'LEGACY',
  LEGACY_LOCATION = 'LEGACY_LOCATION',
}


export enum LocationStatus {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE'
}

export enum DivisionTabs {
  SUB_DIVISION = 'subdivisions',
  ASSETS = 'assets',
  USERS = 'users'
}

export enum DivisionStatusIcon {
  ACTIVE = 'fiber_manual_record',
  INACTIVE = 'stop'
}

export enum DivisionListTable {
  ICON_STATUS = 'status',
  DIVISION_NAME = 'name',
  LEGACY_CODE = 'legacyAccountCode',
  TIME_ZONE = 'timeZone',
  ASSETS = 'assets',
  ICON = 'icon',
  NESTED = 'nested',
  ASSETCOUNT = 'assetCount',
}

export const ColumnWidth = {
  ICON_STATUS: '3%',
  DIVISION_NAME: '49%',
  LEGACY_CODE_NA: '14%',
  LEGACY_CODE_EU: '0%',
  TIME_ZONE: '14%',
  ASSETS_NA: '14%',
  ASSETS_EU: '28%',
  ICON: '3%',
  NESTED: '3%',
};

export enum ColumnDivisionListTable {
  ICON_STATUS = 0,
  DIVISION_NAME,
  LEGACY_CODE,
  TIME_ZONE,
  ASSETS,
  ICON,
  NESTED,
}

export enum ColumnUserListOfDivision {
  STATUS = 0,
  NAME,
  GROUP_POLICY,
  EDIT
}

export enum ColumnAssetListOfDivision {
  STATUS = 0,
  NAME,
  TYPE,
  SUBTYPE,
  VIN,
  LICENSE,
  MODIFIED,
  EDIT
}

export enum DivisionTablePropertyParams {
  PER_PAGE = 'per_page',
  PAGE = 'page',
  NAME = 'q',
  STATUS = 'status',
  SORT = 'sort'
}

export enum DivisionSettingItemName {
  PROFILE = 'profileActiveToggle',
}

export enum ExpandStatus {
  EXPANDED = 'expanded',
  COLLAPSED = 'collapsed'
}

export const ENTIRE_COMPANY = 'Entire Company';
