import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';

/* reducer */
import * as fromDivisionsDataStore from './divisions-data-store.reducer';

/* effects */
import { DivisionsDataStoreEffects } from './divisions-data-store.effects';


@NgModule({
    declarations: [],
    imports: [
        CommonModule,
        StoreModule.forFeature(
            fromDivisionsDataStore.featureKey,
            fromDivisionsDataStore.reducer,
            { initialState: fromDivisionsDataStore.initialState }
        ),
        EffectsModule.forFeature([ DivisionsDataStoreEffects ])

    ],
    exports: [
        StoreModule,
        EffectsModule
    ]
})
export class DivisionsDataStoreModule { }