export const DropdownSkeletonStyle = {
  'border-radius': '0.25rem',
  height: '2.5rem',
  'margin-bottom': '0',
  width: '13.125rem'
};

export const ButtonSkeletonStyle = {
  'border-radius': '0.25rem',
  height: '2rem',
  'margin-bottom': '0',
  width: '4.375rem'
};
