import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MediaObserver } from '@angular/flex-layout';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Translations } from '@app/core/services/i18n/translations.service';
import { Subject, Subscription } from 'rxjs';

@Component({
  selector: 'app-confirm-change-dialog',
  templateUrl: './confirm-change-dialog.component.html',
  styleUrls: ['./confirm-change-dialog.component.scss']
})
export class ConfirmChangeDialogComponent implements OnInit, OnDestroy {
  objectKeys = Object.keys;
  subscription: Subscription;
  private onDestroy$ = new Subject<void>();
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<ConfirmChangeDialogComponent>,
    public translations: Translations,
    public media: MediaObserver,
    private breakpointObserver: BreakpointObserver,
  ) { }

  ngOnInit(): void {
    this.subscription = this.breakpointObserver.observe(Breakpoints.XSmall).subscribe(breakPointState => {
      if (breakPointState.matches) {
        this.dialogRef.updateSize('100vw', '100dvh');
        const dialogWrapper: any = document.querySelector('.cdk-overlay-pane');
        dialogWrapper?.style?.setProperty('max-height', '100dvh');
      } else {
        this.dialogRef.updateSize('20rem', 'auto');
        const sidenav: any = document.querySelector('.cdk-global-overlay-wrapper');
        sidenav?.style?.setProperty('justify-content', 'center');
        sidenav?.style?.setProperty('align-items', 'center');
      }
    });
  }

  onCancel() {
    this.dialogRef.close();
  }

  onConfirm() {
    this.dialogRef.close(true);
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
    this.subscription.unsubscribe();
  }
}
