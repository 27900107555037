
export enum SettingStatus {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
  ANY = 'ANY'
}

export enum OwnerType {
  COMPANY = 'COMPANY',
  USERPROFILE = 'USERPROFILE'
}

export enum SettingType {
  SETTINGTOGGLE = 'settingtoggle',
  PRODUCTTOGGLE = 'producttoggle',
  DROPDOWN = 'dropdown',
  TWINDROPDOWN = 'twindropdown'
}

export enum SettingArrayAction {
  REMOVE = 'remove',
  UPDATE = 'update',
  ATTACH = 'attach'
}

export enum EntityType {
  COMPANY = 0,
  DIVISION,
  ASSET,
  USER,
  GTCSELECTACCOUNT,
  GTCUSERMANAGEMENT,
  GTCASSETMANAGEMENT,
  GTCDRIVERMANAGEMENT,
}
