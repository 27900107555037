import { Injectable } from '@angular/core';
import { CoreCompanyApiService } from '@app/core/services/api/core-company-api.service';
import { concatMap } from 'rxjs/operators';
import { IDivision } from '@app/core/models/company.model';
import { Observable, of, ReplaySubject } from 'rxjs';
import { environment as env } from '@environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DivisionHierarchyService {

  nestedDivisionBuild = new Map<any, any>();
  private nestedDivisionHierarchy: IDivision[] = [];
  private currentNestedDepthLevel = 1;
  private allowNestedDivision$: ReplaySubject<boolean> = new ReplaySubject(1);

  constructor(
    private companyApiService: CoreCompanyApiService,
  ) { }

  getNestedDivisionsHierarchy(divisionId: string): Observable<IDivision[]> {
    this.currentNestedDepthLevel = 1;
    this.nestedDivisionHierarchy = [];
    return this.buildNestedDivisionsHierarchy(divisionId);
  }

  buildNestedDivisionsHierarchy(divisionId: string): Observable<IDivision[]> {
    return this.companyApiService.getDivisionById(divisionId).pipe(
      concatMap((division) => {
        this.nestedDivisionHierarchy.unshift(division);
        if (division.parentId !== null) {
          this.currentNestedDepthLevel++;
          return this.buildNestedDivisionsHierarchy(division.parentId);
        }
        const allowNestedDivision = this.currentNestedDepthLevel < env.nestedDepthLevelLimit;
        this.allowNestedDivision$.next(allowNestedDivision);
        this.allowNestedDivision$ = new ReplaySubject(1);
        return of(this.nestedDivisionHierarchy);
      }));
  }

  allowNestedDivision(): Observable<boolean> {
    return this.allowNestedDivision$;
  }
}
