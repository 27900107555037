import { toTitleCase } from '@app/core/utils';
import { Component, Input } from '@angular/core';
import { Translations } from '@app/core/services/i18n/translations.service';
import { RegionParserService } from '@app/core/services/region-parser.service';
import { I18nService, TranslateService } from '@zonar-ui/i18n';
import { SupportService } from '@app/core/services/support.service';
import { BulkImportRequirementsComponent } from '@app/shared/components/bulk-import-requirements-base/bulk-import-requirements-base';

@Component({
  selector: 'app-bulk-user-import-requirements',
  templateUrl: './bulk-user-import-requirements.component.html',
  styleUrls: ['./bulk-user-import-requirements.component.scss']
})
export class BulkUserImportRequirementsComponent extends BulkImportRequirementsComponent {
  @Input() hasManagedUser: boolean = false;

  constructor(
    public i18nService: I18nService,
    public translateService: TranslateService,
    public translations: Translations,
    public regionParserService: RegionParserService,
    public supportService: SupportService,
  ) {
    super(i18nService, translateService,translations, regionParserService, supportService);
  }

  initGuide() {
    const translateUserFieldsString = 'supportModule.importGuide.user.fields';
    const translateUserDescString = 'supportModule.importGuide.user.guideDescription';
    this.translateService.get([
      translateUserFieldsString,
      translateUserDescString
    ]).subscribe(translated => {
      const userGuideTranslated: any = this.regionParserService.getParsedBucketKeyWords(translateUserDescString, Object.keys(translated[translateUserDescString]));
      this.guideDesc = {
        subDescPrefix: userGuideTranslated.subDescPrefix,
        subDescSuffix: userGuideTranslated.subDescSuffix
      };
      const fieldTranslated: any = this.regionParserService.getParsedBucketKeyWords(translateUserFieldsString, Object.keys(translated[translateUserFieldsString]));
      this.guideList.push(
        {
          fieldName: fieldTranslated.givenNameField,
          requirement: fieldTranslated.givenNameRequirement,
          required: true
        },
        {
          fieldName: fieldTranslated.familyNameField,
          requirement: fieldTranslated.familyNameRequirement,
          required: true
        },
        {
          fieldName: fieldTranslated.userIdField,
          requirement: fieldTranslated.userIdRequirement,
          required: true
        },
        {
          fieldName: toTitleCase(fieldTranslated.divisionField),
          requirement: fieldTranslated.divisionRequirement,
          required: true
        },
        {
          fieldName: fieldTranslated.preferLanguageField,
          requirement: fieldTranslated.preferLanguageRequirement,
        }
      );

      const managedUserGuide = {
        fieldName: toTitleCase(fieldTranslated.zonarManagedField),
        requirement: fieldTranslated.zonarManagedRequirement,
        required: true
      };

      if (this.hasManagedUser) {
        this.guideList.splice(3,0, managedUserGuide);
      }
    });
  }

  getFilePathDownload() {
    this.urlDownload = this.supportService.getFilePathDownloadUser();
  }

}
