
export const VALIDATION_PATTERNS = {
  UPPERCASE_ALPHANUMERIC: /^[A-Z0-9]*$/,
  NUMERIC: /^[0-9]*$/,
  ALPHANUMERIC: /^[a-zA-Z0-9]*$/,
  EMAIL: /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  UUID: /\/([0-9a-f]{8}\b-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-\b[0-9a-f]{12})$/
};
export const CompanyValidationSchema = {
  shortName: {
    minLength: 4,
    maxLength: 9,
    require: true,
    pattern: VALIDATION_PATTERNS.ALPHANUMERIC,
    unique: false
  },
  name: {
    minLength: 3,
    maxLength: null,
    require: true,
    pattern: null,
    unique: true
  },
  country: {
    minLength: null,
    maxLength: null,
    require: true,
    pattern: null,
    unique: false
  },
  street: {
    minLength: null,
    maxLength: null,
    require: true,
    pattern: null,
    unique: false
  },
  zip: {
    minLength: null,
    maxLength: null,
    require: true,
    pattern: null,
    unique: false
  },
  city: {
    minLength: null,
    maxLength: null,
    require: true,
    pattern: null,
    unique: false
  },
  state: {
    minLength: null,
    maxLength: null,
    require: true,
    pattern: null,
    unique: false
  },
  defaultLanguage: {
    minLength: null,
    maxLength: null,
    require: true,
    pattern: null,
    unique: false
  }
};

export const DivisionValidationSchema = {
  name: {
    minLength: 3,
    maxLength: null,
    require: true,
    pattern: null,
    unique: true
  }
};

export const AssetValidationSchema = {
  name: {
    minLength: 3,
    maxLength: 20,
    require: true,
    pattern: /^[a-zA-Z0-9_~`!@#$%^&*+=:;<>.?\\/(){}[\]|'-\s]*$/,
    unique: true
  },
  type: {
    minLength: null,
    maxLength: null,
    require: true,
    pattern: null,
    unique: false
  },
  subType: {
    minLength: null,
    maxLength: null,
    require: true,
    pattern: null,
    unique: false
  },
  vin: {
    minLength: 1,
    maxLength: 17,
    require: false,
    pattern: /[A-HJ-NPR-Z0-9]{17}/,
    unique: false
  },
  powerUnitNumber: {
    minLength: 1,
    maxLength: 10,
    require: false,
    pattern: VALIDATION_PATTERNS.UPPERCASE_ALPHANUMERIC,
    unique: true,
  },
  plate: {
    minLength: 1,
    maxLength: 20,
    require: false,
    pattern: null,
    unique: true
  },
  jurisdiction: {
    minLength: 1,
    maxLength: 100,
    require: false,
    pattern: null,
    unique: false
  },
  divisions: {
    minLength: null,
    maxLength: null,
    require: true,
    pattern: null,
    unique: false
  },
  cviID: {
    minLength: null,
    maxLength: null,
    require: false,
    pattern: VALIDATION_PATTERNS.NUMERIC,
    unique: true
  }
};

export const UserValidationSchema = {
  firstName: {
    minLength: null,
    maxLength: null,
    require: true,
    pattern: null,
    unique: false
  },
  lastName: {
    minLength: null,
    maxLength: null,
    require: true,
    pattern: null,
    unique: false
  },
  email: {
    minLength: null,
    maxLength: null,
    require: true,
    pattern: VALIDATION_PATTERNS.EMAIL,
    unique: true
  },
  userId: {
    minLength: null,
    maxLength: 50,
    require: true,
    pattern: VALIDATION_PATTERNS.ALPHANUMERIC,
    unique: true
  },
  defaultLanguage: {
    minLength: null,
    maxLength: null,
    require: true,
    pattern: null,
    unique: false
  }
};

export const VALIDATION_ERROR = {
  duplicate: 'duplicate',
  required: 'required',
};
