import { ListManagementType } from "@app/core/consts/list-management.const";
import { PolicyListTableColDef } from "@app/core/consts/policy.const";
import { ListManagement } from "@app/core/models/list-management";
import { ZonarUITableCellType as cellType, ZonarUITableCellType } from '@zonar-ui/table';
import { FeatureToggleService } from "@app/shared/services/feature-toggle.service";
import { FeatureKeys } from "@app/core/consts/app.const";

export function getPolicyListCompanyColumnConfig(featureToggleService: FeatureToggleService) {
    return {
    [PolicyListTableColDef.CHECK_BOX]: { 'max-width': '5%' },
    [PolicyListTableColDef.POLICY_NAME]: featureToggleService.isFeatureEnabled(FeatureKeys.POLICY.EDIT) ?
      { 'max-width': '40%' } :
      { 'max-width': '65%' },
    [PolicyListTableColDef.USER_COUNT]: featureToggleService.isFeatureEnabled(FeatureKeys.POLICY.EDIT) ?
      { 'max-width': '10%', 'justify-content': 'right' } :
      { 'max-width': '30%', 'justify-content': 'right', 'margin-right': '1.313rem' },
    [PolicyListTableColDef.EDIT]: { 'max-width': '45%', 'justify-content': 'right', 'margin-right': '1.313rem' },
  };
}

export function getPolicyListColumns(translated, featureToggleService: FeatureToggleService, hasUpdatePoliciesPerm: boolean) {
  return [
    {
      columnDef: PolicyListTableColDef.POLICY_NAME,
      header: translated.tableHeaderName.toUpperCase(),
      type: ZonarUITableCellType.Portal,
      sortable: true,
    },
    {
      columnDef: PolicyListTableColDef.USER_COUNT,
      header: translated.tableHeaderUserCount.toUpperCase(),
      sortable: false,
      cell: (row: any) => row.userCount ?? '-',
    },
    {
      columnDef: PolicyListTableColDef.EDIT,
      header: '',
      type: cellType.Text,
      sortable: false,
      cell: (row) => row.grants.find(grant => grant.roles.find(role => role.restricted)) ? null : translated.tableCellEditLink,
      hidden: !featureToggleService.isFeatureEnabled(FeatureKeys.POLICY.EDIT) || !hasUpdatePoliciesPerm
    },
  ];
}

export function getPolicyListSearchActionView(roleListTranslated): ListManagement {
  return {
    type: ListManagementType.Search,
    searchManagement: {
      controlName: 'name',
      searchQuery: [PolicyListTableColDef.POLICY_NAME],
      searchPlaceHolder: roleListTranslated.searchPlaceholder,
    }
  };
}

export function getPolicyListCreatePolicyButtonManagement(menuList, roleListTranslated, featureToggleService: FeatureToggleService, triggerFunc: () => void) {
  return {
    buttonName: featureToggleService.isFeatureEnabled(FeatureKeys.POLICY.CREATE) ? roleListTranslated.newRoleButton : null,
    menuName: '',
    hasMenu: menuList.length ? true : false,
    menuList: menuList,
    isDisabledButton: true,
    customTrigger: triggerFunc,
  };
}

export const PolicyListCreatePolicyButton: ListManagement = {
  type: ListManagementType.Button,
  buttonManagement: {
    isDisabledButton: false,
    isOpenDialog: false
  },
};

export function getDisplayPolicyData(data: any, roleListTranslated, cardViewActions: any) {
  return {
    title: data.name,
    userText: roleListTranslated ? roleListTranslated.tableHeaderUserCount : '',
    userCount: data.userCount,
    viewText: roleListTranslated ? roleListTranslated.tableCellView : '',
    viewActionTrigger: cardViewActions?.view,
    editText: roleListTranslated ? roleListTranslated.tableCellEditLink : '',
    editActionTrigger: Boolean(data?.grants?.find(grant => grant?.roles?.find(role => role?.restricted))) ? null : cardViewActions?.edit,
    error: data?.error || false,
    row: data,
  };
}
