/* angular */
import { createAction, props } from '@ngrx/store';
import { ErrorAPIPayload, GetCompanyAddressesPayload, GetCompanyAddressesSuccessfulPayload, GetCompanyAssociatedProductsSuccessfulPayload, GetCompanyListPayload, GetCompanyListSuccessfulPayload, GetCompanyPayload, GetCompanySuccessfulPayload } from '@app/shared/data-stores/companies/companies-data-store.interface';

/* action types */
export enum ActionTypes {
  InitCompanies = '[COMPANIES] InitCompanies',
  ResetCompaniesState = '[COMPANIES] ResetCompaniesState',
  /* company list action types */
  GetCompanyList = '[COMPANIES] GetCompanyList',
  GetCompanyListSuccessful = '[COMPANIES] GetCompanyListSuccessful',
  GetCompanyListFail = '[COMPANIES] GetCompanyListFail',
  /* company view action types */
  GetCompany = '[COMPANIES] GetCompany',
  GetCompanySuccessful = '[COMPANIES] GetCompanySuccessful',
  GetCompanyFail = '[COMPANIES] GetCompanyFail',
  GetCompanyAddresses = '[COMPANIES] GetCompanyAddresses',
  GetCompanyAddressesSuccessful = '[COMPANIES] GetCompanyAddressesSuccessful',
  GetCompanyAddressesFail = '[COMPANIES] GetCompanyAddressesFail',
  /* company product action types */
  CreateCompanyAssociatedProduct = '[COMPANIES] CreateCompanyAssociatedProduct',
  CreateCompanyAssociatedProductSuccessful = '[COMPANIES] CreateCompanyAssociatedProductSuccessful',
  CreateCompanyAssociatedProductFail = '[COMPANIES] CreateCompanyAssociatedProductFail',
  DeleteCompanyAssociatedProduct = '[COMPANIES] DeleteCompanyAssociatedProduct',
  DeleteCompanyAssociatedProductSuccessful = '[COMPANIES] DeleteCompanyAssociatedProductSuccessful',
  DeleteCompanyAssociatedProductFail = '[COMPANIES] DeleteCompanyAssociatedProductFail',
  GetCompanyAssociatedProducts = '[COMPANIES] GetCompanyAssociatedProducts',
  GetCompanyAssociatedProductsSuccessful = '[COMPANIES] GetCompanyAssociatedProductsSuccessful',
  GetCompanyAssociatedProductsFailure = '[COMPANIES] GetCompanyAssociatedProductsFailure',
};

/* actions */
export const initCompanies = createAction(ActionTypes.InitCompanies);
export const resetCompaniesState = createAction(ActionTypes.ResetCompaniesState);
/* company list action types */
export const getCompanyList = createAction(ActionTypes.GetCompanyList, props<{ payload: GetCompanyListPayload }>());
export const getCompanyListSuccessful = createAction(ActionTypes.GetCompanyListSuccessful, props<{ payload: GetCompanyListSuccessfulPayload }>());
export const getCompanyListFail = createAction(ActionTypes.GetCompanyListFail, props<{ payload: ErrorAPIPayload }>());
/* company view action types */
export const getCompany = createAction(ActionTypes.GetCompany, props<{ payload: GetCompanyPayload }>());
export const getCompanySuccessful = createAction(ActionTypes.GetCompanySuccessful, props<{ payload: GetCompanySuccessfulPayload }>());
export const getCompanyFail = createAction(ActionTypes.GetCompanyFail, props<{ payload: ErrorAPIPayload }>());
export const getCompanyAddresses = createAction(ActionTypes.GetCompanyAddresses, props<{ payload: GetCompanyAddressesPayload }>());
export const getCompanyAddressesSuccessful = createAction(ActionTypes.GetCompanyAddressesSuccessful, props<{ payload: GetCompanyAddressesSuccessfulPayload }>());
export const getCompanyAddressesFail = createAction(ActionTypes.GetCompanyAddressesFail, props<{ payload: ErrorAPIPayload }>());
/* company product action types */
export const createCompanyAssociatedProduct = createAction(ActionTypes.CreateCompanyAssociatedProduct, props<{ payload: {companyId: string, productId: string} }>());
export const createCompanyAssociatedProductSuccessful = createAction(ActionTypes.CreateCompanyAssociatedProductSuccessful);
export const createCompanyAssociatedProductFail = createAction(ActionTypes.CreateCompanyAssociatedProductFail, props<{ payload: {error: any} }>());
export const deleteCompanyAssociatedProduct = createAction(ActionTypes.DeleteCompanyAssociatedProduct, props<{ payload: {companyId: string, productIds: string[]} }>());
export const deleteCompanyAssociatedProductSuccessful = createAction(ActionTypes.DeleteCompanyAssociatedProductSuccessful);
export const deleteCompanyAssociatedProductFail = createAction(ActionTypes.DeleteCompanyAssociatedProductFail, props<{ payload: {error: any} }>());
export const getCompanyAssociatedProducts = createAction(ActionTypes.GetCompanyAssociatedProducts, props<{ payload: {companyId: string, params: {}} }>());
export const getCompanyAssociatedProductsSuccessful = createAction(ActionTypes.GetCompanyAssociatedProductsSuccessful, props<{ payload: GetCompanyAssociatedProductsSuccessfulPayload }>());
export const getCompanyAssociatedProductsFailure= createAction(ActionTypes.GetCompanyAssociatedProductsFailure, props<{ payload: {error: any} }>());

