<div class="dialog-overlay-spinner" *ngIf="!hasLoadSuccess">
  <app-overlay-spinner [diameter]="120" [mode]="'indeterminate'"></app-overlay-spinner>
</div>
<div class="create-edit-view-container">
  <app-create-edit-with-tree-mobile *ngIf="media.isActive('lt-md')"
    [data]="createEditViewData"
    (confirmEmitter)="onConfirm($event)">
  </app-create-edit-with-tree-mobile>

  <app-create-edit-with-tree *ngIf="!media.isActive('lt-md')"
    [data]="createEditViewData"
    (confirmEmitter)="onConfirm($event)">
  </app-create-edit-with-tree>
</div>
