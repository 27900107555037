import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ZonarUIMaterialModule } from '@zonar-ui/material';
import { SearchComponent } from '@app/shared/components/search/search.component';



@NgModule({
  declarations: [SearchComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    ZonarUIMaterialModule
  ],
  exports: [SearchComponent]
})
export class SearchComponentModule { }
