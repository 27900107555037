<div class="generate-password-container" fxLayout="column" fxLayoutAlign="space-between start">
  <div class="dialog-title" fxFill>
    <span>{{data.title}}</span>
  </div>
  <div class="dialog-body-text" fxLayout="row"
    fxLayoutAlign="space-between center" fxFill>
    <span>{{data.bodyText}}</span>
  </div>

  <div class="description" fxLayout="column" fxFill>
    <span class="title-description">{{data.titleDescription}}</span>
    <span class="body-description">{{data.description}}</span>
  </div>

  <div class="button-container" fxFill fxLayout="row" fxLayoutAlign="end end">
    <button class="continue-button" mat-raised-button type="submit"
      (click)="onConfirm()">{{data?.textCloseButton}}</button>
  </div>
</div>
