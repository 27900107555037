import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { ComponentViewMode, CreateEditViewFeature } from '@app/core/consts/app.const';
import { ICreateEditViewTree } from '@app/core/models/policy.model';
import { Translations } from '@app/core/services/i18n/translations.service';
import { RegionParserService } from '@app/core/services/region-parser.service';
import { createPolicy, resetCreateUpdatePolicy, updatePolicy } from '@app/shared/data-stores/policy/policy-data-store.action';
import { selectCreateUpdateSuccess, selectPolicies } from '@app/shared/data-stores/policy/policy-data-store.selects';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { I18nService } from '@zonar-ui/i18n';
import { Subject, combineLatest, of } from 'rxjs';
import { concatMap, filter, take, takeUntil } from 'rxjs/operators';
import { cloneDeep } from 'lodash';
import { PolicyDataService } from '@app/core/services/data/policy-data.service';
import { selectCompanyGroups } from '@app/shared/data-stores/group/group-data-store.selects';
import { TreeNode } from '@app/shared/components/tree-base/tree-node.model';
import { PermissionsService } from '@zonar-ui/auth';
import { ZonarUiNotificationsService } from '@zonar-ui/notifications';
import { notificationDuration } from '@app/core/utils';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { CreateEditViewDialogData } from '@app/core/models/dialog.model';
import { selectAllApplications, selectManagedApplications } from '@app/shared/data-stores/products/products-data-store.selects';
import { selectHasAllowManaged, selectHasLegacyOperator } from '@app/shared/data-stores/setting/setting-data-store.selects';
import { MediaObserver } from '@angular/flex-layout';
import { PolicyErrorCode } from '@app/core/consts/policy.const';
@Component({
  selector: 'app-create-edit-view-policy-dialog',
  templateUrl: './create-edit-view-policy-dialog.component.html',
  styleUrls: ['./create-edit-view-policy-dialog.component.scss']
})
export class CreateEditViewPolicyDialogComponent implements OnInit, OnDestroy {
  createEditViewData: ICreateEditViewTree;
  applications = [];
  initPolicyInfo = null;
  appTreeNode: TreeNode[];
  translated: any = {};
  private onDestroy$ = new Subject<void>();
  translationsNotLoaded = !!this.translateService.store.translations;
  hasLoadSuccess = false;
  isZonarUser = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public dialogData: CreateEditViewDialogData,
    public translateService: TranslateService,
    public translations: Translations,
    public parsingService: RegionParserService,
    private i18nService: I18nService,
    private store: Store<any>,
    private policyDataService: PolicyDataService,
    private permissionsService: PermissionsService,
    private zonarUiNotificationsService: ZonarUiNotificationsService,
    private dialogRef: MatDialogRef<any>,
    public media: MediaObserver,
  ) {
    if (this.translationsNotLoaded) {
      this.translateService.use(this.i18nService.getLanguageInUse())
        .pipe(takeUntil(this.onDestroy$)).subscribe(() => this.translationsNotLoaded = false);
    }
  }

  ngOnInit(): void {
    this.initPolicyData();
    this.getIsZonarUser();
  }

  getIsZonarUser() {
    this.permissionsService.getIsZonarUser().pipe(takeUntil(this.onDestroy$)).subscribe(isZonarUser => {
      this.isZonarUser = isZonarUser;
    });
  }

  buildAppList(appList) {
    this.applications = cloneDeep(appList);
    this.appTreeNode = this.policyDataService.buildAppTreeNode(this.applications, this.translated);
  }

  initPolicyData() {
    this.hasLoadSuccess = false;

    combineLatest([
      this.translateService.get('role'),
      this.store.select(selectPolicies),
      this.store.select(selectManagedApplications),
      this.store.select(selectCompanyGroups),
      this.store.select(selectHasAllowManaged),
      this.store.select(selectHasLegacyOperator),
    ]).pipe(takeUntil(this.onDestroy$),
      filter(([translated, policyList, companyApps, companyGroups, hasAllowManaged, hasLegacyOperator]) => Boolean(translated) && Boolean(policyList) && Boolean(companyApps) && Boolean(companyGroups) && hasAllowManaged !== null && hasLegacyOperator !== null),
      take(1),
      concatMap(([translated, policyList, companyApps, companyGroups, hasAllowManaged, hasLegacyOperator]) => {
        this.translated = translated;
        const appList = cloneDeep(companyApps)?.filter(app => app.roles = app.roles.filter(role => !role.restricted)) || [];

        this.buildAppList(appList);

        if (this.dialogData.id) {
          this.policyDataService.buildCompanyLevelCapaSelectedList(this.dialogData.policy, appList);
          this.initPolicyInfo = { ...this.policyDataService.updateInitPolicyInfo(this.dialogData.policy, companyGroups, this.isZonarUser, this.applications) };
        }

        const result: ICreateEditViewTree = {
          componentMode: this.dialogData.mode,
          dataList: this.dialogData.id ? this.policyDataService.buildInitStateApplications(this.dialogData.policy, this.appTreeNode) : cloneDeep(this.appTreeNode),
          enableDeterminate: true,
          initInfo: this.initPolicyInfo,
          disableSubItemSelection: false,
          companyLevelCapaSelectedList: this.policyDataService.companyLevelCapaSelectedList?.length ? this.policyDataService.companyLevelCapaSelectedList : null,
          allRoleCompanyLevelCapaList: this.policyDataService.allRoleCompanyLevelCapaList,
          translated: {
            createTitle: translated.createRole?.title,
            editTitle: translated.editRole?.title,
            viewTitle: translated.viewRole?.title,
            dataName: translated.name,
            namePlaceholder: translated.name,
            searchPlaceholder: translated.applicationSearchPlaceholder,
            nameDes: translated.roleNameHelpText,
            errorNameDuplicated: translated.errorNameDuplicated,
            warningData: translated.warningData,
            createMessage: translated.createRole?.message,
          },
          isDialog: false,
          dataValidation: policyList?.map(item => ({ name: item.name })) || [],
          feature: CreateEditViewFeature.POLICY,
          companySettings: {
            hasAllowManaged,
            hasLegacyOperator,
          }
        };

        this.hasLoadSuccess = true;
        return of(result);
      })).subscribe((result) => {
        this.createEditViewData = result;
      }, (err) => {
        this.createEditViewData = null;
        this.hasLoadSuccess = true;
      });
  }

  onConfirm(confirmData: any) {
    this.hasLoadSuccess = false;
    const { data } = confirmData;
    if (data.componentMode === ComponentViewMode.CREATE) {
      this.store.dispatch(createPolicy({
        payload: this.policyDataService.buildDataToCreatePolicy(confirmData, this.dialogData.companyId, this.applications)
      }));
    } else {
      this.store.dispatch(updatePolicy({
        payload: {
          policyId: data?.initInfo?.id,
          confirmData,
          applications: this.applications,
        }
      }));
    }

    this.store.select(selectCreateUpdateSuccess).pipe(filter(createUpdatePolicy => createUpdatePolicy?.isSuccess !== null), takeUntil(this.onDestroy$)).subscribe(createUpdatePolicy => {
      if (createUpdatePolicy?.isSuccess) {
        this.onOpenSuccessNotification();
      } else {
        this.onOpenErrorNotification(createUpdatePolicy.error?.msgId === PolicyErrorCode.NON_MANAGED_USER ? this.translated?.managedNotificationFailMessage : null);
      }
      this.dialogRef.close({ reload: true });
      this.store.dispatch(resetCreateUpdatePolicy());
    });
  };

  onOpenSuccessNotification() {
    this.zonarUiNotificationsService.openSuccess(
      '',
      this.translated?.notificationSuccessMessage,
      notificationDuration
    );
  }

  onOpenErrorNotification(message: string = this.translated?.notificationFailMessage) {
    this.zonarUiNotificationsService.openError(
      '',
      message,
      notificationDuration
    );
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }
}
