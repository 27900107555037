import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-create-edit-with-tree-page',
  templateUrl: './create-edit-with-tree-page.component.html',
  styleUrls: ['./create-edit-with-tree-page.component.scss']
})
export class CreateEditWithTreePageComponent implements OnInit {
  inputData = this.router.getCurrentNavigation()?.extras?.state?.data || {};
  constructor(
    private router: Router
  ) { }

  ngOnInit(): void {
    if (!Object.keys(this.inputData).length) {
      this.router.navigateByUrl('/demo-page');
    }
  }
}
