import { createFeatureSelector, createSelector } from '@ngrx/store';
import { AssetsDataStore, featureKey } from './assets-data-store.reducer';

export const selectAssetState = createFeatureSelector<AssetsDataStore>(featureKey);

export const selectAssetFilterParams = createSelector(selectAssetState, state => {
  return state?.filterParams;
});

export const selectRecordRows = createSelector(selectAssetState, state => {
  return state?.importAssets?.uploadedData?.recordRows;
});

export const selectHeaderArray = createSelector(selectAssetState, state => {
  return state?.importAssets?.uploadedData?.headerArray;
});

export const selectProgressValue = createSelector(selectAssetState, state => {
  return state?.importAssets?.importedData?.processedRowsCount;
});

export const selectSuccessfullyImportedAssets = createSelector(selectAssetState, state => {
  return state?.importAssets?.importedData?.successfullyImportedAssets;
});
export const selectErroredAssets = createSelector(selectAssetState, state => {
  return state?.importAssets?.importedData?.erroredAssets;
});

//#region Bulk Asset Activate/Deactivate Selects
export const selectBulkStatusAssetProgressValue = createSelector(selectAssetState, state => {
  return state?.updateAssetsStatus?.processedAssetsCount;
});
export const selectBulkStatusAssetSuccess = createSelector(selectAssetState, state => {
  return state?.updateAssetsStatus?.successfullyUpdateStatusAssetIds;
});
export const selectBulkStatusAssetFailure = createSelector(selectAssetState, state => {
  return state?.updateAssetsStatus?.failureUpdateStatusAssets;
});

export const selectAssets = createSelector(selectAssetState, state => {
  return state?.assets;
});

export const selectTotalAssets = createSelector(selectAssetState, state => {
  return state?.totalAssets;
});

export const selectCombineAssetList = createSelector(selectAssetState, state => {
  return state?.combineAssetList;
});

export const selectAssetType = createSelector(selectAssetState, state => {
  return state?.assetType;
});

export const selectAssetLoadingState = createSelector(selectAssetState, state => {
  return state?.isLoading;
});

export const selectActiveAssetData = createSelector(selectAssetState, state => state?.activeAsset?.data);
export const selectActiveAssetHomeLocation = createSelector(selectAssetState, state => state?.activeAsset?.homeLocation);
export const selectIsActiveAssetLoading = createSelector(selectAssetState, state => state?.activeAsset?.isLoading);
export const selectActiveAssetError = createSelector(selectAssetState, state => state?.activeAsset?.error);

export const selectActiveAssetUpdateStatus = createSelector(selectAssetState, state => state?.activeAsset?.updateStatus);
export const selectActiveAssetUpdateError = createSelector(selectAssetState, state => state?.activeAsset?.updateError);

export const selectUpdateBulkAssetsTotal = createSelector(selectAssetState, state => state?.updateBulkAssets?.total);
export const selectUpdateBulkAssetsProcessed = createSelector(selectAssetState, state => state?.updateBulkAssets?.processed);
export const selectUpdateBulkAssetsSucceed = createSelector(selectAssetState, state => state?.updateBulkAssets?.succeedUpdated);
export const selectUpdateBulkAssetsFailed = createSelector(selectAssetState, state => state?.updateBulkAssets?.failedUpdated);

