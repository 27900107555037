import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss']
})
export class SearchComponent {
  @Input() searchFormControl: FormControl;
  @Input() placeHolder: string;
  @Output() resetSearch = new EventEmitter<void>();

  resetSearchBox() {
    this.searchFormControl.setValue('');
    this.resetSearch.emit();
  }
  
}

