import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { environment as env } from '@environments/environment';
import { SettingParams } from '@app/core/models/setting.model';
import { EntityApiBaseService } from '@app/core/services/api/entity-api-base.service';


@Injectable({
  providedIn: 'root'
})

export class SettingApiService {
  private SETTING_RESOURCE = 'settings';

  // TODO: Temporary adding the text "beta2" into url as workaround.
  private apiUrl = env.coreEntityApiBase.url + 'beta2';

  constructor(
    private http: HttpClient,
    private entityApiBaseService: EntityApiBaseService
  ) { }

  getCompanySetting(params: any) {
    const url = `${this.apiUrl}/${this.SETTING_RESOURCE}`;
    return this.entityApiBaseService.getData(url, params);
  }

  createSetting(params: SettingParams) {
    const url = `${this.apiUrl}/${this.SETTING_RESOURCE}`;
    return this.http.post(url, params, { observe: 'response', headers: this.entityApiBaseService.getRequiredLoadingHttpHeader() });
  }

  updateSetting(settingId: string, params: SettingParams) {
    const url = `${this.apiUrl}/${this.SETTING_RESOURCE}/${settingId}`;
    return this.http.patch(url, params, { observe: 'response', headers: this.entityApiBaseService.getRequiredLoadingHttpHeader() });
  }

  completeMigrationProcess(companyId: string, legacyAccountCodes: Array<string>, zonarOwnerId: string) {
    const params = {
      env: env.gcpMigration.migrationCompleteEnv,
      legacyAccountCodes,
      companyId,
      zonarOwnerId,
    };
    return this.http.get(env.gcpMigration.migrationCompleteUrl, { params });
  }

}
