import { Component, Inject, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { MatDialogRef } from '@angular/material/dialog';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { Translations } from '@app/core/services/i18n/translations.service';
import { IDivision } from '@app/core/models/company.model';
import { sortBy, sortType } from '@app/core/utils';
import { RegionParserService } from '@app/core/services/region-parser.service';
import { TreeBaseComponent } from '@app/shared/components/tree-base/tree-base.component';
import { TreeNode } from '@app/shared/components/tree-base/tree-node.model';
import { cloneDeep } from 'lodash';
import { UserProfile } from '@app/core/models/user.model';

@Component({
  selector: 'app-division-dialog',
  templateUrl: './division-dialog.component.html',
  styleUrls: ['./division-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class DivisionDialogComponent extends TreeBaseComponent implements OnInit, OnDestroy {
  treeContents: Array<TreeNode> = [];

  constructor(
    public dialogRef: MatDialogRef<DivisionDialogComponent>,
    public translateService: TranslateService,
    public translations: Translations,
    public parsingService: RegionParserService,
    private router: Router,
    @Inject(MAT_DIALOG_DATA) public data: any = {},
  ) {
    super();
  }

  ngOnInit(): void {
    if (!this.data?.allDivision?.length) {
      return;
    }

    this.buildTreeValue(this.prepareDataDivisionForModalDisplay(
      this.data.divisionSSProfile,
      sortBy(this.data.allDivision, 'name', sortType.ASC)
    ));

    this.treeControl.expandAll();
  }

  onCancelClick(): void {
    this.dialogRef.close();
  }

  navigateDivision(id) {
    this.dialogRef.close();
    this.router.navigate([`/company/${this.data.companyId}/division/${id}/`]);
  }

  getParsedKeyWords() {
    return this.parsingService.getParsedKeyWords();
  }

  prepareDataDivisionForModalDisplay(selfServiceProfiles: UserProfile, divisionListAll: Array<IDivision>): Array<any> {
    selfServiceProfiles = cloneDeep(selfServiceProfiles);
    divisionListAll = cloneDeep(divisionListAll);

    if (selfServiceProfiles.divisions.length) {
      selfServiceProfiles.divisions.forEach(divId => {
        divisionListAll.find(division => division.id === divId).selected = true;
      });

      return divisionListAll;
    }
    return divisionListAll.map((division) => {
      return {
        ...division,
        selected: true
      };
    });
  }

  buildTreeValue(treeContentsChanged: Array<TreeNode>): void {
    this.treeContents = cloneDeep(treeContentsChanged);
    this.treeNodeList = cloneDeep(treeContentsChanged);
    const treeItemCheckedList: Array<TreeNode> = [];
    this.initTreeData();
    this.treeControl.dataNodes.forEach((node: TreeNode) => {
      if (node.selected) {
        this.selectDescendants(node);
        const parent = this.getParentNode(node);
        // user profile have all division and parent selected, we dont need to expand parent
        if (this.data.divisionSSProfile.divisions.length && (parent && !parent.selected)) {
          this.selectAndDisableParents(node);
        }
        treeItemCheckedList.push(node);
      }
    });
    this.checklistSelection.select(...treeItemCheckedList);
  }

  selectDescendants(node): void {
    const descendants = this.treeControl.getDescendants(node);
    this.checklistSelection.select(...descendants);
    descendants.forEach((descendant: TreeNode) => {
      descendant.selected = true;
    });
  }

  initTreeData(): void {
    // Notify the change.
    this.treeChanged.next(this.buildNestedTree(this.treeContents));
  }

  selectAndDisableParents(node: TreeNode): void {
    let parent: TreeNode | null = this.getParentNode(node);
    while (parent) {
      parent.selected = true;
      parent.disabled = true;
      parent = this.getParentNode(parent);
    }
  }

}
