import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-bulk-edit-result',
  templateUrl: './bulk-edit-result.component.html',
  styleUrls: ['./bulk-edit-result.component.scss']
})
export class BulkEditResultComponent implements OnInit {
  itemErrorCount = 0;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<BulkEditResultComponent>,
  ) { }

  ngOnInit(): void {
    this.itemErrorCount = this.data.bulkEditResults.filter(item => item.hasError).length;
  }

  onConfirm() {
    this.dialogRef.close(true);
  }

}
