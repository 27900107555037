import { AssetPropertyDialogIds } from "./asset-property.const";

export enum DateFormat {
  NA = 'MM-dd-yyyy (hh:mm a)'
}

export enum PageName {
  ASSET = 'asset',
  USER = 'user'
}

export enum ComponentViewMode {
  CREATE = 'create',
  EDIT = 'edit',
  BULK_EDIT = 'bulk edit',
  VIEW = 'view'
}

export enum CreateEditViewFeature {
  POLICY = 'policy',
  TENANT = 'tenant'
}

export enum FourOhFourMode {
  NO_PERMISSION = 'no-permission',
  DEFAULT = 'default'
}

/* Language */
export enum Languages {
  ENGLISH_UK = 'en-GB',
  ENGLISH_US = 'en-US',
  FRENCH = 'fr-FR',
  GERMANY = 'de-DE',
  ITALIA = 'it-IT',
  SPAIN = 'es-ES',
}

/* Map Spanish to 'es-ES' and French to 'fr-FR' */
export const GenericLanguageMap = [
  {
    languageAbbreviation: 'es',
    useLanguage: 'es-ES'
  },
  {
    languageAbbreviation: 'fr',
    useLanguage: 'fr-FR'
  }
];

export const LegacySupportLanguages = ['en', 'de', 'it'];
export const LegacyLanguageMap = {
  en: { useLanguage: 'en-US' },
  de: { useLanguage: 'de-DE' },
  it: { useLanguage: 'it-IT' },
};

export const NALanguageCodes = ['en-US'];

export const EnumTranslationPath = 'enumValues';
export const LanguageTranslationPath = 'language';

export enum EnumTranslateCategory {
  ASSET_TYPE = 'assetType',
  ASSET_SUBTYPE = 'assetSubType',
  ASSET_FUELTYPE = 'fuelType',
  ASSET_CLASS = 'assetClass',
  COUNTRY_OF_REGISTRATION = 'countryOfRegistration'
}

export const MenuListLabel = {
  editSelected: 'edit selected',
  exportCSV: 'export CSV',
  deactivateProfile: 'deactivate profile',
};

export const MenuListProperty = {
  hasDisabledButtonTooltip: 'hasDisabledDisabledButtonTooltip',
  hasDisabledButton: 'hasDisabled'
};

export const HttpHeaderFields = {
  requiredLoading: 'requiredLoading'
};

export const FeatureKeys = {
  UI: {
    SIDE_NAV: 'app:side-nav', // enable this flag is to use zui-sidenav instead of zui-core
    GROUP_POLICY: 'app:group-policy'
  },
  COMPANY: {
    CREATE: 'company:create',
    EDIT: 'company:edit'
  },
  DIVISION: {
    LIST: 'division:list',
    DETAIL: 'division:detail',
    CREATE: 'division:create',
    EDIT: 'division:edit'
  },
  USER: {
    LIST: 'user:list',
    CREATE: 'user:create',
    EDIT: 'user:edit',
    GROUP_POLICY_INFO_EDIT: 'user:group-policy-info-edit',
    BULK_IMPORT: 'user:bulk-import',
    BULK_EDIT: 'user:bulk-edit',
  },
  ASSET: {
    LIST: 'asset:list',
    CREATE: 'asset:create',
    EDIT: 'asset:edit',
    BULK_IMPORT: 'asset:bulk-import',
    BULK_EDIT: 'asset:bulk-edit'
  },
  POLICY: {
    LIST: 'policy:list',
    DETAIL: 'policy:detail',
    CREATE: 'policy:create',
    EDIT: 'policy:edit'
  }
};

export const MOBILE_BREAK_POINT = 500;

// Migration dialog id list
export const MigrationDialogIds = {
  INDETERMINATE_DIALOG: 'indeterminate-dialog',
  MIGRATION_DIALOG: 'migration-dialog'
};


export const DialogIds = {
  ...MigrationDialogIds,
  ...AssetPropertyDialogIds,
  // Add other dialog id
};

/* common API calls */
export enum EGetGroupPolicyStatus {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
  ANY = 'ANY',
}

export enum EGetGroupPolicyOperator {
  AND = 'and',
  OR = 'or',
}

export enum EGroupPolicyStatus {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
  LOCKED = 'LOCKED',
};

export enum CoreEntityStatus {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
  LOCKED = 'LOCKED',
};

export const NOTIFICATION_DURATION = 7;
