export enum AddressType {
  BILLING = 'BILLING',
  SHIPPING = 'SHIPPING'
}

export enum CompanyTabs {
  DIVISIONS = 'DIVISIONS',
  ASSETS = 'ASSETS',
  USERS = 'USERS',
  ROLES = 'ROLES'
}

export enum CompanyStatus {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
  ANY = 'ANY'
}

export enum LocationStatus {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
  ANY = 'ANY'
}

export enum CompanyStatusIcon {
  ACTIVE = 'fiber_manual_record',
  INACTIVE = 'stop',
}

export enum CompanyListTable {
  ICON_STATUS = 'status',
  COMPANY_NAME = 'name'
}

export enum ProfileActions {
  DEACTIVATE = 'DEACTIVATE'
}

export enum ParamsURL {
  COMPANY = 'company',
  TAB = 'tab',
  DIVISION = 'division',
  USER = 'user'
}

export enum CompanyTablePropertyParams {
  PER_PAGE = 'per_page',
  PAGE = 'page',
  NAME = 'q',
  STATUS = 'status',
  SORT = 'sort'
}

export enum CompanySetting {
  MANAGED = 'ALLOW_MANAGED_USERS',
  DEALER = 'RSO_DEALER',
  SUB_DEALER = 'RSO_SUBDEALER',
  GROUP_POLICY_STATUS = 'GROUP_POLICY_STATUS',
  LEGACY_OPERATOR = 'LEGACY_OPERATOR',
}

export enum CompanySettingItemName {
  MANAGED = 'allowManagedUserToggle',
  EVIR = 'evir',
  PROFILE = 'profileActiveToggle',
  LANGUAGES = 'languageOption'
}

export const BulkImportSize = {
  width: '60rem',
  height: '42rem'
};

export enum CompanyMode {
  GROUP_POLICY = 'group-policy',
  PROFILE = 'user-profile'
}

export const GroupTabIndex = {
  users: 0,
  roles: 1
};
