import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';

@Injectable({
  providedIn: 'root'
})
export class FeatureToggleService {

  constructor() { }

  /** Decides if the feature is enabled by a unique feature key */
  isFeatureEnabled(featureKey: string): boolean {
    /* if the feature is enabled for the company; enable it */
    /* TODO: Implement an API or service for toggling the features for companies */
    /* For now we don't have this implementation as a result we will mock and pass that */
    const isFeatureEnabledForSelectedCompany = false;
    if (isFeatureEnabledForSelectedCompany) {
      return true;
    }
    /* else if the feature is enabled for the environment; enable it */
    else if (environment?.features && environment.features[featureKey]) {
      return true;
    }
    /* else: feature is disabled */
    return false;
  }
}
