import { Injectable } from '@angular/core';
import {
  StyleDefinition,
  LayoutGapParent,
  LayoutGapStyleBuilder,
} from '@angular/flex-layout';

// This class for fix error on console: "Cannot read properties undefined (reading 'endwithds')"
@Injectable()
export class CustomLayoutGapStyleBuilder extends LayoutGapStyleBuilder {
  buildStyles(gapValue: string, parent: LayoutGapParent): StyleDefinition {
    return super.buildStyles(gapValue || '0 px', parent);
  }

  sideEffect(gapValue, styles, parent) {
    return super.sideEffect(gapValue || '0 px', styles, parent);
  }

}
