import { CoreCompanyApiService } from '@app/core/services/api/core-company-api.service';
import { takeUntil } from 'rxjs/operators';
import { ZonarUiNotificationsService } from '@zonar-ui/notifications';
import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { I18nService, TranslateService } from '@zonar-ui/i18n';
import { Translations } from '@app/core/services/i18n/translations.service';
import { Store } from '@ngrx/store';
import {
  selectHeaderArray,
  selectProgressValue,
  selectRecordRows,
  selectErroredUsers,
  selectSuccessfullyImportedUsers
} from '@app/shared/data-stores/users/users-data-store.selects';
import {
  bulkUserCSVDataUpload,
  bulkUsersCSVDataFileSelected,
  bulkUsersImportCompleted,
  bulkUsersImportResetUploadedData
} from '@app/shared/data-stores/users/users-data-store.actions';
import { BulkUserImportService } from '@app/shared/services/bulk-user-import.service';
import { RegionParserService } from '@app/core/services/region-parser.service';
import { cloneDeep } from 'lodash';
import { BulkImportComponent } from '@app/shared/components/bulk-import/bulk-import.component';
import { SupportService } from '@app/core/services/support.service';
import { IDivision } from '@app/core/models/company.model';

@Component({
  selector: 'app-bulk-user-import',
  templateUrl: './bulk-user-import.component.html',
  styleUrls: ['./bulk-user-import.component.scss']
})
export class BulkUserImportComponent extends BulkImportComponent implements OnInit, OnDestroy {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<BulkUserImportComponent>,
    public zonarUiNotificationsService: ZonarUiNotificationsService,
    public translateService: TranslateService,
    public translations: Translations,
    public i18nService: I18nService,
    public store: Store<any>,
    public supportService: SupportService,
    private coreCompanyApiService: CoreCompanyApiService,
    private bulkUserImportService: BulkUserImportService,
    private regionParserService: RegionParserService
  ) {
    super(translateService, i18nService, store, zonarUiNotificationsService, dialogRef);
    if (this.translationsNotLoaded) {
      this.translateService
        .use(this.i18nService.getLanguageInUse()).pipe(takeUntil(this.onDestroy$)).subscribe(() => this.translationsNotLoaded = false);
    }
  }

  ngOnInit(): void {
    this.initNotificationMessage();

    this.store.select(selectSuccessfullyImportedUsers).subscribe(importedUsers => {
      this.uploadedSuccessCount = importedUsers ? importedUsers.length : 0;
    });

    this.store.select(selectHeaderArray).subscribe(headerArray => {
      this.headerArray = headerArray ? headerArray : [];
    });
    this.store.select(selectErroredUsers).subscribe(erroredUsers => {
      this.errorList = erroredUsers ? cloneDeep(erroredUsers) : [];
      this.errCount = this.errorList.length;
    });
  }

  initNotificationMessage() {
    const notificationMessage = 'notificationMessage.user.bulkImport';
    const userHeaderRow = 'bulkImport.userHeaderRow';
    const allDivisionPath = 'bulkImport.allDivision';
    this.translateService.get([notificationMessage, userHeaderRow, allDivisionPath])
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(translatedText => {
        this.notificationMessage = translatedText[notificationMessage];
        this.bulkUserImportService.errorMessagesTranslated = translatedText[notificationMessage];
        this.bulkUserImportService.userMapping = this.regionParserService.getParsedBucketKeyWords(
          userHeaderRow, Object.keys(translatedText[userHeaderRow]));
        this.bulkUserImportService.allDivisionText = this.translateService.instant(
          allDivisionPath, this.regionParserService.getParsedKeyWords());
      });
  }

  onCancel() {
    if (!this.isUploading && !this.isFinishedUpload) {
      // Reset User State
      this.store.dispatch(bulkUsersImportResetUploadedData());
      this.dialogRef.close(false);
    } else if (this.isUploading && this.isFinishedUpload) {
      this.isUploading = false;
      this.isCancelProgressUpload = true;
      this.store.dispatch(bulkUsersImportCompleted({ payload: {} }));
    }
  }

  getRecordData() {
    this.coreCompanyApiService.getAllCompanyDivisions(this.data.companyId).subscribe((divisions: Array<IDivision>) => {
      this.store.dispatch(bulkUsersCSVDataFileSelected(
        {
          payload: {
            csvFile: this.file,
            companyId: this.data.companyId,
            divisions,
            hasManagedUser: this.data.hasManagedUser
          }
        }
      ));
    });
    this.store.select(selectRecordRows).subscribe(recordRows => {
      if (recordRows !== undefined) {
        this.records = recordRows;
      }
    });
  }

  onSubmitData() {
    this.bulkUserImportService.setUserDefaultLanguage(this.data.companyDefaultLanguage);
    this.bulkUserImportService.fetchingFieldOptions(this.data.companyId).subscribe(prePopulateData => {
      this.records.forEach((record, index) => {
        if (!this.isCancelProgressUpload) {
          this.store.dispatch(bulkUserCSVDataUpload(
            {
              payload: {
                record,
                index,
                headerArray: this.headerArray,
                prePopulateData,
                companyId: this.data.companyId
              }
            }
          ));
        }
      });
      this.checkUploadingProgress();
    });
  }

  checkUploadingProgress() {
    this.store.select(selectProgressValue).subscribe((progressValue) => {
      this.progressValue = progressValue;
      if (progressValue === this.records?.length) {
        this.isUploading = false;
        // Send action to complete importing user.
        this.store.dispatch(bulkUsersImportCompleted({ payload: {} }));
      }
    });
  }

  fileUploadEmit(file) {
    this.file = file;

    if (this.file === null) {
      this.isFinishedUpload = false;
      // Reset User State
      this.records = [];
      this.store.dispatch(bulkUsersImportResetUploadedData());
    } else {
      this.getRecordData();
    }
  }

  getFilePathDownload() {
    return this.supportService.getFilePathDownloadUser(this.data.hasManagedUser);
  }

  ngOnDestroy(): void {
    // Reset userDefaultLanguage after uploading done
    this.bulkUserImportService.setUserDefaultLanguage('');
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }
}
