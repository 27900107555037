import { Component, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import { RoleCapability } from '@app/core/models/user.model';
import { I18nService, TranslateService } from '@zonar-ui/i18n';
import { Translations } from '@app/core/services/i18n/translations.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-role-capabilities',
  templateUrl: './role-capabilities.component.html',
  styleUrls: ['./role-capabilities.component.scss']
})
export class RoleCapabilitiesComponent implements OnDestroy {
  @Input() roles: Array<RoleCapability> = [];
  @Input() applicationId: string;
  @Input() appStatus: boolean;
  @Output() roleChanges = new EventEmitter();

  translationsNotLoaded = !!this.translateService.store.translations;

  private onDestroy$ = new Subject<void>();

  constructor(
    private i18nService: I18nService,
    public translateService: TranslateService,
    public translations: Translations
  ) {
    if (this.translationsNotLoaded) {
      this.translateService
        .use(this.i18nService.getLanguageInUse()).pipe(takeUntil(this.onDestroy$)).subscribe(() => this.translationsNotLoaded = false);
    }
  }

  roleChecked(event, role) {
    const roleChecked = {
      hasChecked: event.checked,
      role,
      applicationId: this.applicationId
    };
    this.roles.find(r => r.id === role.id).status = event.checked;
    this.roleChanges.emit(roleChecked);
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }
}
