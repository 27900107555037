/*import angular */
import { createReducer, on } from '@ngrx/store';
import { PolicyState } from './policy-data-store.interface';
import * as PolicyDataStoreActions from './policy-data-store.action';

/* state key */
export const featureKey = 'policy';

/* initial state */
export const initialState: PolicyState = {
  isLoading: false,
  companyTotalPolicyCount: 0,
  policies: null,
  policy: null,
  policyTemplates: null,
  policyTemplate: null,
  createUpdateSuccess: null
};

export const reducer = createReducer(
  initialState,
  on(PolicyDataStoreActions.initPolicy, (state, action) => ({
    ...state
  })),
  on(PolicyDataStoreActions.resetPolicy, (state, action) => ({
    ...state,
    ...initialState
  })),

  on(PolicyDataStoreActions.getPolicies, (state, action) => ({
    ...state,
    isLoading: true,
    policies: null,
  })),

  on(PolicyDataStoreActions.getPoliciesSuccess, (state, action) => ({
    ...state,
    policies: action.payload.policies,
    isLoading: false
  })),

  on(PolicyDataStoreActions.getPoliciesFailure, (state, action) => ({
    ...state,
    isLoading: false
  })),

  on(PolicyDataStoreActions.getPolicyById, (state, action) => ({
    ...state,
    isLoading: true,
    policyDetail: null
  })),

  on(PolicyDataStoreActions.getPolicyByIdSuccess, (state, action) => ({
    ...state,
    policyDetail: action.payload.policyDetail,
    isLoading: false
  })),

  on(PolicyDataStoreActions.getPolicyByIdFailure, (state, action) => ({
    ...state,
    isLoading: false
  })),

  on(PolicyDataStoreActions.addApplicationToPolicy, (state, action) => ({
    ...state,
    isLoading: true
  })),

  on(PolicyDataStoreActions.addApplicationToPolicySuccess, (state, action) => ({
    ...state,
    policy: action.payload.policy,
    isLoading: false
  })),

  on(PolicyDataStoreActions.addApplicationToPolicyFailure, (state, action) => ({
    ...state,
    isLoading: false
  })),

  on(PolicyDataStoreActions.removeApplicationOfPolicy, (state, action) => ({
    ...state,
    isLoading: true
  })),

  on(PolicyDataStoreActions.removeApplicationOfPolicySuccess, (state, action) => ({
    ...state,
    policy: action.payload.policy,
    isLoading: false
  })),

  on(PolicyDataStoreActions.removeApplicationOfPolicyFailure, (state, action) => ({
    ...state,
    isLoading: false
  })),

  on(PolicyDataStoreActions.addCapabilitiesToAppOfPolicy, (state, action) => ({
    ...state,
    isLoading: true
  })),

  on(PolicyDataStoreActions.addCapabilitiesToAppOfPolicySuccess, (state, action) => ({
    ...state,
    policy: action.payload.policy,
    isLoading: false
  })),

  on(PolicyDataStoreActions.addCapabilitiesToAppOfPolicyFailure, (state, action) => ({
    ...state,
    isLoading: false
  })),

  on(PolicyDataStoreActions.removeCapabilitiesOfAppOfPolicy, (state, action) => ({
    ...state,
    isLoading: true
  })),

  on(PolicyDataStoreActions.removeCapabilitiesOfAppOfPolicySuccess, (state, action) => ({
    ...state,
    policy: action.payload.policy,
    isLoading: false
  })),

  on(PolicyDataStoreActions.removeCapabilitiesOfAppOfPolicyFailure, (state, action) => ({
    ...state,
    isLoading: false
  })),

  on(PolicyDataStoreActions.getPolicyTemplates, (state, action) => ({
    ...state,
    isLoading: true
  })),

  on(PolicyDataStoreActions.getPolicyTemplatesSuccess, (state, action) => ({
    ...state,
    policyTemplates: action.payload.policyTemplates,
    isLoading: false
  })),

  on(PolicyDataStoreActions.getPolicyTemplatesFailure, (state, action) => ({
    ...state,
    isLoading: false
  })),

  on(PolicyDataStoreActions.createPolicyTemplate, (state, action) => ({
    ...state,
    isLoading: true
  })),

  on(PolicyDataStoreActions.createPolicyTemplateSuccess, (state, action) => ({
    ...state,
    policyTemplate: action.payload.policyTemplate,
    isLoading: false
  })),

  on(PolicyDataStoreActions.createPolicyTemplateFailure, (state, action) => ({
    ...state,
    isLoading: false
  })),

  on(PolicyDataStoreActions.getPolicyTemplateById, (state, action) => ({
    ...state,
    isLoading: true
  })),

  on(PolicyDataStoreActions.getPolicyTemplateByIdSuccess, (state, action) => ({
    ...state,
    policyTemplate: action.payload.policyTemplate,
    isLoading: false
  })),

  on(PolicyDataStoreActions.getPolicyTemplateByIdFailure, (state, action) => ({
    ...state,
    isLoading: false
  })),

  on(PolicyDataStoreActions.updatePolicyTemplate, (state, action) => ({
    ...state,
    isLoading: true
  })),

  on(PolicyDataStoreActions.updatePolicyTemplateSuccess, (state, action) => ({
    ...state,
    policyTemplate: action.payload.policyTemplate,
    isLoading: false
  })),

  on(PolicyDataStoreActions.updatePolicyTemplateFailure, (state, action) => ({
    ...state,
    isLoading: false
  })),

  on(PolicyDataStoreActions.createPolicy, (state, action) => ({
    ...state,
    isLoading: true,
    createUpdateSuccess: null
  })),

  on(PolicyDataStoreActions.createPolicySuccess, (state, action) => ({
    ...state,
    policy: action.payload.policy,
    isLoading: false,
    createUpdateSuccess: true
  })),

  on(PolicyDataStoreActions.createPolicyFailure, (state, action) => ({
    ...state,
    isLoading: false,
    createUpdateSuccess: false
  })),

  on(PolicyDataStoreActions.updatePolicy, (state, action) => ({
    ...state,
    isLoading: true,
    createUpdateSuccess: null,
    policies: null
  })),

  on(PolicyDataStoreActions.updatePolicySuccess, (state, action) => ({
    ...state,
    isLoading: false,
    createUpdateSuccess: true,
    policies: null
  })),

  on(PolicyDataStoreActions.updatePolicyFailure, (state, action) => ({
    ...state,
    isLoading: false,
    createUpdateSuccess: false,
    policies: null
  })),
);
