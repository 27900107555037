import { EffectsModule } from '@ngrx/effects';
import { AssetPropertyEffect } from './asset-properties-data-store.effect';
import * as AssetPropertyReducer from './asset-properties-data-store.reducer';
import { StoreModule } from '@ngrx/store';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature(
      AssetPropertyReducer.featureKey,
      AssetPropertyReducer.reducer,
      { initialState: AssetPropertyReducer.initialState }
    ),
    EffectsModule.forFeature([ AssetPropertyEffect ])
  ],
  exports: [
    StoreModule,
    EffectsModule
  ]
})
export class AssetPropertyDataStoreModule { }
