import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { forkJoin, fromEvent } from 'rxjs';
import { mergeMap, catchError, tap, switchMap } from 'rxjs/operators';
import { CoreUserApiService } from '@app/core/services/api/core-user-api.service';
import { BulkUserImportService } from '@app/shared/services/bulk-user-import.service';

/* actions */
import * as UsersDataStoreActions from './users-data-store.actions';
import { STATUSAPI } from '@app/core/consts/status-api.const';
import { SupportService } from '@app/core/services/support.service';
import { EntityApiBaseService } from '@app/core/services/api/entity-api-base.service';
import papa from 'papaparse';
import { toTitleCase } from '@app/core/utils';
import { IUser, UserProfile } from '@app/core/models/user.model';
import { ErrorEditedUser } from './users-data-store.interface';
import { UserDataService } from '@app/core/services/data/user-data.service';
import { HttpResponse } from '@angular/common/http';

@Injectable()
export class UsersDataStoreEffects {
  newLineReg = /\r\n|\n/;
  createUserError = 'Create user error';
  createUserProfileError = 'Create user profile error';
  getUserEmailError = `Get user's email error`;
  editUserError = 'Edit User profile error';

  constructor(
    private actions$: Actions,
    private bulkUserImportService: BulkUserImportService,
    private userApiService: CoreUserApiService,
    private userDataService: UserDataService,
    public supportService: SupportService,
    public entityApiBaseService: EntityApiBaseService
  ) { }

  // #region CSV File and Data Validation

  /* when user selects a CSV file, validate the file */
  validateBulkImportCSVFile$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UsersDataStoreActions.bulkUsersCSVDataFileSelected),
      mergeMap((action) => {
        /* parse and validate CSV file */
        const { companyId, divisions, hasManagedUser } = action.payload;
        const params = {
          companyId,
          divisions,
          hasManagedUser
        };
        return action.payload.csvFile ? [UsersDataStoreActions.bulkUsersCSVParsingStarted({
          payload: {
            csvFile: action.payload.csvFile,
            params
          }
        })] : [];
      })
    )
  );
  parseCSV$ = createEffect(() => {
    let tmpPayload: any = {};
    return this.actions$.pipe(
      ofType(UsersDataStoreActions.bulkUsersCSVParsingStarted),
      tap(action => tmpPayload = action.payload),
      switchMap((action: any) => {
        const reader = new FileReader();
        reader.readAsText(action.payload.csvFile);
        return fromEvent(reader, 'loadend');
      }),
      mergeMap((fileReader: any) => {
        /* parse and validate CSV file */
        return this.entityApiBaseService.getData(
          this.supportService.getFilePathDownloadUser(tmpPayload.params.hasManagedUser), null, { responseType: 'text' })
          .pipe(mergeMap(fileData => {
            const headerSampleFile = papa.parse(fileData)?.data[0]?.map(item => item.trim());
            const resultRecords = [];
            const recordHashmap = {};
            const csvData = fileReader.target.result;
            const csvRecordsArray = papa.parse(csvData);
            const headers = csvRecordsArray?.data[0]?.map(item => item.trim());
            if (headers?.length !== headerSampleFile?.length) {
              return [UsersDataStoreActions.bulkUsersCSVRecordParsed({
                payload: {
                  resultRecords: null,
                  headers: null,
                  recordHashmap,
                  params: tmpPayload.params
                }
              })];
            }

            let emptyRow = 0;

            for (let record = 1; record < csvRecordsArray?.data?.length; record++) {
              const recordData = csvRecordsArray?.data[record];
              if (recordData[0] === '' && recordData?.length === 1) {
                emptyRow++;
              } else {
                recordHashmap[record - emptyRow] = recordData;

                const recordDataObject = {};
                for (let index = 0; index < headers?.length; index++) {
                  recordDataObject[headers[index]?.toLowerCase()] = recordData[index]?.trim();
                }
                resultRecords.push(recordDataObject);
              }
            }
            return [UsersDataStoreActions.bulkUsersCSVRecordParsed({
              payload: {
                resultRecords,
                headers,
                recordHashmap,
                params: tmpPayload.params
              }
            })];
          }));
      })
    );
  }
  );

  /* when the CSV file is validated, parsing row from string array to nested object */
  parsingRecordCSV$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UsersDataStoreActions.bulkUsersCSVRecordParsed),
      mergeMap((action) => {
        let recordParsed = [];
        if (action.payload.resultRecords) {
          action.payload.resultRecords.forEach(record => {
            recordParsed.push(this.bulkUserImportService.parseRecord(record, action.payload.params));
          });
        } else {
          recordParsed = null;
        }
        return [UsersDataStoreActions.bulkUsersValidateDuplicate({ payload: { recordParsed } })];
      })
    )
  );
  /* when the CSV file is validated, validate the data in the file: find duplicates and check required fields */
  validateBulkImportCSVData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UsersDataStoreActions.bulkUsersValidateDuplicate),
      mergeMap((action) => {
        // Add DeDup field to recordParsed
        const recordRows = this.bulkUserImportService.deDupRecord(action.payload.recordParsed);
        return [UsersDataStoreActions.bulkUsersCSVDataValidated({
          payload: { recordRows }
        })];
      })
    )
  );

  // #endregion CSV File and Data Validation

  prepareUserParams$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UsersDataStoreActions.importValidatedBulkUsersData),
      mergeMap((action: any) => {
        /* prepare user params for posting */
        const userParams = this.bulkUserImportService.translateUploadedRowToUser(action.payload.record);
        return [UsersDataStoreActions.importUser(
          {
            payload:
            {
              userParams,
              record: action.payload.record,
              companyId: action.payload.companyId,
              index: action.payload.index,
              prePopulateData: action.payload.prePopulateData
            }
          }
        )];
      })
    ));

  bulkUserCSVDataUpload$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UsersDataStoreActions.bulkUserCSVDataUpload),
      mergeMap((action) => {
        const { record, index, headerArray, prePopulateData, companyId } = action.payload;
        if (!record.hasOwnProperty('fieldDup') || !record.fieldDup.length) {
          if (this.bulkUserImportService.getMisMatchColumnAmount(record, headerArray) < 0) {
            // Save error row to ErrorList
            return [UsersDataStoreActions.saveError({
              payload: { index, message: this.bulkUserImportService.errorMessagesTranslated.misMatchingColumnAmount }
            })];
          }

          const validatedRecord = this.bulkUserImportService.validateRecord(record, prePopulateData);
          if (!validatedRecord.isValid) {
            // Save error row to ErrorList
            return [UsersDataStoreActions.saveError({
              payload: { index, message: `${toTitleCase(validatedRecord.fieldName)} ${this.bulkUserImportService.errorMessagesTranslated.validateErrorMessage}` }
            })];
          } else {
            return this.bulkUserImportService.processDupRecordAPI(validatedRecord.data, companyId).pipe(
              mergeMap((res: any) => {
                if (res.isDuplicateRecord) {
                  // Save error row to ErrorList
                  return [UsersDataStoreActions.saveError({
                    payload: { index, message: `${this.bulkUserImportService.errorMessagesTranslated.systemDuplicateMessage} ${toTitleCase(res.fieldName)}` }
                  })];
                } else {
                  return [UsersDataStoreActions.importValidatedBulkUsersData(
                    {
                      payload: {
                        record: res.record,
                        companyId,
                        index,
                        prePopulateData
                      }
                    }
                  )];
                }
              }),
              catchError(err => {
                return [UsersDataStoreActions.saveError({
                  payload: { index, message: err?.error?.message ? err.error.message : this.getUserEmailError }
                })];
              })
            );
          }
        } else {
          // Save error row to ErrorList
          return [UsersDataStoreActions.saveError({
            payload: { index, message: `${this.bulkUserImportService.errorMessagesTranslated.csvDuplicateMessage} ${toTitleCase(record.fieldDup?.join(' & '))}` }
          })];
        }
      })
    )
  );

  importUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UsersDataStoreActions.importUser),
      mergeMap((action) => {
        const { companyId, userParams, record, index, prePopulateData } = action.payload;
        return this.bulkUserImportService.createRecord(userParams).pipe(
          mergeMap((response: any) => {
            return this.bulkUserImportService.createProfile(response.body.id, record, companyId, prePopulateData, true).pipe(
              mergeMap((res: any) => {
                return [UsersDataStoreActions.importUserSuccessful(
                  {
                    payload: {
                      userId: response?.body?.id
                    }
                  })];
              }),
              catchError((error) => {
                return [
                  UsersDataStoreActions.saveError({
                    payload: {
                      index,
                      message: error?.error?.message ? error.error.message : this.createUserProfileError,
                    }
                  })
                ];
              })
            );
          }),
          catchError(error => {
            if (error.status === STATUSAPI.CONFLICT) {
              return [UsersDataStoreActions.createProfileForExistingUser({
                payload: {
                  companyId,
                  userParams,
                  record,
                  index,
                  prePopulateData
                }
              })];
            }
            // Save error row to ErrorList
            return [UsersDataStoreActions.saveError({
              payload: {
                index: action.payload.index,
                message: error?.error?.message ? error.error.message : this.createUserError,
              }
            })];
          }));
      })
    )
  );
  createProfileForExistingUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UsersDataStoreActions.createProfileForExistingUser),
      mergeMap((action) => {
        const { companyId, userParams, record, index, prePopulateData } = action.payload;
        return this.userApiService.getUserList({ email: userParams.email }).pipe(
          mergeMap((response: any) => {
            return this.bulkUserImportService.createProfile(response.body[0]?.id, record, companyId, prePopulateData).pipe(
              mergeMap((res: any) => {
                return [UsersDataStoreActions.importUserSuccessful(
                  {
                    payload: {
                      userId: res.body?.id
                    }
                  })];
              }),
              catchError((error) => {
                return [UsersDataStoreActions.saveError({
                  payload: {
                    index,
                    message: error?.error?.message ? error.error.message : this.createUserProfileError,
                  }
                })];
              })
            );
          }),
          catchError((error) => {
            return [UsersDataStoreActions.saveError({
              payload: {
                index,
                message: error?.error?.message ? error.error.message : this.getUserEmailError,
              }
            })];
          })
        );
      })
    )
  );
  // #endregion


  // Bulk user edit

  bulkUserEditSubmit$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UsersDataStoreActions.bulkUserEditSubmit),
      mergeMap((action) => {
        return forkJoin(action.payload).pipe(
          mergeMap(() => {
            return [UsersDataStoreActions.bulkUserEditSuccessResult({
              payload: { user: action.user }
            })];
          }),
          catchError((error) => {
            const userError: ErrorEditedUser = { ...action.user, ...{ editErrorMessage: error?.error?.message ? error.error.message : this.editUserError } };
            return [UsersDataStoreActions.bulkUserEditErrorResult({
              payload: { user: userError, }
            })];
          })
        );
      })
    )
  );

  bulkDeactivateUserProfile$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UsersDataStoreActions.bulkDeactivateUserProfile),
      mergeMap((action) => {
        return forkJoin(action.payload).pipe(
          mergeMap(() => {
            return [UsersDataStoreActions.bulkDeactivateUserProfileSuccess({
              payload: { user: action.user }
            })];
          }),
          catchError(() => {
            return [UsersDataStoreActions.bulkDeactivateUserProfileError({
              payload: { user: action.user }
            })];
          })
        );
      })
    )
  );

  getAllUsers$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UsersDataStoreActions.getAllUsers),
      mergeMap((action) => {
        const { companyId, sort } = action.payload;
        return this.userApiService.getAllUsers(companyId, sort).pipe(
          mergeMap((userList: IUser[]) => {
            return [UsersDataStoreActions.getAllUsersSuccessful(
              {
                payload: { userList }
              })];
          }),
          catchError((error) => {
            return [UsersDataStoreActions.getAllUsersFailure({
              payload: { error }
            })];
          })
        );
      })
    )
  );

  getAllUsersProfiles$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UsersDataStoreActions.getAllUsersProfiles),
      mergeMap((action) => {
        const { companyId, params } = action.payload;
        return this.userApiService.getAllUserProfiles(companyId, params).pipe(
          mergeMap((userProfileList: UserProfile[]) => {
            return [UsersDataStoreActions.getAllUsersProfilesSuccessful(
              {
                payload: { userProfileList }
              })];
          }),
          catchError((error) => {
            return [UsersDataStoreActions.getAllUsersProfilesFailure({
              payload: { error }
            })];
          })
        );
      })
    )
  );

  getUserPolicies$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UsersDataStoreActions.getUserPolicies),
      mergeMap((action) => {
        const { userId } = action.payload;
        return this.userApiService.getUserPolicies({ userId }).pipe(
          mergeMap((res) => {
            return [UsersDataStoreActions.getUserPoliciesSuccess(
              {
                payload: { userPolicies: res.body }
              })];
          }),
          catchError((error) => {
            return [UsersDataStoreActions.getUserPoliciesFailure({
              payload: { error }
            })];
          })
        );
      })
    )
  );

  getUsersByCompanyId$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UsersDataStoreActions.getUsersByCompanyId),
      mergeMap((action) => {
        const { companyId } = action.payload;
        return this.userDataService.getUsersByCompanyId(companyId).pipe(
          mergeMap((userList: any) => {
            return [UsersDataStoreActions.getUsersByCompanyIdSuccessful(
              {
                payload: { userList }
              })];
          }),
          catchError((error) => {
            return [UsersDataStoreActions.getUsersByCompanyIdFailure({
              payload: { error }
            })];
          })
        );
      })
    )
  );
  // #endregion
  //#region Common
  getUserById$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UsersDataStoreActions.getUserById),
      mergeMap((action) => {
        return this.userApiService.getUserById(action.payload.userId).pipe(
          mergeMap((res: HttpResponse<IUser>) => {
            return [UsersDataStoreActions.getUserByIdSuccess({
              payload: { user: res?.body || {} }
            })];
          }),
          catchError((error) => {
            return [UsersDataStoreActions.getUserByIdFail({
              payload: { error }
            })];
          })
        );
      })
    )
  );
  //#endregion
}
