/* Angular */
import { createAction, props } from '@ngrx/store';
import { ErrorAPIPayload, ErrorActionPayload, GetCompanyModePayload, GetCompanyModeSuccessfulPayload } from '@app/shared/data-stores/app/app-data-store.interface';
import { ICompanyMode } from '@app/core/models/user-company.model';

/* action types */
export enum ActionTypes {
  InitApp = '[APP] InitApp',
  OnCompanyModeSelected = '[COMPANIES] OnCompanyModeSelected',
  SetCompanyId = '[COMPANIES] SetCompanyId',
}

/* actions */
export const initApp = createAction(ActionTypes.InitApp);
export const onCompanyModeSelected = createAction(ActionTypes.OnCompanyModeSelected, props<{ payload: ICompanyMode }>());
export const setCompanyId = createAction(ActionTypes.SetCompanyId, props<{ payload: { id: string } }>());
