<div class="data-list-container">
  <span class="data-name required">{{dataName | titlecase}}</span>
  <section class="section-list" fxLayout="column" fxLayoutAlign="start">
    <span class="section-item">
      <mat-checkbox 
      [checked]="allComplete" 
      [indeterminate]="someComplete()"
      (change)="setAll($event.checked)"
      [disabled]="disabledDivisionList"
      >
      {{ translations.checkboxList.all | translate }} {{dataName | titlecase}}
    </mat-checkbox>
  </span>
  <span class="section-item" *ngFor="let data of dataList">
    <mat-checkbox [(ngModel)]="data.checked" (ngModelChange)="updateAllComplete(data)" [disabled]="disabledDivisionList">
      {{data.name}}
    </mat-checkbox>
  </span>
</section>
</div>