<div class="search-container">
  <mat-form-field appearance="outline">
    <mat-icon matPrefix class="search-icon">search</mat-icon>
    <input
      matInput
      placeholder={{placeHolder}}
      [formControl]="searchFormControl"
    >
    <button
      [style.visibility]="searchFormControl.value.length ? 'visible' : 'hidden'"
      class="clear-btn"
      matSuffix
      mat-icon-button
      aria-label="Clear"
      (click)="resetSearchBox()"
    >
      <mat-icon>clear</mat-icon>
    </button>
  </mat-form-field>
</div>

