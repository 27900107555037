import { AssetCustomProperty, IGetAssetsCustomPropertiesParams } from "@app/core/models/asset-property.model";
import { createAction, props } from "@ngrx/store";
import { CreateAssetCustomPropertyParams, CreateCustomPropertyValueParams, PatchAssetCustomPropertyParams, PatchCustomPropertyValueParams } from "./asset-properties-data-store.interface";

export enum ActionTypes {
  ResetAssetCustomProperty = '[ASSET-PROPERTY] ResetAssetCustomProperty',
  ResetAssetCustomPropertyProgress = '[ASSET-PROPERTY] ResetAssetCustomPropertyProgress',

  GetAssetCustomProperties = '[ASSET-PROPERTY] GetAssetCustomProperties',
  GetAssetCustomPropertiesSuccess = '[ASSET-PROPERTY] GetAssetCustomPropertiesSuccess',
  GetAssetCustomPropertiesFailure = '[ASSET-PROPERTY] GetAssetCustomPropertiesFailure',

  CreateAssetProperty = '[ASSET-PROPERTY] CreateAssetProperty',
  CreateAssetPropertySuccess = '[ASSET-PROPERTY] CreateAssetPropertySuccess',
  CreateAssetPropertyFailure = '[ASSET-PROPERTY] CreateAssetPropertyFailure',

  UpdateAssetProperty = '[ASSET-PROPERTY] UpdateAssetProperty',
  UpdateAssetPropertySuccess = '[ASSET-PROPERTY] UpdateAssetPropertySuccess',
  UpdateAssetPropertyFailure = '[ASSET-PROPERTY] UpdateAssetPropertyFailure',

  CreatePropertyValue = '[ASSET-PROPERTY] CreatePropertyValue',
  CreatePropertyValueSuccess = '[ASSET-PROPERTY] CreatePropertyValueSuccess',
  CreatePropertyValueFailure = '[ASSET-PROPERTY] CreatePropertyValueFailure',

  UpdatePropertyValue = '[ASSET-PROPERTY] UpdatePropertyValue',
  UpdatePropertyValueSuccess = '[ASSET-PROPERTY] UpdatePropertyValueSuccess',
  UpdatePropertyValueFailure = '[ASSET-PROPERTY] UpdatePropertyValueFailure',

  InactivePropertyValue = '[ASSET-PROPERTY] InactivePropertyValue',
  InactivePropertyValueSuccess = '[ASSET-PROPERTY] InactivePropertyValueSuccess',
  InactivePropertyValueFailure = '[ASSET-PROPERTY] InactivePropertyValueFailure',
};

export const resetAssetCustomProperty = createAction(ActionTypes.ResetAssetCustomProperty);

export const getAssetCustomProperties = createAction(ActionTypes.GetAssetCustomProperties, props<{ payload: { params?: IGetAssetsCustomPropertiesParams } }>());
export const getAssetCustomPropertiesSuccess = createAction(ActionTypes.GetAssetCustomPropertiesSuccess, props<{ payload: { assetCustomProperties: Array<AssetCustomProperty> } }>());
export const getAssetCustomPropertiesFailure = createAction(ActionTypes.GetAssetCustomPropertiesFailure,  props<{ payload: { error: any } }>());

export const createAssetProperty = createAction(ActionTypes.CreateAssetProperty, props<{ payload: { params?: CreateAssetCustomPropertyParams } }>());
export const createAssetPropertySuccess = createAction(ActionTypes.CreateAssetPropertySuccess, props<{ payload: {assetCustomPropertyCreated: AssetCustomProperty} }>());
export const createAssetPropertyFailure = createAction(ActionTypes.CreateAssetPropertyFailure, props<{ payload: { error: any } }>());

export const updateAssetProperty = createAction(ActionTypes.UpdateAssetProperty, props<{ payload: { params?: PatchAssetCustomPropertyParams } }>());
export const updateAssetPropertySuccess = createAction(ActionTypes.UpdateAssetPropertySuccess, props<{ payload: {} }>());
export const updateAssetPropertyFailure = createAction(ActionTypes.UpdateAssetPropertyFailure, props<{ payload: { error: any } }>());

export const createPropertyValue = createAction(ActionTypes.CreatePropertyValue, props<{ payload: { params?: CreateCustomPropertyValueParams } }>());
export const createPropertyValueSuccess = createAction(ActionTypes.CreatePropertyValueSuccess, props<{ payload: {} }>());
export const createPropertyValueFailure = createAction(ActionTypes.CreatePropertyValueFailure, props<{ payload: { error: any } }>());

export const updatePropertyValue = createAction(ActionTypes.UpdatePropertyValue, props<{ payload: { params?: PatchCustomPropertyValueParams } }>());
export const updatePropertyValueSuccess = createAction(ActionTypes.UpdatePropertyValueSuccess, props<{ payload: {} }>());
export const updatePropertyValueFailure = createAction(ActionTypes.UpdatePropertyValueFailure, props<{ payload: { error: any } }>());

export const inactivePropertyValue = createAction(ActionTypes.InactivePropertyValue, props<{ payload: { params?: PatchCustomPropertyValueParams } }>());
export const inactivePropertyValueSuccess = createAction(ActionTypes.InactivePropertyValueSuccess, props<{ payload: {} }>());
export const inactivePropertyValueFailure = createAction(ActionTypes.InactivePropertyValueFailure, props<{ payload: { error: any } }>());

export const resetAssetCustomPropertyProgress = createAction(ActionTypes.ResetAssetCustomPropertyProgress);

