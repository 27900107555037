import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { environment as env } from '@environments/environment';
import { ICompanyDataSearchParams } from '@app/core/models/company.model';
import { IAssetParams, IGetAssetsParams, SearchAssetQueryParams, UpdateAssetCustomPropertyValue } from '@app/core/models/asset.model';
import { EntityApiBaseService } from '@app/core/services/api/entity-api-base.service';
import { AssetCustomProperty, IGetAssetsCustomPropertiesParams } from '@app/core/models/asset-property.model';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class CoreAssetApiService {
  private ASSET_RESOURCE = 'assets';
  private ENUM_RESOURCE = 'enum';
  private ASSET_TYPES = 'types';
  private ASSET_FUELS = 'fuelType';
  private ASSET_PROPERTIES_RESOURCE = 'properties';


  constructor(
    private http: HttpClient,
    private entityApiBaseService: EntityApiBaseService
  ) { }

  getCompanyAssetCount(companyId: string) {
    const url = `${env.coreEntityApiBase.url}/${this.ASSET_RESOURCE}?companyId=${companyId}`;
    return this.http.head(url, { observe: 'response' });
  }

  getCompanyAssets(companyId: string, params?: IGetAssetsParams) {
    const url = `${env.coreEntityApiBase.url}/${this.ASSET_RESOURCE}?companyId=${companyId}`;
    return this.entityApiBaseService.getData(url, params);
  }

  getCompanyAssetsWithQuery(companyId: string, params) {
    const url = `${env.coreEntityApiBase.url}/${this.ASSET_RESOURCE}?companyId=${companyId}`;
    const arr = [url];
    Object.keys(params).forEach(key => {
      arr.push(`${key}=${encodeURIComponent(params[key])}`);
    });
    return this.http.get(arr.join('&'));
  }

  getDivisionAssets(companyId: string, divisionId: string, params?: ICompanyDataSearchParams) {
    const url = `${env.coreEntityApiBase.url}/${this.ASSET_RESOURCE}?companyId=${companyId}&divisionId=${divisionId}`;
    return this.entityApiBaseService.getData(url, params);
  }

  getDivisionAssetsCount(params) {
    const httpParams: HttpParams = new HttpParams({ fromObject: params as any });
    const options: any = { params: httpParams, observe: 'response' };
    const url = `${env.coreEntityApiBase.url}/${this.ASSET_RESOURCE}`;
    return this.http.head(url, options);
  }

  createAsset(params: IAssetParams) {
    const url = `${env.coreEntityApiBase.url}/${this.ASSET_RESOURCE}`;
    return this.http.post(url, params, { observe: 'response', headers: this.entityApiBaseService.getRequiredLoadingHttpHeader() });
  }

  getAssetById(assetId: string, params?: IGetAssetsParams) {
    const url = `${env.coreEntityApiBase.url}/${this.ASSET_RESOURCE}/${assetId}`;
    return this.entityApiBaseService.getData(url, params);
  }

  updateAsset(assetId: string, params: Partial<IAssetParams>) {
    const url = `${env.coreEntityApiBase.url}/${this.ASSET_RESOURCE}/${assetId}`;
    return this.http.patch(url, params, { observe: 'response', headers: this.entityApiBaseService.getRequiredLoadingHttpHeader() });
  }

  getAssets(companyId: string, params?: IGetAssetsParams) {
    const url = `${env.coreEntityApiBase.url}/${this.ASSET_RESOURCE}?companyId=${companyId}`;
    return this.entityApiBaseService.getAll(url, params);
  }

  getTypeAssets() {
    const url = `${env.coreEntityApiBase.url}/${this.ASSET_RESOURCE}/${this.ASSET_TYPES}`;
    return this.entityApiBaseService.getData(url);
  }

  getSubTypesAsset(assetType: string) {
    const url = `${env.coreEntityApiBase.url}/${this.ASSET_RESOURCE}/${this.ASSET_TYPES}/${assetType}`;
    return this.entityApiBaseService.getData(url);
  }

  getFuelTypes() {
    const url = `${env.coreEntityApiBase.url}/${this.ENUM_RESOURCE}?name=${this.ASSET_FUELS}`;
    return this.entityApiBaseService.getData(url);
  }

  getAllCompanyCustomAssetProperties(params: IGetAssetsCustomPropertiesParams): Observable<Array<AssetCustomProperty>> {
    const url = `${env.coreEntityApiBase.url}/${this.ASSET_RESOURCE}/${this.ASSET_PROPERTIES_RESOURCE}`;
    return this.entityApiBaseService.getData(url, params, {});
  }

  createAssetCustomProperty(params: AssetCustomProperty): Observable<HttpResponse<AssetCustomProperty>> {
    const url = `${env.coreEntityApiBase.url}/${this.ASSET_RESOURCE}/${this.ASSET_PROPERTIES_RESOURCE}`;
    return this.http.post(url, params, { observe: 'response' });
  }

  updateAssetCustomProperty(params: { propertyId: string, customPropertyName?: string, status?: string }): Observable<HttpResponse<AssetCustomProperty>> {
    const url = `${env.coreEntityApiBase.url}/${this.ASSET_RESOURCE}/${this.ASSET_PROPERTIES_RESOURCE}/${params.propertyId}`;
    const paramAPI = {
      name: params.customPropertyName,
      status: params.status
    };
    return this.http.patch(url, paramAPI, { observe: 'response' });
  }

  createAssetCustomPropertyValues(params: { propertyId: string, customPropertyValue: string, status?: string }): Observable<HttpResponse<AssetCustomProperty>> {
    const url = `${env.coreEntityApiBase.url}/${this.ASSET_RESOURCE}/${this.ASSET_PROPERTIES_RESOURCE}/${params.propertyId}/values`;
    const paramAPI = {
      value: params.customPropertyValue,
      status: params.status
    };
    return this.http.post(url, paramAPI, { observe: 'response' });
  }

  updateAssetCustomPropertyValue(propertyId: string, propertyValueId: string, params: UpdateAssetCustomPropertyValue): Observable<HttpResponse<AssetCustomProperty>> {
    const url = `${env.coreEntityApiBase.url}/${this.ASSET_RESOURCE}/${this.ASSET_PROPERTIES_RESOURCE}/${propertyId}/values/${propertyValueId}`;
    return this.http.patch(url, params, { observe: 'response' });
  }

  searchAssetProperties(queryParams: SearchAssetQueryParams, requestBody: any, isInit: boolean ) {
    if (isInit) {
      const { page, per_page} = queryParams;
      const url = `${env.coreEntityApiBase.url}/${this.ASSET_RESOURCE}/search?page=${page}&per_page=${per_page}`;
      return this.http.post(url, requestBody, { observe: 'response' }).pipe(map(res => ({assets: res.body, totalAssets: +res.headers.get('x-total-count') })));
    }
    const url = `${env.coreEntityApiBase.url}/${this.ASSET_RESOURCE}/search`;
    return this.entityApiBaseService.getAllByPostMethod(url, queryParams, requestBody).pipe(map((res: any) => ({assets: res, totalAssets: res.length })));
  }
 
}
