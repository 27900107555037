<div class="container" fxLayout="column" fxLayoutAlign="space-around none">
  <div fxLayout="row" fxLayoutAlign="start" class="bulk-edit-title">
    <label>{{data?.title}}</label>
  </div>

  <div class="message">
    <span>{{data.bulkEditResults.length - itemErrorCount}}</span>
    <span class="message1">{{data?.message1}}</span>
    <span>{{data.bulkEditResults.length}}</span>
    <span class="message2">{{data?.message2}}</span>
  </div>

  <div class="bulk-edit-item-container">
    <div *ngFor="let item of data.bulkEditResults" fxLayout="column">
      <div fxFill fxLayout="row" fxLayoutAlign="space-between">
        <span class="bulk-edit-item">{{item.name}}</span>
        <mat-icon *ngIf="!item.hasError" class="icon-success">done</mat-icon>
        <mat-icon *ngIf="item.hasError" class="icon-error">error</mat-icon>
      </div>
      <mat-divider class="divider"></mat-divider>
    </div>
  </div>

  <div class="pop-up-button-container" fxLayout="row" fxLayoutAlign="end center">
    <button mat-raised-button type="submit" (click)="onConfirm()">{{data?.textCloseButton}}</button>
  </div>
</div>
