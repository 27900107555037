import { HttpClient, HttpResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment as env } from '@environments/environment';
import { EntityApiBaseService } from '@app/core/services/api/entity-api-base.service';
import { Observable } from "rxjs";
import { ICreateTenantParams, IGetTenantsParams, ITenant, IUpdateTenantInfoParams, IUpdateTenantScopeParams } from "@app/core/models/tenant.model";

@Injectable({
  providedIn: 'root'
})
export class CoreTenantApiService {
  private TENANTS_RESOURCE = 'tenants';
  private COMPANIES_RESOURCE = 'companies';
  private DIVISIONS_RESOURCE = 'divisions';

  constructor(
    private http: HttpClient,
    private entityApiBaseService: EntityApiBaseService
  ) { }

  /**
   * Get all tenants that matches query parameters, return a full model
   * @param params Query parameters to search for tenants
   * @returns Http response of tenant list in full model
   */
  getTenantList(params?: IGetTenantsParams): Observable<HttpResponse<Array<ITenant>>> {
    const url = `${env.coreEntityApiBase.url}/${this.TENANTS_RESOURCE}`;
    return this.entityApiBaseService.getData(url, params);
  }

  /**
   * Create a tenant from parameters
   * @param params Tenant parameters to create
   * @returns Http response of request status
   */
  createTenant(params: ICreateTenantParams): Observable<HttpResponse<any>> {
    const url = `${env.coreEntityApiBase.url}/${this.TENANTS_RESOURCE}`;
    return this.http.post(url, params, { observe: 'response', headers: this.entityApiBaseService.getRequiredLoadingHttpHeader() });
  }

  /**
   * Get tenant by querying the tenant id, return a full model
   * @param tenantId The tenant id
   * @returns Http response of tenant in full model
   */
  getTenantById(tenantId: string): Observable<HttpResponse<ITenant>> {
    const url = `${env.coreEntityApiBase.url}/${this.TENANTS_RESOURCE}/${tenantId}`;
    return this.entityApiBaseService.getData(url);
  }

  /**
   * Update the tenant's information
   * @param tenantId The tenant id
   * @param params Tenant's information such as status, name, etc.
   * @returns Http response of request status
   */
  updateTenantInfo(tenantId: string, params: IUpdateTenantInfoParams): Observable<HttpResponse<any>> {
    const url = `${env.coreEntityApiBase.url}/${this.TENANTS_RESOURCE}/${tenantId}`;
    return this.http.patch(url, params, { observe: 'response', headers: this.entityApiBaseService.getRequiredLoadingHttpHeader() });
  }

  /**
   * Update the tenant's grants
   * @param tenantId The tenant id
   * @param params Scope of the tenant to replace
   * @returns Http response of request status
   */
  updateTenantScope(tenantId: string, params: IUpdateTenantScopeParams): Observable<HttpResponse<any>> {
    const url = `${env.coreEntityApiBase.url}/${this.TENANTS_RESOURCE}/${tenantId}`;
    return this.http.put(url, params, { observe: 'response', headers: this.entityApiBaseService.getRequiredLoadingHttpHeader() });
  }

  /**
   * Add companies to a tenant
   * @param tenantId The tenant id
   * @param params An array of company ids to be added to the tenant
   * @returns Http response of request status
   */
  addCompaniesToTenant(tenantId: string, params: Array<string>): Observable<HttpResponse<any>> {
    const url = `${env.coreEntityApiBase.url}/${this.TENANTS_RESOURCE}/${tenantId}/${this.COMPANIES_RESOURCE}`;
    return this.http.patch(url, params, { observe: 'response', headers: this.entityApiBaseService.getRequiredLoadingHttpHeader() });
  }

  /**
   * Remove companies from a tenant
   * @param tenantId The tenant id
   * @param params An array of company ids to be removed from the tenant
   * @returns Http response of request status
   */
  removeCompaniesOfTenant(tenantId: string, params: Array<string>): Observable<HttpResponse<any>> {
    const url = `${env.coreEntityApiBase.url}/${this.TENANTS_RESOURCE}/${tenantId}/${this.COMPANIES_RESOURCE}`;
    return this.http.delete(url, { observe: 'response', headers: this.entityApiBaseService.getRequiredLoadingHttpHeader(), body: params });
  }

  /**
   * Add divisions to a tenant
   * @param tenantId The tenant id
   * @param params An array of division ids to be added to the tenant
   * @returns Http response of request status
   */
  addDivisionsToTenant(tenantId: string, params: Array<string>): Observable<HttpResponse<any>> {
    const url = `${env.coreEntityApiBase.url}/${this.TENANTS_RESOURCE}/${tenantId}/${this.DIVISIONS_RESOURCE}`;
    return this.http.patch(url, params, { observe: 'response', headers: this.entityApiBaseService.getRequiredLoadingHttpHeader() });
  }

  /**
   * Remove divisions from a tenant
   * @param tenantId The tenant id
   * @param params An array of division ids to be removed from the tenant
   * @returns Http response of request status
   */
  removeDivisionsOfTenant(tenantId: string, params: Array<string>): Observable<HttpResponse<any>> {
    const url = `${env.coreEntityApiBase.url}/${this.TENANTS_RESOURCE}/${tenantId}/${this.DIVISIONS_RESOURCE}`;
    return this.http.delete(url, { observe: 'response', headers: this.entityApiBaseService.getRequiredLoadingHttpHeader(), body: params });
  }
}
