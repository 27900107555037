import { Injectable } from '@angular/core';
import { SettingsService } from '@app/core/services/settings.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { mergeMap, catchError } from 'rxjs/operators';
import * as SettingDataStoreActions from './setting-data-store.action';
import { EGroupPolicySetting } from '@app/core/consts/group.const';

@Injectable()
export class SettingDataStoreEffects {
  constructor(
    private actions$: Actions,
    private settingsService: SettingsService
  ) { }

  getAllowManagedCompany$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SettingDataStoreActions.getAllowManagedCompany),
      mergeMap((action) => {
        return this.settingsService.isAllowManagedCompany(action.payload.companyId).pipe(
          mergeMap((hasAllowManaged: boolean) => {
            return [SettingDataStoreActions.getAllowManagedCompanySuccessfully(
              {
                payload: { hasAllowManaged }
              })];
          }),
          catchError((error) => {
            return [SettingDataStoreActions.getAllowManagedCompanyFailure({
              payload: { error: error }
            })];
          })
        );
      })
    )
  );

  // #region LEGACY
  getLegacyOperatorSetting$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SettingDataStoreActions.getLegacyOperatorSetting),
      mergeMap((action) => {
        return this.settingsService.isLegacyOperatorCompany(action.payload.companyId).pipe(
          mergeMap((hasLegacyOperator: boolean) => {
            return [SettingDataStoreActions.getLegacyOperatorSettingSuccess(
              {
                payload: { hasLegacyOperator }
              })];
          }),
          catchError((error) => {
            return [SettingDataStoreActions.getLegacyOperatorSettingFail({
              payload: { error: error }
            })];
          })
        );
      })
    )
  );
  // #endregion LEGACY

  getGroupPolicySetting$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SettingDataStoreActions.getGroupPolicySetting),
      mergeMap((action) => {
        return this.settingsService.getCompanyMigrationStatus(action.payload.companyId).pipe(
          mergeMap((setting: string) => {
            return [SettingDataStoreActions.getGroupPolicySettingSuccess(
              {
                payload: { setting }
              })
            ];
          }),
          catchError((error) => {
            return [SettingDataStoreActions.getAllowManagedCompanyFailure({
              payload: { error: error }
            })];
          })
        );


        /*         
        // const setting = { value: EGroupPolicySetting.DISABLED };
        const setting = null;
        if (!setting) {
          return [SettingDataStoreActions.createGroupPolicySetting(
            {
              payload: { companyId: action.payload.companyId }
            })
          ];
        }

        return [SettingDataStoreActions.getGroupPolicySettingSuccess(
          {
            payload: { setting }
          })
        ]; 
        */
      })
    )
  );

  createGroupPolicySetting$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SettingDataStoreActions.createGroupPolicySetting),
      mergeMap((action) => {
        const setting = EGroupPolicySetting.DISABLED;
        return [SettingDataStoreActions.createGroupPolicySettingSuccess(
          {
            payload: { setting }
          })
        ];
      })
    )
  );
}