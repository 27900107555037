import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';

/* reducer */
import * as fromSettingDataStore from './setting-data-store.reducer';

/* effects */
import { SettingDataStoreEffects } from './setting-data-store.effects';


@NgModule({
    declarations: [],
    imports: [
        CommonModule,
        StoreModule.forFeature(
            fromSettingDataStore.featureKey,
            fromSettingDataStore.reducer,
            { initialState: fromSettingDataStore.initialState }
        ),
        EffectsModule.forFeature([ SettingDataStoreEffects ])

    ],
    exports: [
        StoreModule,
        EffectsModule
    ]
})
export class SettingDataStoreModule { }