<div class="container">
  <mat-accordion>
    <mat-expansion-panel hideToggle [expanded]="primaryToggleState">
        <mat-expansion-panel-header class="title-border">
          <div class="my-panel" [ngStyle]="{'width': true ? '100%' : '98%' }" fxLayout="row"
            fxLayoutAlign="space-between center" (click)="$event.stopPropagation();">
            <mat-panel-title>
              <mat-slide-toggle (change)="toggleChanged($event.checked)">
              </mat-slide-toggle >
              <span class="toggle-name">{{displayingContext?.label}}</span>
              <span class="toggle-warn" *ngIf="hasWarning()">{{(displayingContext?.warnContext)}}</span>
            </mat-panel-title>
            <mat-icon class="tooltip-icon" fxLayoutAlign="center"
              matTooltip={{displayingContext?.iconTooltipContext}} matTooltipPosition="below">
              info_outline
            </mat-icon>
          </div>
        </mat-expansion-panel-header>

        <mat-radio-group fxLayout="row" fxLayoutAlign="start" class="radio-container"
          [(ngModel)]="selectedValue" (ngModelChange)="onRadioSelection()">
          <mat-radio-button fxFlex="50" *ngFor="let input of innerStatesArray" [value]="input?.dataContent">{{ input?.displayContent | titlecase }}</mat-radio-button>
        </mat-radio-group>
    </mat-expansion-panel>
  </mat-accordion>
</div>
