import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
/* reducer */
import * as fromAssetsDataStore from './assets-data-store.reducer';

/* effects */
import { AssetsDataStoreEffects } from './assets-data-store.effects';


@NgModule({
    declarations: [],
    imports: [
        CommonModule,
        StoreModule.forFeature(
            fromAssetsDataStore.featureKey,
            fromAssetsDataStore.reducer,
            { initialState: fromAssetsDataStore.initialState }
        ),
        EffectsModule.forFeature([AssetsDataStoreEffects])

    ],
    exports: [
        StoreModule,
        EffectsModule
    ]
})
export class AssetsDataStoreModule { }
