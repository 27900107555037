import { Component, ViewChild } from '@angular/core';
import { MediaObserver } from '@angular/flex-layout';

import { MatSidenav } from '@angular/material/sidenav';

import { TranslateService } from '@zonar-ui/i18n';

@Component({
  selector: 'app-mobile-drawer',
  templateUrl: './mobile-drawer.component.html',
  styleUrls: ['./mobile-drawer.component.scss']
})
export class MobileDrawerComponent {
  @ViewChild('mobileSidenav') mobileSidenav: MatSidenav;

  constructor(
    public translateService: TranslateService,
    public media: MediaObserver
  ) {}

  openDrawer() {
    this.mobileSidenav.open();
  }

  closeDrawer() {
    this.mobileSidenav.close();
  }

}
