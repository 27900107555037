<div class="bulk-user-preview-dialog" fxLayout="column" fxLayoutAlign="space-between start">
  <div fxLayout="row" fxLayoutAlign="start" class="title">
    <span>{{translations.bulkUserPreviewDialog.title | translate}}</span>
  </div>
  <div class="description">
    <span>{{translations.bulkUserPreviewDialog.description | translate}}</span>
  </div>

  <div fxLayout="column" fxFill class="preview-dialog-body">
    <div *ngIf="data?.divisions?.type" fxLayout="column" class="division body-item">
      <span>{{translatedText?.divisions | titlecase}}:</span>
      <span class="change-status">{{data?.divisions?.type === null ? (translations?.user?.bulkUser?.editBehavior?.replace | translate | uppercase) : data?.divisions?.type | uppercase}}:</span>
      <div fxLayout="column" class="value-changed tree-checkbox">
        <app-tree-with-checkboxes [treeContents]="data?.divisions?.value" [checkedAll]="data?.divisions?.allDivisionsChecked"
          [hasTitle]="false" [isReadOnly]="true" [rootNodeName]="translatedText?.divisions"></app-tree-with-checkboxes>
      </div>
    </div>

    <div *ngIf="data?.permissions.type" fxLayout="row" class="permission body-item">
      <span fxFlex="50">{{translations.bulkUserPreviewDialog.serviceLevelPermissions | translate}}:</span>
      <div fxLayoutAlign="end end" fxLayout="column" fxFlex="50">
        <span class="change-status">{{data?.permissions.type | uppercase}}:</span>
        <span class="permission-item" *ngFor="let perm of data?.permissions.value">
          {{perm}}
        </span>
      </div>
    </div>
  </div>

  <div *ngIf="data?.language.value" fxLayout="row" fxFill class="language body-item">
    <span fxFlex="50">{{translations.bulkUserPreviewDialog.language | translate}}:</span>
    <div fxLayoutAlign="end end" fxLayout="column" fxFlex="50">
      <span>{{data?.language.value}}</span>
    </div>
  </div>

  <div class="dialog-button" fxLayout="row" fxLayoutAlign="end end">
    <button mat-stroked-button type="button" fxLayoutAlign="center center" (click)="onCancel()">
      {{translations.bulkUserPreviewDialog.cancel | translate}}</button>
    <button mat-raised-button type="submit" fxLayoutAlign="center center" class="button-ml"
      (click)="onConfirm()">{{translations.bulkUserPreviewDialog.continue | translate}}</button>
  </div>
</div>
