import { environment } from "@environments/environment";
import { ADMIN_PLUS_BASE_URL } from "./base-urls";

export const SidenavHeaderConfig = {
  displayName: true, // if we want to display name of the user
  avatarImageUrl: null, // if we want to show a specific avatar picture e.g. user's avatar
  optionalSubtitle: null
};

export const SidenavMenuConfig: any[] = [
  {
    text: 'Administration',
    icon: 'settings',
    route: '/',
    routerLink: ADMIN_PLUS_BASE_URL?.[environment?.region]?.[environment?.authEnv]?.route,
    appId: ADMIN_PLUS_BASE_URL?.[environment?.region]?.[environment?.authEnv]?.appId,
    children: [
      {
        text: 'User Management',
        route: 'company',
        routerLink: 'company',
        appId: ADMIN_PLUS_BASE_URL?.[environment?.region]?.[environment?.authEnv]?.appId,
        children: [],
        isExpanded: false,
        routeLevel: 2,
      },
    ],
    isExpanded: false,
    routeLevel: 1,
  },
];

export const SidenavFooterConfig = {
  items: []
};
