import { Component, OnInit } from '@angular/core';
import { LoadingService } from '@app/core/services/loading.service';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-loading-spinner',
  templateUrl: './loading-spinner.component.html',
  styleUrls: ['./loading-spinner.component.scss']
})
export class LoadingSpinnerComponent implements OnInit {
  loading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);

  constructor(
    private _loading: LoadingService,
  ) { }

  ngOnInit(): void {
    this.loading$ = this._loading.loadingSub;
  }

}
