import { Injectable } from '@angular/core';
import { Actions } from '@ngrx/effects';

/* actions */
import * as AppActions from './app-data-store.actions';
import { Router } from '@angular/router';

@Injectable()
export class AppDataStoreEffects {
  constructor(
    private actions$: Actions,
    private router: Router,
  ) { }
}
