export enum ListManagementType {
  Search = 'Search',
  Filter = 'Filter',
  Button = 'Button'
}

export enum SearchOperator {
  And = 'and',
  Or = 'or',
}

export enum ErrorConst {
  CONTEXT = 'errorContext',
  TOOLTIP = 'errorTooltip'
}
export enum IconColor {
  ACTIVE = 'pass',
  INACTIVE = 'steel',
  EDIT = 'accent',
  INFO = 'steel'
}

export enum MiscIcon {
  EDIT = 'edit',
  LAUNCH = 'flip_to_front',
  INFO = 'error_outline',
  INFO_ICON = 'info_outline',
}


export const FilterSelectOptionsControlName = {
  USERS_PROFILE: {
    STATUS: 'status',
    DIVISIONS: 'divisions'
  },
  USERS_GROUP: {
    STATUS: 'status',
    GROUPS: 'group',
    LOGINTYPES: 'logintypes',
  },
  ASSET: {
    TYPES: 'types',
    STATUS: 'status',
    ASSET: 'asset',
    PROPERTIES: 'properties',
  },
  DIVISION: {
    STATUS: 'status',
  },
  COMPANY: {
    STATUS: 'status',
  }
};

export enum CardViewActionType {
  FUNCTION = 'FUNCTION',
};
