import { Component, Input, OnDestroy } from '@angular/core';
import { ISingleGridPanel } from '@app/core/models/single-grid-panel.model';
import { Subject } from 'rxjs';
import { MediaObserver } from '@angular/flex-layout';

@Component({
  selector: 'app-single-grid-panel',
  templateUrl: './single-grid-panel.component.html',
  styleUrls: ['./single-grid-panel.component.scss']
})
export class SingleGridPanelComponent implements OnDestroy {
  @Input() gridObjectData: ISingleGridPanel = {
    gridTitle: '',
    gridDescription: '',
    gridData: []
  };
  private onDestroy$ = new Subject<void>();

  constructor(
    public media: MediaObserver,
  ) { }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }
}
