<div class="container" fxLayout="column" fxLayoutAlign="space-around none">
  <div fxLayout="row" fxLayoutAlign="start"  class="title">
    <label>{{data?.title}}</label>
  </div>

  <div class="message1">
    <span>{{data?.message1}}</span>
  </div>

  <div class="message2">
    <span>{{data?.message2}}</span>
  </div>

  <mat-form-field class="input-container common-input" appearance="outline">
    <mat-label>{{ translations.deactivateCautionPopupDialog.confirmPlaceholderText | translate }}</mat-label>
    <input class="common-input" matInput [(ngModel)]="inputConfirm" [formControl]="confirmKeyword">
    <mat-error *ngIf="confirmKeyword.hasError('pattern')" class="input-error">
      {{ data?.errorMessage }}
    </mat-error>
  </mat-form-field>

  <div class="pop-up-button-container" fxLayout="row" fxLayoutAlign="end center">
    <button mat-stroked-button type="button" (click)="onCancel()" class="button-margin ">
      {{data?.textCancelButton}}</button>
    <button mat-raised-button type="submit" 
     (click)="onConfirm()" 
     [disabled]="hasDisableButton()"
     >{{data?.textConfirmButton}}</button>
  </div>
</div>
