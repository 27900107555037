import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Subject } from 'rxjs';
import { map, mergeMap, catchError } from 'rxjs/operators';
import * as GroupActions from './group-data-store.action';
import { CoreGroupApiService } from '@app/core/services/api/core-group-api.service';
import { IGroup } from '@app/core/models/group.model';
import { HttpResponse } from '@angular/common/http';

@Injectable()
export class GroupDataStoreEffects {
  private cancelSubject$ = new Subject<boolean>();

  constructor(
    private actions$: Actions,
    private coreGroupApiService: CoreGroupApiService
  ) { }

  getGroupListByCompanyId$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GroupActions.getGroupByCompanyId),
      mergeMap((action) => {
        return this.coreGroupApiService.getGroups(action.payload.params).pipe(
          mergeMap((res: HttpResponse<Array<IGroup>>) => {
            return [GroupActions.getGroupByCompanyIdSuccess(
              {
                payload: { companyGroups: res.body }
              })];
          }),
          catchError((error) => {
            return [GroupActions.getGroupByCompanyIdFailure({
              payload: { error }
            })];
          })
        );
      })
    )
  );

  cancelRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GroupActions.cancelRequest),
      map(() => {
        this.cancelSubject$.next();
      })
    ), { dispatch: false }
  );
}