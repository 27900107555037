export enum Permissions {
  CREATE_COMPANY = 'create:company',
  READ_COMPANY = 'read:company',
  READ_COMPANIES = 'read:companies',
  UPDATE_COMPANY = 'update:company',
  ASSOCIATE_COMPANY = 'associate:company',
  DEASSOCIATE_COMPANY = 'deassociate:company',

  CREATE_ASSET = 'create:asset',
  UPDATE_ASSET = 'update:asset',
  READ_ASSET = 'read:asset',
  READ_ASSETS = 'read:assets',

  CREATE_USER = 'create:user',
  READ_USER = 'read:user',
  READ_USERS = 'read:users',
  READ_SELF = 'read:self',
  UPDATE_USER = 'update:user',

  CREATE_POLICY = 'create:policy',
  READ_POLICY = 'read:policy',
  READ_POLCIES = 'read:policies',
  UPDATE_POLICY = 'update:policy',

  CREATE_TENANT = 'create:tenant',
  READ_TENANT = 'read:tenant',
  UPDATE_TENANT = 'update:tenant',
}
