import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { environment as env } from '@environments/environment';
import { EntityApiBaseService } from '@app/core/services/api/entity-api-base.service';
import { IDriver, IDriverProfile } from '@app/core/models/driver.model';
import { Observable } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class CoreDriverApiService {
  private DRIVERS_RESOURCE = 'drivers';
  private DRIVER_PROFILE_RESOURCE = 'driverprofiles';

  constructor(
    private entityApiBaseService: EntityApiBaseService
  ) { }

  getUserDrivers(params?: IDriver) {
    const url = `${env.coreEntityApiBase.url}/${this.DRIVERS_RESOURCE}`;
    return this.entityApiBaseService.getAll(url, params);
  };

  getDriverProfiles(params?: IDriverProfile) {
    const url = `${env.coreEntityApiBase.url}/${this.DRIVER_PROFILE_RESOURCE}`;
    return this.entityApiBaseService.getAll(url, params);
  };

}
