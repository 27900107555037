/* angular */
import { IDivision } from '@app/core/models/company.model';
import { createAction, props } from '@ngrx/store';
import { GetDivisionsFailurePayload, GetDivisionsPayload, GetDivisionsSuccessPayload, GetNestedDivisionsPayload } from './divisions-data-store.interface';

/* action types */
export enum ActionTypes {
  InitDivisions = '[DIVISIONS] InitDivisions',
  // user list actions
  GetAllCompanyDivisions = '[DIVISIONS] GetAllCompanyDivisions',
  GetAllCompanyDivisionsSuccessFully = '[DIVISIONS] GetAllCompanyDivisionsSuccessFully',
  GetAllCompanyDivisionsFailure = '[DIVISIONS] GetAllCompanyDivisionsFailure',

  // division list actions
  GetDivisions = '[DIVISIONS] GetDivisions',
  GetNestedDivisions = '[DIVISIONS] GetNestedDivisions',
  GetDivisionListSuccess = '[DIVISIONS] GetDivisionListSuccess',
  GetNestedDivisionListSuccess = '[DIVISIONS] GetNestedDivisionListSuccess',
  GetDivisionsFailure = '[DIVISIONS] GetDivisionsFailure',

  // common actions
  ResetDivisions = '[DIVISIONS] ResetDivisions',
  CancelRequest = '[DIVISIONS] CancelRequest',
};

/* actions */
export const initDivisions = createAction(ActionTypes.InitDivisions);

// user list actions
export const getAllCompanyDivisions = createAction(ActionTypes.GetAllCompanyDivisions, props<{ payload: {companyId: string, params?: any} }>());
export const getAllCompanyDivisionsSuccessFully = createAction(ActionTypes.GetAllCompanyDivisionsSuccessFully, props<{ payload: {divisionList : IDivision[]} }>());
export const getAllCompanyDivisionsFailure = createAction(ActionTypes.GetAllCompanyDivisionsFailure, props<{ payload: {error: any} }>());

// division list actions
export const getDivisions = createAction(ActionTypes.GetDivisions, props<{ payload: GetDivisionsPayload }>());
export const getNestedDivisions = createAction(ActionTypes.GetNestedDivisions, props<{ payload: GetNestedDivisionsPayload }>());
export const getDivisionListSuccess = createAction(ActionTypes.GetDivisionListSuccess, props<{ payload: GetDivisionsSuccessPayload }>());
export const getNestedDivisionListSuccess = createAction(ActionTypes.GetNestedDivisionListSuccess, props<{ payload: GetDivisionsSuccessPayload }>());
export const getDivisionsFailure = createAction(ActionTypes.GetDivisionsFailure, props<{ payload: GetDivisionsFailurePayload }>());

// common actions
export const resetDivisions = createAction(ActionTypes.ResetDivisions);
export const cancelRequest = createAction(ActionTypes.CancelRequest);
