import { ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatTooltip } from '@angular/material/tooltip';
import { Router } from '@angular/router';
import { CreateEditViewTreeFilter, ICreateEditViewTree } from '@app/core/models/policy.model';
import { ComponentViewMode, CreateEditViewFeature, MOBILE_BREAK_POINT } from '@app/core/consts/app.const';
import { sortBy } from '@app/core/utils';
import { Store } from '@ngrx/store';
import { cloneDeep } from 'lodash';
import { I18nService, TranslateService } from '@zonar-ui/i18n';
import { Translations } from '@app/core/services/i18n/translations.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { TreeWithCheckboxesComponent } from '../tree-with-checkboxes/tree-with-checkboxes.component';
import { RegionParserService } from '@app/core/services/region-parser.service';
import { PermissionsService } from '@zonar-ui/auth';
import { Permissions } from '@app/core/consts/permission.const';
import { UserType } from '@app/core/consts/user.const';
import { PolicyEntityFields, PolicyEntityRestrictions } from '@app/core/consts/policy.const';

@Component({
  selector: 'app-create-edit-with-tree-base',
  template: '',
})
export class CreateEditWithTreeBaseComponent implements OnInit, OnChanges, OnDestroy {
  @Input() data: ICreateEditViewTree;
  @Output() confirmEmitter = new EventEmitter<any>();
  @ViewChild('viewChild') viewChild!: TreeWithCheckboxesComponent;

  disabledTree = false;
  allItemsChecked = false;
  persistTooltip = false;
  hasTreeItemsChanged = false;
  itemsSelectedOnView = [];
  searchValue = null;
  initListName = '';
  searchControl = new FormControl('');
  listName = new FormControl('', Validators.required);
  translationsNotLoaded = !!this.translateService.store.translations;
  private onDestroy$ = new Subject<void>();
  itemDuplicated = {};
  documentComponent = document;
  disableSubItemSelection = true;
  itemSelectedDataSource = [];
  hasCreateTenantPerm: boolean;
  hasCreatePolicyPerm: boolean;
  hasUpdateTenantPerm: boolean;
  hasUpdatePolicyPerm: boolean;
  allRoleCompanyLevelCapaList = [];
  companyLevelCapaSelectedList = [];
  warningData: any = {};
  imageSrc = 'assets/icons/Severity-icons.svg';
  userAffectTooltipMessage = '';

  // policy filter
  policyTypeFilterMessageKey: string = this.translations.role.filter.standard;
  filteredPolicyType = UserType.STANDARD;
  policyTypes = [];
  currentPolicyTypeFilter: CreateEditViewTreeFilter = null;

  // all filters
  filterValues: CreateEditViewTreeFilter[] = null;

  constructor(
    public matDialog: MatDialog,
    public router: Router,
    public dialogRef: MatDialogRef<CreateEditWithTreeBaseComponent>,
    public translateService: TranslateService,
    public translations: Translations,
    public i18nService: I18nService,
    public store: Store<any>,
    public regionParserService: RegionParserService,
    public changeDetector: ChangeDetectorRef,
    public permissionService: PermissionsService,
  ) {
    if (this.translationsNotLoaded) {
      this.translateService.use(this.i18nService.getLanguageInUse())
        .pipe(takeUntil(this.onDestroy$)).subscribe(() => this.translationsNotLoaded = false);
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.data && changes.data.currentValue) {
      this.data = changes.data.currentValue;
      this.buildUserAffectedTooltip();
      this.allRoleCompanyLevelCapaList = this.data.allRoleCompanyLevelCapaList;
      this.companyLevelCapaSelectedList = this.data.companyLevelCapaSelectedList;
      if (this.data.feature === CreateEditViewFeature.POLICY) {
        this.buildWarningData();
      }
      this.disableSubItemSelection = this.data.disableSubItemSelection ?? true;
      this.initComponent();
      this.changeDetector.detectChanges();
    }
  }

  ngOnInit(): void {
    this.searchControl.valueChanges.pipe(takeUntil(this.onDestroy$)).subscribe(value => {
      this.searchValue = value;
      this.buildFilterValues();
    });
  }

  buildUserAffectedTooltip() {
    if (this.data?.initInfo?.members) {
      const tmpMembers = cloneDeep(this.data.initInfo.members);
      this.userAffectTooltipMessage = sortBy(tmpMembers).join('\n');
    } else {
      this.userAffectTooltipMessage = '';
    }
  }

  buildWarningData() {
    if (this.companyLevelCapaSelectedList === null) {
      this.warningData = {
        image: this.imageSrc,
        title: this.data.translated?.warningData?.restrictedRoleText?.title,
        message: this.data.translated?.warningData?.restrictedRoleText?.message,
      };
    } else if (!this.companyLevelCapaSelectedList?.length) {
      this.warningData = {
        image: this.imageSrc,
        title: this.data.translated?.warningData?.accessChangeText?.title,
        message: this.data.translated?.warningData?.accessChangeText?.message,
      };
    } else {
      this.warningData = null;
    }

  }

  initComponent() {
    if (this.data?.componentMode && this.data.componentMode !== ComponentViewMode.CREATE) {
      this.allItemsChecked = this.data.allItemsChecked;
      this.listName.setValue(this.data.initInfo?.name);
      this.initListName = this.data.initInfo?.name;
      this.itemsSelectedOnView = cloneDeep(this.data.initInfo?.initItemSelected);
    }

    if (this.data?.componentMode && this.data.componentMode === ComponentViewMode.VIEW) {
      if (this.data.feature === CreateEditViewFeature.TENANT) {
        this.permissionService.hasPermission(Permissions.CREATE_TENANT).pipe(takeUntil(this.onDestroy$)).subscribe(hasPerm => {
          this.hasCreateTenantPerm = hasPerm;
        });
        this.permissionService.hasPermission(Permissions.UPDATE_TENANT).pipe(takeUntil(this.onDestroy$)).subscribe(hasPerm => {
          this.hasUpdateTenantPerm = hasPerm;
        });
      } else {
        this.permissionService.hasPermission(Permissions.CREATE_POLICY).pipe(takeUntil(this.onDestroy$)).subscribe(hasPerm => {
          this.hasCreatePolicyPerm = hasPerm;
        });
        this.permissionService.hasPermission(Permissions.UPDATE_POLICY).pipe(takeUntil(this.onDestroy$)).subscribe(hasPerm => {
          this.hasUpdatePolicyPerm = hasPerm;
        });
      }
      this.disabledTree = true;
      this.listName.disable();
    }

    this.initFilters();
  }

  getContentMargin() {
    let element: any = this.documentComponent.querySelector('.create-edit-tree-content');
    let elementTitle: any = this.documentComponent.querySelector('.title');

    if (element?.scrollWidth < MOBILE_BREAK_POINT) {
      element.style.cssText = 'display: flex; flex-direction: column';
      elementTitle.style.cssText = 'width: 100%';
    } else {
      element.style.cssText = 'display: flex; flex-direction: row';
      elementTitle.style.cssText = 'width: 50%';
    }
    return '';
  }

  checkDataChanged() {
    if (this.data?.componentMode === ComponentViewMode.CREATE) {
      return this.hasNameChanged() && this.hasTreeChanged();
    }

    return this.hasNameChanged() || this.hasTreeChanged();
  }

  hasNameChanged() {
    return this.listName.value?.trim() !== this.initListName?.trim();
  }

  public get ComponentMode(): typeof ComponentViewMode {
    return ComponentViewMode;
  }

  resetSelectedData() {
    this.data.dataList.forEach(item => {
      item.selected = false;
      item.disabled = false;

      if (item.children?.length) {
        item.children.forEach(child => {
          if (child && typeof child === 'object') {
            child.selected = false;
            child.disabled = false;
          }
        });
      }
    });
    this.data.dataList = [...this.data.dataList];
  }

  onCreate() {
    this.data.componentMode = ComponentViewMode.CREATE;
    this.resetSelectedData();
    this.listName.enable();
    this.listName.setValue('');
    this.itemsSelectedOnView = [];
    this.data.initInfo.initItemSelected = [];

    this.allItemsChecked = false;
    this.disabledTree = false;
    this.initListName = '';
    this.persistTooltip = false;
    this.initFilters();
  }

  onEdit() {
    this.data.componentMode = ComponentViewMode.EDIT;
    this.listName.enable();
    this.disabledTree = false;
    this.disableSubItemSelection = this.data?.disableSubItemSelection;
    this.initFilters();
  }

  onCancel() {
    this.matDialog.closeAll();
  }

  hasDuplicatedTree(): boolean {
    if (!this.hasTreeChanged()) {
      return;
    }

    let itemsSelected = cloneDeep(this.itemsSelectedOnView);

    let result = false;
    this.itemDuplicated = {};
    itemsSelected = itemsSelected.slice().sort();

    for (let item of this.data?.dataValidation) {
      if (JSON.stringify(item?.validationList.slice().sort()) === JSON.stringify(itemsSelected)) {
        result = true;
        this.itemDuplicated = item;
      }
    };

    return result;
  }

  hasDuplicatedName() {
    if (this.hasNameChanged()) {
      return this.data.dataValidation.find(item => item.name.toLowerCase() === this.listName.value.toLowerCase());
    }
  }

  onConfirm() {
    if (this.hasDuplicatedName()) {
      this.listName.markAsTouched();
      this.listName.setErrors({ 'duplicated-name': true });

      return;
    }

    if (this.data.feature === CreateEditViewFeature.TENANT) {
      if (this.hasDuplicatedTree()) {
        this.listName.markAsTouched();
        this.listName.setErrors({ 'duplicated-tree': true });

        return;
      }
    }

    const result = {
      data: this.data,
      dataChanged: this.hasTreeItemsChanged ? this.itemsSelectedOnView : [],
      name: this.listName.value,
      allItemsChecked: this.allItemsChecked
    };
    if (this.data.isDialog) {
      this.dialogRef.close(result);
    } else {
      this.confirmEmitter.emit(result);
    }
  }

  onNavigateBack() {
    this.router.navigateByUrl(this.data?.pageNavigateLink?.navigateBack);
  }

  resetFormName() {
    this.listName.setErrors({ duplicated: null });
    this.listName.updateValueAndValidity();
  }

  buildItemSelectedOnView() {
    this.itemSelectedDataSource = [];
    this.itemSelectedDataSource = this.buildItemSelectedDataSource(this.viewChild.tmpDataSource);

    this.itemsSelectedOnView = this.itemSelectedDataSource.map(item => item.id);
    if (this.data.componentMode === ComponentViewMode.EDIT) {
      this.hasTreeItemsChanged = JSON.stringify(this.itemsSelectedOnView.sort()) !== JSON.stringify(this.data.initInfo.initItemSelected.sort());
    } else {
      this.hasTreeItemsChanged = !!this.itemSelectedDataSource.length;
    }
  }

  selectOrDeselectAll(value) {
    this.viewChild.selectOrDeselectedItems(value);
    this.selectItem({ addComplete: value });
  }

  buildItemSelectedDataSource(array) {
    array.forEach(item => {
      if (item.selected && !item.disabled) {
        this.itemSelectedDataSource.push(item);
      }

      if (item.children.length) {
        this.buildItemSelectedDataSource(item.children);
      }
    });
    return this.itemSelectedDataSource;
  }

  selectItem(event) {
    this.resetFormName();
    this.allItemsChecked = event.addComplete;
    this.buildItemSelectedOnView();
    if (this.data?.companyLevelCapaSelectedList?.length) {
      this.isShowAlertMessage(event.data);
    }
  }

  isShowAlertMessage(data) {
    let temp = cloneDeep(this.companyLevelCapaSelectedList);
    if (data.parentId) {
      data.descendants = [data];
    }

    if (data.selected) {
      data.descendants.forEach(des => {
        if (this.data.allRoleCompanyLevelCapaList.includes(des.id)) {
          temp.push(des.id);
        }
      });
    } else {
      data.descendants.forEach(des => {
        if (temp.includes(des.id)) {
          temp = temp.filter(item => item !== des?.id);
        }
      });
    }

    this.companyLevelCapaSelectedList = temp;
    this.buildWarningData();
  }

  hasTreeChanged(): boolean {
    return !!(this.hasTreeItemsChanged && this.itemsSelectedOnView.length);
  }

  onUserAffectTooltipMouseEnter(event: MouseEvent, userAffectTooltip: MatTooltip) {
    event.stopImmediatePropagation();
    if (this.persistTooltip) {
      // persist state
      if (!userAffectTooltip._isTooltipVisible()) {
        userAffectTooltip.show();
        this.persistTooltip = false;
      }
    } else {
      // idle state
      userAffectTooltip.show();
    }
  }


  onUserAffectTooltipMouseLeave(event: MouseEvent, userAffectTooltip: MatTooltip) {
    event.stopImmediatePropagation();
    if (this.persistTooltip) {
      // persist state

    } else {
      // idle state
      userAffectTooltip.hide();
    }
  }

  onUserAffectTooltipClick(event: MouseEvent, userAffectTooltip: MatTooltip) {
    if (this.persistTooltip) {
      // persist state
      this.persistTooltip = false;
      userAffectTooltip.hide();
    } else {
      // idle state
      userAffectTooltip.show();
      this.persistTooltip = true;
    }
  }

  onUserAffectTooltipClose(event: MouseEvent, userAffectTooltip: MatTooltip) {
    event.stopImmediatePropagation();
    userAffectTooltip.show();
  }

  getTitle() {
    switch (this.data?.componentMode) {
      case ComponentViewMode.CREATE:
        return this.data.translated?.createTitle;
      case ComponentViewMode.EDIT:
        return this.data.translated?.editTitle;
      case ComponentViewMode.VIEW:
        return this.data.translated?.viewTitle;
      default:
        return '';
    }
  }

  getParsedKeyWords() {
    return this.regionParserService.getParsedKeyWords();
  }

  isEmptyRoleWithViewMode() {
    return this.data?.componentMode === ComponentViewMode.VIEW && this.data?.initInfo?.error;
  }

  onPolicyTypeFilterChanged() {
    this.selectOrDeselectAll(false);
    this.searchValue = '';
    this.searchControl.setValue('', { emitEvent: false });
    switch (this.filteredPolicyType) {
      case UserType.STANDARD:
        this.policyTypeFilterMessageKey = this.translations.role.filter.standard;
        this.currentPolicyTypeFilter = { property: PolicyEntityFields.RESTRICTIONS, value: PolicyEntityRestrictions.ZONAR_ADMIN, reverseFilter: true };
        break;
      case UserType.LEGACY_OPERATOR:
        this.policyTypeFilterMessageKey = this.translations.role.filter.legacy;
        this.currentPolicyTypeFilter = { property: PolicyEntityFields.RESTRICTIONS, value: PolicyEntityRestrictions.MANAGED_USERS_ALLOWED };
        break;
      case UserType.MANAGED:
        this.policyTypeFilterMessageKey = this.translations.role.filter.managed;
        this.currentPolicyTypeFilter = { property: PolicyEntityFields.RESTRICTIONS, value: PolicyEntityRestrictions.MANAGED_USERS_ALLOWED };
        break;
      default:
        this.policyTypeFilterMessageKey = '';
        this.currentPolicyTypeFilter = null;
        break;
    }

    this.buildFilterValues();
  }

  buildFilterValues() {
    const newFilterValue = [];

    if (this.currentPolicyTypeFilter) {
      newFilterValue.push(this.currentPolicyTypeFilter);
    }

    if (this.searchValue) {
      newFilterValue.push({ property: 'name', value: this.searchValue });
    }

    this.filterValues = newFilterValue;
  }

  buildFilters() {
    if (this.data?.feature === CreateEditViewFeature.POLICY && this.data?.componentMode === ComponentViewMode.CREATE) {
      this.policyTypes = [
        { value: UserType.STANDARD, translateKey: this.translations.user.loginTypes.standard },
      ];

      if (this.data.companySettings?.hasLegacyOperator) {
        this.policyTypes.push({ value: UserType.LEGACY_OPERATOR, translateKey: this.translations.user.loginTypes.legacyOperator });
      }
      if (this.data.companySettings?.hasAllowManaged) {
        this.policyTypes.push({ value: UserType.MANAGED, translateKey: this.translations.user.loginTypes.managed });
      }

      this.currentPolicyTypeFilter = { property: PolicyEntityFields.RESTRICTIONS, value: PolicyEntityRestrictions.ZONAR_ADMIN, reverseFilter: true };
    }
  }

  initFilters() {
    this.policyTypes = [];
    this.filterValues = null;
    this.searchValue = '';
    this.searchControl.setValue('', { emitEvent: false });

    this.buildFilters();
    this.buildFilterValues();
  }

  get Feature() {
    return CreateEditViewFeature;
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }
}
