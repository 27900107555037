import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { mergeMap, catchError } from 'rxjs/operators';
import * as ProductDataStoreActions from './products-data-store.action';
import { ProductDataService } from '@app/core/services/data/product-data.service';
import { CoreProductApiService } from '@app/core/services/api/core-product-api.service';
import { environment as env } from '@environments/environment';

@Injectable()
export class ProductsDataStoreEffects {
  constructor(
    private actions$: Actions,
    private productDataService: ProductDataService,
    private coreProductApiService: CoreProductApiService,
  ) { }

  getManagedApplications$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProductDataStoreActions.getManagedApplications),
      mergeMap((action) => {
        return this.productDataService.getApplicationOfProduct(action.payload.companyId).pipe(
          mergeMap((result) => {
            return [ProductDataStoreActions.getManagedApplicationsSuccessful(
              {
                payload: {
                  managedApps: result?.managedApps || [],
                  allApps: result?.allApps || [],
                }
              })];
          }),
          catchError((error) => {
            return [ProductDataStoreActions.getManagedApplicationsFailure({
              payload: {error}
            })];
          })
        );
      })
    )
  );

  getAllProduct$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProductDataStoreActions.getAllProducts),
      mergeMap((action) => {
        return this.coreProductApiService.getProducts().pipe(
          mergeMap((products: any) => {
            // filter out products not in whitelist
            const productWhitelist = products.filter(product => env.productWhitelist.includes(product.id));
            return [ProductDataStoreActions.getAllProductsSuccessful(
              {
                payload: {allProducts: productWhitelist}
              })];
          }),
          catchError((error) => {
            return [ProductDataStoreActions.getAllProductsFailure({
              payload: {error}
            })];
          })
        );
      })
    )
  );

  getAllApplications$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProductDataStoreActions.getAllApplications),
      mergeMap((action) => {
        return this.coreProductApiService.getApplications().pipe(
          mergeMap((res: any) => {
            return [ProductDataStoreActions.getAllApplicationsSuccessful(
              {
                payload: {allApplications: res}
              })];
          }),
          catchError((error) => {
            return [ProductDataStoreActions.getAllApplicationsFailure({
              payload: {error}
            })];
          })
        );
      })
    )
  );
}