import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

import { AuthHttpInterceptor, AuthModule } from '@auth0/auth0-angular';

// Pattern Library Modules
import { FourOhFourRoutes, ZonarUICoreModule, ZonarUiFaviconModule } from '@zonar-ui/core';
import { NoPermissionRoutes, ZonarOwnerIdInterceptor, ZonarUiAuthModule, ZonarUiPermissionsModule } from '@zonar-ui/auth';
import { ZonarUITableModule } from '@zonar-ui/table';
import { ZonarUIMaterialModule } from '@zonar-ui/material';
import { ZonarUiI18nModule } from '@zonar-ui/i18n';
import { SidenavModule as ZonarUiSidenavModule } from '@zonar-ui/sidenav';
import { ZonarUiFooterModule } from '@zonar-ui/footer';

// Local Modules
import { environment } from '@environments/environment';
import { CoreModule } from '@app/core/core.module';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { permissions } from '@environments/shared';
import { SharedModule } from '@app/shared/shared.module';
import { HandleGlobalErrorInterceptor } from './core/interceptors/handle-global-error.interceptor';
import { FlexModule, LayoutGapStyleBuilder } from '@angular/flex-layout';
import { CustomLayoutGapStyleBuilder } from './custom-layout-gap-style';
import { GenericLanguageMap } from './core/consts/app.const';
import { DataStoresModule } from '@app/shared/data-stores/data-stores.module';
import { HttpRequestInterceptor } from './core/interceptors/http-request-interceptor.interceptor';

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    AppRoutingModule,
    ZonarUICoreModule,
    ZonarUITableModule,
    ZonarUiAuthModule,
    ZonarUiPermissionsModule,
    ZonarUiFaviconModule,
    ZonarUiSidenavModule,
    ZonarUiFooterModule,
    CoreModule,
    AppRoutingModule,
    FlexModule,
    SharedModule,
    AuthModule.forRoot({
      clientId: environment.auth.clientID,
      domain: environment.auth.domain,
      audience: environment.auth.audience,
      redirect_uri: window.location.origin,
      scope: 'openid profile email',
      useRefreshTokens: true,
      max_age: 36000,
      cookieDomain: '.zonarsystems.net',
      httpInterceptor: {
        allowedList: [
          // core entity api base urls. these are used by the auth module to make calls to the user-api
          `${environment.coreEntityApiBase.url}/*`,
          `${environment.coreEntityApiBase.url}beta2/*`, // TODO: this is temporary workaround for setting API
          `${environment.coreEntityApiBase.urlCVIConfig}/*`,
          `${environment.gcpMigration.migrationCompleteUrl}`,
          `${environment.userPreferenceApiBase.url}/*`,
        ]
      }
    }),
    NoPermissionRoutes,
    FourOhFourRoutes,
    ZonarUiI18nModule,
    ZonarUIMaterialModule,
    DataStoresModule
  ],
  providers: [
    // Auth settings
    { provide: 'applicationId', useValue: environment.auth.applicationId },
    { provide: 'defaultPermPrefix', useValue: permissions.defaultPermPrefix },
    { provide: 'routePermissions', useValue: permissions.routes },
    { provide: 'redirectUrl', useValue: permissions.defaultRedirectUrl },
    { provide: 'logoutReturnToUrl', useValue: environment.appUrl },
    { provide: 'environment', useValue: environment.authEnv },
    { provide: 'region', useValue: environment.region },

    { provide: HTTP_INTERCEPTORS, useClass: AuthHttpInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ZonarOwnerIdInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: HandleGlobalErrorInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: HttpRequestInterceptor, multi: true },
    { provide: 'supportedLanguages', useValue: environment.i18n.supportedLanguages },
    { provide: 'defaultLanguage', useValue: environment.i18n.defaultLanguage },
    { provide: 'useBrowserLanguage', useValue: true },
    { provide: 'genericLanguageMap', useValue: GenericLanguageMap },
    ZonarUIMaterialModule,
    ZonarUiAuthModule,
    ZonarUiPermissionsModule,
    { provide: LayoutGapStyleBuilder, useClass: CustomLayoutGapStyleBuilder },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
