import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';

/* reducer */
import * as fromTenantDataStore from './tenant-data-store.reducer';

/* effects */
import { TenantDataStoreEffects } from './tenant-data-store.effects';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature(
      fromTenantDataStore.featureKey,
      fromTenantDataStore.reducer,
      { initialState: fromTenantDataStore.initialState }
    ),
    EffectsModule.forFeature([ TenantDataStoreEffects ])
  ],
  exports: [
    StoreModule,
    EffectsModule
  ]
})
export class TenantDataStoreModule { }
