import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ICreateEditViewTree } from '@app/core/models/policy.model';

@Component({
  selector: 'app-create-edit-with-tenant-dialog',
  templateUrl: './create-edit-with-tenant-dialog.component.html',
  styleUrls: ['./create-edit-with-tenant-dialog.component.scss']
})
export class CreateEditWithTenantDialogComponent implements OnInit {
  inputData: ICreateEditViewTree;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit(): void {
    this.inputData = {
      componentMode: this.data?.mode,
      dataList: this.data?.dataList,
      enableDeterminate: this.data?.enableDeterminate,
      initInfo: this.data?.initInfo,
      translated: this.data?.dialogText,
      isDialog: this.data?.isDialog,
      feature: this.data?.feature,
      dataValidation: this.data?.dataValidation,
      allItemsChecked: this.data?.allItemsChecked,
      disableSubItemSelection: this.data?.disableSubItemSelection,
      companyLevelCapaSelectedList: this.data?.companyLevelCapaSelectedList,
      allRoleCompanyLevelCapaList: this.data?.allRoleCompanyLevelCapaList
    };
  }

}
