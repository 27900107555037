import { Injectable } from '@angular/core';
import { NA_OBJECT_PARSER } from '@app/core/consts/region-keyword.const';
import { TranslateService } from '@zonar-ui/i18n';

@Injectable({
  providedIn: 'root'
})
export class RegionParserService {
  constructor(public translateService: TranslateService) { }

  getParsedKeyWords() {
    return NA_OBJECT_PARSER;
  }

  getParsedBucketKeyWords(translateString: string, keys: Array<string>) {
    const result = {};
    keys.forEach((key => {
      result[key] = this.translateService.instant(translateString + '.' + key, NA_OBJECT_PARSER);
    }));
    return result;
  }
}
