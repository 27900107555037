import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpResponse
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { LoadingService } from '@app/core/services/loading.service';
import { catchError, finalize, map } from 'rxjs/operators';
import { HttpHeaderFields } from '@app/core/consts/app.const';

@Injectable()
export class HttpRequestInterceptor implements HttpInterceptor {

  constructor(
    private _loading: LoadingService
  ) { }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    let httpRequest = request.clone({});
    if (request.headers.get(HttpHeaderFields.requiredLoading)) {
      httpRequest = httpRequest.clone({ headers: request.headers.delete(HttpHeaderFields.requiredLoading) });
      this._loading.setLoading(true, request.url);
    }
    return next.handle(httpRequest)
      .pipe(catchError((err) => {
        this._loading.setLoading(false, request.url);
        return throwError(err);
      }))
      .pipe(map<HttpEvent<any>, any>((evt: HttpEvent<any>) => {
        if (evt instanceof HttpResponse) {
          this._loading.setLoading(false, request.url);
        }
        return evt;
      }),
        finalize(() => {
          this._loading.setLoading(false, request.url);
        })
      );
  }
}
