import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { IInnerState, IToggleContext } from '@app/core/models/asset.model';

@Component({
  selector: 'app-multi-states-toggle',
  templateUrl: './multi-states-toggle.component.html',
  styleUrls: ['./multi-states-toggle.component.scss']
})
export class MultiStatesToggleComponent implements OnInit {

  @Input() innerStatesArray: Array<IInnerState>;
  @Input() displayingContext: IToggleContext;
  @Output() toggleEmit = new EventEmitter<any>();
  selectedValue = null;
  primaryToggleState: boolean = null;

  constructor() { }

  ngOnInit() {
    this.onRadioSelection();
  }

  onRadioSelection() {
    this.toggleEmit.emit({selectedValue: this.selectedValue, hasReminder: this.hasWarning()});
  }

  toggleChanged(value) {
    this.primaryToggleState = value;
    // reset value upon toggle off
    if (!value) {
      this.selectedValue = null;
    }
    this.onRadioSelection();
  }

  hasWarning() {
    return this.primaryToggleState && this.selectedValue === null;
  }
}
