/*import angular */
import { createReducer, on } from '@ngrx/store';
import { CompaniesDataStore, ErrorAPIPayload } from './companies-data-store.interface';
import * as CompaniesDataStoreActions from './companies-data-store.action';

/* state key */
export const featureKey = 'companies';

/* initial state */
const initialErrorState: ErrorAPIPayload = {
  status: null,
  message: null
};

export const initialState: CompaniesDataStore = {
  isLoading: null,
  companyList: {
    companyList: [],
    companyListCount: 0,
    error: initialErrorState
  },
  company: {
    company: null,
    addresses: [],
    companyError: initialErrorState,
    companyAddressesError: initialErrorState
  },
  companyProduct: {
    error: null
  },
  companyProducts: {
    companyProducts: [],
    error: null
  },
};

export const reducer = createReducer(
  initialState,
  on(CompaniesDataStoreActions.initCompanies, (state, action) => ({
    ...state
  })),
  on(CompaniesDataStoreActions.resetCompaniesState, (state, action) => (initialState)),
  /* company list reducers */
  on(CompaniesDataStoreActions.getCompanyList, (state, action) => ({
    ...state,
    isLoading: true,
    companyList: {
      ...state.companyList,
      error: {
        ...state.companyList.error,
        status: null,
        message: ''
      }
    }
  })),
  on(CompaniesDataStoreActions.getCompanyListSuccessful, (state, action) => ({
    ...state,
    isLoading: false,
    companyList: {
      ...state.companyList,
      companyList: action.payload.companyList,
      companyListCount: action.payload.companyListCount
    }
  })),
  on(CompaniesDataStoreActions.getCompanyListFail, (state, action) => ({
    ...state,
    isLoading: false,
    companyList: {
      ...state.companyList,
      companyList: [],
      companyListCount: 0,
      error: {
        ...state.companyList.error,
        status: action.payload.status,
        message: action.payload.message
      }
    }
  })),
  /* company view reducers */
  on(CompaniesDataStoreActions.getCompany, (state, action) => ({
    ...state,
    isLoading: true,
    company: {
      ...state.company,
      companyError: {
        ...state.company.companyError,
        status: null,
        message: ''
      }
    }
  })),
  on(CompaniesDataStoreActions.getCompanySuccessful, (state, action) => ({
    ...state,
    isLoading: false,
    company: {
      ...state.company,
      company: action.payload.company
    }
  })),
  on(CompaniesDataStoreActions.getCompanyFail, (state, action) => ({
    ...state,
    isLoading: false,
    company: {
      ...state.company,
      companyError: {
        ...state.company.companyError,
        status: action.payload.status,
        message: action.payload.message
      }
    }
  })),
  on(CompaniesDataStoreActions.getCompanyAddresses, (state, action) => ({
    ...state,
    isLoading: true,
    company: {
      ...state.company,
      companyAddressesError: {
        ...state.company.companyAddressesError,
        status: null,
        message: ''
      }
    }
  })),
  on(CompaniesDataStoreActions.getCompanyAddressesSuccessful, (state, action) => ({
    ...state,
    isLoading: false,
    company: {
      ...state.company,
      addresses: action.payload.companyAddresses
    }
  })),
  on(CompaniesDataStoreActions.getCompanyAddressesFail, (state, action) => ({
    ...state,
    isLoading: false,
    company: {
      ...state.company,
      companyAddressesError: {
        ...state.company.companyAddressesError,
        status: action.payload.status,
        message: action.payload.message
      }
    }
  })),
  on(CompaniesDataStoreActions.deleteCompanyAssociatedProduct, (state, action) => ({
    ...state,
    isLoading: true,
  })),
  on(CompaniesDataStoreActions.deleteCompanyAssociatedProductSuccessful, (state, action) => ({
    ...state,
    isLoading: false,
  })),
  on(CompaniesDataStoreActions.deleteCompanyAssociatedProductFail, (state, action) => ({
    ...state,
    isLoading: false,
    companyProduct: {
      error: action.payload.error
    }
  })),

  on(CompaniesDataStoreActions.getCompanyAssociatedProducts, (state, action) => ({
    ...state,
    isLoading: true,
  })),

  on(CompaniesDataStoreActions.getCompanyAssociatedProductsSuccessful, (state, action) => ({
    ...state,
    isLoading: false,
    companyProducts: {
      companyProducts: action.payload.companyProducts,
    }
  })),

  on(CompaniesDataStoreActions.getCompanyAssociatedProductsFailure, (state, action) => ({
    ...state,
    isLoading: false,
    companyProducts: {
      error: action.payload.error,
      companyProducts: null
    }
  })),
);
