export interface AppBaseUrl {
  [key: string]: EnvBaseUrl;
}

export interface EnvBaseUrl {
  [key: string]: AppConfig;
}

export interface AppConfig {
  route: string;
  appId: string;
}

export const ADMIN_PLUS_BASE_URL: AppBaseUrl = {
  NA: {
    development: {
      route: 'https://administration.dev.zonarsystems.net',
      appId: 'b9203a2f-1a72-42ff-bcba-b7c017a1d49d'
    },
    qa: {
      route: 'https://administration.qa.dev.zonarsystems.net',
      appId: '7bd2fe46-cec2-435a-a42d-dff34669605e'
    },
    staging: {
      route: 'https://administration.staging.production.zonarsystems.net',
      appId: '5e6403e5-b8db-4637-ab3a-3bec41efd725'
    },
    production: {
      route: 'https://administration.production.zonarsystems.net',
      appId: '5e6403e5-b8db-4637-ab3a-3bec41efd725'
    },
  }
};
