import { Component, Input } from "@angular/core";
import { LabelTypes } from "@app/core/models/label.model";

@Component({
  selector: 'app-label',
  templateUrl: './label.component.html',
  styleUrls: ['./label.component.scss']
})
export class LabelComponent {
  @Input() text: string;
  @Input() type: LabelTypes = LabelTypes.GENERAL;

}
