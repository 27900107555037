import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Subject, forkJoin } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import * as PolicyActions from './policy-data-store.action';
import { CorePolicyApiService } from '@app/core/services/api/core-policy-api.service';
import { IPolicy, IPolicyTemplate } from '@app/core/models/policy.model';
import { HttpResponse } from '@angular/common/http';
import { PolicyDataService } from '@app/core/services/data/policy-data.service';

@Injectable()
export class PolicyDataStoreEffects {
  private cancelSubject$ = new Subject<boolean>();

  constructor(
    private actions$: Actions,
    private corePolicyApiService: CorePolicyApiService,
    private policyDataService: PolicyDataService,
  ) { }

  getPolicies$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PolicyActions.getPolicies),
      mergeMap((action) => {
        return this.corePolicyApiService.getPolicies(action.payload.params).pipe(
          mergeMap((res: Array<IPolicy>) => {
            return [PolicyActions.getPoliciesSuccess(
              {
                payload: { policies: res }
              })];
          }),
          catchError((error) => {
            return [PolicyActions.getPoliciesFailure({
              payload: { error }
            })];
          })
        );
      })
    )
  );

  getPolicyById$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PolicyActions.getPolicyById),
      mergeMap((action) => {
        return this.corePolicyApiService.getPolicyById(action.payload.policyId).pipe(
          mergeMap((res: any) => {
            return [PolicyActions.getPolicyByIdSuccess(
              {
                payload: {
                  policyDetail: res.body
                }
              })];
          }),
          catchError((error) => {
            return [PolicyActions.getPolicyByIdFailure({
              payload: { error }
            })];
          })
        );
      })
    )
  );

  addApplicationToPolicy$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PolicyActions.addApplicationToPolicy),
      mergeMap((action) => {
        const { policyId, params } = action.payload;
        return this.corePolicyApiService.addApplicationToPolicy(policyId, params).pipe(
          mergeMap((res: HttpResponse<any>) => {
            return [PolicyActions.addApplicationToPolicySuccess(
              {
                payload: { policy: res.body }
              })];
          }),
          catchError((error) => {
            return [PolicyActions.addApplicationToPolicyFailure({
              payload: { error }
            })];
          })
        );
      })
    )
  );

  removeApplicationOfPolicy$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PolicyActions.removeApplicationOfPolicy),
      mergeMap((action) => {
        const { policyId, appId } = action.payload;
        return this.corePolicyApiService.removeApplicationOfPolicy(policyId, appId).pipe(
          mergeMap((res: HttpResponse<any>) => {
            return [PolicyActions.removeApplicationOfPolicySuccess(
              {
                payload: { policy: res.body }
              })];
          }),
          catchError((error) => {
            return [PolicyActions.removeApplicationOfPolicyFailure({
              payload: { error }
            })];
          })
        );
      })
    )
  );

  addCapabilitiesToAppOfPolicy$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PolicyActions.addCapabilitiesToAppOfPolicy),
      mergeMap((action) => {
        const { policyId, appId, params } = action.payload;
        return this.corePolicyApiService.addCapabilitiesToAppOfPolicy(policyId, appId, params).pipe(
          mergeMap((res: HttpResponse<any>) => {
            return [PolicyActions.addCapabilitiesToAppOfPolicySuccess(
              {
                payload: { policy: res.body }
              })];
          }),
          catchError((error) => {
            return [PolicyActions.addCapabilitiesToAppOfPolicyFailure({
              payload: { error }
            })];
          })
        );
      })
    )
  );

  removeCapabilitiesOfAppOfPolicy$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PolicyActions.removeCapabilitiesOfAppOfPolicy),
      mergeMap((action) => {
        const { policyId, appId, params } = action.payload;
        return this.corePolicyApiService.removeCapabilitiesOfAppOfPolicy(policyId, appId, params).pipe(
          mergeMap((res: HttpResponse<any>) => {
            return [PolicyActions.removeCapabilitiesOfAppOfPolicySuccess(
              {
                payload: { policy: res.body }
              })];
          }),
          catchError((error) => {
            return [PolicyActions.removeCapabilitiesOfAppOfPolicyFailure({
              payload: { error }
            })];
          })
        );
      })
    )
  );

  getPolicyTemplates$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PolicyActions.getPolicyTemplates),
      mergeMap((action) => {
        const { params } = action.payload;
        return this.corePolicyApiService.getPolicyTemplates(params).pipe(
          mergeMap((res: HttpResponse<IPolicyTemplate[]>) => {
            return [PolicyActions.getPolicyTemplatesSuccess(
              {
                payload: { policyTemplates: res.body }
              })];
          }),
          catchError((error) => {
            return [PolicyActions.getPolicyTemplatesFailure({
              payload: { error }
            })];
          })
        );
      })
    )
  );

  createPolicyTemplate$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PolicyActions.createPolicyTemplate),
      mergeMap((action) => {
        const { params } = action.payload;
        return this.corePolicyApiService.createPolicyTemplate(params).pipe(
          mergeMap((res: HttpResponse<IPolicyTemplate>) => {
            return [PolicyActions.createPolicyTemplateSuccess(
              {
                payload: { policyTemplate: res.body }
              })];
          }),
          catchError((error) => {
            return [PolicyActions.createPolicyTemplateFailure({
              payload: { error }
            })];
          })
        );
      })
    )
  );

  getPolicyTemplateById$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PolicyActions.getPolicyTemplateById),
      mergeMap((action) => {
        const { policyTemplateId } = action.payload;
        return this.corePolicyApiService.getPolicyTemplateById(policyTemplateId).pipe(
          mergeMap((res: HttpResponse<IPolicyTemplate>) => {
            return [PolicyActions.getPolicyTemplateByIdSuccess(
              {
                payload: { policyTemplate: res.body }
              })];
          }),
          catchError((error) => {
            return [PolicyActions.getPolicyTemplateByIdFailure({
              payload: { error }
            })];
          })
        );
      })
    )
  );

  updatePolicyTemplate$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PolicyActions.updatePolicyTemplate),
      mergeMap((action) => {
        const { policyTemplateId, params } = action.payload;
        return this.corePolicyApiService.updatePolicyTemplate(policyTemplateId, params).pipe(
          mergeMap((res: HttpResponse<IPolicyTemplate>) => {
            return [PolicyActions.updatePolicyTemplateSuccess(
              {
                payload: { policyTemplate: res.body }
              })];
          }),
          catchError((error) => {
            return [PolicyActions.updatePolicyTemplateFailure({
              payload: { error }
            })];
          })
        );
      })
    )
  );

  createGroupFromPolicyTemplate$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PolicyActions.createGroupFromPolicyTemplate),
      mergeMap((action) => {
        const { policyTemplateId, params } = action.payload;
        return this.corePolicyApiService.createGroupFromPolicyTemplate(policyTemplateId, params).pipe(
          mergeMap((res: HttpResponse<IPolicyTemplate>) => {
            return [PolicyActions.createGroupFromPolicyTemplateSuccess(
              {
                payload:  res.body 
              })];
          }),
          catchError((error) => {
            return [PolicyActions.createGroupFromPolicyTemplateFailure({
              payload: { error }
            })];
          })
        );
      })
    )
  );

  createPolicy$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PolicyActions.createPolicy),
      mergeMap((action) => {
        return this.corePolicyApiService.createPolicy(action.payload).pipe(
          mergeMap((res: HttpResponse<IPolicy>) => {
            return [PolicyActions.createPolicySuccess(
              {
                payload: {policy: res.body }
              })];
          }),
          catchError((error) => {
            return [PolicyActions.createPolicyFailure({
              payload: { error }
            })];
          })
        );
      })
    )
  );

  updatePolicy$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PolicyActions.updatePolicy),
      mergeMap((action) => {
        const {policyId, confirmData, applications} = action.payload;
        const policyUpdateApi = [];
        if (confirmData?.data?.initInfo?.name !== confirmData?.name) {
          policyUpdateApi.push(this.corePolicyApiService.updatePolicyInfo(policyId, { name: confirmData?.name }));
        }

        if (confirmData?.dataChanged.length) {
          policyUpdateApi.push(this.corePolicyApiService.updatePolicyGrants(policyId, { grants: this.policyDataService.buildGrants(confirmData?.dataChanged, applications) }));
        }

        return forkJoin(policyUpdateApi).pipe(
          mergeMap(() => {
            return [PolicyActions.updatePolicySuccess()];
          }),
          catchError((error) => {
            return [PolicyActions.updatePolicyFailure({
              payload: { error }
            })];
          })
        );
      })
    )
  );

  cancelRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PolicyActions.cancelRequest),
      map(() => {
        this.cancelSubject$.next();
      })
    ), { dispatch: false }
  );
}