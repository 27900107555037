import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import * as DriversDataStoreActions from "./drivers-data-store.actions";
import { catchError, filter, mergeMap, switchMap } from "rxjs/operators";
import { CoreDriverApiService } from "@app/core/services/api/core-driver-api.service";
import { forkJoin } from "rxjs";

@Injectable()
export class DriversDataStoreEffects {
  constructor(
    private actions$: Actions,
    private driverApiService: CoreDriverApiService
  ) { }

  getDriverProfilesByUserId$ = createEffect(() => this.actions$.pipe(
    ofType(DriversDataStoreActions.getDriverProfilesByUserId),
    mergeMap((action) => {
      return this.driverApiService.getUserDrivers({ userId: action.payload.userId }).pipe(
        mergeMap((userDrivers) => {
          return this.driverApiService.getDriverProfiles({ driverId: userDrivers[0].id }).pipe(
            mergeMap((driverProfiles) => {
              return [DriversDataStoreActions.getDriverProfilesByUserIdSuccessful({
                payload: { driverProfiles: driverProfiles },
              })];
            }),
          );
        }),
        catchError((error) => {
          return [DriversDataStoreActions.getDriverProfilesByUserIdFailure({
            payload: { error }
          })];
        })
      );
    })
  ));

  getUserDriversByCompanyId$ = createEffect(() => this.actions$.pipe(
    ofType(DriversDataStoreActions.getUserDriversByCompanyId),
    mergeMap((action) => {
      const apiArray = [
        this.driverApiService.getUserDrivers({ companyId: action.payload.companyId }),
        this.driverApiService.getDriverProfiles({ companyId: action.payload.companyId })
      ];

      return forkJoin(apiArray).pipe(
        mergeMap(([userDrivers, driverProfiles]) => {
          if (userDrivers?.length && driverProfiles?.length) {
            const companyUserDrivers = userDrivers.filter(
              (driver) => driverProfiles.find(profile => profile?.driverId === driver?.id)
            );

            return [DriversDataStoreActions.getUserDriversByCompanyIdSuccessful({
              payload: { companyUserDrivers },
            })];
          }
        }),
        catchError((error) => {
          return [DriversDataStoreActions.getUserDriversByCompanyIdFailure({
            payload: { error }
          })];
        })
      );
    })
  ));
}
