import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { I18nService, TranslateService } from '@zonar-ui/i18n';
import { ZonarUiNotificationsService } from '@zonar-ui/notifications';
import { Subject } from 'rxjs';
import papa from 'papaparse';
import { saveAs } from 'file-saver';
import { formatDate } from '@angular/common';

@Component({
  selector: 'app-bulk-import',
  template: ''
})
export abstract class BulkImportComponent {
  file: any;
  isFinishedUpload = false;
  isUploading = false;
  progressValue = 0;
  records = [];
  duration = 7;
  notificationMessage: any = {};
  errCount = 0;
  uploadedSuccessCount = 0;
  isCancelProgressUpload = false;
  translationsNotLoaded = !!this.translateService.store.translations;
  errorHeader = 'Error';
  errorList = [];
  headerArray = [];
  onDestroy$ = new Subject<void>();

  constructor(
    public translateService: TranslateService,
    public i18nService: I18nService,
    public store: Store<any>,
    public zonarUiNotificationsService: ZonarUiNotificationsService,
    public dialogRef: MatDialogRef<any>,
  ) { }

  abstract initNotificationMessage();
  abstract onCancel();
  abstract getRecordData();
  abstract onSubmitData();
  abstract checkUploadingProgress();
  abstract fileUploadEmit(file: any);

  onUpload() {
    this.isUploading = true;
    this.isFinishedUpload = true;
    this.onSubmitData();
  }

  onConfirm() {
    let dialogFunc = this.onOpenErrorNotification();
    dialogFunc = this.uploadedSuccessCount ? this.onOpenWarningsNotification() : dialogFunc;
    dialogFunc = this.uploadedSuccessCount === this.records.length ? this.onOpenSuccessNotification() : dialogFunc;
    this.writeCSVFile();
    this.dialogRef.close(!this.isShowError());
  }

  isShowSuccess() {
    return !this.isUploading && this.isFinishedUpload && !this.errCount && this.uploadedSuccessCount === this.records.length;
  }
  isShowWarning() {
    return !this.isUploading && this.isFinishedUpload && this.uploadedSuccessCount
      && ((this.errCount && this.errCount < this.records.length) || (this.progressValue < this.records.length));
  }
  isShowError() {
    return !this.isUploading && this.isFinishedUpload
      && ((this.errCount === this.records.length) || (!this.uploadedSuccessCount && this.errCount));
  }

  onOpenSuccessNotification() {
    this.zonarUiNotificationsService.openSuccess(
      '',
      this.notificationMessage.successMessage,
      this.duration
    );
  }
  onOpenWarningsNotification() {
    this.zonarUiNotificationsService.openWarning(
      '',
      this.notificationMessage.warningMessage,
      this.duration
    );
  }
  onOpenErrorNotification() {
    this.zonarUiNotificationsService.openError(
      '',
      this.notificationMessage.errorMessage,
      this.duration
    );
  }

  writeCSVFile() {
    if (this.errorList.length) {
      const errorHeader = this.headerArray + ',' + this.errorHeader;
      this.errorList.unshift(errorHeader.split(','));
      const blob = new Blob([papa.unparse(this.errorList)], { type: 'text/csv' });
      saveAs(blob, `error_records_${formatDate(new Date(), 'yyyy-MM-dd-hh-mm-a', 'en').toString()}.csv`);
    }
  }

}
