/* angular */
import { IDriver, IDriverProfile } from '@app/core/models/driver.model';
import { createReducer, on } from '@ngrx/store';
import * as DriversDataStoreActions from './drivers-data-store.actions';

/* state key */
export const featureKey = 'drivers';

/* state interface */
export interface DriversDataStore {
  driverProfiles?: IDriverProfile[],
}


/* initial state */
export const initialState: DriversDataStore = {
  driverProfiles: null,
};

export const reducer = createReducer(
  initialState,

  on(DriversDataStoreActions.getDriverProfilesByUserId, (state, action) => ({
    ...state,
    isLoading: true,
  })),

  on(DriversDataStoreActions.getDriverProfilesByUserIdSuccessful, (state, action) => ({
    ...state,
    driverProfiles: action.payload.driverProfiles,
    isLoading: false,
  })),

  on(DriversDataStoreActions.getDriverProfilesByUserIdFailure, (state, action) => ({
    ...state,
    isLoading: false,
  })),

  on(DriversDataStoreActions.resetDriverProfiles, (state, action) => ({
    ...state,
    driverProfiles: null,
  })),
);

