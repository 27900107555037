import { Injectable } from '@angular/core';
import { forkJoin } from 'rxjs';
import { map } from 'rxjs/operators';
import { CoreProductApiService } from '@app/core/services/api/core-product-api.service';
import { CoreCompanyApiService } from '@app/core/services/api/core-company-api.service';
import { environment as env } from '@environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ProductDataService {

  constructor(
    private coreProductApiService: CoreProductApiService,
    private coreCompanyApiService: CoreCompanyApiService
  ) { }

  getApplicationOfProduct(companyId: string) {
    return forkJoin([
      this.coreProductApiService.getApplications(),
      this.coreCompanyApiService.getCompanyProducts(companyId, { status: 'ACTIVE' })
    ]).pipe(map(([allApps, companyProducts]: any) => {
      return {
        allApps,
        managedApps: allApps.filter(app => {
          if (!app.products?.length) {
            return false;
          }
          return app.products.find(productId =>
            companyProducts.find(product => product.id === productId && env.productWhitelist.includes(productId))) ? true : false;
          })
      };
    }));
  }

  getAndBuildCompanySupportedProducts(companyId: string) {
    return this.coreCompanyApiService.getCompanyProducts(companyId, { status: 'ACTIVE' })
      .pipe(
        map((associatedProducts) => {
          const supportedProducts = [];
          env.supportedProducts.forEach(envProduct => {
            const productEntry = {
              productId: envProduct.productId,
              translatedPath: envProduct.translatedPath,
              enabled: false,
              name: envProduct.name
            };
            if (associatedProducts
              .find(associatedProduct => associatedProduct.id === envProduct.productId)) {
              productEntry.enabled = true;
            }
            supportedProducts.push(productEntry);
          });
          return supportedProducts;
        })
      );
  }
}
