/*import angular */
import { createReducer, on } from '@ngrx/store';
import { ProductsDataStore } from './products-data-store.interface';
import * as ProductsDataStoreActions from './products-data-store.action';

/* state key */
export const featureKey = 'products';

/* initial state */
export const initialState: ProductsDataStore = {
  isLoading: null,
  managedApplicationList: null,
  allProducts: null,
  allApplications: null,
};

export const reducer = createReducer(
  initialState,
  on(ProductsDataStoreActions.initProducts, (state, action) => ({
    ...state
  })),

  on(ProductsDataStoreActions.getManagedApplications, (state, action) => ({
    ...state,
    isLoading: true
  })),

  on(ProductsDataStoreActions.getManagedApplicationsSuccessful, (state, action) => ({
    ...state,
    managedApplicationList: action.payload.managedApps,
    allApplications: action.payload.allApps,
    isLoading: false
  })),

  // TODO handle error
  on(ProductsDataStoreActions.getManagedApplicationsFailure, (state, action) => ({
    ...state,
    isLoading: false
  })),

  on(ProductsDataStoreActions.getAllProducts, (state, action) => ({
    ...state,
    isLoading: true
  })),

  on(ProductsDataStoreActions.getAllProductsSuccessful, (state, action) => ({
    ...state,
    allProducts: action.payload.allProducts,
    isLoading: false
  })),

  // TODO handle error
  on(ProductsDataStoreActions.getAllProductsFailure, (state, action) => ({
    ...state,
    isLoading: false
  })),

  on(ProductsDataStoreActions.getAllApplications, (state, action) => ({
    ...state,
    isLoading: true
  })),

  on(ProductsDataStoreActions.getAllApplicationsSuccessful, (state, action) => ({
    ...state,
    allApplications: action.payload.allApplications,
    isLoading: false
  })),

  // TODO handle error
  on(ProductsDataStoreActions.getAllApplicationsFailure, (state, action) => ({
    ...state,
    isLoading: false
  })),
);
