/* angular */
import { ICreateTenantParams, IGetTenantsParams, ITenant } from '@app/core/models/tenant.model';
import { createAction, props } from '@ngrx/store';
import { UpdateTenantPayload } from './tenant-data-store.interface';

/* action types */
export enum ActionTypes {
  // common action types
  InitTenant = '[TENANT] InitTenant',
  ResetTenant = '[TENANT] ResetTenant',
  CancelRequest = '[TENANT] CancelRequest',

  GetTenantList = '[TENANT] GetTenantList',
  GetTenantListSuccess = '[TENANT] GetTenantListSuccess',
  GetTenantListFailure = '[TENANT] GetTenantListFailure',

  CreateTenant = '[TENANT] CreateTenant',
  CreateTenantSuccess = '[TENANT] CreateTenantSuccess',
  CreateTenantFailure = '[TENANT] CreateTenantFailure',

  GetTenantById = '[TENANT] GetTenantById',
  GetTenantByIdSuccess = '[TENANT] GetTenantByIdSuccess',
  GetTenantByIdFailure = '[TENANT] GetTenantByIdFailure',

  UpdateTenant = '[TENANT] UpdateTenant',
  UpdateTenantSuccess = '[TENANT] UpdateTenantSuccess',
  UpdateTenantFailure = '[TENANT] UpdateTenantFailure',

  AddCompaniesToTenant = '[TENANT] AddCompaniesToTenant',
  AddCompaniesToTenantSuccess = '[TENANT] AddCompaniesToTenantSuccess',
  AddCompaniesToTenantFailure = '[TENANT] AddCompaniesToTenantFailure',

  RemoveCompaniesOfTenant = '[TENANT] RemoveCompaniesOfTenant',
  RemoveCompaniesOfTenantSuccess = '[TENANT] RemoveCompaniesOfTenantSuccess',
  RemoveCompaniesOfTenantFailure = '[TENANT] RemoveCompaniesOfTenantFailure',

  AddDivisionsToTenant = '[TENANT] AddDivisionsToTenant',
  AddDivisionsToTenantSuccess = '[TENANT] AddDivisionsToTenantSuccess',
  AddDivisionsToTenantFailure = '[TENANT] AddDivisionsToTenantFailure',

  RemoveDivisionsOfTenant = '[TENANT] RemoveDivisionsOfTenant',
  RemoveDivisionsOfTenantSuccess = '[TENANT] RemoveDivisionsOfTenantSuccess',
  RemoveDivisionsOfTenantFailure = '[TENANT] RemoveDivisionsOfTenantFailure',

  ResetTenantList = '[TENANT] ResetTenantList',
};

// common actions
export const initTenant = createAction(ActionTypes.InitTenant);
export const resetTenant = createAction(ActionTypes.ResetTenant);
export const cancelRequest = createAction(ActionTypes.CancelRequest);

export const getTenantList = createAction(ActionTypes.GetTenantList, props<{ payload: { params?: IGetTenantsParams } }>());
export const getTenantListSuccess = createAction(ActionTypes.GetTenantListSuccess, props<{ payload: { tenantList: Array<ITenant>} }>());
export const getTenantListFailure = createAction(ActionTypes.GetTenantListFailure, props<{ payload: { error: any } }>());

export const createTenant = createAction(ActionTypes.CreateTenant, props<{ payload: { params?: ICreateTenantParams } }>());
export const createTenantSuccess = createAction(ActionTypes.CreateTenantSuccess, props<{ payload: { tenant?: ITenant} }>());
export const createTenantFailure = createAction(ActionTypes.CreateTenantFailure, props<{ payload: { error: any } }>());

export const getTenantById = createAction(ActionTypes.GetTenantById, props<{ payload: { tenantId: string } }>());
export const getTenantByIdSuccess = createAction(ActionTypes.GetTenantByIdSuccess, props<{ payload: { tenant: ITenant} }>());
export const getTenantByIdFailure = createAction(ActionTypes.GetTenantByIdFailure, props<{ payload: { error: any } }>());

export const updateTenant = createAction(ActionTypes.UpdateTenant, props<{ payload: UpdateTenantPayload }>());
export const updateTenantSuccess = createAction(ActionTypes.UpdateTenantSuccess, props<{ payload: { tenantId: string } }>());
export const updateTenantFailure = createAction(ActionTypes.UpdateTenantFailure, props<{ payload: { error: any } }>());

export const addCompaniesToTenant = createAction(ActionTypes.AddCompaniesToTenant, props<{ payload: { tenantId: string, params: string[] } }>());
export const addCompaniesToTenantSuccess = createAction(ActionTypes.AddCompaniesToTenantSuccess);
export const addCompaniesToTenantFailure = createAction(ActionTypes.AddCompaniesToTenantFailure, props<{ payload: { error: any } }>());

export const removeCompaniesOfTenant = createAction(ActionTypes.RemoveCompaniesOfTenant, props<{ payload: { tenantId: string, params: string[] } }>());
export const removeCompaniesOfTenantSuccess = createAction(ActionTypes.RemoveCompaniesOfTenantSuccess);
export const removeCompaniesOfTenantFailure = createAction(ActionTypes.RemoveCompaniesOfTenantFailure, props<{ payload: { error: any } }>());

export const addDivisionsToTenant = createAction(ActionTypes.AddDivisionsToTenant, props<{ payload: { tenantId: string, params: string[] } }>());
export const addDivisionsToTenantSuccess = createAction(ActionTypes.AddDivisionsToTenantSuccess);
export const addDivisionsToTenantFailure = createAction(ActionTypes.AddDivisionsToTenantFailure, props<{ payload: { error: any } }>());

export const removeDivisionsOfTenant = createAction(ActionTypes.RemoveDivisionsOfTenant, props<{ payload: { tenantId: string, params: string[] } }>());
export const removeDivisionsOfTenantSuccess = createAction(ActionTypes.RemoveDivisionsOfTenantSuccess);
export const removeDivisionsOfTenantFailure = createAction(ActionTypes.RemoveDivisionsOfTenantFailure, props<{ payload: { error: any } }>());
export const resetTenantList = createAction(ActionTypes.ResetTenantList);
