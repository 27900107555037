import { Injectable } from '@angular/core';
import { EntityApiBaseService } from '@app/core/services/api/entity-api-base.service';


@Injectable({
  providedIn: 'root'
})
export class VINApiService {

  URLServer = 'https://vpic.nhtsa.dot.gov/api/vehicles/';

  constructor(
    private entityApiBaseService: EntityApiBaseService
  ) { }


  getAllMakes() {
    const url = `${this.URLServer}getallmakes`;
    return this.entityApiBaseService.getData(url, { format: 'json' }, null);
  }

  getModelsByMake(makeName: string) {
    const url = `${this.URLServer}getmodelsformake/${makeName}`;
    return this.entityApiBaseService.getData(url, { format: 'json' }, null);
  }

  parsingVinAPI(vin: string) {
    const url = `${this.URLServer}decodevin/${vin}`;
    return this.entityApiBaseService.getData(url, { format: 'json' }, null);
  }
}
