import { toTitleCase } from '@app/core/utils';
import { takeUntil } from 'rxjs/operators';
import { Component, Input, OnChanges, SimpleChanges, EventEmitter, Output, OnDestroy } from '@angular/core';
import { TranslateService } from '@zonar-ui/i18n';
import { cloneDeep } from 'lodash';
import { TreeBaseComponent } from '@app/shared/components/tree-base/tree-base.component';
import { TreeNode } from '@app/shared/components/tree-base/tree-node.model';
import { CreateEditViewTreeFilter } from '@app/core/models/policy.model';

@Component({
  selector: 'app-tree-with-checkboxes',
  templateUrl: './tree-with-checkboxes.component.html',
  styleUrls: ['./tree-with-checkboxes.component.scss']
})
export class TreeWithCheckboxesComponent extends TreeBaseComponent implements OnChanges, OnDestroy {
  @Input() treeContents: Array<TreeNode> = [];
  @Input() rootNodeName = '';
  @Input() disabledTree = false;
  @Input() hasTitle = true;
  @Input() isReadOnly: boolean;
  @Input() checkedAll: boolean;
  @Input() filterValues: CreateEditViewTreeFilter[] = [];
  @Input() enableDeterminate = false;
  @Input() labelName = '';
  @Input() feature = null;
  @Input() disableSubItemSelection = true;
  @Input() hasRootNode = false;
  @Input() isIgnoreSelectChildDisabled = false;
  @Output() selectedItem = new EventEmitter<any>();
  @Output() selectedAllItem = new EventEmitter<any>();

  allText = '';

  constructor(
    private translateService: TranslateService
  ) {
    super();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.treeContents?.currentValue) {
      this.treeNodeList = changes.treeContents.currentValue;
      this.buildValueAfterChanged(changes.treeContents.currentValue);
    }

    if (changes.filterValues) {
      if (changes.filterValues.currentValue?.length) {
        this.applyFilters(changes.filterValues.currentValue);
      } else {
        // reset filters
        this.treeDataSource.data = cloneDeep(this.tmpDataSource) || [];
        this.selectedNodeAfterFilter();
        this.treeControl.expandAll();
      }
    }

  }

  buildValueAfterChanged(treeContentsChanged: Array<TreeNode>): void {
    const initTreeItemsLength = treeContentsChanged.length;
    this.treeContents = cloneDeep(treeContentsChanged);
    const treeItemCheckedList: Array<TreeNode> = [];
    if (this.hasRootNode) {
      this.initRootNodeName(initTreeItemsLength, treeItemCheckedList);
    } else {
      this.treeChanged.next(this.buildNestedTree(this.treeContents));
      this.tmpDataSource = cloneDeep(this.treeDataSource.data);
      this.treeControl.expandAll();
      this.buildTreeNodes(initTreeItemsLength, treeItemCheckedList);
    }
  }

  initRootNodeName(initTreeItemsLength, treeItemCheckedList): void {
    this.translateService.get('checkboxList.all').pipe(
      takeUntil(this.onDestroy$)
    ).subscribe((res: string) => {
      this.allText = res;
      this.initTreeData(initTreeItemsLength, treeItemCheckedList);
    });
  }

  initTreeData(initTreeItemsLength, treeItemCheckedList): void {
    // Notify the change.
    this.treeChanged.next(this.addRootTree(this.buildNestedTree(this.treeContents)));
    this.tmpDataSource = cloneDeep(this.treeDataSource.data);
    this.treeControl.expand(this.treeControl.dataNodes[0]);
    this.buildTreeNodes(initTreeItemsLength, treeItemCheckedList);
  }

  buildTreeNodes(initTreeItemsLength, treeItemCheckedList) {
    let countTreeItemsChecked = 0;
    if (this.treeControl.dataNodes[0]) {
      this.treeControl.dataNodes[0].disabled = this.disabledTree;
    }
    this.treeControl.dataNodes.forEach((node: TreeNode) => {
      if (node.selected) {
        this.selectAndDisabledDescendants(node, this.disableSubItemSelection);
        this.expandParents(node);
        countTreeItemsChecked++;
        treeItemCheckedList.push(node);
      }

      if (node.children?.length && !this.descendantsAllSelected(node)) {
        this.checklistSelection.deselect(node);
      }
    });

    if (initTreeItemsLength && initTreeItemsLength === countTreeItemsChecked && this.checkedAll) {
      this.todoItemSelectionToggle(this.treeControl.dataNodes[0]);
    } else {
      this.checklistSelection.select(...treeItemCheckedList);
    }
  }

  addRootTree(treeItems: Array<TreeNode>): Array<TreeNode> {
    return [
      {
        id: null,
        name: toTitleCase(this.labelName ? this.labelName : this.allText + ' ' + this.rootNodeName),
        level: 0,
        children: treeItems
      }
    ];
  }

  selectOrDeselectedItems(isSelected) {
    this.treeControl.dataNodes.forEach(node => {
      if (isSelected) {
        this.checklistSelection.select(node);
        node.disabled = this.disableSubItemSelection ? (this.getParentNode(node) ? true : false) : false;
      } else {
        this.checklistSelection.deselect(node);
        node.disabled = false;
      }

      this.updateSelectedChildrenNode(this.tmpDataSource, node, this.checklistSelection.isSelected(node));
    });
  }

  /** Toggle the to-do item selection. Select/deselect all the descendants node */
  todoItemSelectionToggle(node: TreeNode, event = null): void {
    if (event) {
      event.preventDefault();
    }

    let descendants = this.treeControl.getDescendants(node);

    if (this.isIgnoreSelectChildDisabled) {
      this.updateChecklistSelection(node, descendants);
    } else {
      this.checklistSelection.toggle(node);
      this.checklistSelection.isSelected(node)
        ? this.checklistSelection.select(...descendants)
        : this.checklistSelection.deselect(...descendants);
      // Force update for disabled children
      descendants.forEach((child: TreeNode) => {
        child.disabled = this.disableSubItemSelection ? this.checklistSelection.isSelected(node) : false;
      });
      const parentNode = this.getParentNode(node);
      if (parentNode) {
        this.updateSelectedParentNode(this.tmpDataSource, node, this.checklistSelection.isSelected(node));
      } else {
        this.updateSelectedChildrenNode(this.tmpDataSource, node, this.checklistSelection.isSelected(node));
      }
    }

    // Toggle parent node
    if (node.id) {
      this.selectedItem.emit(
        {
          addComplete: false,
          data: {
            id: node.id,
            selected: this.checklistSelection.isSelected(node),
            name: node.name,
            descendants,
            parentId: this.getParentNode(node)?.id || null,
            dataSource: this.tmpDataSource
          }
        }
      );
    } else {
      // Toggle All node
      this.selectedAllItem.emit(this.checklistSelection.isSelected(node));
    }
  }

  updateChecklistSelection(node, descendants) {
    if (this.descendantsAllSelected(node)) {
      this.checklistSelection.deselect(node);
      this.checklistSelection.deselect(...descendants);
    } else {
      if (this.descendantsPartiallySelected(node)) {
        descendants = descendants.filter(des => !des.disabled);
        if (descendants.length === 1) {
          this.checklistSelection.deselect(node);
          this.checklistSelection.deselect(...descendants);
        } else {
          this.checklistSelection.toggle(node);
          this.checklistSelection.isSelected(node) ?  this.checklistSelection.select(...descendants) : this.checklistSelection.deselect(...descendants);
        }
      } else {
        this.checklistSelection.select(node);

        descendants = descendants.filter(des => !des.disabled);
        this.checklistSelection.select(...descendants);
      }
    }
    this.updateSelectedChildrenNode(this.tmpDataSource, node, this.checklistSelection.isSelected(node), this.descendantsAllSelected(node));
  }

  /** Toggle a leaf to-do item selection. Check all the parents to see if they changed */
  todoLeafItemSelectionToggle(node: TreeNode): void {
    this.checklistSelection.toggle(node);
    const parentNode = this.getParentNode(node);

    if (parentNode) {
      if (this.descendantsAllSelected(parentNode)) {
        this.checklistSelection.select(parentNode);
        const descendants = this.treeControl.getDescendants(parentNode);
        if (!this.isIgnoreSelectChildDisabled) {
          descendants.forEach((child: TreeNode) => {
            child.disabled = this.disableSubItemSelection;
          });
        }

      } else {
        this.checklistSelection.deselect(parentNode);
      }
      this.updateSelectedParentNode(this.tmpDataSource, parentNode, this.checklistSelection.isSelected(parentNode));
    }

    this.updateSelectedChildrenNode(this.tmpDataSource, node, this.checklistSelection.isSelected(node));
    this.selectedItem.emit(
      {
        addComplete: false,
        data: {
          id: node.id,
          selected: this.checklistSelection.isSelected(node),
          name: node.name,
          descendants: [],
          allChildrenSelected: this.descendantsAllSelected(parentNode),
          parentId: parentNode?.id || null,
          dataSource: this.tmpDataSource
        }
      }
    );
  }

  /** Whether all the descendants of the node are selected */
  descendantsAllSelected(node: TreeNode): boolean {
    const descendants = this.treeControl.getDescendants(node);
    return descendants.every(child => this.checklistSelection.isSelected(child));
  }

  descendantsAllUnSelected(node: TreeNode): boolean {
    const descendants = this.treeControl.getDescendants(node);
    return descendants.every(child => !this.checklistSelection.isSelected(child));
  }

  /** Whether part of the descendants are selected */
  descendantsPartiallySelected(node: TreeNode): boolean {
    const descendants = this.treeControl.getDescendants(node);
    const result = descendants.some(child => this.checklistSelection.isSelected(child));
    return result && !this.descendantsAllSelected(node);
  }

  copyObject(obj: any) {
    return Object.assign({}, obj);
  }

  filterRecursive(filterText: string, array: any[], property: string, reverseFilter: boolean = false) {
    let filteredData;

    filterText = filterText.toLowerCase();
    // copy obj so we don't mutate it and filter
    filteredData = array.map(this.copyObject).filter(item => this.filterTree(item, filterText, property, reverseFilter));

    return filteredData;
  }

  filterTree(item, filterText, property, reverseFilter: boolean = false) {
    if (this.filterBasedOnType(item, filterText, property, reverseFilter)) {
      item.children = item.children?.map(this.copyObject).filter(i => {
        return this.filterTree(i, filterText, property, reverseFilter);
      });
      return true;
    }
    // if children match
    if (item.children) {
      return (item.children = item.children.map(this.copyObject).filter(i => {
        return this.filterTree(i, filterText, property, reverseFilter);
      })).length;
    }
  }

  filterBasedOnType(item, filterText, property, reverseFilter: boolean = false) {
    if (Array.isArray(item[property])) {
      const propertyArray = item[property].map(i => typeof i === 'string' ? i.toLowerCase() : i);
      return reverseFilter ? !propertyArray.includes(filterText) : propertyArray.includes(filterText);
    }

    if (typeof item[property] === 'string') {
      const propertyString = item[property].toLowerCase();
      return reverseFilter ? !propertyString.includes(filterText) : propertyString.toLowerCase().includes(filterText);
    }

    return false;
  }

  applyFilters(filters: CreateEditViewTreeFilter[]) {
    // copy obj so we don't mutate it and filter
    this.treeDataSource.data = this.tmpDataSource.map(this.copyObject);

    filters.forEach((filter) => {
      this.treeDataSource.data = this.filterRecursive(filter.value, this.treeDataSource.data, filter.property, filter.reverseFilter);
    });

    this.selectedNodeAfterFilter();
    // show based on state of filter string
    this.treeControl.expandAll();
  }

  selectedNodeAfterFilter() {
    const treeItemCheckedList = [];

    this.treeChanged.next(this.treeDataSource.data);
    this.treeControl.dataNodes.forEach((node: TreeNode) => {
      if (node.selected) {
        this.selectAndDisabledDescendants(node, this.disableSubItemSelection);
        treeItemCheckedList.push(node);
      }
    });
    this.checklistSelection.select(...treeItemCheckedList);
  }
}
