<div
  class="snack-banner-container snack-banner-{{data.type}}"
>
  <div
    fxFlex="0 0 3rem"
    fxLayoutAlign="center center"

    class="snack-banner-icon-container snack-banner-{{data.type}}"
  >
    <mat-icon *ngIf="data.type === snackBarType.Info">info</mat-icon>
    <mat-icon *ngIf="data.type === snackBarType.Success">check_circle_outline</mat-icon>
    <mat-icon *ngIf="data.type === snackBarType.Warning">warning</mat-icon>
    <mat-icon *ngIf="data.type === snackBarType.Error">error</mat-icon>
  </div>
  <div
    fxFlex
    fxLayout="column"
    fxLayoutAlign="center start"
    class="snack-banner-content"
  >
    <p class="snack-banner-title">{{data.title}}</p>
    <p class="snack-banner-text">{{data.text}}
      <a *ngIf="data?.guideline" target="_blank" href="{{guidelineLink}}">{{data?.guideline}}</a>
    </p>
  </div>
  <div *ngIf="showButton"
    fxFlex="0 0 7.625rem"
    fxLayoutAlign="center center"
    class="action-button-containter"
  >
    <ng-container *ngIf="showActionButton">
      <button
        mat-button
        color="accent"
        class="action-button"
        (click)="handleButtonClick()"
      >{{buttonText}}</button>
    </ng-container>
  </div>
</div>

