<div class="migration-indeterminate-dialog" fxLayout="column" fxLayoutAlign="space-between start">
  <div class="migration-indeterminate-title" fxLayout="row" fxLayoutAlign="start center">
    <span>{{translated?.title}}</span>
  </div>
  <div class="migration-indeterminate-description">
    <span>{{translated?.description}}</span>
  </div>
  <div class="migration-indeterminate-progress" fxLayout="row" fxLayoutAlign="space-between start">
    <div class="icon-area" fxLayout="column" fxLayoutAlign="end center">
      <mat-icon class="upload-icon">{{data?.progressBarIcon}}</mat-icon>
    </div>
    <div class="progress-area" fxLayout="column" fxLayoutAlign="space-between start">
      <span class="progress-text">{{translated?.progressBarDescription}}</span>
      <mat-progress-bar class="progress-bar" mode="indeterminate"></mat-progress-bar>
    </div>
  </div>
</div>
