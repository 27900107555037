import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-overlay-spinner',
  templateUrl: './overlay-spinner.component.html',
  styleUrls: ['./overlay-spinner.component.scss']
})
export class OverlaySpinnerComponent {
  @Input() mode: string;
  @Input() diameter: number;

  constructor() { }

  getCenterPositionForSpinner() {
    if (this.diameter) {
      return {
        'top': `calc(50% - ${this.diameter/2}px)`,
        'left': `calc(50% - ${this.diameter/2}px)`,
      };
    } else {
      return {};
    }
  }
}
