<div class="preview-dialog" fxLayout="column" fxLayoutAlign="space-between start">
  <div fxLayout="row" fxLayoutAlign="start" class="title">
    <span>{{data?.title}}</span>
  </div>
  <div class="description">
    <span>{{data?.description}}</span>
  </div>
  <div fxLayout="column" fxFill fxLayoutAlign="start" class="contain-value">
    <div fxFill fxLayout="row" fxLayoutAlign="start" *ngFor="let item of data?.valueChanged; last as isLast">
      <div *ngIf="item" class="value-item" [ngClass]="{'remove-border': isLast}"
        fxLayout="{{item.values?.isDivision ? 'column' : 'row'}}" fxLayoutAlign="start">
        <span *ngIf="item.fieldName" class="field-name padding-tb" [fxFlex]="item.fieldName ? 50 : 0"
          [innerHTML]="item.fieldName + ':'"></span>
        <span *ngIf="typeOf(item.values) === 'string'" class="value-changed padding-tb"
          [ngClass]="{'changed-color': !item.values}" fxLayoutAlign="end" fxFlex="50">
          {{item.values ? item.values : data?.removed}}
        </span>
        <div *ngIf="item.values?.isDivision" fxLayout="column" class="value-changed tree-checkbox">
          <app-tree-with-checkboxes [treeContents]="mapArrayDisplay[item.fieldName]" [hasTitle]="false"
            [isReadOnly]="true" [checkedAll]="item.values?.checkedAllDivision" [rootNodeName]="item.values.labelListName"></app-tree-with-checkboxes>
        </div>
        <div *ngIf="typeOf(item.values) === 'object' && hasArray(item.values)" fxLayout="column" class="value-changed"
          fxFlex="50">
          <div *ngFor="let i of mapArrayDisplay[item.fieldName]" class="array-item padding-tb" fxLayoutAlign="end">
            <span>{{i.name}}</span>
          </div>
          <div *ngIf="item.values.length > 5 && mapArrayDisplay[item.fieldName].length < item.values.length"
            class="show-button padding-tb" fxLayoutAlign="end">
            <span (click)="showMore(item.fieldName)">{{data?.showMore}}</span>
          </div>
          <div *ngIf="item.values.length > 5 && mapArrayDisplay[item.fieldName].length === item.values.length"
            class="show-button padding-tb" fxLayoutAlign="end">
            <span (click)="showLess(item.fieldName)">{{data?.showLess}}</span>
          </div>
        </div>
        <div
          *ngIf="typeOf(item.values) === 'object' && !hasArray(item.values) && !item.values?.isPassword && !item.values?.isDivision"
          class="value-changed padding-tb" fxFlex="50">
          <span>{{item.values?.value}}</span>
          <span [ngClass]="item.values.iconData" class="icon-data"></span>
        </div>
        <div *ngIf="typeOf(item.values) === 'boolean'" class="value-changed padding-tb" fxFlex="50">
          <div *ngIf="item.iconType === iconType.STATUS">
            <span class="bg-gray" [ngClass]="{'bg-green': item.values}">
              {{item.values ? data?.active : data?.inactive}}
            </span>
          </div>
          <div *ngIf="item.iconType === iconType.HAZMATLOAD">
            <mat-icon [ngClass]="item.values ? 'local_shipping-active' : 'local_shipping-inactive'">local_shipping
            </mat-icon>
          </div>
          <div *ngIf="item.iconType === iconType.TOGGLE">
            <span class="bg-gray" [ngClass]="{'bg-green': item.values}">
              {{item.values ? data?.on : data?.off}}
            </span>
          </div>
        </div>
        <div fxLayout="column" *ngIf="item.values?.isPassword" fxFill class="password-container">
          <div fxLayout="row" fxLayoutAlign="start" class="title">
            <span>{{translations.previewDialog.newPassword | translate }}</span>
          </div>
          <span fxLayout="row" fxLayoutAlign="center center" class="password-value"
            [ngClass]="'password-default'">
            {{item.values.value}}
          </span>
          <div fxLayout="column" fxLayoutAlign="start">
            <span class="description-title">{{translations.previewDialog.passwordTitle | translate }}</span>
            <span class="description-message">{{translations.previewDialog.passwordDescription | translate }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="dialog-button" fxLayout="row" fxLayoutAlign="end end">
    <button mat-stroked-button type="button" fxLayoutAlign="center center" (click)="onCancel()">
      {{data?.textCancelButton}}</button>
    <button mat-raised-button type="submit" fxLayoutAlign="center center" class="button-ml"
      (click)="onConfirm()">{{data?.textProceedButton}}</button>
  </div>
</div>
