import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ProductsDataStore } from './products-data-store.interface';
import { featureKey } from './products-data-store.reducer';

export const selectProductState = createFeatureSelector<ProductsDataStore>(featureKey);

export const selectManagedApplications = createSelector(selectProductState, state => {
  return state?.managedApplicationList;
});

export const selectManagedAppLoadingState = createSelector(selectProductState, state => {
  return state?.isLoading;
});

export const selectAllProducts = createSelector(selectProductState, state => {
  return state?.allProducts;
});

export const selectAllApplications = createSelector(selectProductState, state => {
  return state?.allApplications;
});