import { HttpClient, HttpResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment as env } from '@environments/environment';
import { EntityApiBaseService } from '@app/core/services/api/entity-api-base.service';
import { ICreateGroupParams, IGetGroupsParams, IGroup, IMoveGroupParams, ISimpleGroup } from "@app/core/models/group.model";
import { Observable } from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class CoreGroupApiService {
  private GROUP_RESOURCE = 'groups';
  private SIMPLE_RESOURCE = 'simple';
  private NESTED_RESOURCE = 'nested';
  private MOVE_RESOURCE = 'move';
  private VALIDATE_RESOURCE = 'validate';
  private MEMBERS_RESOURCE = 'members';

  constructor(
    private http: HttpClient,
    private entityApiBaseService: EntityApiBaseService
  ) { }

  /**
   * Get all groups that matches query parameters, return a full model
   * @param params Query parameters to search for groups
   * @returns Http response of group list in full model
   */
  getGroups(params?: IGetGroupsParams): Observable<HttpResponse<Array<IGroup>>> {
    const url = `${env.coreEntityApiBase.url}/${this.GROUP_RESOURCE}`;
    return this.entityApiBaseService.getData(url, params);
  }

  /**
   * Create a group from parameters
   * @param params Group parameters to create
   * @returns Http response of created group in full model
   */
  createGroup(params: ICreateGroupParams): Observable<HttpResponse<IGroup>> {
    const url = `${env.coreEntityApiBase.url}/${this.GROUP_RESOURCE}`;
    return this.http.post(url, params, { observe: 'response', headers: this.entityApiBaseService.getRequiredLoadingHttpHeader() });
  }

  /**
   * Get all groups that matches query parameters, return a simple model
   * @param params Query parameters to search for groups
   * @returns Http response of group list in simple model
   */
  getSimpleGroups(params?: IGetGroupsParams): Observable<HttpResponse<Array<ISimpleGroup>>> {
    const url = `${env.coreEntityApiBase.url}/${this.GROUP_RESOURCE}/${this.SIMPLE_RESOURCE}`;
    return this.entityApiBaseService.getData(url, params);
  }

  /**
   * Get group by querying the group id, return a full model
   * @param groupId The group id
   * @returns Http response of group in full model
   */
  getGroupById(groupId: string): Observable<HttpResponse<IGroup>> {
    const url = `${env.coreEntityApiBase.url}/${this.GROUP_RESOURCE}/${groupId}`;
    return this.http.get(url, { observe: 'response', headers: this.entityApiBaseService.getRequiredLoadingHttpHeader() });
  }

  /**
   * Get group by querying the group id, return a simple model
   * @param groupId The group id
   * @returns Http response of group in simple model
   */
  getSimpleGroupById(groupId: string): Observable<HttpResponse<ISimpleGroup>> {
    const url = `${env.coreEntityApiBase.url}/${this.GROUP_RESOURCE}/${groupId}/${this.SIMPLE_RESOURCE}`;
    return this.http.get(url, { observe: 'response', headers: this.entityApiBaseService.getRequiredLoadingHttpHeader() });
  }

  /**
   * Get a group children ids array
   * @param groupId The group id
   * @param firstChildrenOnly Whether to return only the top-level children of this group
   * @returns Http response of an array of this group children's ids
   */
  getAllChildGroupIds(groupId: string, firstChildrenOnly: boolean = false): Observable<HttpResponse<Array<string>>> {
    const url = `${env.coreEntityApiBase.url}/${this.GROUP_RESOURCE}/${groupId}/${this.NESTED_RESOURCE}`;
    let params = {
      'first-children-only': firstChildrenOnly
    };
    return this.entityApiBaseService.getData(url, params);
  }

  /**
   * Update a parent group's nested children groups
   * @param parentGroupId The parent group id
   * @param params An array of group ids that nested underneath this parent group
   * @returns Http response of request status
   */
  updateParentChildGroupIds(parentGroupId: string, params: Array<string>): Observable<HttpResponse<any>> {
    const url = `${env.coreEntityApiBase.url}/${this.GROUP_RESOURCE}/${parentGroupId}/${this.NESTED_RESOURCE}`;
    return this.http.patch(url, params, { observe: 'response', headers: this.entityApiBaseService.getRequiredLoadingHttpHeader() });
  }

  /**
   * Delete the association of group ids to a parent group
   * @param parentGroupId The parent group id
   * @param params The array of group ids that is to be disassociate with this parent group
   * @returns Http response of request status
   */
  deleteParentChildGroupIds(parentGroupId: string, params: Array<string>): Observable<HttpResponse<any>> {
    const url = `${env.coreEntityApiBase.url}/${this.GROUP_RESOURCE}/${parentGroupId}/${this.NESTED_RESOURCE}`;
    return this.http.delete(url, { observe: 'response', headers: this.entityApiBaseService.getRequiredLoadingHttpHeader(), body: params });
  }

  /**
   * Move a nested group ids from one parent group to another target group
   * @param groupId The group id that have its nested group ids removed
   * @param params The array of group ids to be moved to a target group id
   * @returns Http response of request status
   */
  moveGroup(groupId: string, params: IMoveGroupParams): Observable<HttpResponse<any>> {
    const url = `${env.coreEntityApiBase.url}/${this.GROUP_RESOURCE}/${groupId}/${this.MOVE_RESOURCE}`;
    return this.http.post(url, params, { observe: 'response', headers: this.entityApiBaseService.getRequiredLoadingHttpHeader() });
  }

  /**
   * Validate if the move operation can be done
   * @param groupId The group id that have its nested group ids removed
   * @param params The array of group ids to be moved to a target group id
   * @returns Http response of request status
   */
  validateMoveGroupOperation(groupId: string, params: IMoveGroupParams): Observable<HttpResponse<any>> {
    const url = `${env.coreEntityApiBase.url}/${this.GROUP_RESOURCE}/${groupId}/${this.MOVE_RESOURCE}/${this.VALIDATE_RESOURCE}`;
    return this.http.post(url, params, { observe: 'response', headers: this.entityApiBaseService.getRequiredLoadingHttpHeader() });
  }

  /**
   * Get a group current member ids
   * @param groupId The group id
   * @returns Http response of an array of the group member's ids
   */
  getGroupMembers(groupId: string): Observable<HttpResponse<Array<string>>> {
    const url = `${env.coreEntityApiBase.url}/${this.GROUP_RESOURCE}/${groupId}/${this.MEMBERS_RESOURCE}`;
    return this.entityApiBaseService.getData(url);
  }

  /**
   * Add members to a group
   * @param groupId The group id
   * @param params The array of member's ids to be added to this group member array
   * @returns Http response of request status
   */
  addGroupMembers(groupId: string, params: Array<string>): Observable<HttpResponse<any>> {
    const url = `${env.coreEntityApiBase.url}/${this.GROUP_RESOURCE}/${groupId}/${this.MEMBERS_RESOURCE}`;
    return this.http.patch(url, params, { observe: 'response', headers: this.entityApiBaseService.getRequiredLoadingHttpHeader() });
  }

  /**
   * Remove members from a group
   * @param groupId The group id
   * @param params The array of member's ids to be removed from this group member array
   * @returns Http response of request status
   */
  removeGroupMembers(groupId: string, params: Array<string>): Observable<HttpResponse<any>> {
    const url = `${env.coreEntityApiBase.url}/${this.GROUP_RESOURCE}/${groupId}/${this.MEMBERS_RESOURCE}`;
    return this.http.delete(url, { observe: 'response', headers: this.entityApiBaseService.getRequiredLoadingHttpHeader(), body: params });
  }

  /**
   * Get all members of a group and its child groups
   * @param groupId The group id
   * @returns Http response of an array of member ids that is comprised of this group and all its child groups's members
   */
  getAllGroupMembersNested(groupId: string): Observable<HttpResponse<Array<string>>> {
    const url = `${env.coreEntityApiBase.url}/${this.GROUP_RESOURCE}/${groupId}/${this.MEMBERS_RESOURCE}/${this.NESTED_RESOURCE}`;
    return this.entityApiBaseService.getData(url);
  }
}
