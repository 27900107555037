import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { EGroupPolicySetting } from '@app/core/consts/group.const';
import { setGroupPolicySetting } from '@app/shared/data-stores/setting/setting-data-store.action';
import { Store } from '@ngrx/store';

@Component({
  selector: 'app-migration-start-dialog',
  templateUrl: './migration-start-dialog.component.html',
  styleUrls: ['./migration-start-dialog.component.scss']
})
export class MigrationStartDialogComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<MigrationStartDialogComponent>,
    private store: Store<any>,
  ) { }

  onProceed() {
    this.store.dispatch(setGroupPolicySetting({ payload: { setting: EGroupPolicySetting.SNAPSHOTTING_PROFILES } }));
    this.dialogRef.close(true);
  }

}

