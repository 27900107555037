import { createFeatureSelector, createSelector } from "@ngrx/store";
import { AssetPropertyStoreState } from "./asset-properties-data-store.interface";
import * as AssetPropertyReducer from './asset-properties-data-store.reducer';

export const selectAssetPropertyState = createFeatureSelector<AssetPropertyStoreState>(AssetPropertyReducer.featureKey);

export const selectAssetCustomProperties = createSelector(selectAssetPropertyState, state => state?.assetCustomProperties);
export const selectIsAssetCustomPropertiesLoading = createSelector(selectAssetPropertyState, state => state?.isAssetCustomPropertiesLoading);
export const selectAssetCustomPropertyCreated = createSelector(selectAssetPropertyState, state => state?.assetCustomPropertyCreated);
export const selectCreatePropertyValueProgress = createSelector(selectAssetPropertyState, state => state?.createPropertyValueProgress);
export const selectUpdatePropertyValueProgress = createSelector(selectAssetPropertyState, state => state?.updatePropertyValueProgress);
export const selectSavePropertyAttributeResult = createSelector(selectAssetPropertyState, state => {
  return { isLoading: state?.isSavePropertyAttributeLoading, hasError: state?.hasError };
});
export const selectDeletePropertyValueProgress = createSelector(selectAssetPropertyState, state => state?.deletePropertyValueProgress);
