/*import angular */
import { createReducer, on } from '@ngrx/store';
import { TenantsState } from './tenant-data-store.interface';
import * as TenantDataStoreActions from './tenant-data-store.action';
import { cloneDeep } from 'lodash';

/* state key */
export const featureKey = 'tenant';

/* initial state */
export const initialState: TenantsState = {
  isLoading: false,
  tenantList: null,
  tenant: null,
  createUpdateSuccess: null
};

export const reducer = createReducer(
  initialState,
  on(TenantDataStoreActions.initTenant, (state, action) => ({
    ...state
  })),

  on(TenantDataStoreActions.getTenantList, (state, action) => ({
    ...state,
    isLoading: true
  })),

  on(TenantDataStoreActions.getTenantListSuccess, (state, action) => ({
    ...state,
    tenantList: action.payload.tenantList,
    isLoading: false
  })),

  on(TenantDataStoreActions.getTenantListFailure, (state, action) => ({
    ...state,
    isLoading: false
  })),

  on(TenantDataStoreActions.createTenant, (state, action) => ({
    ...state,
    isLoading: true,
    createUpdateSuccess: false
  })),

  on(TenantDataStoreActions.createTenantSuccess, (state, action) => ({
    ...state,
    isLoading: false,
    createUpdateSuccess: true
  })),

  on(TenantDataStoreActions.createTenantFailure, (state, action) => ({
    ...state,
    isLoading: false,
    createUpdateSuccess: false
  })),

  on(TenantDataStoreActions.getTenantById, (state, action) => ({
    ...state,
    tenant: null,
    isLoading: true,
    createUpdateSuccess: null
  })),

  on(TenantDataStoreActions.getTenantByIdSuccess, (state, action) => ({
    ...state,
    tenant: action.payload.tenant,
    isLoading: false,
    createUpdateSuccess: null
  })),

  on(TenantDataStoreActions.getTenantByIdFailure, (state, action) => ({
    ...state,
    isLoading: false
  })),

  on(TenantDataStoreActions.updateTenant, (state, action) => ({
    ...state,
    isLoading: true,
    createUpdateSuccess: false
  })),

  on(TenantDataStoreActions.updateTenantSuccess, (state, action) => {
    return {
      ...state,
      isLoading: false,
      createUpdateSuccess: true
    };
  }),

  on(TenantDataStoreActions.updateTenantFailure, (state, action) => ({
    ...state,
    isLoading: false,
    createUpdateSuccess: false
  })),

  on(TenantDataStoreActions.addCompaniesToTenant, (state, action) => ({
    ...state,
    isLoading: true
  })),

  on(TenantDataStoreActions.addCompaniesToTenantSuccess, (state, action) => ({
    ...state,
    isLoading: false
  })),

  on(TenantDataStoreActions.addCompaniesToTenantFailure, (state, action) => ({
    ...state,
    isLoading: false
  })),

  on(TenantDataStoreActions.removeCompaniesOfTenant, (state, action) => ({
    ...state,
    isLoading: true
  })),

  on(TenantDataStoreActions.removeCompaniesOfTenantSuccess, (state, action) => ({
    ...state,
    isLoading: false
  })),

  on(TenantDataStoreActions.removeCompaniesOfTenantFailure, (state, action) => ({
    ...state,
    isLoading: false
  })),

  on(TenantDataStoreActions.addDivisionsToTenant, (state, action) => ({
    ...state,
    isLoading: true
  })),

  on(TenantDataStoreActions.addDivisionsToTenantSuccess, (state, action) => ({
    ...state,
    isLoading: false
  })),

  on(TenantDataStoreActions.addDivisionsToTenantFailure, (state, action) => ({
    ...state,
    isLoading: false
  })),

  on(TenantDataStoreActions.removeDivisionsOfTenant, (state, action) => ({
    ...state,
    isLoading: true
  })),

  on(TenantDataStoreActions.removeDivisionsOfTenantSuccess, (state, action) => ({
    ...state,
    isLoading: false
  })),

  on(TenantDataStoreActions.removeDivisionsOfTenantFailure, (state, action) => ({
    ...state,
    isLoading: false
  })),

  on(TenantDataStoreActions.resetTenantList, (state, action) => ({
    ...state,
    tenantList: null
  })),
);
