export enum AssetPropertyListTableColDef {
  PROPERTY = 'name',
  VALUES = 'values',
  EDIT = 'edit',
};

export enum AssetPropertyTablePropertyParams {
  PER_PAGE = 'per_page',
  PAGE = 'page',
  SORT = 'sort',
};

export const AssetPropertyDialogIds = {
  CREATE_EDIT_CUSTOM_PROPERTY_DIALOG: 'create-edit-custom-property-dialog',
  ASSET_DETAIL_DIALOG: 'asset-detail-dialog',
};

export enum CreateEditPropertyDialogCloseCode {
  SUCCESS = 'success',
  CANCEL = 'cancel',
  ERROR = 'error',
  DELETE = 'delete',
};
