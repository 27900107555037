import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Translations } from '@app/core/services/i18n/translations.service';
import { TranslateService } from '@zonar-ui/i18n';

@Component({
  selector: 'app-generate-password-dialog',
  templateUrl: './generate-password-dialog.component.html',
  styleUrls: ['./generate-password-dialog.component.scss']
})
export class GeneratePasswordDialogComponent {

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<GeneratePasswordDialogComponent>,
    public translateService: TranslateService,
    public translations: Translations,
  ) { }

  onConfirm() {
    this.dialogRef.close();
  }

}
