import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';

/* reducer */
import * as fromGroupDataStore from './group-data-store.reducer';

/* effects */
import { GroupDataStoreEffects } from './group-data-store.effects';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature(
      fromGroupDataStore.featureKey,
      fromGroupDataStore.reducer,
      { initialState: fromGroupDataStore.initialState }
    ),
    EffectsModule.forFeature([ GroupDataStoreEffects ])
  ],
  exports: [
    StoreModule,
    EffectsModule
  ]
})
export class GroupDataStoreModule { }
