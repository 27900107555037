<div *ngIf="!isFetchPermsSuccess" class="no-login-container">
  <router-outlet></router-outlet>
</div>
<div class="wrapper" fxLayout="column">
  <div fxLayout="row">
    <zui-sidenav [sidenavParams]="sidenavParams" [header]="sidenavHeader" [footer]="sidenavFooter"
      (emitSelectedCompany)="switchToSelectedCompany($event)"
      (emitCloseSelectedCompany)="onCloseSelectedCompany($event)">
      <div class="sidenav-content-container app-bg-color" [ngClass]="hasPageNotFound ? 'no-perm-container' : ''" fxLayout="column" fxLayoutAlign="space-between stretch"
        fxLayoutGap="3rem">
        <div [ngStyle]="{'height': hasPageNotFound ? '100%' : 'unset'}" fxLayout="column" fxLayoutAlign="start stretch">
          <zui-sidenav-header class="header-bar-sidenav"
            (menuToggled)="onSideNavMobileMenuButtonToggled()"></zui-sidenav-header>
          <div *ngIf="hasLoadData && isFetchPermsSuccess" class="content-container">
              <router-outlet></router-outlet>
              <app-loading-spinner></app-loading-spinner>
          </div>
        </div>
        <zui-footer-component></zui-footer-component>
      </div>
    </zui-sidenav>
  </div>
</div>