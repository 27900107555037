import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FourOhFourMode } from '@app/core/consts/app.const';
import { Translations } from '@app/core/services/i18n/translations.service';
import { I18nService, TranslateService } from '@zonar-ui/i18n';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-page-not-found',
  templateUrl: './page-not-found.component.html',
  styleUrls: ['./page-not-found.component.scss']
})
export class PageNotFoundComponent implements OnInit, OnDestroy {
  translationsNotLoaded = !!this.translateService.store.translations;
  fourOhFourMode = FourOhFourMode.DEFAULT;
  private onDestroy$ = new Subject<void>();

  constructor(
    private i18nService: I18nService,
    public translateService: TranslateService,
    public translations: Translations,
    private route: ActivatedRoute
  ) {
    if (this.translationsNotLoaded) {
      this.translateService
        .use(this.i18nService.getLanguageInUse()).pipe(takeUntil(this.onDestroy$)).subscribe(() => this.translationsNotLoaded = false);
    }
  }
  ngOnInit(): void {
    if (this.route.snapshot?.data?.mode) {
      this.fourOhFourMode = this.route.snapshot.data.mode;
    }
  }

  public get FourOhFourMode(): typeof FourOhFourMode {
    return FourOhFourMode;
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

}
