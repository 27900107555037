import { Injectable } from '@angular/core';
import { IDivision } from '@app/core/models/company.model';

@Injectable({
  providedIn: 'root'
})
export class TenantDataService {

  constructor() { }

  buildDivisionParams(divisionsChanged, divisions: IDivision[]) {
    const result = [];
    divisionsChanged.forEach(divId => {
      const divExisted = divisions.find(div => div.id === divId);
      if (divExisted && !result.includes(divExisted.parentId)) {
        result.push(divExisted.id);
        divExisted.children.forEach(childId => {
          const index = result.findIndex(id => id === childId);
          if (index !== -1) {
            result.splice(index, 1);
          }
        });
      }
    });
    return result;
  }
}
