import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@zonar-ui/i18n';
import { ConfirmDialogComponent } from '@app/shared/dialogs/confirm-dialog/confirm-dialog.component';

@Component({
  selector: 'app-alert-dialog',
  templateUrl: './alert-dialog.component.html',
  styleUrls: ['./alert-dialog.component.scss']
})
export class AlertDialogComponent implements OnInit  {

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<ConfirmDialogComponent>,
    private translateService: TranslateService,
    ) {}

  alertDialogText: any;
  ngOnInit(): void {
    this.initDefaultTextAlertDialog();
  }

  onConfirm() {
    this.dialogRef.close(true);
  }

  initDefaultTextAlertDialog() {
    if (!!Object.values(this.data).length) {
      this.translateService.get('alertDialog').subscribe(res => {
        this.alertDialogText = res;
        this.data = {
          headIcon: 'error_outline',
          title: res?.title,
          messages: res?.messages,
          textConfirmButton: res?.textConfirmButton,
        };
      });
    }
  }
}
