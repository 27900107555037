export enum EGroupPolicySetting {
  /* Initial status for the companies that has Group Policy disabled */
  /* In this status; UI will display a welcome page with a "Proceed" button to initialize the group policy */
  DISABLED = 'DISABLED',

  /* Status when we are in process of creating group policy data from user profiles  */
  /* When an admin user starts the group policy migration process, the status updates from DISABLED to  SNAPSHOTTING_PROFILES */
  SNAPSHOTTING_PROFILES = 'SNAPSHOTTING_PROFILES',

  /* The status when an admin updates users, policies and tenants manually */
  /* This status will be set; when the snapshotting completes */
  PENDING = 'PENDING',

  /* The status when we are in process of enabling the group policy authorization */
  ENABLING = 'ENABLING',

  /* Admin+ UI only status when enabling to complete migration fails */
  ENABLING_ERROR = 'ENABLING_ERROR',

  /* The status when the group policy migration completed and ready to use  */
  ENABLED = 'ENABLED',

  /* The status when group policy migration errors out due to migration process */
  ERROR = 'ERROR'
}


export interface IIndeterminateDialogParams {
  mode: EGroupPolicySetting,
  apiFetchIntervalTime?: number,
  progressBarIcon: string,
  companyId?: string
}

export enum EGroupJobType {
  STANDARD = 'STANDARD'
}

