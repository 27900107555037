export const NA_OBJECT_PARSER = {
  selfServiceName: 'Administration',
  evirMobileName: 'EVIR 2.0 Mobile',
  evirWebName: 'EVIR 2.0 Web',
  inspectionBuilderName: 'Inspection Builder',
  trademark: '&trade;',
  HAZMAT: 'HAZMAT',
  EVIR: 'EVIR',
  EVIRFullName: 'Electronic Verified Inspection Reporting',
  division: 'division',
  DIVISION: 'DIVISION',
  Division: 'Division',
  Zonar: 'Zonar'
};
