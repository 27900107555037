import { IUser } from '@app/core/models/user.model';
import { IDivision } from '@app/core/models/company.model';



export enum BulkUsersImportStatuses {
  VALIDATING_FILE = 'validating_file',
  PARSING_DATA = 'parsing_data',
  INVALID_FILE = 'invalid_file',
  LOADING_DATA = 'loading_data',
  DATA_LOADED = 'data_loaded',
  IMPORTING_USERS = 'importing_users',
  IMPORTING_COMPLETED = 'importing_completed'

}

export interface GetUsersPayload {
  data?: any;
}


export interface GetUsersSuccessfulPayload {
  data?: any;
}

export interface LoadUsersForBulkImportSuccessfulPayload {
  data?: any[];
}

export interface LoadUsersForBulkImportPayload {
  data?: any;
}

export interface BulkUsersCSVDataFileSelectedPayload {
  /* csv file data to import bulk users */
  csvFile: any;
  companyId: string;
  divisions: any[];
  hasManagedUser: boolean;
}

export interface BulkUsersCSVFileValidatedPayload {
  isValidFile?: boolean;
  validRows?: UploadedUser[];
  invalidRows?: UploadedUser[];
}

// export interface BulkUserCSVDataRowsSeparatedPayload {
//     data?: any
// }

export interface BulkUsersCSVDataValidatedPayload {
  validRows?: UploadedUser[];
  invalidRows?: UploadedUser[];
  duplicateRows?: UploadedUser[];
}

export interface BulkUserCSVFileForInvalidDataGeneratedPayload {
  csvFile?: any;
}

export interface ImportValidatedBulkUsersDataPayload {
  data?: any;
}

export interface ImportUserPayload {
  user: any;
  params?: any;
}

export interface ImportUserSuccessfulPayload {
  user?: Partial<IUser>[];
  userId?: string;
}

export interface ImportUserFailurePayload {
  erroredUser: Partial<IUser>;
  error: any;
}

export interface BulkUsersImportCompletedPayload {
  data?: any;
}
export interface RowError {
  index?: number;
  message?: string;
}

export interface TestToDoActionPayload {
  data?: any;
}


export interface UploadedUser {
  id?: string;
  firstName?: string;
  middleName?: string;
  lastName?: string;
  email?: string;
  identityProviderId?: string;
  companyId?: string;
  isValid?: boolean;
  validationError?: string;
  isManagedUser?: boolean;
  defaultLanguage?: string;
  password?: string;
}

/**
 * Translation interface for uploaded csv data to user
 */
export interface ImportUser {
  id?: string;
  firstName?: string;
  middleName?: string;
  lastName?: string;
  email?: string;
  identityProviderId?: string;
  companyId?: string;
}

export interface ImportedUser extends UploadedUser {
  id?: string;
  error?: any;
}

export interface ImportBulkUsers {
  /* please update the status from the  */
  status?: string;
  company?: {
    /* active company id that will be used to import users */
    id: string;
    /* All divisions of the active company to compare the division data is valid in the CSV import file */
    divisions: IDivision[];
  };
  uploadedData?: {
    csvFile: any;
    headerArray: string[];
    recordHashmap?: any[];
    recordRows: UploadedUser[];
  };
  importedData?: {
    processedRowsCount: number;
    /* Successfully imported users */
    successfullyImportedUsers?: ImportedUser[];
    /* total rows that will be imported */
    totalRowsCount: number;
    erroredUsers?: ImportedUser[];
  };
}

export interface ErrorEditedUser extends IUser {
  editErrorMessage : string
}
export interface EditBulkUsers {
  selectedUsers?: IUser[];
  processedRowsCount: number;
  successfullyEditedUsers?: IUser[];
  errorEditedUsers?: ErrorEditedUser[];
}

export interface BulkUserEditSelectedPayload {
  selectedUsers: IUser[];
}
