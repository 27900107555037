import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Translations } from '@app/core/services/i18n/translations.service';
import { RegionParserService } from '@app/core/services/region-parser.service';
import { TranslateService } from '@zonar-ui/i18n';

@Component({
  selector: 'app-announcement-dialog',
  templateUrl: './announcement-dialog.component.html',
  styleUrls: ['./announcement-dialog.component.scss']
})
export class AnnouncementDialogComponent {

  hasManagedUserId = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<AnnouncementDialogComponent>,
    public translateService: TranslateService,
    public translations: Translations,
    public parsingService: RegionParserService
  ) { }

  onCancel() {
    this.dialogRef.close();
  }

  onConfirm() {
    this.dialogRef.close(this.hasManagedUserId);
  }

  toggleChange(value) {
    this.hasManagedUserId = value.checked;
  }

  getParsedKeyWords() {
    return this.parsingService.getParsedKeyWords();
  }

}
