import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from "@angular/core";
import { ECardListCategory } from "@app/core/consts/card-list.const";
import { CardViewActions, ListManagement } from "@app/core/models/list-management";
import { ZonarUITableDataSource } from "@zonar-ui/table";
import { Subject } from "rxjs";
import { ListManagementType as typeList } from '@app/core/consts/list-management.const';
import { FormBuilder, FormControl, FormGroup } from "@angular/forms";
import { Translations } from "@app/core/services/i18n/translations.service";
import { Router } from "@angular/router";
import { IDivision } from "@app/core/models/company.model";
import { takeUntil } from "rxjs/operators";
import { getDisplayPolicyData } from "@app/shared/config/policy-list.config";
import { getDisplayUserGroupPolicy } from "@app/shared/config/user-list-group-policy.config";

@Component({
  selector: 'app-card-list',
  templateUrl: './card-list.component.html',
  styleUrls: ['./card-list.component.scss']
})
export class CardListComponent implements OnInit, OnDestroy, OnChanges {
  @Input() dataSource: ZonarUITableDataSource;
  @Input() viewActions: ListManagement[] = [];

  @Input() category: ECardListCategory;
  @Input() translated = {};
  @Input() companyId: string;
  @Input() division: Partial<IDivision> = {};
  @Input() cardViewActions: CardViewActions = {};

  searchList = [];
  buttonList = [];
  filterList = [];
  searchesFormGroup: FormGroup = this.fb.group({});

  cardList = [];
  hasLoadSuccess = false;

  private onDestroy$ = new Subject<void>();

  constructor(
    private fb: FormBuilder,
    private translations: Translations,
    private router: Router,
  ) { }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.viewActions && changes.viewActions.currentValue.length) {
      this.viewActions = changes.viewActions.currentValue;
      this.searchList = this.buildSmallList(typeList.Search);
      this.buttonList = this.buildSmallList(typeList.Button);
      this.filterList = this.buildSmallList(typeList.Filter);
      this.setSearchFormGroupData();
    }
  }

  ngOnInit(): void {
    this.dataSource.loadData({});
    this.dataSource.loading$.pipe(takeUntil(this.onDestroy$)).subscribe((isLoading: boolean) => {
      if(!isLoading) {
        this.buildCardList();
      } else {
        this.hasLoadSuccess = false;
      }
    });
  }

  buildCardList() {
    this.hasLoadSuccess = false;
    this.cardList = [];
    switch (this.category) {
      case ECardListCategory.GROUP_POLICY_USERS:
        this.dataSource.data.forEach((data:any) => {
          this.cardList.push(getDisplayUserGroupPolicy(data, this.translations, this.translated, this.companyId));
        });
        break;

      case ECardListCategory.POLICIES:
        this.dataSource.data.forEach((data:any) => {
          this.cardList.push(getDisplayPolicyData(data, this.translated, this.cardViewActions));
        });
        break;
    }
    this.hasLoadSuccess = true;
  }

  buildSmallList(typeManagement: typeList) {
    return this.viewActions.reduce((pre, cur) =>
      typeManagement === cur?.type ? [...pre,
      typeManagement === typeList.Search ? cur?.searchManagement :
        typeManagement === typeList.Button ? cur?.buttonManagement : cur?.filterManagement] : pre, []);
  }

  setSearchFormGroupData() {
    if (this.searchList && this.searchList.length) {
      this.searchList.forEach((search) => {
        if (!this.searchesFormGroup.controls[search.controlName]) {
          this.searchesFormGroup.registerControl(search.controlName, new FormControl(''));
        }
      });
    }
  }

  onNavigate(buttonItem) {
    if (!buttonItem.isDisabledButton && buttonItem.customTrigger) {
      buttonItem.customTrigger();
      return;
    }
    
    if (!buttonItem.isDisabledButton) {
      this.router.navigate([buttonItem.locateNavigate], { state: { division: this.division } });
    }
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }
}
