import { Injectable } from "@angular/core";
import { MatTooltip } from "@angular/material/tooltip";

@Injectable({
  providedIn: 'root'
})
export class PersistTooltipService {
  onTooltipMouseEnter(event: MouseEvent, matTooltip: MatTooltip, data: any) {
    event.stopImmediatePropagation();
    if (data.persistTooltip) {
      if (!matTooltip._isTooltipVisible()) {
        matTooltip.show();
        data.persistTooltip = false;
      }
    } else {
      matTooltip.show();
    }
  }

  onTooltipMouseLeave(event: MouseEvent, matTooltip: MatTooltip, data: any) {
    event.stopImmediatePropagation();
    if (!data.persistTooltip) {
      matTooltip.hide();
    }
  }

  onTooltipClick(matTooltip: MatTooltip, data: any) {
    if (data.persistTooltip) {
      data.persistTooltip = false;
      matTooltip.hide();
    } else {
      matTooltip.show();
      data.persistTooltip = true;
    }
  }
}
