import { Component, ElementRef, EventEmitter, Input, OnInit, Output, OnChanges, SimpleChanges, ViewChild } from '@angular/core';
import { Translations } from '@app/core/services/i18n/translations.service';
import { I18nService, TranslateService } from '@zonar-ui/i18n';

@Component({
  selector: 'app-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.scss']
})
export class FileUploadComponent implements OnInit, OnChanges {
  @Input() isUploading = false;
  @Input() isFinishedUpload = false;
  @Input() progressValue = 0;
  @Input() totalRows = 0;
  @Input() errCount = 0;
  @Input() isShowSuccess = false;
  @Input() isShowWarning = false;
  @Input() isShowError = false;
  @Input() recordsParsed = null;
  @Output() fileUploadEmit = new EventEmitter<any>();

  file: any;
  progressValuePercent = 0;
  columnAmountError: any = {};

  constructor(
    private el: ElementRef,
    public translateService: TranslateService,
    public translations: Translations
  ) { }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.progressValue) {
      this.progressValuePercent = (changes.progressValue.currentValue / this.totalRows) * 100;
    }
  }

  ngOnInit(): void {
    this.initColumnAmountErrorText();
  }

  initColumnAmountErrorText() {
    this.translateService.get('uploadDialog.columnAmountError').subscribe(translated => {
      this.columnAmountError = {
        icon: 'error_outline',
        message: translated
      };
    });
  }

  onBrowse() {
    this.el.nativeElement.querySelector('#fileDropRef').click();
  }

  onFileDropped(file) {
    if (this.isValidFormatFile(file)) {
      this.file = file;
      this.fileUploadEmit.emit(file);
    }
  }

  isValidFormatFile(file) {
    return file.name.endsWith('.csv');
  }

  deleteFile() {
    this.file = null;
    this.fileUploadEmit.emit(null);
  }
}
