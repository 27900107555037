import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { AppDataStoreModule } from '@app/shared/data-stores/app/app-data-store-module';
import { UsersDataStoreModule } from '@app/shared/data-stores/users/users-data-store.module';
import { AssetsDataStoreModule } from '@app/shared/data-stores/assets/assets-data-store.module';
import { CompaniesDataStoreModule } from '@app/shared/data-stores/companies/companies-data-store.module';
import { DivisionsDataStoreModule } from '@app/shared/data-stores/divisions/divisions-data-store.module';
import { SettingDataStoreModule } from '@app/shared/data-stores/setting/setting-data-store.module';
import { ProductsDataStoreModule } from '@app/shared/data-stores/products/products-data-store.module';
import { RoleDataStoreModule } from '@app/shared/data-stores/policy/policy-data-store.module';
import { GroupDataStoreModule } from '@app/shared/data-stores/group/group-data-store.module';
import { TenantDataStoreModule } from '@app/shared/data-stores/tenant/tenant-data-store.module';
import { AssetPropertyDataStoreModule } from './asset-properties/asset-properties-data-store.module';
import { DriversDataStoreModule } from './drivers/drivers-data-store.module';

/* data stores */
// import necessary data stores here

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    AppDataStoreModule,
    UsersDataStoreModule,
    AssetsDataStoreModule,
    CompaniesDataStoreModule,
    DivisionsDataStoreModule,
    SettingDataStoreModule,
    ProductsDataStoreModule,
    RoleDataStoreModule,
    GroupDataStoreModule,
    TenantDataStoreModule,
    AssetPropertyDataStoreModule,
    DriversDataStoreModule,
  ],
  exports: [
    StoreModule,
    EffectsModule
  ]
})
export class DataStoresModule { }
