<h1 mat-dialog-title>{{ translations.companyView.division.header | translate:getParsedKeyWords() | titlecase }}</h1>
<mat-dialog-content>
  <div class="data-list-container">
    <mat-tree [dataSource]="treeDataSource" [treeControl]="treeControl" class="tree-container">
      <mat-tree-node *matTreeNodeDef="let node" matTreeNodeToggle [style.display]="!node.selected ? 'none' : 'block'"
        matTreeNodePadding matTreeNodePaddingIndent="22">
        <button mat-icon-button></button>
        <span class="text-disable" *ngIf="node.selected && node.disabled">{{node.name}}</span>
        <a (click)="navigateDivision(node.id)" *ngIf="node.selected && !node.disabled"
          class="html-link">{{node.name}}</a>
      </mat-tree-node>

      <mat-tree-node *matTreeNodeDef="let node; when: hasChild" [style.display]="!node.selected ? 'none' : 'block'"
        matTreeNodePadding matTreeNodePaddingIndent="22">
        <button [disableRipple]="true" mat-icon-button matTreeNodeToggle [attr.aria-label]="'Toggle ' + node.name"
          class="icon-container">
          <mat-icon class="mat-icon-rtl-mirror">
            {{treeControl.isExpanded(node) ? 'expand_more' : 'expand_less'}}
          </mat-icon>
        </button>
        <span class="text-disable" *ngIf="node.selected && node.disabled">{{node.name}}</span>
        <a (click)="navigateDivision(node.id)" *ngIf="node.selected && !node.disabled"
          class="html-link">{{node.name}}</a>
      </mat-tree-node>
    </mat-tree>
  </div>
</mat-dialog-content>
<mat-dialog-actions fxLayoutAlign="end end">
  <button mat-stroked-button class="cancel-btn" (click)="onCancelClick()">{{ translations.companyView.division.cancel |
    translate | titlecase }}</button>
</mat-dialog-actions>