import { Injectable } from '@angular/core';
import { get } from 'lodash';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export abstract class BulkImportService {

  constructor() { }

  abstract deDupRecord(inputRecords: any[], fieldsCheckDup?: any): Array<any>;
  abstract validateRecord(record: any, params: any): any;
  abstract checkDupRecordAPI(record: any, companyId: string): Observable<any>;
  abstract createRecord(record: any): Observable<any>;
  abstract fetchingFieldOptions(data?: any): any;
  abstract parseRecord(recordDataObject: any, params: any): any;

  getMisMatchColumnAmount(row, headerArray) {
    return row ? headerArray.length - row.length : null;
  }

  getDivisionByName(divisions, divName) {
    const divNameLower = divName?.trim().toLocaleLowerCase();
    return divisions.find(division => division.name?.trim().toLocaleLowerCase() === divNameLower)?.id;
  }

  removeRedundantFields(record) {
    delete record?.fieldDup;
    return record;
  }

  checkRecordByFields(recordSource, recordTargets, startIndex, mappingFields) {
    recordTargets.forEach((record, index) => {
      if (index < startIndex) {
        return;
      }

      Object.keys(mappingFields).forEach(key => {
        const value = get(recordSource, mappingFields[key]);
        if (
          value !== null &&
          value === get(record, mappingFields[key])
        ) {
          if (!record.hasOwnProperty('fieldDup')) {
            record.fieldDup = [];
          }
          if (!record.fieldDup.includes(key)) {
            record.fieldDup.push(key);
          }
        }
      });
    });
  }

  /**
   * Function returns parsed boolean value
   * @param value - Input boolean value
   * @param isRequiredField - Whether this field is required or not
   * @param defaultValue - Optional default value to return when field is not required and field's input is empty
   * @returns Returns corresponding boolean if value is of correct format, otherwise returns undefined
   */
  parseBoolean(value: string, isRequiredField: boolean, defaultValue: boolean = false): boolean | undefined {
    if (/^(t\b)/.test(value?.toLowerCase())) {
      return true;
    }
    if (/^(f\b)/.test(value?.toLowerCase())) {
      return false;
    }
    if (!isRequiredField && !value) {
      return defaultValue;
    }
    return undefined;
  }
}
