import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { environment as env } from '@environments/environment';
import { GetUserParams, GetUserProfilesParams, IGetUserPoliciesParams, IUser, IUserPolicy, UserProfile } from '@app/core/models/user.model';
import { EntityApiBaseService } from '@app/core/services/api/entity-api-base.service';
import { Observable } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class CoreUserApiService {
  private USER_RESOURCE = 'users';
  private USER_PROFILE_RESOURCE = 'userprofiles';
  private USER_POLICIES_RESOURCE = 'userpolicies';
  private COMPANY_RESOURCE = 'companies';

  constructor(
    private http: HttpClient,
    private entityApiBaseService: EntityApiBaseService
  ) { }

  getUserList(params?: GetUserParams) {
    const url = `${env.coreEntityApiBase.url}/${this.USER_RESOURCE}`;
    return this.entityApiBaseService.getData(url, params);
  }

  createUser(params: IUser) {
    const url = `${env.coreEntityApiBase.url}/${this.USER_RESOURCE}`;
    return this.http.post(url, params, { observe: 'response', headers: this.entityApiBaseService.getRequiredLoadingHttpHeader() });
  }

  countAllUser() {
    const url = `${env.coreEntityApiBase.url}/${this.USER_RESOURCE}`;
    return this.http.head(url, { observe: 'response' });
  }

  countUser(params?: GetUserParams) {
    const additionalParams: HttpParams = new HttpParams({ fromObject: { ...params } });
    const url = `${env.coreEntityApiBase.url}/${this.USER_RESOURCE}`;
    return this.http.head(url, { params: additionalParams, observe: 'response' });
  }

  getUserById(userId: string) {
    const url = `${env.coreEntityApiBase.url}/${this.USER_RESOURCE}/${userId}`;
    return this.http.get(url, { observe: 'response', headers: this.entityApiBaseService.getRequiredLoadingHttpHeader() });
  }

  updateUser(userId: string, params: Partial<IUser>) {
    const url = `${env.coreEntityApiBase.url}/${this.USER_RESOURCE}/${userId}`;
    return this.http.patch(url, params, { observe: 'response', headers: this.entityApiBaseService.getRequiredLoadingHttpHeader() });
  }

  getUserProfileList(params?: GetUserProfilesParams) {
    const url = `${env.coreEntityApiBase.url}/${this.USER_PROFILE_RESOURCE}`;
    return this.entityApiBaseService.getData(url, params);
  }

  createUserProfile(params: UserProfile) {
    const url = `${env.coreEntityApiBase.url}/${this.USER_PROFILE_RESOURCE}`;
    return this.http.post(url, params, { observe: 'response', headers: this.entityApiBaseService.getRequiredLoadingHttpHeader() });
  }

  countAllUserProfile() {
    const url = `${env.coreEntityApiBase.url}/${this.USER_PROFILE_RESOURCE}`;
    return this.http.head(url, { observe: 'response' });
  }

  getUserProfileById(userProfileId: string) {
    const url = `${env.coreEntityApiBase.url}/${this.USER_PROFILE_RESOURCE}/${userProfileId}`;
    return this.http.get(url, { observe: 'response' });
  }

  updateUserProfile(userProfileId: string, params: Partial<UserProfile>) {
    const url = `${env.coreEntityApiBase.url}/${this.USER_PROFILE_RESOURCE}/${userProfileId}`;
    return this.http.patch(url, params, { observe: 'response', headers: this.entityApiBaseService.getRequiredLoadingHttpHeader() });
  }

  getCompanyUserCount(companyId: string) {
    const url = `${env.coreEntityApiBase.url}/${this.USER_RESOURCE}?companyId=${companyId}`;
    return this.http.head(url, { observe: 'response' });
  }

  getAllUsers(companyId: string, params?: GetUserParams) {
    const url = `${env.coreEntityApiBase.url}/${this.USER_RESOURCE}?companyId=${companyId}`;
    return this.entityApiBaseService.getAll(url, params);
  }

  getAllUserProfiles(companyId: string, params?: GetUserProfilesParams) {
    const url = `${env.coreEntityApiBase.url}/${this.USER_PROFILE_RESOURCE}?companyId=${companyId}`;
    return this.entityApiBaseService.getAll(url, params);
  }

  createWelcomeEmail(companyId: string, userId: string) {
    const url = `${env.coreEntityApiBase.url}/${this.USER_RESOURCE}/${userId}/welcome-email?companyId=${companyId}`;
    return this.http.post(url, null, { observe: 'response' });
  }

  createPasswordResetEmail(userId: string) {
    const url = `${env.coreEntityApiBase.url}/${this.USER_RESOURCE}/${userId}/password-reset`;
    return this.http.post(url, null, { observe: 'response' });
  }

  generatePassword(params) {
    const url = `${env.coreEntityApiBase.url}/${this.USER_RESOURCE}/gen-password`;
    return this.http.post(url, params, { observe: 'response' });
  }

  generateEmail(params) {
    const url = `${env.coreEntityApiBase.url}/${this.USER_RESOURCE}/gen-email`;
    return this.http.post(url, params, { observe: 'response' });
  }

  /**
   * Get a user policy list. At least one unique user-identifying search parameter (userId, email, identityProviderId) is required for params.
   * @param params Query parameters to search for this user policies
   * @returns Http response of user policy list in full model
   */
  getUserPolicies(params: IGetUserPoliciesParams): Observable<HttpResponse<Array<IUserPolicy>>> {
    const url = `${env.coreEntityApiBase.url}/${this.USER_POLICIES_RESOURCE}`;
    return this.entityApiBaseService.getData(url, params);
  }

  getUsersByCompanyId(companyId: string, params?: GetUserParams) {
    const url = `${env.coreEntityApiBase.url}/${this.COMPANY_RESOURCE}/${companyId}/${this.USER_RESOURCE}`;
    return this.entityApiBaseService.getAll(url, params);
  }
}
