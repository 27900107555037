<mat-card class="mobile-list-card app-card-container" fxLayout="column" fxLayoutAlign="start start">
  <!--HEADER-->
  <mat-card-header>
    <span class="typography-header-20 color-zonar-black word-break">
      {{data.title}}
    </span>
    <span class="padding-left-4" fxFlex fxLayout="row" fxLayoutAlign="start center" *ngIf="category === cardCategory.POLICIES && data?.error" ><img matTooltip="{{translations?.companyView?.emptyRoleTooltip | translate}}"
      matTooltipPosition="right" src="assets/icons/flag.svg"></span>
  </mat-card-header>
  <!--GP USER-->
  <span class="width-full" *ngIf="category === cardCategory.GROUP_POLICY_USERS">
    <!--GP USER CONTENT-->
    <mat-card-content fxLayout="column" fxLayoutAlign="start start">
      <span class="word-break" class="typography-text-16 color-zonar-black">
        {{data.groupText}} <mat-label class="typography-text-16 bold color-zonar-blue"
        [matTooltip]="data.groupTooltip"
        #tooltip="matTooltip"
        matTooltipClass="tooltip-card tooltip-gp"
        (mouseenter)="tooltipService.onTooltipMouseEnter($event, tooltip, data)"
        (mouseleave)="tooltipService.onTooltipMouseLeave($event, tooltip, data)"
        (click)="tooltipService.onTooltipClick(tooltip, data)"
        >{{data.groupTooltipText}}</mat-label> <span class="padding-left-4 inline-block" *ngIf="data?.error"><img class="vertical-sub" matTooltip="{{translations?.companyView?.emptyRoleTooltip | translate}}" matTooltipPosition="right" matTooltipClass="tooltip-card" src="assets/icons/flag.svg"></span>
      </span>
      <span class="typography-text-16 color-zonar-gray">{{data.status}}</span>
    </mat-card-content>
    <!--GP USER ACTION-->
    <mat-card-actions fxLayout="row" fxLayoutAlign="end center">
      <button *ngIf="data?.editLink" mat-stroked-button type="button" (click)="onNavigate(data.editLink)">
        {{data.editText}}
      </button>
    </mat-card-actions>
  </span>

  <!--POLICY-->
  <span class="width-full" *ngIf="category === cardCategory.POLICIES">
    <!--POLICY CONTENT-->
    <mat-card-content fxLayout="column" fxLayoutAlign="start start">
      <span class="typography-text-16 color-zonar-gray">
        {{ data.userText + ":&nbsp;" + data.userCount }}
      </span>
    </mat-card-content>
    <!--POLICY ACTION-->
    <mat-card-actions fxLayout="row" fxLayoutAlign="end center">
      <button *ngIf="data?.viewLink" mat-stroked-button (click)="onNavigate(data.viewLink)" >{{data?.viewText}}</button>
      <button *ngIf="data?.editLink" mat-flat-button class="button-ml" (click)="onNavigate(data.editLink)">{{data?.editText}}</button>
    </mat-card-actions>
  </span>
</mat-card>
