import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { Subject } from "rxjs";
import * as CustomAssetsDataStoreActions from './asset-properties-data-store.action';
import { catchError, mergeMap } from "rxjs/operators";
import { CoreAssetApiService } from "@app/core/services/api/core-asset-api.service";
import { AssetCustomProperty } from "@app/core/models/asset-property.model";
import { HttpResponse } from "@angular/common/http";
@Injectable()
export class AssetPropertyEffect {
  constructor(
    private actions$: Actions,
    private coreAssetApiService: CoreAssetApiService
  ) { }

  // #region PROPERTY
  getCustomAssetsProperties$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CustomAssetsDataStoreActions.getAssetCustomProperties),
      mergeMap((action) => {
        const { params } = action.payload;
        return this.coreAssetApiService.getAllCompanyCustomAssetProperties(params).pipe(
          mergeMap((assetCustomProperties: AssetCustomProperty[]) => {
            return [CustomAssetsDataStoreActions.getAssetCustomPropertiesSuccess({
              payload: { assetCustomProperties }
            })];
          }),
          catchError(error => {
            return [CustomAssetsDataStoreActions.getAssetCustomPropertiesFailure({
              payload: { error }
            })];
          })
        );
      })
    )
  );

  createAssetCustomProperty$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CustomAssetsDataStoreActions.createAssetProperty),
      mergeMap((action) => {
        const { companyId, name } = action.payload.params;
        return this.coreAssetApiService.createAssetCustomProperty({ companyId, name }).pipe(
          mergeMap((res: HttpResponse<AssetCustomProperty>) => {
            return [CustomAssetsDataStoreActions.createAssetPropertySuccess({
              payload: { assetCustomPropertyCreated: res.body }
            })];
          }),
          catchError(error => {
            return [CustomAssetsDataStoreActions.createAssetPropertyFailure({
              payload: { error }
            })];
          })
        );
      })
    )
  );

  updateAssetCustomProperty$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CustomAssetsDataStoreActions.updateAssetProperty),
      mergeMap((action) => {
        const { propertyId, name, status } = action.payload.params;
        return this.coreAssetApiService.updateAssetCustomProperty({ propertyId, customPropertyName: name, status }).pipe(
          mergeMap((res: HttpResponse<AssetCustomProperty>) => {
            return [CustomAssetsDataStoreActions.updateAssetPropertySuccess({
              payload: {}
            })];
          }),
          catchError(error => {
            return [CustomAssetsDataStoreActions.updateAssetPropertyFailure({
              payload: { error }
            })];
          })
        );
      })
    )
  );
  // #endregion PROPERTY

  // #region PROPERTY VALUE
  createAssetCustomPropertyValue$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CustomAssetsDataStoreActions.createPropertyValue),
      mergeMap((action) => {
        const { propertyId, value } = action.payload.params;
        return this.coreAssetApiService.createAssetCustomPropertyValues({ propertyId, customPropertyValue: value }).pipe(
          mergeMap((res: HttpResponse<AssetCustomProperty>) => {
            return [CustomAssetsDataStoreActions.createPropertyValueSuccess({
              payload: {}
            })];
          }),
          catchError(error => {
            return [CustomAssetsDataStoreActions.createPropertyValueFailure({
              payload: { error }
            })];
          })
        );
      })
    )
  );

  inactiveAssetCustomPropertyValue$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CustomAssetsDataStoreActions.inactivePropertyValue),
      mergeMap((action) => {
        const { propertyId, propertyValueId } = action.payload.params;
        return this.coreAssetApiService.updateAssetCustomPropertyValue(propertyId, propertyValueId, { status: 'INACTIVE' }).pipe(
          mergeMap((res: HttpResponse<AssetCustomProperty>) => {
            return [CustomAssetsDataStoreActions.inactivePropertyValueSuccess({
              payload: {}
            })];
          }),
          catchError(error => {
            return [CustomAssetsDataStoreActions.inactivePropertyValueFailure({
              payload: { error }
            })];
          })
        );
      })
    )
  );

  updateAssetCustomPropertyValue$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CustomAssetsDataStoreActions.updatePropertyValue),
      mergeMap((action) => {
        const { propertyId, propertyValueId, value } = action.payload.params;
        return this.coreAssetApiService.updateAssetCustomPropertyValue(propertyId, propertyValueId, { value: value }).pipe(
          mergeMap((res: HttpResponse<AssetCustomProperty>) => {
            return [CustomAssetsDataStoreActions.updatePropertyValueSuccess({
              payload: {}
            })];
          }),
          catchError(error => {
            return [CustomAssetsDataStoreActions.updatePropertyValueFailure({
              payload: { error }
            })];
          })
        );
      })
    )
  );
  // #endregion PROPERTY VALUE

}
