export enum PolicyTablePropertyParams {
  PER_PAGE = 'per_page',
  PAGE = 'page',
  NAME = 'q'
};

export enum PolicyListTableColDef {
  CHECK_BOX = 'checkbox',
  POLICY_NAME = 'policyName',
  USER_COUNT = 'userCount',
  EDIT = 'edit',
};

/* policy template */
export enum EPolicyTemplateJobType {
  STANDARD = 'STANDARD',
}

export enum PolicyEntityFields {
  NAME = 'name',
  RESTRICTIONS = 'restrictions',
}

export enum PolicyEntityRestrictions {
  ZONAR_ADMIN = 'ZONAR_ADMIN',
  MANAGED_USERS_ALLOWED = 'MANAGED_USERS_ALLOWED',
}

export enum PolicyErrorCode {
  NON_MANAGED_USER = '7f6b5df6'
}
