import { Component, EventEmitter, Input, OnChanges, OnDestroy, Output, SimpleChanges } from '@angular/core';
import { Translations } from '@app/core/services/i18n/translations.service';
import { RegionParserService } from '@app/core/services/region-parser.service';
import { I18nService, TranslateService } from '@zonar-ui/i18n';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-checkbox-list',
  templateUrl: './checkbox-list.component.html',
  styleUrls: ['./checkbox-list.component.scss']
})
export class CheckboxListComponent implements OnDestroy, OnChanges {
  @Input() dataList: Array<any> = [];
  @Input() dataName = '';
  @Input() disabledDivisionList;

  @Output() dataSelected = new EventEmitter<any>();
  @Output() selectedAll = new EventEmitter<any>();

  translationsNotLoaded = !!this.translateService.store.translations;
  private onDestroy$ = new Subject<void>();

  allComplete = false;
  checkedBoxes = {};

  constructor(
    private i18nService: I18nService,
    public translateService: TranslateService,
    public translations: Translations,
    public parsingService: RegionParserService
  ) {
    if (this.translationsNotLoaded) {
      this.translateService
        .use(this.i18nService.getLanguageInUse()).pipe(takeUntil(this.onDestroy$)).subscribe(() => this.translationsNotLoaded = false);
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.dataList && changes.dataList.currentValue) {
      this.checkedBoxes = {};
      this.dataList.forEach(data => {
        if (data.checked) {
          this.checkedBoxes[data.id] = data;
        }
      });
      this.allComplete = Object.keys(this.checkedBoxes).length === this.dataList.length;
    }
  }

  updateAllComplete(data) {
    if (this.checkedBoxes[data.id]) {
      delete this.checkedBoxes[data.id];
    } else {
      this.checkedBoxes[data.id] = data;
    }

    this.allComplete = Object.keys(this.checkedBoxes).length === this.dataList.length;
    const dataEmit = {
      data,
      addComplete: this.allComplete
    };
    this.dataSelected.emit(dataEmit);
  }

  someComplete(): boolean {
    if (!this.dataList) {
      return false;
    }
    return this.dataList.filter(data => data.checked).length > 0 && !this.allComplete;
  }

  setAll(checked: boolean) {
    this.allComplete = checked;
    if (!this.dataList) {
      return;
    }

    this.checkedBoxes = {};
    this.dataList.forEach(data => {
      data.checked = checked;
      if (checked) {
        this.checkedBoxes[data.id] = data;
      }
    });

    this.selectedAll.emit(this.allComplete);
  }

  getParsedKeyWords() {
    return this.parsingService.getParsedKeyWords();
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

}
