import { Component, EventEmitter, Input, OnChanges, OnDestroy, Output, SimpleChanges } from '@angular/core';
import { MediaObserver } from '@angular/flex-layout';
import { FormGroup } from '@angular/forms';
import { SingleDropdownEvent, SingleDropdownFormType, SingleDropdownPanelData } from '@app/core/models/single-dropdown-panel.model';
import { Subject } from 'rxjs';
import { distinctUntilChanged, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-single-dropdown-panel',
  templateUrl: './single-dropdown-panel.component.html',
  styleUrls: ['./single-dropdown-panel.component.scss']
})
export class SingleDropdownPanelComponent implements OnChanges, OnDestroy {
  @Input() data: SingleDropdownPanelData = null;

  @Output() formGroupDataChange = new EventEmitter<Partial<SingleDropdownEvent>[]>();

  private onDestroyFormGroups$ = new Subject<void>();

  constructor(
    public media: MediaObserver,
  ) { }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.data && changes.data.currentValue && changes.data.currentValue.formGroups) {
      this.onDestroyFormGroups$.next();
      this.subscribeToFormGroupChanges();
    }
  }

  onClearButtonClick(form: FormGroup<SingleDropdownFormType>) {
    form?.controls?.selectedOption?.setValue(null);
  }

  subscribeToFormGroupChanges() {
    this.data?.formGroups?.valueChanges?.pipe(
      distinctUntilChanged(),
      takeUntil(this.onDestroyFormGroups$)
    ).subscribe((data) => {
      this.formGroupDataChange.emit(data);
    });
  }

  ngOnDestroy(): void {
    this.onDestroyFormGroups$.next();
    this.onDestroyFormGroups$.complete();
  }
}
