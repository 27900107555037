<div class="alert-dialog" fxLayout="column" fxLayoutAlign="space-between start">
  <mat-icon fxLayout="row" fxLayoutAlign="center center" class="head-icon">
    {{data.headIcon}}
  </mat-icon>
  <div fxLayout="row" fxLayoutAlign="start" class="title">
    <span>{{data?.title}}</span>
  </div>
  <div class="messages">
    <span>{{data?.messages}}</span>
  </div>
  <div class="dialog-button" fxLayout="row" fxLayoutAlign="end end">
    <button mat-raised-button type="submit" (click)="onConfirm()">{{data?.textConfirmButton}}</button>
  </div>
</div>
