import { Component, Input, OnInit } from '@angular/core';
import { SettingType } from '@app/core/consts/setting.const';
import { SettingObject } from '@app/core/models/setting.model';

@Component({
  selector: 'app-setting-panel',
  templateUrl: './setting-panel.component.html',
  styleUrls: ['./setting-panel.component.scss']
})
export class SettingPanelComponent implements OnInit {
  @Input() settingsArray: SettingObject[];
  constructor() { }

  ngOnInit(): void {
    this.initSettingsArray();
  }

  initSettingsArray() {
    this.settingsArray?.forEach(item => {
      switch (item?.type) {
        case SettingType.SETTINGTOGGLE:
          this.initToggle(item);
          break;
        case SettingType.PRODUCTTOGGLE:
          this.initToggle(item);
          break;
        case SettingType.DROPDOWN:
          this.initDropdown(item);
          break;
        default:
          break;
      }
    });
  }

  initDropdown(input) {
    input.isMultiple = input.isMultiple !== undefined ? input.isMultiple : false;
    input.sortByStringTitle = input.sortByStringTitle !== undefined  ? input.sortByStringTitle : true;
    input.columnWidthClass = !!input.columnWidthClass ? input.columnWidthClass : 'two-columns-width zui-dropdown';
    input.indicatorClasses = input.indicatorClasses + ' setting-dropdown';
  }

  initToggle(input) {
    input.showCautionConfirm = input.showCautionConfirm !== undefined  ? input.showCautionConfirm : true;
    input.showNotificationOff = input.showNotificationOff !== undefined  ? input.showNotificationOff : false;
    input.showNotificationOn = input.showNotificationOn !== undefined  ? input.showNotificationOn : false;
    input.hasShowMessageProfileOn = input.hasShowMessageProfileOn !== undefined  ? input.hasShowMessageProfileOn : false;
    input.hasTooltipTitle = input.hasTooltipTitle !== undefined  ? input.hasTooltipTitle : true;
    input.columnWidthClass = !!input.columnWidthClass ? input.columnWidthClass : 'one-column-width';
    input.indicatorClasses = !!input.indicatorClasses ? input.indicatorClasses : 'background-stripes';
    input.showIcon = input.showIcon !== undefined ? input.showIcon : true;
  }

  get settingType() {
    return SettingType;
  }
}
