import { createFeatureSelector, createSelector } from '@ngrx/store';
import { DivisionState } from './divisions-data-store.interface';
import { featureKey } from './divisions-data-store.reducer';

export const selectDivisionState = createFeatureSelector<DivisionState>(featureKey);

export const selectCompanyDivisions = createSelector(selectDivisionState, state => {
  return state?.companyDivisions;
});

export const selectDivisionCount = createSelector(selectDivisionState, state => {
  return state?.totalDivisionCount;
});
export const selectDivisionLoadingState = createSelector(selectDivisionState, state => state?.isLoading);
export const selectCompanyDivisionLoadingState = createSelector(selectDivisionState, state => state?.companyDivisionLoading);
