<div *ngIf="!file" fxLayoutAlign="center" class="file-upload-container" fxLayout="column" fxFill appDragAndDrop
  (fileDropped)="onFileDropped($event)">
  <mat-icon fxFlex="10" fxLayoutAlign="end center" class="mat-icon material-icons mat-icon-no-color file-img"
    fxFlex="50">
    backup</mat-icon>
  <input class="input-upload" type="file" id="fileDropRef" [multiple]="false"
    (change)="onFileDropped($event.target.files[0])" accept=".csv" />
  <label fxFlex="50">
    {{ translations.uploadDialog.dragAndDropDesStart | translate }}
    <span class="browse-text" (click)="onBrowse()">{{ translations.uploadDialog.browser | translate }}</span>
    {{ translations.uploadDialog.dragAndDropDesEnd | translate }}
  </label>
</div>
<div *ngIf="!recordsParsed && file" class="warning-container">
  <app-warning [warningData]="columnAmountError"></app-warning>
</div>

<div *ngIf="file" class="file-info" fxLayout="row" fxLayoutAlign="center center" fxFill>
  <div fxLayout="row" fxLayoutAlign="start center" [fxFlex]="isUploading ? '100' : '90'">
    <img fxFlex="5" class="file-icon" src="assets/images/file.svg">
    <div fxFlex="95">
      <div fxLayout="row" fxLayoutAlign="space-between">
        <span fxFlex="75" class="file-name">{{file.name}}</span>
        <span fxLayoutAlign="end" *ngIf="isUploading">{{progressValue}} of {{totalRows}}</span>
      </div>
      <section *ngIf="isUploading" class="progress-section">
        <mat-slider fxFill [(ngModel)]="progressValuePercent"></mat-slider>
      </section>
    </div>
  </div>
  <div *ngIf="!isUploading" fxFlex="10" fxLayoutAlign="end" class="mat-icon material-icons mat-icon-no-color">
    <mat-icon *ngIf="!isUploading && !isFinishedUpload" fxLayoutAlign="end"
      class="mat-icon material-icons mat-icon-no-color delete-icon" (click)="deleteFile()">
      clear</mat-icon>

    <mat-icon *ngIf="isShowSuccess" class="icon-success">done</mat-icon>
    <mat-icon *ngIf="isShowWarning" class="icon-warning">warning</mat-icon>
    <mat-icon *ngIf="isShowError" class="icon-error">error</mat-icon>
  </div>
</div>
