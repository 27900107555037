<div class="chip-list">
  <mat-chip-list class="mat-chip-list">
    <mat-chip [ngClass]="chip?.chipClassColor || ''" *ngFor="let chip of chipDisplay" (removed)="removeChip(chip.id)">
      <span [ngClass]="chip?.chipTitleColor || ''">{{chip.name}}</span>
      <mat-icon [ngClass]="chip?.chipCancelIconColor || ''" matChipRemove class="cancel-icon">cancel</mat-icon>
    </mat-chip>
  </mat-chip-list>
</div>
<div class="chip-btn-container">
  <mat-chip *ngIf="hasSeeMore" class="more-btn" (click)="seeMore()">{{showMoreLessText.seeMoreText}}</mat-chip>
  <mat-chip *ngIf="hasSeeLess" class="less-btn" (click)="seeLess()">{{showMoreLessText.seeLessText}}</mat-chip>
</div>