import { trigger, state, style, transition, animate } from '@angular/animations';
import { ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ColumnDivisionListTable, DivisionListTable, DivisionStatus, ExpandStatus } from '@app/core/consts/division.const';
import { IDivision } from '@app/core/models/company.model';
import { DivisionHierarchyService } from '@app/core/services/division-hierarchy.service';
import { TranslateService } from '@zonar-ui/i18n';
import { ZonarUITableCellType as cellType } from '@zonar-ui/table';
import { cloneDeep } from 'lodash';
import { FeatureKeys } from '@app/core/consts/app.const';
import { FeatureToggleService } from '@app/shared/services/feature-toggle.service';

const detailExpandAnimate = animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)');

@Component({
  selector: 'app-nested-division',
  templateUrl: './nested-division.component.html',
  styleUrls: ['./nested-division.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed, void', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', detailExpandAnimate),
      transition('expanded <=> void', detailExpandAnimate),
    ]),
  ],

})
export class NestedDivisionComponent implements OnInit, OnChanges, OnDestroy {
  @Input() rootDivision: IDivision = {};
  @Input() allDivisions: Array<IDivision> = [];
  @Input() rowChanged: IDivision = {};

  @Output() rowClick = new EventEmitter<any>();
  ct = cellType;

  columnsToDisplay: string[] = [
    DivisionListTable.ICON_STATUS,
    DivisionListTable.DIVISION_NAME,
    DivisionListTable.LEGACY_CODE,
    DivisionListTable.TIME_ZONE,
    DivisionListTable.ASSETCOUNT,
    DivisionListTable.ICON,
    DivisionListTable.NESTED
  ];
  columnsHeaders: Array<any> = [];
  expandedElements: IDivision[] = [];
  allowAccessDivisionDetail = false;
  dataSource: Array<IDivision> = [];
  companyId = '';
  textTranslated: any = {};
  columnStyleConfig = {
    [DivisionListTable.ICON_STATUS]: { 'max-width': '3%' },
    [DivisionListTable.DIVISION_NAME]: { 'max-width': '49%' },
    [DivisionListTable.LEGACY_CODE]: { 'max-width': '14%' },
    [DivisionListTable.TIME_ZONE]: { 'max-width': '14%' },
    [DivisionListTable.ASSETCOUNT]: { 'max-width': '14%', 'justify-content': 'right' },
    [DivisionListTable.ICON]: this.featureToggleService.isFeatureEnabled(FeatureKeys.DIVISION.EDIT) ?  { 'max-width': '3%', 'justify-content': 'right' } : {'max-width': '0%'},
    [DivisionListTable.NESTED]: { 'max-width': '3%', 'justify-content': 'right' },
  };

  constructor(
    private cd: ChangeDetectorRef,
    private route: ActivatedRoute,
    private translateService: TranslateService,
    private divisionHierarchyService: DivisionHierarchyService,
    private featureToggleService: FeatureToggleService,
  ) { }

  ngOnChanges(changes: SimpleChanges): void {
    let division = null;
    if (changes.rootDivision?.currentValue?.expanded) {
      division = changes.rootDivision.currentValue;
      this.expandedElements = cloneDeep(this.allDivisions);
    } else if (changes.rowChanged?.currentValue?.id === this.rootDivision?.id
      && changes.rowChanged?.currentValue?.expanded
    ) {
      division = changes.rowChanged.currentValue;
    }

    if (division) {
      if (this.divisionHierarchyService.nestedDivisionBuild.has(division.id)) {
        this.dataSource = this.divisionHierarchyService.nestedDivisionBuild.get(division.id);

      } else {
        this.dataSource = this.buildNestedDivisionDataSource(division);
        this.divisionHierarchyService.nestedDivisionBuild.set(division.id, this.dataSource);
      }
    }
  }

  ngOnInit(): void {
    this.companyId = this.route.snapshot.params.id;
    this.initTextTranslated();
    this.buildColumnsHeader();
    this.allowAccessDivisionDetail = this.featureToggleService.isFeatureEnabled(FeatureKeys.DIVISION.DETAIL);
  }

  initTextTranslated(): void {
    this.translateService.get('companyView.tableStatusTooltip').subscribe(data => {
      this.textTranslated = data;
    });
  }

  buildColumnsHeader(): void {
    this.columnsHeaders = [
      {
        columnDef: DivisionListTable.ICON_STATUS,
        type: cellType.Icon,
        cell: (row) => {
          if (row.status === DivisionStatus.ACTIVE) {
            return 'fiber_manual_record';
          }
          return 'stop';
        }
      },
      {
        columnDef: DivisionListTable.DIVISION_NAME,
        type: cellType.Text,
        iLink: (row: any) => `/company/${this.companyId}/division/${row.id}/`
      },
      {
        columnDef: DivisionListTable.LEGACY_CODE,
        type: cellType.Text
      },
      {
        columnDef: DivisionListTable.TIME_ZONE,
        type: cellType.Text
      },
      {
        columnDef: DivisionListTable.ASSETCOUNT,
        type: cellType.Text
      },
      {
        columnDef: DivisionListTable.ICON,
        type: cellType.Icon,
        cell: (row) => 'edit',
        hidden: this.featureToggleService.isFeatureEnabled(FeatureKeys.DIVISION.EDIT) ? false : true
      },
      {
        columnDef: DivisionListTable.NESTED,
        type: cellType.Icon,
        cell: (row) => {
          if (row.children?.length) {
            return this.isExpanded(row) === ExpandStatus.EXPANDED ? 'expand_less' : 'expand_more';
          }

          return null;
        }
      },
    ];

    this.columnsHeaders.forEach((col: any) => {
      col.columnStyle = this.columnStyleConfig[col.columnDef];
    });
  }

  buildNestedDivisionDataSource(division: any): any {
    const result = [];
    division.children.forEach(item => {
      const divisionFound: any = this.allDivisions.find(div => item.id ? div.id === item.id : div.id === item);
      if (divisionFound) {
        divisionFound.level = divisionFound.level ?? 0;
        divisionFound.children = this.buildChildrenDivision(divisionFound);
        result.push(divisionFound);
        this.buildNestedDivisionDataSource(divisionFound);
      }
    });
    return result;
  }

  buildChildrenDivision(division) {
    const childs = [];
    division.children.forEach(divisionId => {
      const div: any = this.allDivisions.find(item => item.id === divisionId);
      if (div) {
        div.level = division.level + 1;
        childs.push(div);
      }
    });
    return childs;
  }

  getClassIcon(division, column): string {
    if (column.columnDef !== 'status') {
      if (column.columnDef === 'icon') {
        return 'edit-button-color text-align-right';
      }

      return 'text-align-right';
    }

    if (division.status === DivisionStatus.ACTIVE) {
      return 'color-active';
    }

    return 'color-inactive';
  }

  getTextTooltip(column, row): string {
    if (column.columnDef !== DivisionListTable.ICON_STATUS) {
      return null;
    }
    return row.status?.toUpperCase() === DivisionStatus.ACTIVE ?
    this.textTranslated.active : this.textTranslated.inactive;
  }

  iconClicked(row, columnIndex): void {
    if (columnIndex === ColumnDivisionListTable.NESTED) {
      if (row.children && row.children.length) {
        this.toggleElement(row);
      }
      this.cd.detectChanges();
    } else if (columnIndex === ColumnDivisionListTable.ICON) {
      this.rowClick.emit(Object.assign({}, row, { columnIndex }));
    }
  }

  toggleElement(row): void {
    const index = this.expandedElements.findIndex(el => el.name === row.name);
    if (index === -1) {
      this.expandedElements.push(row);
    } else {
      this.expandedElements.splice(index, 1);
    }
  }

  isExpanded(row): string {
    const index = this.expandedElements.findIndex(el => el.name === row.name);
    if (index !== -1) {
      return ExpandStatus.EXPANDED;
    }
    return ExpandStatus.COLLAPSED;
  }

  ngOnDestroy(): void {
    this.divisionHierarchyService.nestedDivisionBuild = new Map<any, any>();
  }
}
