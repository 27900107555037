import { Injectable } from '@angular/core';
import { environment as env } from '@environments/environment';
import { EntityApiBaseService } from '@app/core/services/api/entity-api-base.service';


@Injectable({
  providedIn: 'root'
})
export class EvirApiService {

  private EVIR_CONFIG = 'evir-configs';

  constructor(
    private entityApiBaseService: EntityApiBaseService
  ) { }

  getEvirConfig(companyId: string, params: any) {
    const url = `${env.coreEntityApiBase.urlCVIConfig}/${this.EVIR_CONFIG}?companyId=${companyId}`;
    return this.entityApiBaseService.getData(url, params);
  }
}
