import { Injectable } from '@angular/core';
import { datadogRum } from '@datadog/browser-rum';
import { datadogLogs } from '@datadog/browser-logs';
import { environment } from '@environments/environment';
import { appName } from '@environments/shared';
import { PermissionsService } from '@zonar-ui/auth';
import { filter, first } from 'rxjs/operators';
import { IUser } from '@zonar-ui/auth/lib/models/user.model';

@Injectable({
  providedIn: 'root'
})
export class DataDogService {
  user: IUser;
  constructor(
    private permissionsService: PermissionsService
  ) {
    if (environment.name !== 'local') {
      datadogRum.init({
        applicationId: environment.datadog.applicationId,
        clientToken: environment.datadog.clientToken,
        env: environment.datadog.tagEnvironment,
        service: appName,
        site: environment.datadog.site,
        sessionSampleRate: 100,
        premiumSampleRate: 100,
        replaySampleRate: 100,
        silentMultipleInit: true,
        trackUserInteractions: true,
        allowedTracingOrigins: [/https:\/\/.*\.zonarsystems\.net/],
        useSecureSessionCookie: true,
        useCrossSiteSessionCookie: true,
        defaultPrivacyLevel: 'mask-user-input'
      });
      datadogLogs.init({
        clientToken: environment.datadog.clientToken,
        env: environment.name,
        service: appName,
        silentMultipleInit: true,
        site: environment.datadog.site,
        forwardErrorsToLogs: false,
        sampleRate: 100
      });

      this.permissionsService.getUser().pipe(filter(user => Boolean(user))).subscribe(user => {
        this.user = user;
        datadogRum.setUser({
          id: this.user.id,
          email: this.user.email,
        });
      });

    }
  }

  addRumAction(name: string, context?: object | undefined): void {
    if (environment.name !== 'local') {
      datadogRum.addAction(name, context);
    }
  }

  addRumError(error: Error, context?: object | undefined): void {
    if (environment.name !== 'local') {
      datadogRum.addError(error, context);
    }
  }

  /**
   * Sends a default RUM timing, or creates a RumTiming object which can be used for more precise measuring.
   * @param name The name of the timing
   * @param useView If true, sends a one-off RUM timing which counts from start time of the current RUM view.
   * If false, returns a RumTiming object which can be passed to sendRumTiming.
   * Defaults to true.
   * https://docs.datadoghq.com/real_user_monitoring/browser/monitoring_page_performance/#add-your-own-performance-timing
   */
  newRumTiming(name: string, useView: boolean = true): RumTiming {
    if (environment.name !== 'local') {
      if (useView) {
        datadogRum.addTiming(name);
        return;
      }
      // Note: performance uses its own timestamp internally. However, we use the current epoch as a unique identifier.
      const markName = Date.now().toString();
      performance.mark(markName);
      return {
        name,
        markName
      };
    }
  }

  /**
   * Sends a timing (as a RUM action), starting from when the RumTiming was created.
   * The RumTiming is not preserved, so additional timings will need their own unique RumTiming.
   * @param timing The RumTiming object to measure from
   */
  sendRumTiming(timing: RumTiming): void {
    if (environment.name !== 'local') {
      const measureName = Date.now().toString();
      try {
        performance.measure(measureName, timing.markName);
        datadogRum.addAction(timing.name, {
          [`${timing.name}_timing`]: performance.getEntriesByName(measureName)[0].duration
        });
        performance.clearMarks(timing.markName);
        performance.clearMeasures(measureName);
      } catch (error) {
        // if we get an error (such as because a mark doesn't exist), only throw if we are local,
        // otherwise the error will show in console errors in RUM on datadog
        // (note you would need to temporarily remove the !isLocalDevEnv check from methods to expose this)
        if (environment.name !== 'local') throw error;
      }
    }
  }

  getRumUser(): IUser | undefined {
    return this.user;
  }

  startSessionReplayRecording(): void {
    datadogRum.startSessionReplayRecording();
  }

  stopSessionReplayRecording(): void {
    datadogRum.stopSessionReplayRecording();
  }

  log(message: string, attributes: object = {}): void {
    if (environment.name !== 'local') {
      datadogLogs.logger.info(message, attributes);
    }
  }

  logError(message: string, err: any, attributes: object = {}): void {
    if (environment.name !== 'local') {
      datadogLogs.logger.error(message, attributes, err);
    }
  }

  addFeatureFlagEvaluation(key, value) {
    datadogRum.addFeatureFlagEvaluation(key, value);
  }
}
export interface RumTiming {
  name: string;
  markName: string;
}