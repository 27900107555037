<div class="confirm-dialog" fxLayout="column" fxLayoutAlign="start start">
  <div fxLayout="row" fxLayoutAlign="start" class="title">
    <span>{{data?.title}}</span>
  </div>
  <div class="messages">
    <span>{{data?.messages}}</span>
  </div>
  <div class="dialog-button" [ngStyle]="{'margin-top': data?.buttonMarginTop}" fxLayout="row" fxLayoutAlign="end end">
    <button mat-stroked-button type="button" (click)="onCancel()" fxLayoutAlign="center center"> {{data?.textCancelButton}}</button>
    <button mat-raised-button type="submit" class="button-ml" fxLayoutAlign="center center" (click)="onConfirm()">{{data?.textConfirmButton}}</button>
  </div>
</div>