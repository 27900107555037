import { Injectable } from '@angular/core';
import { STATUSAPI } from '@app/core/consts/status-api.const';
import { DEFAULT_ROLE_SELF_SERVICE, UserFieldCheckDup, UserStatus, UserType } from '@app/core/consts/user.const';
import { IUser, UserProfile } from '@app/core/models/user.model';
import { of, Observable, iif, forkJoin } from 'rxjs';
import { UploadedUser } from '@app/shared/data-stores/users/users-data-store.interface';
import { cloneDeep } from 'lodash';
import { TranslateService } from '@zonar-ui/i18n';
import { Languages, LanguageTranslationPath } from '@app/core/consts/app.const';
import { map, mergeMap, switchMap } from 'rxjs/operators';
import { CoreUserApiService } from '@app/core/services/api/core-user-api.service';
import { HttpResponse } from '@angular/common/http';
import { IDivision } from '@app/core/models/company.model';
import { ValidationService } from '@app/core/services/validation.service';
import { environment } from '@environments/environment';
import { BulkImportService } from './bulk-import.service';
import { ProductDataService } from '@app/core/services/data/product-data.service';
import { UserValidationSchema } from '@app/core/consts/validation.const';
import { getSupportedLanguageCodes } from '@app/core/utils';

@Injectable({
  providedIn: 'root'
})
export class BulkUserImportService extends BulkImportService {
  userMapping: any = {};
  allDivisionText = '';
  userDefaultLanguage: string = '';
  errorMessagesTranslated: any = {};

  constructor(
    private translateService: TranslateService,
    private coreUserApiService: CoreUserApiService,
    private validationService: ValidationService,
    private productDataService: ProductDataService
    ) {
      super();
    }

  setUserDefaultLanguage(companyDefaultLanguage: string) {
    companyDefaultLanguage = getSupportedLanguageCodes(companyDefaultLanguage);
    this.userDefaultLanguage = companyDefaultLanguage;
  }

  /* Translated raw csv user row to user object to ingest by create user API */
  translateUploadedRowToUser(csvUserRow: UploadedUser): Partial<IUser> {
    const userParams: Partial<IUser> = {
      firstName: csvUserRow.firstName?.trim(),
      lastName: csvUserRow.lastName?.trim(),
      middleName: csvUserRow.middleName?.trim(),
      email: csvUserRow.email?.trim(),
      defaultLanguage: csvUserRow.defaultLanguage ? csvUserRow.defaultLanguage?.trim() : this.userDefaultLanguage,
      credentialType: csvUserRow.isManagedUser ? UserType.MANAGED : UserType.STANDARD
    };
    return userParams;
  }

  parseRecord(recordDataObject, params) {
    const { divisions, hasManagedUser, companyDefaultLanguage } = params;
    this.userDefaultLanguage = companyDefaultLanguage;
    return {
      firstName: recordDataObject[this.userMapping.firstName],
      lastName: recordDataObject[this.userMapping.lastName],
      middleName: recordDataObject[this.userMapping.middleName],
      email: recordDataObject[this.userMapping.userId],
      defaultLanguage: recordDataObject[this.userMapping.language]?.toLowerCase(),
      divisions: this.getDivisionIdsByNames(recordDataObject[this.userMapping.divisions], divisions),
      isManagedUser: hasManagedUser ? this.parseBoolean(recordDataObject[this.userMapping.zonarManaged.toLowerCase()], true) : false
    };
  }

  getDivisionIdsByNames(divisionNames: string, divisions: IDivision[]): string[] {
    if (this.allDivisionText?.toLowerCase() === divisionNames?.trim().toLowerCase()) {
      return [];
    } else if (!divisionNames) {
      return null;
    }
    const results = [];
    const divisionNameList = divisionNames.split(',');
    divisionNameList.forEach(divisionName => {
      results.push(this.getDivisionByName(divisions, divisionName));
    });

    return results;
  }

  deDupRecord(inputRecords): Array<any> {
    const tmpInputRecords = cloneDeep(inputRecords);
    const mappingFields = {};
    Object.keys(UserFieldCheckDup).forEach(key => {
      mappingFields[this.userMapping[key]] = UserFieldCheckDup[key];
    });
    tmpInputRecords?.forEach((record, index) => {
      this.checkRecordByFields(record, tmpInputRecords, index + 1, mappingFields);
    });

    return tmpInputRecords;
  }

  validateRecord(record, prePopulateData) {
    // Reset CurrentDataField
    this.validationService.setRecordDataValidate(null);
    return {
      isValid: this.validateDivision(record.divisions)
        && this.validateLanguage(record, prePopulateData.languageOption)
        && this.validateIsManagedUser(record.isManagedUser)
        && this.validateFirstName(record.firstName)
        && this.validateLastName(record.lastName)
        && this.validateEmail(record.email, record.isManagedUser),
      fieldName: this.validationService.getCurrentValidationField(),
      data: this.validationService.getRecordDataValidate() ? this.validationService.getRecordDataValidate() : record,
    };
  }

  validateDivision(divisions: string[]) {
    this.validationService.setCurrentValidationField(this.userMapping.divisions);

    if (this.validationService.validateRequiredField(divisions)) {
      return divisions.length ? divisions.every(div => !!div) : true;
    }
    return false;
  }

  validateLanguage(record: IUser, languageOption: Map<string, string>) {
    const tmpRecord = cloneDeep(record);
    this.validationService.setCurrentValidationField(this.userMapping.language);
    if (tmpRecord.defaultLanguage) {
      const enumValid = this.validationService.validateAndReviseEnumerated(tmpRecord.defaultLanguage, languageOption);
      if (enumValid.validCheck) {
        tmpRecord.defaultLanguage = enumValid.optionRevised;
        this.validationService.setRecordDataValidate(tmpRecord);
      }
      return enumValid.validCheck;
    }
    return true;
  }

  validateFirstName(firstName :string) {
    this.validationService.setCurrentValidationField(this.userMapping.firstName);
    return this.validationService.validateRequiredField(firstName);
  }

  validateLastName(lastName :string) {
    this.validationService.setCurrentValidationField(this.userMapping.lastName);
    return this.validationService.validateRequiredField(lastName);
  }

  validateEmail(email :string, isManagedUser :boolean) {
    this.validationService.setCurrentValidationField(this.userMapping.userId);
    return this.validationService.validateRequiredField(email)
    && (isManagedUser ?
      this.validationService.validateFormatPattern(email, UserValidationSchema.userId.pattern):
      this.validationService.validateFormatPattern(email, UserValidationSchema.email.pattern));
  }

  validateIsManagedUser(isManagedUser) {
    this.validationService.setCurrentValidationField(this.userMapping.zonarManaged.toLowerCase());
    return isManagedUser !== undefined;
  }

  processDupRecordAPI(record, companyId): Observable<any> {
    if (record.isManagedUser) {
      return this.coreUserApiService.generateEmail({ userCode: record.email, companyId }).pipe(
        map((res: any) => res.body),
        switchMap((resEmail: any) => {
          const tmpRecord = cloneDeep(record);
          tmpRecord.email = resEmail.email;
          return this.checkDupRecordAPI(tmpRecord, companyId);
      }));
    }
    return this.checkDupRecordAPI(record, companyId);
  }

  checkDupRecordAPI(record, companyId: string): Observable<any> {
    return this.coreUserApiService.getUserList({ email: record.email }).pipe(
      map((res: any) => res.body),
      switchMap((userList: any) => iif(() =>
        userList && userList.length,
        this.coreUserApiService.getUserProfileList({ userId: userList[0]?.id, status: UserStatus.ANY }),
        of(null))),
      map((response: any) => {
        return {
          isDuplicateRecord: response && response.body && response.body.some(profile => profile.companyId === companyId),
          fieldName: this.userMapping.userId,
          record: record
        };
      }),
    );
  }

  createRecord(userParams): Observable<HttpResponse<any>> {
    // Handle password params for Managed User before posting
    if ( userParams.credentialType === UserType.MANAGED ) {
      const params = {
        lang: userParams.defaultLanguage.split('-')[0],
        words: 3
      };
      return this.coreUserApiService.generatePassword(params).pipe(
        switchMap((resPassword: any) => {
          // userParams come from ngrx state, cannot be mutated normally
          let tmpUserParams = cloneDeep(userParams);
          tmpUserParams.password = resPassword?.body?.password;
          return this.coreUserApiService.createUser(tmpUserParams);
        })
      );
    } else {
      return this.coreUserApiService.createUser(userParams);
    }
  }

  createProfile(userId: string, record, companyId: string, prePopulateData, shouldSendEmail = false): Observable<any> {
    const applications = prePopulateData.applications;
    const params: UserProfile = {
      applicationId: environment.auth.applicationId,
      companyId,
      status: UserStatus.INACTIVE,
      divisions: record.divisions,
      allDivisions: record.divisions.length ? false : true,
      userId,
      roles: [(((applications.find(app => app.id === environment.auth.applicationId) || {}).roles
        || []).find(role => role.name === DEFAULT_ROLE_SELF_SERVICE) || {}).id]
    };

    return this.coreUserApiService.createUserProfile(params).pipe(
      mergeMap(res => {
        if (res.status === STATUSAPI.POST_SUCCESS && !record.isManagedUser && shouldSendEmail) {
          return this.coreUserApiService.createWelcomeEmail(companyId, userId);
        }
        return of(res);
      })
    );
  }

  fetchingFieldOptions(companyId: string): Observable<any> {
    return forkJoin([
      this.productDataService.getApplicationOfProduct(companyId),
      this.translateService.get(LanguageTranslationPath)
    ]).pipe(
      map(([ apps, languageFieldOption]) => {
        return {
          applications: apps.managedApps,
          languageOption: new Map(Object.keys(languageFieldOption).map(language => {
            return [languageFieldOption[language].toLowerCase(), Languages[language.toUpperCase()]];
          }))
        };
      })
    );
  }
}
