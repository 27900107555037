export enum UserStatus {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
  ANY = 'ANY'
}

export enum UserDefaultOperator {
  AND = 'and',
  OR = 'or',
  ANY = 'any'
}

export enum UserStatusIcon {
  ACTIVE = 'fiber_manual_record',
  INACTIVE = 'stop',
  INFO = 'error_outline'
}

export enum UserListTable {
  CHECK_BOX = 'checkbox',
  ICON_STATUS = 'status',
  FIRST_NAME = 'firstName',
  USER_ID = 'userId',
  DIV_COUNT = 'divisions',
  ICON = 'icon',
}

export const UserColumnWidth = {
  CHECK_BOX: '3%',
  ICON_STATUS: '4%',
  FIRST_NAME: '40%',
  USER_ID: '34.5%',
  DIV_COUNT_W_EDIT: '12.5%',
  DIV_COUNT: '14.5%',
  ICON: '3%',
};

export const DivisionColumnWidth = {
  ICON_STATUS: '3%',
  FIRST_NAME: '44%',
  USER_ID: '50%',
  ICON: '3%',
};

export enum CompanyUserListProfileTable {
  CHECK_BOX = 'checkbox',
  STATUS = 'status',
  NAME = 'name',
  USER_ID = 'userId',
  EDIT = 'edit'
}

export enum UserListGroupPolicyColDef {
  NAME = 'name',
  GROUP_POLICY = 'groupPolicy',
  LOGIN_TYPE = 'loginType',
  STATUS = 'status',
  EDIT = 'edit',
}

export enum UserListProfileColDef {
  NAME = 'name',
  USER_ID = 'userId',
  DIVISIONS = 'divisions',
  EDIT = 'edit',
}

export enum DivisionUserListTable {
  STATUS = 'status',
  NAME = 'name',
  GROUP_POLICY = 'groupPolicy',
  EDIT = 'edit'
}

export const CompanyUserListColumnWidth = {
  CHECK_BOX: '2%',
  STATUS_W_CHECK_BOX: '4%',
  STATUS: '6%',
  NAME: '45%',
  GROUP_POLICY: '49%',
  GROUP_POLICY_W_EDIT: '40%',
  EDIT: '9%',
};

export const CompanyGroupPolicyUserColumnWidth = {
  CHECK_BOX: '2%',
  NAME: '22%',
  NAME_W_CHECK_BOX: '20%',
  GROUP_POLICY: '34%',
  LOGIN_TYPE: '26%',
  STATUS: '18%',
  STATUS_W_EDIT: '14%',
  EDIT: '4%',
};

export const DivisionUserListColumnWidth = {
  STATUS: '6%',
  NAME: '45%',
  GROUP_POLICY: '49%',
  GROUP_POLICY_W_EDIT: '40%',
  EDIT: '9%',
};

export enum ColumnUserList {
  CHECK_BOX = 0,
  STATUS,
  NAME,
  GROUP_POLICY,
  EDIT
}

export enum ColumnUserProfileList {
  CHECKBOX = 0,
  STATUS,
  NAME,
  USER_ID,
  DIVISION,
  EDIT,
}

export enum UserTablePropertyParams {
  PER_PAGE = 'per_page',
  PAGE = 'page',
  NAME = 'name',
  USER_ID = 'userId',
  STATUS = 'status',
  DIVISIONS = 'divisions',
  GROUP = 'group',
  LOGIN_TYPES = 'logintypes',
  SEARCH_BAR = 'search_bar',
  COMMON_SEARCH_BAR = 'common_search_bar',
}

export enum UserOperation {
  CREATE = 'create',
  UPDATE = 'update'
}

export enum UserType {
  MANAGED = 'MANAGED',
  PASSWORDLESS = 'PASSWORDLESS',
  STANDARD = 'STANDARD',
  LEGACY_OPERATOR = 'LEGACY_OPERATOR',
}

export enum UserSettingItemName {
  PROFILE = 'profileActiveToggle',
  LANGUAGES = 'languageOption'
}

/**
 * key: entity of userHeaderRow in translate json file
 * value: path name of UserParams
 */
export const userCSVFileFieldMap = {
  userId: 'name'
};

/**
 * key: entity of userHeaderRow in translate json file
 * value: path name of UserParams
 */
export const UserFieldCheckDup = {
  userId: 'email',
};

export enum BulkUserEditBehavior {
  NONE = 'none',
  APPEND = 'append',
  REPLACE = 'replace',
  REMOVE = 'remove'
}

export const USER_CHIP_CLASS = {
  MANAGED_USER: {
    BACKGROUND: 'managed-user-color',
    TITLE: 'managed-icon-color',
    ICON: 'managed-icon-color'
  },
  STANDARD_USER: {
    BACKGROUND: 'standard-user-color',
    TITLE: 'standard-icon-color',
    ICON: 'standard-icon-color'
  },
  ADMIN_USER: {
    BACKGROUND: 'admin-user-color',
    TITLE: 'admin-icon-color',
    ICON: 'admin-icon-color'
  },
};

export const DEFAULT_ROLE_SELF_SERVICE = 'Manage Users';
export const UNASSIGNED_GROUP_USER = 'Unassigned';

export const UserDialogIds = {
  MULTI_TAGID_DIALOG: 'user-multi-tagid-dialog',
};
