/* angular */
import { ICreatePolicyParams, ICreatePolicyTemplateParams, IGetPolicyTemplatesParams, IPolicy, IPolicyTemplate, IPolicyTemplatePromoteParams, IUpdatePolicyTemplateParams } from '@app/core/models/policy.model';
import { createAction, props } from '@ngrx/store';
import { UpdatePolicyPayload } from './policy-data-store.interface';

/* action types */
export enum ActionTypes {
  // common action types
  InitPolicy = '[POLICY] InitPolicy',
  ResetPolicy = '[POLICY] ResetPolicy',
  ResetCreateUpdatePolicy = '[POLICY] ResetCreateUpdatePolicy',
  ResetPolicyError = '[POLICY] ResetPolicyError',
  CancelRequest = '[POLICY] CancelRequest',

  GetPolicies = '[POLICY] GetGetPolicies',
  GetPoliciesSuccess = '[POLICY] GetPoliciesSuccess',
  GetPoliciesFailure = '[POLICY] GetPoliciesFailure',

  GetPolicyById = '[POLICY] GetPolicyById',
  GetPolicyByIdSuccess = '[POLICY] GetPolicyByIdSuccess',
  GetPolicyByIdFailure = '[POLICY] GetPolicyByIdFailure',

  GetPolicyByPolicyId = '[POLICY] GetPolicyByPolicyId',
  GetPolicyByPolicyIdSuccess = '[POLICY] GetPolicyByPolicyIdSuccess',
  GetPolicyByPolicyIdFailure = '[POLICY] GetPolicyByPolicyIdFailure',

  AddApplicationToPolicy = '[POLICY] AddApplicationToPolicy',
  AddApplicationToPolicySuccess = '[POLICY] AddApplicationToPolicySuccess',
  AddApplicationToPolicyFailure = '[POLICY] AddApplicationToPolicyFailure',

  RemoveApplicationOfPolicy = '[POLICY] RemoveApplicationOfPolicy',
  RemoveApplicationOfPolicySuccess = '[POLICY] RemoveApplicationOfPolicySuccess',
  RemoveApplicationOfPolicyFailure = '[POLICY] RemoveApplicationOfPolicyFailure',

  AddCapabilitiesToAppOfPolicy = '[POLICY] AddCapabilitiesToAppOfPolicy',
  AddCapabilitiesToAppOfPolicySuccess = '[POLICY] AddCapabilitiesToAppOfPolicySuccess',
  AddCapabilitiesToAppOfPolicyFailure = '[POLICY] AddCapabilitiesToAppOfPolicyFailure',

  RemoveCapabilitiesOfAppOfPolicy = '[POLICY] RemoveCapabilitiesOfAppOfPolicy',
  RemoveCapabilitiesOfAppOfPolicySuccess = '[POLICY] RemoveCapabilitiesOfAppOfPolicySuccess',
  RemoveCapabilitiesOfAppOfPolicyFailure = '[POLICY] RemoveCapabilitiesOfAppOfPolicyFailure',

  GetPolicyTemplates = '[POLICY] GetPolicyTemplates',
  GetPolicyTemplatesSuccess = '[POLICY] GetPolicyTemplatesSuccess',
  GetPolicyTemplatesFailure = '[POLICY] GetPolicyTemplatesFailure',

  CreatePolicyTemplate = '[POLICY] CreatePolicyTemplate',
  CreatePolicyTemplateSuccess = '[POLICY] CreatePolicyTemplateSuccess',
  CreatePolicyTemplateFailure = '[POLICY] CreatePolicyTemplateFailure',

  GetPolicyTemplateById = '[POLICY] GetPolicyTemplateById',
  GetPolicyTemplateByIdSuccess = '[POLICY] GetPolicyTemplateByIdSuccess',
  GetPolicyTemplateByIdFailure = '[POLICY] GetPolicyTemplateByIdFailure',

  UpdatePolicyTemplate = '[POLICY] UpdatePolicyTemplate',
  UpdatePolicyTemplateSuccess = '[POLICY] UpdatePolicyTemplateSuccess',
  UpdatePolicyTemplateFailure = '[POLICY] UpdatePolicyTemplateFailure',

  CreateGroupFromPolicyTemplate = '[POLICY] CreateGroupFromPolicyTemplate',
  CreateGroupFromPolicyTemplateSuccess = '[POLICY] CreateGroupFromPolicyTemplateSuccess',
  CreateGroupFromPolicyTemplateFailure = '[POLICY] CreateGroupFromPolicyTemplateFailure',

  CreatePolicy = '[POLICY] CreatePolicy',
  CreatePolicySuccess = '[POLICY] CreatePolicySuccess',
  CreatePolicyFailure = '[POLICY] CreatePolicyFailure',

  UpdatePolicy = '[POLICY] UpdatePolicy',
  UpdatePolicySuccess = '[POLICY] UpdatePolicySuccess',
  UpdatePolicyFailure = '[POLICY] UpdatePolicyFailure',
};

// common actions
export const initPolicy = createAction(ActionTypes.InitPolicy);
export const resetPolicy = createAction(ActionTypes.ResetPolicy);
export const resetCreateUpdatePolicy = createAction(ActionTypes.ResetCreateUpdatePolicy);
export const resetPolicyError = createAction(ActionTypes.ResetPolicyError);
export const cancelRequest = createAction(ActionTypes.CancelRequest);

export const getPolicies = createAction(ActionTypes.GetPolicies, props<{ payload: { params?: any } }>());
export const getPoliciesSuccess = createAction(ActionTypes.GetPoliciesSuccess, props<{ payload: { policies: Array<IPolicy> } }>());
export const getPoliciesFailure = createAction(ActionTypes.GetPoliciesFailure, props<{ payload: { error: any } }>());

export const getPolicyById = createAction(ActionTypes.GetPolicyById, props<{ payload: { policyId: string, params?: any } }>());
export const getPolicyByIdSuccess = createAction(ActionTypes.GetPolicyByIdSuccess, props<{ payload: { policyDetail: IPolicy } }>());
export const getPolicyByIdFailure = createAction(ActionTypes.GetPolicyByIdFailure, props<{ payload: { error: any } }>());

export const addApplicationToPolicy = createAction(ActionTypes.AddApplicationToPolicy, props<{ payload: { policyId: string, params: string } }>());
export const addApplicationToPolicySuccess = createAction(ActionTypes.AddApplicationToPolicySuccess, props<{ payload: { policy: IPolicy } }>());
export const addApplicationToPolicyFailure = createAction(ActionTypes.AddApplicationToPolicyFailure, props<{ payload: { error: any } }>());

export const removeApplicationOfPolicy = createAction(ActionTypes.RemoveApplicationOfPolicy, props<{ payload: { policyId: string, appId: string } }>());
export const removeApplicationOfPolicySuccess = createAction(ActionTypes.RemoveApplicationOfPolicySuccess, props<{ payload: { policy: IPolicy } }>());
export const removeApplicationOfPolicyFailure = createAction(ActionTypes.RemoveApplicationOfPolicyFailure, props<{ payload: { error: any } }>());

export const addCapabilitiesToAppOfPolicy = createAction(ActionTypes.AddCapabilitiesToAppOfPolicy, props<{ payload: { policyId: string, appId: string, params: string[] } }>());
export const addCapabilitiesToAppOfPolicySuccess = createAction(ActionTypes.AddCapabilitiesToAppOfPolicySuccess, props<{ payload: { policy: IPolicy } }>());
export const addCapabilitiesToAppOfPolicyFailure = createAction(ActionTypes.AddCapabilitiesToAppOfPolicyFailure, props<{ payload: { error: any } }>());

export const removeCapabilitiesOfAppOfPolicy = createAction(ActionTypes.RemoveCapabilitiesOfAppOfPolicy, props<{ payload: { policyId: string, appId: string, params: string[] } }>());
export const removeCapabilitiesOfAppOfPolicySuccess = createAction(ActionTypes.RemoveCapabilitiesOfAppOfPolicySuccess, props<{ payload: { policy: IPolicy } }>());
export const removeCapabilitiesOfAppOfPolicyFailure = createAction(ActionTypes.RemoveCapabilitiesOfAppOfPolicyFailure, props<{ payload: { error: any } }>());

export const getPolicyTemplates = createAction(ActionTypes.GetPolicyTemplates, props<{ payload: { params: IGetPolicyTemplatesParams } }>());
export const getPolicyTemplatesSuccess = createAction(ActionTypes.GetPolicyTemplatesSuccess, props<{ payload: { policyTemplates: IPolicyTemplate[] } }>());
export const getPolicyTemplatesFailure = createAction(ActionTypes.GetPolicyTemplatesFailure, props<{ payload: { error: any } }>());

export const createPolicyTemplate = createAction(ActionTypes.CreatePolicyTemplate, props<{ payload: { params: ICreatePolicyTemplateParams } }>());
export const createPolicyTemplateSuccess = createAction(ActionTypes.CreatePolicyTemplateSuccess, props<{ payload: { policyTemplate: IPolicyTemplate } }>());
export const createPolicyTemplateFailure = createAction(ActionTypes.CreatePolicyTemplateFailure, props<{ payload: { error: any } }>());

export const getPolicyTemplateById = createAction(ActionTypes.GetPolicyTemplateById, props<{ payload: { policyTemplateId: string } }>());
export const getPolicyTemplateByIdSuccess = createAction(ActionTypes.GetPolicyTemplateByIdSuccess, props<{ payload: { policyTemplate: IPolicyTemplate } }>());
export const getPolicyTemplateByIdFailure = createAction(ActionTypes.GetPolicyTemplateByIdFailure, props<{ payload: { error: any } }>());

export const updatePolicyTemplate = createAction(ActionTypes.UpdatePolicyTemplate, props<{ payload: { policyTemplateId: string, params: IUpdatePolicyTemplateParams } }>());
export const updatePolicyTemplateSuccess = createAction(ActionTypes.UpdatePolicyTemplateSuccess, props<{ payload: { policyTemplate: IPolicyTemplate } }>());
export const updatePolicyTemplateFailure = createAction(ActionTypes.UpdatePolicyTemplateFailure, props<{ payload: { error: any } }>());

export const createGroupFromPolicyTemplate = createAction(ActionTypes.CreateGroupFromPolicyTemplate, props<{ payload: { policyTemplateId: string, params: IPolicyTemplatePromoteParams } }>());
export const createGroupFromPolicyTemplateSuccess = createAction(ActionTypes.CreateGroupFromPolicyTemplateSuccess, props<{ payload: any }>());
export const createGroupFromPolicyTemplateFailure = createAction(ActionTypes.CreateGroupFromPolicyTemplateFailure, props<{ payload: { error: any } }>());

export const createPolicy = createAction(ActionTypes.CreatePolicy, props<{ payload: ICreatePolicyParams }>());
export const createPolicySuccess = createAction(ActionTypes.CreatePolicySuccess, props<{ payload: { policy: IPolicy } }>());
export const createPolicyFailure = createAction(ActionTypes.CreatePolicyFailure, props<{ payload: { error: any } }>());

export const updatePolicy = createAction(ActionTypes.UpdatePolicy, props<{ payload: UpdatePolicyPayload }>());
export const updatePolicySuccess = createAction(ActionTypes.UpdatePolicySuccess);
export const updatePolicyFailure = createAction(ActionTypes.UpdatePolicyFailure, props<{ payload: { error: any } }>());
