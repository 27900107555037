import { CoreCompanyApiService } from '@app/core/services/api/core-company-api.service';
import { takeUntil } from 'rxjs/operators';
import { ZonarUiNotificationsService } from '@zonar-ui/notifications';
import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { I18nService, TranslateService } from '@zonar-ui/i18n';
import { Translations } from '@app/core/services/i18n/translations.service';

import { bulkAssetCSVDataUpload, bulkAssetsCSVDataFileSelected, bulkAssetsImportCompleted, resetImportAssets } from '@app/shared/data-stores/assets/assets-data-store.actions';
import { selectErroredAssets, selectHeaderArray, selectProgressValue, selectRecordRows, selectSuccessfullyImportedAssets } from '@app/shared/data-stores/assets/assets-data-store.selects';
import { BulkAssetImportService } from '@app/shared/services/bulk-asset-import.service';
import { cloneDeep } from 'lodash';
import { RegionParserService } from '@app/core/services/region-parser.service';
import { EnumTranslationPath } from '@app/core/consts/app.const';
import { BulkImportComponent } from '@app/shared/components/bulk-import/bulk-import.component';
import { SupportService } from '@app/core/services/support.service';
import { IDivision } from '@app/core/models/company.model';
import { Store } from '@ngrx/store';

@Component({
  selector: 'app-bulk-asset-import',
  templateUrl: './bulk-asset-import.component.html',
  styleUrls: ['./bulk-asset-import.component.scss']
})
export class BulkAssetImportComponent extends BulkImportComponent implements OnInit, OnDestroy {

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<BulkAssetImportComponent>,
    public zonarUiNotificationsService: ZonarUiNotificationsService,
    public translateService: TranslateService,
    public translations: Translations,
    public i18nService: I18nService,
    public store: Store<any>,
    public supportService: SupportService,
    private coreCompanyApiService: CoreCompanyApiService,
    private bulkAssetImportService: BulkAssetImportService,
    private regionParserService: RegionParserService
  ) {
    super(translateService, i18nService, store, zonarUiNotificationsService, dialogRef);

    if (this.translationsNotLoaded) {
      this.translateService
        .use(this.i18nService.getLanguageInUse()).pipe(takeUntil(this.onDestroy$)).subscribe(() => this.translationsNotLoaded = false);
    }
  }

  ngOnInit(): void {
    this.initNotificationMessage();
    this.store.select(selectHeaderArray).subscribe(headerArray => {
      this.headerArray = headerArray ? headerArray : [];
    });
    this.store.select(selectSuccessfullyImportedAssets).subscribe(importedAssets => {
      this.uploadedSuccessCount = importedAssets ? importedAssets.length : 0;
    });

    this.store.select(selectErroredAssets).subscribe(erroredAssets => {
      this.errorList = erroredAssets ? cloneDeep(erroredAssets) : [];
      this.errCount = this.errorList.length;
    });
  }

  initNotificationMessage() {
    const notificationMessage = 'notificationMessage.asset.bulkImport';
    const assetHeaderRow = 'bulkImport.assetHeaderRow';
    this.translateService.get([notificationMessage, assetHeaderRow, EnumTranslationPath])
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(translatedText => {
        this.bulkAssetImportService.bulkImportTranslated = translatedText;

        this.notificationMessage = translatedText[notificationMessage];
        this.bulkAssetImportService.errorMessagesTranslated = translatedText[notificationMessage];
        this.bulkAssetImportService.assetMapping = this.buildAssetMapping(assetHeaderRow, translatedText);
      });
  }

  buildAssetMapping(assetHeaderRow, translatedText) {
    const assetMapping: any = this.regionParserService.getParsedBucketKeyWords(
      assetHeaderRow, Object.keys(translatedText[assetHeaderRow]));
    Object.keys(assetMapping).forEach(key => {
      assetMapping[key] = assetMapping[key].toLowerCase();
    });

    return assetMapping;
  }

  onCancel() {
    if (!this.isUploading && !this.isFinishedUpload) {
      this.store.dispatch(resetImportAssets());
      this.dialogRef.close(false);
    } else if (this.isUploading && this.isFinishedUpload) {
      this.isUploading = false;
      this.isCancelProgressUpload = true;
      this.store.dispatch(bulkAssetsImportCompleted({ payload: {} }));
    }
  }

  getRecordData() {
    this.coreCompanyApiService.getAllCompanyDivisions(this.data.companyId).subscribe((divisions: Array<IDivision>) => {
      this.store.dispatch(bulkAssetsCSVDataFileSelected(
        {
          payload: {
            csvFile: this.file,
            companyId: this.data.companyId,
            divisions,
            fieldsCheckDup: this.data.fieldsCheckDup
          }
        }
      ));
    });

    this.store.select(selectRecordRows).subscribe(recordRows => {
      if (recordRows !== undefined) {
        this.records = recordRows;
      }
    });
  }

  onSubmitData() {
    this.bulkAssetImportService.fetchingFieldOptions(this.data).subscribe(prePopulateData => {
      this.records.forEach((record, index) => {
        if (!this.isCancelProgressUpload) {
          this.store.dispatch(bulkAssetCSVDataUpload(
            {
              payload: {
                record,
                index,
                headerArray: this.headerArray,
                prePopulateData,
                companyId: this.data.companyId,
              }
            }
          ));
        }
      });
      this.checkUploadingProgress();
    });
  }

  checkUploadingProgress() {
    this.store.select(selectProgressValue).subscribe((progressValue) => {
      this.progressValue = progressValue;
      if (progressValue === this.records?.length) {
        this.isUploading = false;
        this.store.dispatch(bulkAssetsImportCompleted({ payload: {} }));
      }
    });
  }

  fileUploadEmit(file) {
    this.file = file;

    if (this.file === null) {
      this.isFinishedUpload = false;
      // Reset User State
      this.records = [];
      this.store.dispatch(resetImportAssets());
    } else {
      this.getRecordData();
    }
  }

  getFilePathDownload() {
    return  this.supportService.getFilePathDownloadAsset();
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }
}
