import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { FilterSelectOptionsControlName, ListManagementType as typeList } from '@app/core/consts/list-management.const';
import { ListManagement } from '@app/core/models/list-management';
import { removeSpaces } from '@app/core/utils';
import { ZonarUITableComponent, ZonarUITableDataSource } from '@zonar-ui/table';
import { BehaviorSubject, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { DialogService } from '@app/core/services/dialog.service';
import { TranslateService } from '@zonar-ui/i18n';
import { Translations } from '@app/core/services/i18n/translations.service';
import { RegionParserService } from '@app/core/services/region-parser.service';
import { IDivision } from '@app/core/models/company.model';
import { Store } from '@ngrx/store';
import { setUserNavigation } from '@app/shared/data-stores/users/users-data-store.actions';
import { MediaObserver } from '@angular/flex-layout';

@Component({
  selector: 'app-list-management',
  templateUrl: './list-management.component.html',
  styleUrls: ['./list-management.component.scss']
})
export class ListManagementComponent implements OnDestroy, OnChanges, OnInit {
  @ViewChild(ZonarUITableComponent) table: ZonarUITableComponent;
  @Input() columns = new BehaviorSubject([]);
  @Input() dataSource: ZonarUITableDataSource;
  @Input() viewActions: ListManagement[] = [];
  @Input() toolTipData?: {};
  @Input() errorConfig = new BehaviorSubject({});
  @Input() iconColors?: {};
  @Input() columnStyleConfig?: {};
  @Input() contentProjectionEnabled = false;
  @Input() division: Partial<IDivision> = {};
  @Input() dataList = [];
  @Input() uniqueRowIdentifier: string;
  @Input() externallyManaged: boolean;
  @Input() customCellPortals: any = {};
  @Input() enableMobileTable: boolean = false;
  @Input() initialPageSize: number;

  @Output() formChange = new EventEmitter();
  @Output() iconClick = new EventEmitter();
  @Output() rowSelect = new EventEmitter();

  searchBoxList: FormControl[] = [];
  filtersFormGroup: FormGroup = this.fb.group({});
  searchesFormGroup: FormGroup = this.fb.group({});
  listManagementType = typeList;
  searchList = [];
  buttonList = [];
  filterList = [];
  announcementDialogText: any = {};
  menuOpen: boolean;
  oldChanges = null;
  
  private searchParams = {};
  private filterParams = {};
  private combineParams = {};
  private onDestroy$ = new Subject<void>();
  private minSearchStringLength = 3;
  private rowClickEvent = {};

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private dialogService: DialogService,
    private translateService: TranslateService,
    public translations: Translations,
    public parsingService: RegionParserService,
    public store: Store<any>,
    public media:  MediaObserver,
  ) { }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.viewActions && changes.viewActions.currentValue.length) {
      this.viewActions = changes.viewActions.currentValue;
      this.searchList = this.buildSmallList(typeList.Search);
      this.buttonList = this.buildSmallList(typeList.Button);
      this.filterList = this.buildSmallList(typeList.Filter);
      this.setSearchFormGroupData();
    }
  }

  ngOnInit() {
    this.filtersFormGroup.valueChanges.pipe(
      takeUntil(this.onDestroy$)
    ).subscribe(changes => {

      const tmpFilterParams = {};
      Object.keys(changes).forEach((key) => {
        const selectControlExisted = Object.keys(FilterSelectOptionsControlName).find(item => {
          return Object.values(FilterSelectOptionsControlName[item]).includes(key);
        });
        if (!selectControlExisted) {
          delete changes[key];
        } else if (changes[key]) {
          tmpFilterParams[key] = changes[key];
        }
      });

      if (this.oldChanges && JSON.stringify(changes) === JSON.stringify(this.oldChanges)) {
        return;
      } else {
        this.filterParams = tmpFilterParams;
      }

      this.oldChanges = { ...changes };

      if (Object.keys(this.searchParams).length) {
        this.combineParams = { ...this.searchParams, ...this.filterParams };
      } else {
        this.combineParams = { ...this.filterParams };
      }
      if (Object.keys(this.combineParams).length) {
        this.formChange.emit(this.combineParams);
        // Fire the clear event here to scope out with other the loading state.
        if (this.uniqueRowIdentifier) {
          this.table.selection.clear();
          this.table.selectedRows.emit(this.table.selection);
        }
        this.table.resetPaging();
      }
    });

    this.searchesFormGroup.valueChanges.pipe(
      takeUntil(this.onDestroy$),
      debounceTime(200),
      distinctUntilChanged()
    ).subscribe((changes) => {
      Object.keys(changes).forEach((key) => {
        // trim and remove multi space.
        const str = removeSpaces(changes[key]);
        if (str.length >= this.minSearchStringLength) {
          this.searchParams = { [key]: changes[key] };
          this.combineParams = { ...this.filterParams, ...this.searchParams };
          this.formChange.emit(this.combineParams);
        } else if (Object.keys(this.searchParams).length) {
          this.searchParams = {};
          this.combineParams = { ...this.filterParams, ...this.searchParams };
          this.formChange.emit(this.combineParams);
        }
      });
      // Fire the clear event here to scope out with other the loading state.
      if (this.uniqueRowIdentifier) {
        this.table.selection.clear();
        this.table.selectedRows.emit(this.table.selection);
      }
      this.table.resetPaging();
    });
    this.initTextAnnouncementDialog();
  }

  initTextAnnouncementDialog() {
    this.translateService.get('announcementDialog').pipe(
      takeUntil(this.onDestroy$)
    ).subscribe(res => {
      this.announcementDialogText = this.parsingService.getParsedBucketKeyWords('announcementDialog', Object.keys(res));
    });
  }

  buildSmallList(typeManagement: typeList) {
    return this.viewActions.reduce((pre, cur) =>
      typeManagement === cur?.type ? [...pre,
      typeManagement === typeList.Search ? cur?.searchManagement :
        typeManagement === typeList.Button ? cur?.buttonManagement : cur?.filterManagement] : pre, []);
  }

  setSearchFormGroupData() {
    if (this.searchList && this.searchList.length) {
      this.searchList.forEach((search) => {
        if (!this.searchesFormGroup.controls[search.controlName]) {
          this.searchesFormGroup.registerControl(search.controlName, new FormControl(''));
        }
      });
    }
  }

  rowClick(event) {
    this.iconClick.emit(event);
    this.rowClickEvent = event;
  }

  selectedRows(event) {
    this.rowSelect.emit(event);
  }

  onShowAnnouncementDialog(locateNavigate) {
    const params = {
      title: this.announcementDialogText.selectUserTitle,
      titleDescription: this.announcementDialogText.titleDescription,
      description: this.announcementDialogText.selectUserDescription,
      textCancelButton: this.announcementDialogText.cancelButton,
      textContinueButton: this.announcementDialogText.continueButton
    };
    return this.dialogService.showAnnouncementDialog(params, '27.5rem', '31.25rem').pipe(takeUntil(this.onDestroy$)).subscribe(data => {
      if (data !== undefined) {
        this.store.dispatch(setUserNavigation({ payload: { userInfo: { hasManagedUser: data, division: this.division } } }));
        this.router.navigate([locateNavigate]);
      }
    });
  }

  onNavigate(buttonItem) {
    if (buttonItem.isOpenDialog) {
      return this.onShowAnnouncementDialog(buttonItem.locateNavigate);
    }

    if (!buttonItem.isDisabledButton && buttonItem.customTrigger) {
      buttonItem.customTrigger();
      return;
    }

    if (!buttonItem.isDisabledButton) {
      this.router.navigate([buttonItem.locateNavigate], { state: { division: this.division } });
    }
  }

  menuItemClick(menuAction, actionParam?) {
    if (typeof menuAction === 'function') {
      return menuAction(actionParam);
    }
  }

  resetSelection() {
    this.table?.selection?.clear();
    this.rowSelect.emit({ selected: [] });
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }
}
