<div class="grid-panel" fxLayout="column" fxLayoutAlign="start start" fxLayoutGap="0" fxLayoutGap.lt-sm="8px">
  <span class="grid-panel-title">
    {{ gridObjectData.gridTitle | translate }}
  </span>
  <div class="grid-panel-content" fxLayout="column" fxLayoutAlign="start start" fxLayoutGap="16px">
    <div class="grid-panel-desc" [innerHTML]="gridObjectData.gridDescription"></div>
    <div class="grid-panel-data" fxLayout="row" fxLayoutAlign="space-between start" fxLayoutGap="0" fxLayout.lt-sm="column" fxLayoutAlign.lt-sm="start start" fxLayoutGap.lt-sm="16px"
    [ngClass]="media.isActive('lt-sm') ? '' : 'minimum-row-gap'">
      <div class="grid-data" *ngFor="let item of gridObjectData.gridData; let last = last" fxLayout="column" fxLayoutGap="8px" fxLayoutGap.lt-sm="0">
        <span class="data-header" [ngClass]="media.isActive('lt-sm') ? 'text-wrap' : 'nowrap'" fxLayout="column" fxLayoutAlign="start center" fxLayoutAlign.lt-sm="start start">
          {{item.headerTranslatePath | translate}}
        </span>
        <div class="data-cell" fxLayout="row" fxLayoutAlign="center center" fxLayoutAlign.lt-sm="start center" fxLayoutGap="4px">
          <span class="data-cell-text" [ngClass]="media.isActive('lt-sm') ? 'text-wrap' : 'text-wrap-center'">{{item?.data || (item?.emptyTranslatePath | translate)}}</span>
          <span *ngIf="item?.icon && item?.iconHover && item?.isShowIcon" class="data-cell-icon" fxLayout="row" fxLayoutAlign="start center">
            <img [src]="item?.icon" [alt]="item?.icon" matTooltip="{{ item?.iconHover() }}"
            matTooltipPosition="right"/>
          </span>
          <span *ngIf="item?.icon && item?.iconClick && item?.isShowIcon" class="data-cell-icon clickable" fxLayout="row" fxLayoutAlign="start center">
            <img [src]="item?.icon" [alt]="item?.icon" (click)="item.iconClick()"/>
          </span>
        </div>
      </div>
    </div>
  </div>
</div>
