<ng-container *ngTemplateOutlet="recursiveListTmpl; context:{ list: dataSource }"></ng-container>

<ng-template #recursiveListTmpl let-list="list">
  <table mat-table [dataSource]="list" multiTemplateDataRows class="nested-division-table">
    <ng-container matColumnDef="{{column.columnDef}}" *ngFor="let column of columnsHeaders; let columnIndex = index">
      <th mat-header-cell *matHeaderCellDef [ngStyle]="column.columnStyle" [fxFlex]="column.columnStyle">
        {{column.columnDef}}
      </th>

      <td *matCellDef="let row" [ngStyle]="column.columnStyle" [fxFlex]="column.columnStyle">
        <ng-container *ngIf="column.type === ct.Text && column.columnDef !== 'name'">
          <span class="text-overflow padding-0">
            {{row[column.columnDef] ?? '-'}}
          </span>
        </ng-container>

        <ng-container *ngIf="column.type === ct.Text && column.columnDef === 'name'">
          <a class="text-overflow" [routerLink]="allowAccessDivisionDetail ? column.iLink(row) : null"
            [ngStyle]="{'padding-left': 1.45 * (row.level + 1) + 'rem'}">
            {{row[column.columnDef] ?? '-'}}
          </a>
        </ng-container>

        <ng-container *ngIf="column.type === ct.Icon && !column.hidden">
          <mat-icon [ngClass]="getClassIcon(row, column)" (click)="iconClicked(row, columnIndex)"
            [matTooltip]="getTextTooltip(column, row)" matTooltipClass="table-tooltip">
            {{column.cell(row)}}
          </mat-icon>
        </ng-container>

      </td>
    </ng-container>

    <ng-container matColumnDef="expandedDetail">
      <td *matCellDef="let row" [attr.colspan]="columnsToDisplay.length">
        <div class="element-detail" *ngIf="row.children?.length" [@detailExpand]="isExpanded(row)">
          <div class="mat-elevation-z8" *ngIf="isExpanded(row)">
            <ng-container *ngTemplateOutlet="recursiveListTmpl; context:{ list: row.children }">
            </ng-container>
          </div>
        </div>
      </td>
    </ng-container>

    <tr mat-row *matRowDef="let row; columns: columnsToDisplay;" fxLayout="row" [ngClass]="row.hasHighlight ? 'highlight-row' : '' "></tr>
    <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="detail-row"></tr>
  </table>
</ng-template>