<div class="single-dropdown-panel" fxLayout="column" fxLayoutAlign="start start" [ngClass]="media.isActive('lt-sm') ? 'card-mobile' : 'card'">
  <span class="title">
    {{ data?.title }}
  </span>
  <div class="description" [innerHTML]="data?.description"></div>
  <div class="form">
    <div [ngClass]="media.isActive('lt-sm') ? 'form-group-mobile' : 'form-group'" *ngFor="let form of data?.formGroups?.controls; index as i; let last = last" [formGroup]="form">
      <div *ngIf="!media.isActive('lt-sm')" fxLayout="row" fxLayoutAlign="start center">
        <span class="group-title" fxFlex="36">
          {{ form?.get('staticValues')?.value?.name }}:
        </span>
        <div fxFlex="54" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="0.5rem">
          <zui-searchable-dropdown
            [z_formGroup]="form"
            [options]="form.get('options')?.value"
            [selectOptionsControlName]="'selectedOption'"
            [isMultiple]="false"
            [z_disabled]="false"
            [label]="data?.formDropdownLabel">
          </zui-searchable-dropdown>
          <div *ngIf="form?.get('selectedOption')?.value" class="remove-icon-container" fxLayout="column" fxLayoutAlign="start center">
            <mat-icon (click)="onClearButtonClick(form)" class="icon color-zonar-blue material-icons-outlined">cancel</mat-icon>
          </div>
        </div>
      </div>

      <div *ngIf="media.isActive('lt-sm')" fxLayout="column" fxLayoutAlign="start start" fxLayoutGap="0.75rem">
        <span class="mobile-dropdown-title">
          {{ form?.get('staticValues')?.value?.name }}:
        </span>
        <div class="mobile-dropdown-container" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="0.5rem">
          <zui-searchable-dropdown
            [ngClass]="form?.get('selectedOption')?.value ? 'has-value' : 'no-value'"
            [z_formGroup]="form"
            [options]="form.get('options')?.value"
            [selectOptionsControlName]="'selectedOption'"
            [isMultiple]="false"
            [z_disabled]="false"
            [label]="data?.formDropdownLabel">
          </zui-searchable-dropdown>
          <div *ngIf="form?.get('selectedOption')?.value" class="remove-icon-container" fxLayout="column" fxLayoutAlign="start center">
            <mat-icon (click)="onClearButtonClick(form)" class="icon color-zonar-blue material-icons-outlined">cancel</mat-icon>
          </div>
        </div>
      </div>
      
    </div>
  </div>
</div>
