import { ICompanyMode } from "@app/core/models/user-company.model";

/* state interface */
export interface AppState {
    initialized: boolean;
    loading: boolean;
    companyModeInfo: ICompanyMode;
    companyId: string;
}

/* initial values */
export const initialState: AppState = {
    initialized: false,
    loading: null,
    companyModeInfo: null,
    companyId: null
};
