<div class="bulk-user-import-dialog" fxLayout="column" fxLayoutAlign="space-between start">
    <div fxLayout="row" fxLayoutAlign="start" class="title" fxFlex="10">
      <span>{{ data.translatedText?.title }}</span>
    </div>

    <span *ngIf="isUploading && isFinishedUpload" class="uploading-message status-message">{{ translations.uploadDialog.uploadingFile | translate }}</span>
    <div *ngIf="!isUploading && isFinishedUpload" fxLayout="column" class="result-container">
      <span class="result-column">
        {{uploadedSuccessCount}} {{ translations.uploadDialog.of | translate }} {{records?.length}} {{ data.translatedText?.uploaded }}
      </span>
      <span *ngIf="isShowSuccess()" class="status-message">
        {{ data.translatedText?.successMessage }}
      </span>
      <span *ngIf="isShowWarning()" class="status-message">
        {{ translations.uploadDialog.warningMessage | translate }}
      </span>
      <span *ngIf="isShowError()" class="status-message">
        {{ translations.uploadDialog.errorMessage | translate }}
      </span>
    </div>
    <div fxLayout="row" fxLayoutAlign=" stretch" fxFlex="80" class="file-upload-container">
      <div [fxFlex]="file ? '100' : '40'" class="body-file-upload">
        <app-file-upload (fileUploadEmit)="fileUploadEmit($event)" [isUploading]="isUploading" [progressValue]="progressValue"
          [errCount]="errCount" [isFinishedUpload]="isFinishedUpload"
          [totalRows]="records?.length"
          [isShowSuccess]="isShowSuccess()"
          [isShowWarning]="isShowWarning()"
          [isShowError]="isShowError()"
          [recordsParsed]="records"
        ></app-file-upload>
      </div>
      <div *ngIf="!file" class="description" fxFlex="60" fxLayout="column" fxLayoutAlign="space-between">
        <div class="description-title">{{ translations.uploadDialog.titleDescription | translate }}</div>
        <span>
          {{ translations.uploadDialog.bodyDescription | translate }} <a [href]="getFilePathDownload()" download>{{translations.supportModule.importGuide.here | translate}}</a>
        </span>
        <app-bulk-user-import-requirements [hasManagedUser]="data?.hasManagedUser"></app-bulk-user-import-requirements>
      </div>
    </div>

    <div class="button-container" fxFill fxLayout="row" fxLayoutAlign="end end" fxFlex="10">
      <button *ngIf="isUploading || !isFinishedUpload" mat-stroked-button type="button" (click)="onCancel()">
        {{ translations.uploadDialog.textCancelButton | translate }}</button>
      <button *ngIf="!isFinishedUpload" [disabled]="!file || !records?.length" class="upload-button" mat-raised-button type="submit"
        (click)="onUpload()">{{ translations.uploadDialog.textUploadButton | translate }}</button>
      <button *ngIf="isFinishedUpload" [disabled]="file && isUploading" class="upload-button" mat-raised-button
        type="submit" (click)="onConfirm()">{{ translations.uploadDialog.textOkButton | translate }}</button>
    </div>
  </div>
