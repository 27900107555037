<div class="confirm-message-dialog" fxLayout="column" fxLayoutAlign="space-between start">
  <div class="content" fxLayout="column" fxLayoutAlign="start start">
    <div fxLayout="row" fxLayoutAlign="start" class="title">
      <span>{{data?.title}}</span>
    </div>
    <div class="messages">
      <span>{{data?.messages}}</span>
    </div>
  </div>
  <div class="dialog-button" fxLayout="row" fxLayoutAlign="end end">
    <button mat-stroked-button type="button" (click)="onCancel()" class="stroked-button" fxLayoutAlign="center center"> {{data?.textCancelButton}}</button>
    <button mat-raised-button type="submit" class="raised-button" fxLayoutAlign="center center" (click)="onConfirm()">{{data?.textConfirmButton}}</button>
  </div>
</div>
