import { BulkUsersCSVDataValidatedPayload, ImportBulkUsers } from './users-data-store.interface';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { UsersDataStore, featureKey } from './users-data-store.reducer';

export const selectUserState = createFeatureSelector<UsersDataStore>(featureKey);


export const selectProgressValue = createSelector(selectUserState, state => {
  return state?.importUsers?.importedData?.processedRowsCount;
});
export const selectRecordRows = createSelector(selectUserState, state => {
  return state?.importUsers?.uploadedData?.recordRows;
});
export const selectHeaderArray = createSelector(selectUserState, state => {
  return state?.importUsers?.uploadedData?.headerArray;
});
export const selectSuccessfullyImportedUsers = createSelector(selectUserState, state => {
  return state?.importUsers?.importedData?.successfullyImportedUsers;
});
export const selectErroredUsers = createSelector(selectUserState, state => {
  return state?.importUsers?.importedData?.erroredUsers;
});

// #region Bulk Edit User selector
export const selectSelectedUsers = createSelector(selectUserState, state => {
  return state?.editUsers?.selectedUsers;
});

export const selectSuccessfullyEditedUsers = createSelector(selectUserState, state => {
  return state?.editUsers?.successfullyEditedUsers;
});

export const selectErrorEditedUsers = createSelector(selectUserState, state => {
  return state?.editUsers?.errorEditedUsers;
});

export const selectProcessedRowsCount = createSelector(selectUserState, state => {
  return state?.editUsers?.processedRowsCount;
});

export const selectBulkDeactivateUserCount = createSelector(selectUserState, state => {
  return state?.bulkDeactivateUser?.processedRowsCount;
});

export const selectSuccessBulkDeactivateUsers = createSelector(selectUserState, state => {
  return state?.bulkDeactivateUser?.successfullyEditedUsers;
});

export const selectErrorBulkDeactivateUsers = createSelector(selectUserState, state => {
  return state?.bulkDeactivateUser?.errorEditedUsers;
});

export const selectAllUsers = createSelector(selectUserState, state => {
  return state?.users;
});

export const selectAllUserProfiles = createSelector(selectUserState, state => {
  return state?.userProfileList;
});

export const selectCombineUserList = createSelector(selectUserState, state => {
  return state?.combineUserList;
});

export const selectUserLoadingState = createSelector(selectUserState, state => {
  return state?.isLoading;
});

export const selectUserPolicies = createSelector(selectUserState, state => {
  return state?.userPolicies;
});

export const selectUsersByCompanyId = createSelector(selectUserState, state => {
  return state?.usersGroupPolicy;
});

export const selectUser = createSelector(selectUserState, state => {
  return state?.user;
});

export const selectUserInfoNavigate = createSelector(selectUserState, state => {
  return state?.userInfoNavigate;
});
