import { Injectable } from '@angular/core';
import { get, set } from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class ValidationService {
  currentValidationField = '';
  currentDataField = null;

  constructor() { }

  setCurrentValidationField(fieldName: string) {
    this.currentValidationField = fieldName;
  }

  setRecordDataValidate(data: any, key?: string) {
    if (key) {
      this.currentDataField = set(this.currentDataField, key, data);
    } else {
      this.currentDataField = data;
    }
  }

  getCurrentValidationField() {
    return this.currentValidationField;
  }

  getRecordDataValidate(key?: string) {
    if (key) {
      return get(this.currentDataField, key);
    }

    return this.currentDataField;
  }

  validateRequiredField(value: any): boolean {
    return value !== '' && value !== null && value !== undefined;
  }

  validateMaxLength(value: string, maxLength: number): boolean {
    return value.length <= maxLength;
  }

  validateMinLength(value: string, minLength: number): boolean {
    return value.length >= minLength;
  }

  validateFormatPattern(value: string, pattern: RegExp): boolean {
    return pattern.test(value);
  }

  validateAndReviseEnumerated(value: string, options: Map<any, any>): any {
    if (options.has(value)) {
      return {
        validCheck: true,
        optionRevised: options.get(value)
      };
    }
    return {
      validCheck: false,
      optionRevised: null
    };
  }
}
