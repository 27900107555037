import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-chip-list',
  templateUrl: './chip-list.component.html',
  styleUrls: ['./chip-list.component.scss']
})
export class ChipListComponent implements OnChanges {
  @Input() chipList = [];
  @Input() showMoreLessText = {};
  @Input() defaultChipItems = 4;
  @Output() removeChipEmitter = new EventEmitter<any>();

  hasSeeMore = false;
  hasSeeLess = false;
  chipDisplay = [];

  constructor() { }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.chipList && changes.chipList.currentValue) {
      this.chipList = changes.chipList.currentValue;
      this.buildChipDisplay();
    }
  }

  buildChipDisplay() {
    this.hasSeeMore = this.chipList.length > this.defaultChipItems;
    this.chipDisplay = this.chipList.slice(0, this.defaultChipItems);
  }

  seeMore() {
    this.chipDisplay = [...this.chipList];
    this.hasSeeLess = true;
    this.hasSeeMore = false;
  }

  seeLess() {
    this.chipDisplay = this.chipList.slice(0, this.defaultChipItems);
    this.hasSeeLess = false;
    this.hasSeeMore = true;
  }

  removeChip(chipId: string): void {
    const index = this.chipList.findIndex(chip => chip.id === chipId);

    if (index >= 0) {
      this.chipList.splice(index, 1);
      this.chipDisplay = this.hasSeeMore ? this.chipList.slice(0, this.defaultChipItems) : [...this.chipList];

      if (this.chipList.length <= this.defaultChipItems) {
        this.hasSeeMore = false;
        this.hasSeeLess = false;
      }
    }

    this.removeChipEmitter.emit(this.chipList);
  }

}
