import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Subject, forkJoin } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import * as TenantActions from './tenant-data-store.action';
import { CoreTenantApiService } from '@app/core/services/api/core-tenant-api.service';
import { ITenant } from '@app/core/models/tenant.model';
import { HttpResponse } from '@angular/common/http';
import { TenantDataService } from '@app/core/services/data/tenant-data.service';

@Injectable()
export class TenantDataStoreEffects {
  private cancelSubject$ = new Subject<boolean>();

  constructor(
    private actions$: Actions,
    private coreTenantApiService: CoreTenantApiService,
    private tenantDataService: TenantDataService,
  ) { }

  getTenantList$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TenantActions.getTenantList),
      mergeMap((action) => {
        return this.coreTenantApiService.getTenantList(action.payload.params).pipe(
          mergeMap((res: HttpResponse<Array<ITenant>>) => {
            return [TenantActions.getTenantListSuccess(
              {
                payload: { tenantList: res.body }
              })];
          }),
          catchError((error) => {
            return [TenantActions.getTenantListFailure({
              payload: { error: error }
            })];
          })
        );
      })
    )
  );

  createTenant$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TenantActions.createTenant),
      mergeMap((action) => {
        return this.coreTenantApiService.createTenant(action.payload.params).pipe(
          mergeMap((res: HttpResponse<ITenant>) => {
            return [TenantActions.createTenantSuccess(
              {
                payload: { tenant: res.body }
              })];
          }),
          catchError((error) => {
            return [TenantActions.createTenantFailure({
              payload: { error: error }
            })];
          })
        );
      })
    )
  );

  getTenantById$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TenantActions.getTenantById),
      mergeMap((action) => {
        return this.coreTenantApiService.getTenantById(action.payload.tenantId).pipe(
          mergeMap((res: HttpResponse<ITenant>) => {
            return [TenantActions.getTenantByIdSuccess(
              {
                payload: { tenant: res.body }
              })];
          }),
          catchError((error) => {
            return [TenantActions.getTenantByIdFailure({
              payload: { error: error }
            })];
          })
        );
      })
    )
  );

  updateTenant$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TenantActions.updateTenant),
      mergeMap((action) => {
        const { confirmData, divisions, companyId } = action.payload;
        const tenantId = confirmData.data?.initInfo?.id;
        const tenantUpdateApi = [];
        if (confirmData?.data?.initInfo?.name !== confirmData?.name) {
          tenantUpdateApi.push(this.coreTenantApiService.updateTenantInfo(tenantId, { name: confirmData?.name }));
        }

        if (confirmData?.dataChanged.length) {
          tenantUpdateApi.push(this.coreTenantApiService.updateTenantScope(tenantId, {
            companies: [companyId],
            divisions: this.tenantDataService.buildDivisionParams(confirmData?.dataChanged, divisions),
          }));
        }

        return forkJoin(tenantUpdateApi).pipe(
          mergeMap(() => {
            return [TenantActions.updateTenantSuccess({
              payload: {
                tenantId,
              }
            })];
          }),
          catchError((error) => {
            return [TenantActions.updateTenantFailure({
              payload: { error }
            })];
          })
        );
      })
    )
  );

  addCompaniesToTenant$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TenantActions.addCompaniesToTenant),
      mergeMap((action) => {
        const { tenantId, params } = action.payload;
        return this.coreTenantApiService.addCompaniesToTenant(tenantId, params).pipe(
          mergeMap((res: HttpResponse<ITenant>) => {
            return [TenantActions.addCompaniesToTenantSuccess()];
          }),
          catchError((error) => {
            return [TenantActions.addCompaniesToTenantFailure({
              payload: { error: error }
            })];
          })
        );
      })
    )
  );

  removeCompaniesOfTenant$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TenantActions.removeCompaniesOfTenant),
      mergeMap((action) => {
        const { tenantId, params } = action.payload;
        return this.coreTenantApiService.removeCompaniesOfTenant(tenantId, params).pipe(
          mergeMap((res: HttpResponse<ITenant>) => {
            return [TenantActions.removeCompaniesOfTenantSuccess()];
          }),
          catchError((error) => {
            return [TenantActions.removeCompaniesOfTenantFailure({
              payload: { error: error }
            })];
          })
        );
      })
    )
  );

  addDivisionsToTenant$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TenantActions.addDivisionsToTenant),
      mergeMap((action) => {
        const { tenantId, params } = action.payload;
        return this.coreTenantApiService.addDivisionsToTenant(tenantId, params).pipe(
          mergeMap((res: HttpResponse<ITenant>) => {
            return [TenantActions.addDivisionsToTenantSuccess()];
          }),
          catchError((error) => {
            return [TenantActions.addDivisionsToTenantFailure({
              payload: { error: error }
            })];
          })
        );
      })
    )
  );

  removeDivisionsOfTenant$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TenantActions.removeDivisionsOfTenant),
      mergeMap((action) => {
        const { tenantId, params } = action.payload;
        return this.coreTenantApiService.removeDivisionsOfTenant(tenantId, params).pipe(
          mergeMap((res: HttpResponse<ITenant>) => {
            return [TenantActions.removeDivisionsOfTenantSuccess()];
          }),
          catchError((error) => {
            return [TenantActions.removeDivisionsOfTenantFailure({
              payload: { error: error }
            })];
          })
        );
      })
    )
  );

  cancelRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TenantActions.cancelRequest),
      map(() => {
        this.cancelSubject$.next();
      })
    ), { dispatch: false }
  );
}