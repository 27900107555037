import { Injectable } from '@angular/core';
import { CoreCompanyApiService } from '@app/core/services/api/core-company-api.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, mergeMap } from 'rxjs/operators';
import * as CompaniesActions from './companies-data-store.action';
import { ErrorResponse } from './companies-data-store.interface';

@Injectable()
export class CompaniesDataStoreEffects {
  constructor(
    private actions$: Actions,
    private companyApiService: CoreCompanyApiService
  ) { }
  /* company list effects */
  getCompanyList$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CompaniesActions.getCompanyList),
      mergeMap((action) => {
        return this.companyApiService.getCompanies(action.payload.params).pipe(
          mergeMap((res: any) => {
            return [CompaniesActions.getCompanyListSuccessful({
              payload: {
                companyList: res?.body || [],
                companyListCount: +res?.headers.get('x-total-count')
              }
            })];
          }),
          catchError((error: ErrorResponse) => {
            return [CompaniesActions.getCompanyListFail({
              payload: {
                status: error?.status,
                message: error?.error?.message
              }
            })];
          })
        );
      })
    )
  );
  /* company view effects */
  getCompany$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CompaniesActions.getCompany),
      mergeMap((action) => {
        return this.companyApiService.getCompany(action.payload.companyId).pipe(
          mergeMap((res: any) => {
            return [CompaniesActions.getCompanySuccessful({
              payload: {
                company: res || {}
              }
            })];
          }),
          catchError((error: ErrorResponse) => {
            return [CompaniesActions.getCompanyFail({
              payload: {
                status: error?.status,
                message: error?.error?.message
              }
            })];
          })
        );
      })
    )
  );

  getCompanyAddresses$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CompaniesActions.getCompanyAddresses),
      mergeMap((action) => {
        return this.companyApiService.getAllCompanyAddresses(action.payload.companyId).pipe(
          mergeMap((res: any) => {
            return [CompaniesActions.getCompanyAddressesSuccessful({
              payload: {
                companyAddresses: res?.body?.length ? res.body : null
              }
            })];
          }),
          catchError((error: ErrorResponse) => {
            return [CompaniesActions.getCompanyAddressesFail({
              payload: {
                status: error?.status,
                message: error?.error?.message
              }
            })];
          })
        );
      })
    )
  );

  createCompanyProduct$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CompaniesActions.createCompanyAssociatedProduct),
      mergeMap((action) => {
        const { companyId, productId } = action.payload;
        return this.companyApiService.createCompanyAssociatedProduct(companyId, productId).pipe(
          mergeMap((res: any) => {
            return [CompaniesActions.createCompanyAssociatedProductSuccessful()];
          }),
          catchError((error: ErrorResponse) => {
            return [CompaniesActions.createCompanyAssociatedProductFail({
              payload: { error }
            })];
          })
        );
      })
    )
  );

  deleteCompanyProduct$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CompaniesActions.deleteCompanyAssociatedProduct),
      mergeMap((action) => {
        const { companyId, productIds } = action.payload;
        return this.companyApiService.deleteCompanyAssociatedProduct(companyId, productIds).pipe(
          mergeMap((res: any) => {
            return [CompaniesActions.deleteCompanyAssociatedProductSuccessful()];
          }),
          catchError((error: ErrorResponse) => {
            return [CompaniesActions.deleteCompanyAssociatedProductFail({
              payload: { error }
            })];
          })
        );
      })
    )
  );

  getCompanyProducts$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CompaniesActions.getCompanyAssociatedProducts),
      mergeMap((action) => {
        const {companyId, params} = action.payload;
        return this.companyApiService.getCompanyProducts(companyId, params).pipe(
          mergeMap((products: any) => {
            return [CompaniesActions.getCompanyAssociatedProductsSuccessful({
              payload: {companyProducts: products}
            })];
          }),
          catchError((error: ErrorResponse) => {
            return [CompaniesActions.getCompanyAssociatedProductsFailure({
              payload: { error }
            })];
          })
        );
      })
    )
  );
}
