import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { CoreUserApiService } from '@app/core/services/api/core-user-api.service';
import { CoreCompanyApiService } from '@app/core/services/api/core-company-api.service';
import { BehaviorSubject, forkJoin, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { UserStatus } from '@app/core/consts/user.const';

@Injectable({
  providedIn: 'root'
})
export class UserDataService {
  currentUserLogin: any = {};
  queryParams = null;

  constructor(
    private coreCompanyApiService: CoreCompanyApiService,
    private coreUserApiService: CoreUserApiService,
  ) { }

  getAllowedDivisions(companyId: string, userId: string) {
    return forkJoin([
      this.coreCompanyApiService.getAllCompanyDivisions(companyId),
      this.coreUserApiService.getUserProfileList({
        companyId,
        userId,
        applicationId: environment.auth.applicationId
      })
    ]).pipe(
      map((res: any) => [res[0], res[1].body]),
      map(([divisions, profiles]) => {
        if (divisions && profiles) {
          const profilesDivision = profiles[0]?.divisions || [];
          return profilesDivision.length ? divisions.filter(div => profilesDivision.find(division => division === div.id)) : divisions;
        }

        return [];
      })
    );
  }

  getCurrentUserLogin() {
    return this.currentUserLogin;
  }

  setCurrentUserLogin(user) {
    this.currentUserLogin = user;
  }

  getQueryParams() {
    return this.queryParams;
  }

  setQueryParams(queryParams) {
    this.queryParams = queryParams;
  }

  getUsersByCompanyId(companyId: string) {
    return forkJoin([
      this.coreUserApiService.getAllUsers(companyId, { status: UserStatus.ANY }),
      this.coreUserApiService.getUsersByCompanyId(companyId, { status: UserStatus.ANY })
    ]).pipe(switchMap(([userList, userCompanyDirectory]) => {
        const result = [];
        userCompanyDirectory.forEach(userCompany => {
          const user = userList.find(user => user.id === userCompany.userId);
          if (user) {
            user.status = userCompany.status;
            user.userId = user.id;
            result.push(user);
          }
        });
        return of(result);
      }));
  }
}
