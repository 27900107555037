<div class="create-edit-tree-container" fxLayout="column" fxLayoutAlign="space-around none">
  <!--TITLE-->
  <div class="title-container" fxLayout="column" fxLayoutGap="8px">
    <label class="typography-desktop-headline-3">{{getTitle()}}</label>
    <span class="typography-desktop-body-1" *ngIf="data?.feature === Feature.POLICY && data?.componentMode === ComponentMode.CREATE">{{ data.translated?.createMessage }}</span>
  </div>

  <div class="warning-container"
    [ngStyle]="{'padding': isEmptyRoleWithViewMode() ? '0.625rem 0.5rem': '0.375rem 0.5rem'}"
    fxFlex fxLayout="row" fxLayoutAlign="start center"
    *ngIf="(data?.componentMode === ComponentMode.EDIT || isEmptyRoleWithViewMode()) && data?.feature === Feature.POLICY">
    <div>
      <img class="warning-image" [src]="'assets/icons/Severity-icons.svg'" />
    </div>
    <div fxFlex fxLayout="column" fxLayoutAlign="start start">
      <span class="warning-text"
        [ngClass]="isEmptyRoleWithViewMode() ? 'error-title' : ''"
        *ngIf="isEmptyRoleWithViewMode()">
        {{translations?.companyView?.emptyRoleTooltip | translate}}
      </span>
      <span [ngClass]="isEmptyRoleWithViewMode() ? '' :'warning-text'"> {{
        isEmptyRoleWithViewMode() ? (translations?.createEditTree?.warningMessageEmptyRole | translate) :
        (translations?.createEditTree?.warningMessage | translate:{dataName:
        data?.translated?.dataName }) }}
        <!-- Note: Editing this {{data?.translated?.dataName}} will affect -->
        <span class="user-count" color="accent" [matTooltip]="userAffectTooltipMessage" matTooltipPosition="below"
          matTooltipClass="user-affect-tooltip" #tooltip="matTooltip"
          (mouseenter)="onUserAffectTooltipMouseEnter($event, tooltip)"
          (mouseleave)="onUserAffectTooltipMouseLeave($event, tooltip)"
          (click)="onUserAffectTooltipClick($event, tooltip)" [autoClose]="false">
          {{data?.initInfo?.members?.length}} {{isEmptyRoleWithViewMode() ?
          (translations?.createEditTree?.assigned | translate) : ''}} {{ data?.initInfo?.members?.length > 1 ? (translations?.createEditTree?.users | translate) :
            (translations?.createEditTree?.user | translate)}}
        </span>
      </span>
    </div>
  </div>
  <div *ngIf="data?.componentMode === ComponentMode.EDIT && data?.feature === Feature.POLICY ">
    <app-warning *ngIf="warningData" [warningData]="warningData"></app-warning>
  </div>
  <div *ngIf="data?.componentMode !== ComponentMode.VIEW && data?.feature === Feature.TENANT ">
    <div fxLayout="column" class="text-description">
      <span>{{translations?.createEditTree?.description | translate:getParsedKeyWords()}}</span>
      <span>{{translations?.createEditTree?.note | translate:getParsedKeyWords()}}</span>
    </div>
    <div class="select-all" fxLayout="row" fxLayoutAlign="end center">
      <span class="button-all" (click)="selectOrDeselectAll(false)">{{translations?.createEditTree?.clearAll |
        translate}}</span>
      <span class="divider"></span>
      <span class="button-all" (click)="selectOrDeselectAll(true)">{{translations?.createEditTree?.selectAll |
        translate}}</span>
    </div>
  </div>

  <!--TREE-->
  <div [ngClass]="data?.componentMode === ComponentMode.VIEW && data?.initInfo?.error ? 'tree-container' : ''">
    <div
      [ngStyle]="{'border-color': data?.componentMode === ComponentMode.VIEW || itemsSelectedOnView?.length ? '' : 'red' }"
      class="division-container">
      <!--FILTER-->
      <div class="policy-type-filter-container" *ngIf="policyTypes?.length > 1" fxLayout="column" fxLayoutGap="8px">
        <div fxLayout="row" fxLayoutGap="16px">
          <span class="typography-body-2 policy-type-filter-title">FILTER</span>
          <mat-radio-group fxLayout="row" fxLayoutGap="16px" [(ngModel)]="filteredPolicyType" (ngModelChange)="onPolicyTypeFilterChanged()">
            <mat-radio-button *ngFor="let policyType of policyTypes" [value]="policyType?.value">{{ policyType.translateKey | translate }}</mat-radio-button>
          </mat-radio-group>
        </div>
        <div *ngIf="policyTypeFilterMessageKey" class="policy-type-filter-message-container">
          <span class="color-zonar-black typography-body-2">{{policyTypeFilterMessageKey | translate}}</span>
        </div>
      </div>
      <!--SEARCH-->
      <div *ngIf="data?.componentMode !== ComponentMode.VIEW" class="tree-search-container">
        <app-search [searchFormControl]="searchControl" [placeHolder]="data?.translated?.searchPlaceholder" class="search-container">
        </app-search>
      </div>
      <!--TREE-->
      <app-tree-with-checkboxes #viewChild [treeContents]="data?.dataList"
        [isIgnoreSelectChildDisabled]="!data?.companyLevelCapaSelectedList?.length"
        [labelName]="translations?.createEditTree?.labelName | translate" [checkedAll]="allItemsChecked"
        (selectedItem)="selectItem($event)" [isReadOnly]="disabledTree" [hasTitle]="false"
        [disableSubItemSelection]="disableSubItemSelection" [enableDeterminate]="data?.enableDeterminate"
        [feature]="data?.feature" [filterValues]="filterValues"></app-tree-with-checkboxes>
    </div>
    <span class="role-empty-error-message"
      *ngIf="data?.componentMode === ComponentMode.EDIT && data?.initInfo?.error && !itemsSelectedOnView?.length">
        {{ translations?.createEditTree?.errorMessageEmptyRole | translate}}
    </span>
  </div>

  <div class="name-container" fxLayout="column" fxLayoutAlign="space-around none">
    <span class="name-description"
      *ngIf="data?.componentMode !== ComponentMode.VIEW && !listName.hasError('duplicated-tree') && !listName.hasError('duplicated-name')">{{data?.translated?.nameDes}}</span>
    <mat-error *ngIf="listName.hasError('duplicated-name')" class="duplicated-error-message">
      {{ data?.translated?.errorNameDuplicated }}
    </mat-error>
    <mat-error *ngIf="!listName.hasError('duplicated-name') && listName.hasError('duplicated-tree')"
      class="duplicated-error-message">
      {{ data?.translated?.errorTreeDuplicated }} '{{itemDuplicated?.name}}'
    </mat-error>
    <mat-form-field appearance="outline">
      <mat-label>{{data?.translated?.namePlaceholder}}</mat-label>
      <input matInput [formControl]="listName">
      <mat-error *ngIf="listName.hasError('required')">
        {{ translations?.createEditTree?.errorNameRequired | translate}}
      </mat-error>
    </mat-form-field>
  </div>
  <div class="button-container" fxLayout="row" fxLayoutAlign="start center">
    <span *ngIf="data?.componentMode !== ComponentMode.CREATE && (hasCreateTenantPerm || hasCreatePolicyPerm)"
      fxFlex="30" class="create-button" (click)="onCreate()">{{
      translations?.createEditTree?.createNew | translate}}</span>
    <div fxLayout="row" fxLayoutAlign="end center">
      <button mat-stroked-button type="button" (click)="onCancel()" class="button-margin ">
        {{ translations?.createEditTree?.cancel | translate}}</button>
      <button *ngIf="data?.componentMode !== ComponentMode.VIEW" mat-raised-button type="submit" (click)="onConfirm()"
        [disabled]="!checkDataChanged() || listName.status !== 'VALID'">{{ translations?.createEditTree?.save |
        translate}}</button>
      <button *ngIf="data?.componentMode === ComponentMode.VIEW && (hasUpdateTenantPerm || hasUpdatePolicyPerm)"
        mat-raised-button type="submit" (click)="onEdit()" [disabled]="data.initInfo?.hasDisabled">{{
        translations?.createEditTree?.edit | translate}}</button>
    </div>
  </div>
</div>