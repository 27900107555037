<div class="setting-panel-container" fxLayout="row wrap" fxLayoutAlign="space-between center" fxLayoutGap="0 0 grid">
    <ng-container *ngFor="let item of settingsArray">
      <h4 class="setting-header one-column-width" *ngIf="item?.headerText">{{ item.headerText }}</h4>
      <!-- Toggle section -->
      <div class="setting-body" *ngIf="item?.type === settingType.SETTINGTOGGLE || item?.type === settingType.PRODUCTTOGGLE" [ngClass]="item?.columnWidthClass">
        <app-setting-toggle
          [ngClass]="item?.indicatorClasses"
          (toggleEvent)="item?.toggleEvent($event)"
          [toggleName]="item.name"
          [isToggleActive]="item?.isToggleActive"
          [isDisplaySetting]="true"
          [cautionText]="item?.cautionText || ''"
          [label]="item?.label || ''"
          [hasTooltipTitle]="item?.hasTooltipTitle"
          [showCautionConfirm]="item?.showCautionConfirm"
          [showNotificationOff]="item?.showNotificationOff"
          [showNotificationOn]="item?.showNotificationOn"
          [hasShowMessageProfileOn]="item?.hasShowMessageProfileOn"
          [disabledToggle]="item?.disabledToggle"
          [triStateOptions]="item?.triStateOptions"
          [showIcon]="item?.showIcon"
          >
        </app-setting-toggle>
        <div *ngIf="item?.warnings?.length">
          <app-warning *ngFor="let warn of item?.warnings" [warningData]="warn"></app-warning>
        </div>
      </div>
      <!-- Dropdown section -->
      <div  class="setting-body" *ngIf="item?.type === settingType.DROPDOWN" [ngClass]="item?.columnWidthClass">
        <zui-searchable-dropdown
          [placeholder]="item?.placeholder"
          [ngClass]="item?.indicatorClasses"
          [options]="item?.options"
          [label]="item?.label | translate"
          [selectOptionsControlName]="item?.selectOptionsControlName"
          [searchFormControlName]="item?.searchFormControlName"
          [isMultiple]="item?.isMultiple"
          [z_formGroup]="item?.z_formGroup"
          [sortByStringTitle]="item?.sortByStringTitle"
          [z_disabled]="item?.z_disabled">
        </zui-searchable-dropdown>
      </div>
    </ng-container>
  </div>
