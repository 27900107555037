import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { ZonarUiAnalyticsService, ZonarUIAnalyticsTokens } from '@zonar-ui/analytics';

import { environment } from '@environments/environment';
import { HttpClient } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TranslateLoader, TranslateModule } from '@zonar-ui/i18n';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}
@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    })
  ],
  exports: [
  ],
  providers: [
    {provide: ZonarUIAnalyticsTokens.ID, useValue: environment.googleTagManager.id},
    {provide: ZonarUIAnalyticsTokens.AUTH, useValue: environment.googleTagManager.auth},
    {provide: ZonarUIAnalyticsTokens.ENVIRONMENT, useValue: environment.googleTagManager.environment},
    // pendo service
    {provide: 'pendoParams', useValue: environment.pendo},
    ZonarUiAnalyticsService,
    // The Symbols to include header `Zonar-owner-id` to APIs that required this header.
    {
      provide: 'options',
      useValue: {
        zonarOwnerIdInterceptor: {
          requiresZonarOwnerId: [environment.userPreferenceApiBase.url]
        }
      }
    },
  ]
})
export class CoreModule { }
