import { ChangeDetectorRef, Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from "@angular/core";
import { CreateEditWithTreeBaseComponent } from "../create-edit-with-tree-base/create-edit-with-tree-base.component";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { Store } from "@ngrx/store";
import { RegionParserService } from "@app/core/services/region-parser.service";
import { PermissionsService } from "@zonar-ui/auth";
import { I18nService, TranslateService } from "@zonar-ui/i18n";
import { Translations } from "@app/core/services/i18n/translations.service";
import { Router } from "@angular/router";

@Component({
  selector: 'app-create-edit-with-tree-mobile',
  templateUrl: './create-edit-with-tree-mobile.component.html',
  styleUrls: ['./create-edit-with-tree-mobile.component.scss']
})
export class CreateEditWithTreeMobileComponent extends CreateEditWithTreeBaseComponent implements OnInit, OnChanges, OnDestroy {
  constructor(
    public matDialog: MatDialog,
    public router: Router,
    public dialogRef: MatDialogRef<CreateEditWithTreeBaseComponent>,
    public translateService: TranslateService,
    public translations: Translations,
    public i18nService: I18nService,
    public store: Store<any>,
    public regionParserService: RegionParserService,
    public changeDetector: ChangeDetectorRef,
    public permissionService: PermissionsService,
  ) {
    super(matDialog, router, dialogRef, translateService, translations, i18nService, store, regionParserService, changeDetector, permissionService);
  }

  ngOnChanges(changes: SimpleChanges): void {
    super.ngOnChanges(changes);
  }

  ngOnInit(): void {
    super.ngOnInit();
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }
}
