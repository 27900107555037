import { Injectable } from '@angular/core';
import { EvirApiService } from '@app/core/services/api/evir-api.service';
import { VINApiService } from '@app/core/services/api/vin-api.service';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AssetLogicService {
  result$ = new BehaviorSubject({});

  constructor(
    private vinApiService: VINApiService,
    private evirApiService: EvirApiService
  ) { }


  parsingVin(vin) {
    this.vinApiService.parsingVinAPI(vin)
      .subscribe(response => {
        let hashmap = {};
        if (response.Results && response.Results.length) {
          hashmap = response.Results.reduce((a, v) => ({ ...a, [v.Variable]: v }), {});
        }
        this.result$.next(hashmap);
      });
  }

  getCVIConfigurationProfile(companyId): Observable<any> {
    return this.evirApiService.getEvirConfig(companyId, { activeOnly: 'True' })
      .pipe(
        map((config: any) => {
          return (config.body[0]?.zoneLayouts.filter(zone => zone.assetType && zone.assetType.at(-1)) || [])
            .map(zone => zone.assetType.at(-1));
        }),
        catchError((error: any) => {
          return of([]);
        })
      );
  }

}
