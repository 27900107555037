import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
/* reducer */
import * as fromUsersDataStore from './users-data-store.reducer';

/* effects */
import { UsersDataStoreEffects } from './users-data-store.effects';


@NgModule({
    declarations: [],
    imports: [
        CommonModule,
        StoreModule.forFeature(
            fromUsersDataStore.featureKey,
            fromUsersDataStore.reducer,
            { initialState: fromUsersDataStore.initialState }
        ),
        EffectsModule.forFeature([UsersDataStoreEffects])

    ],
    exports: [
        StoreModule,
        EffectsModule
    ]
})
export class UsersDataStoreModule { }
