export enum PolicyTablePropertyParams {
  PER_PAGE = 'per_page',
  PAGE = 'page',
  NAME = 'q'
};

export enum PolicyListTableColDef {
  CHECK_BOX = 'checkbox',
  POLICY_NAME = 'policyName',
  USER_COUNT = 'userCount',
  EDIT = 'edit',
};

/* policy template */
export enum EPolicyTemplateJobType {
  STANDARD = 'STANDARD',
}
