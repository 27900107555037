<div class="multi-tagid-dialog-container" fxLayout="column">
  <div id="multiTagIdDialogContent" class="content-container" fxLayout="column" fxLayoutGap="0.5rem">
    <span class="typography-desktop-headline-3">{{ translations.userMultiTagIdDialog.title | translate }}</span>
    <ol class="order-list">
      <li class="list-item-spacing">
        <span class="typography-desktop-body-1">
          {{ translations.userMultiTagIdDialog.step1 | translate }}
          <a class="hyperlink-zonar-blue" [href]="gtcDriverUrl" target="_blank">
            {{ translations.userMultiTagIdDialog.step1Link | translate }}<mat-icon class="icon-20px">launch</mat-icon>
          </a>.
        </span>
      </li>
      <li>
        <span class="typography-desktop-body-1">{{ translations.userMultiTagIdDialog.step2 | translate }}</span>
      </li>
    </ol>
    <!--TABLE-->
    <table class="simple-table" mat-table [dataSource]="data.multiTagIdList">
      <!--ICON-->
      <ng-container matColumnDef="critical-icon">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let row">
          <div fxLayout="column" fxLayoutAlign="start center">
            <mat-icon class="nonclickable-icon icon-20px color-zonar-critical material-icons-outlined">error_outline</mat-icon>
          </div>
        </td>
      </ng-container>
      <!--ACCOUNT CODE-->
      <ng-container matColumnDef="account-code">
        <th class="typography-desktop-table-header" mat-header-cell *matHeaderCellDef>{{ (translations.userMultiTagIdDialog.accountCode | translate)?.toUpperCase() }}</th>
        <td mat-cell *matCellDef="let row">{{row?.accountCode || DEFAULT_VALUE}}</td>
      </ng-container>
      <!--TAGID-->
      <ng-container matColumnDef="tag-id">
        <th class="typography-desktop-table-header" mat-header-cell *matHeaderCellDef>{{ (translations.userMultiTagIdDialog.tagid | translate)?.toUpperCase() }}</th>
        <td mat-cell *matCellDef="let row">{{row?.tagId || DEFAULT_VALUE}}</td>
      </ng-container>
      <!--PIN-->
      <ng-container matColumnDef="pin">
        <th class="typography-desktop-table-header" mat-header-cell *matHeaderCellDef>{{ (translations.userMultiTagIdDialog.pin | translate)?.toUpperCase() }}</th>
        <td mat-cell *matCellDef="let row">{{row?.pin || DEFAULT_VALUE}}</td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
    <!--TABLE END-->
    <ol class="order-list" start="3" [ngClass]="isMainContentOverflowY ? 'scroll-area-last-item' : ''">
      <li class="list-item-spacing">
        <span class="typography-desktop-body-1">{{ translations.userMultiTagIdDialog.step3 | translate }}</span>
      </li>
      <li>
        <span class="typography-desktop-body-1">{{ translations.userMultiTagIdDialog.step4 | translate }}</span>
      </li>
    </ol>
  </div>
  <div id="multiTagIdDialogButton" class="button-container" fxLayout="row" fxLayoutAlign="end end" fxLayoutGap="0.75rem" [ngClass]="isMainContentOverflowY ? 'overflow-border' : ''">
    <button mat-stroked-button type="button" (click)="onClose()">
      {{ translations.userMultiTagIdDialog.close | translate }}
    </button>
    <button mat-raised-button type="submit" (click)="onGoToClassicGTC()">
      <span fxLayout="row" fxLayoutGap="0.25rem">
        <span>{{ translations.userMultiTagIdDialog.gotoClassicGTC | translate }}</span>
        <mat-icon class="icon-20px">launch</mat-icon>
      </span>
    </button>
  </div>
</div>