import { HttpClient, HttpResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment as env } from '@environments/environment';
import { EntityApiBaseService } from '@app/core/services/api/entity-api-base.service';
import { Observable } from "rxjs";
import { ICreatePolicyParams, IGetPoliciesParams, IGetPolicyTemplatesParams, IPolicy, IPolicyTemplate, IPolicyTemplatePromoteParams, IUpdatePolicyInfoParams, IUpdatePolicyGrantsParams, ICreatePolicyTemplateParams, IUpdatePolicyTemplateParams } from "@app/core/models/policy.model";

@Injectable({
  providedIn: 'root'
})
export class CorePolicyApiService {
  private POLICIES_RESOURCE = 'policies';
  private POLICY_TEMPLATES_RESOURCE = 'policytemplates';
  private APPLICATION_RESOURCE = 'application';
  private ROLES_RESOURCE = 'roles';
  private PROMOTE_RESOURCE = 'promote';

  constructor(
    private http: HttpClient,
    private entityApiBaseService: EntityApiBaseService
  ) { }

  /**
   * Get all policies that matches query parameters
   * @param params Query parameters to search for policies
   * @returns Http response of policy list
   */
  getPolicies(params?: IGetPoliciesParams) {
    const url = `${env.coreEntityApiBase.url}/${this.POLICIES_RESOURCE}`;
    return this.entityApiBaseService.getAll(url, params);
  }

  /**
   * Get policy by querying the group id
   * @param policyId The policy id
   * @returns Http response of policy
   */
  getPolicyById(policyId: string): Observable<HttpResponse<IPolicy>> {
    const url = `${env.coreEntityApiBase.url}/${this.POLICIES_RESOURCE}/${policyId}`;
    return this.entityApiBaseService.getData(url);
  }

  /**
   * Update the policy's grants
   * @param policyId The policy id
   * @param params Grants of the policy to replace
   * @returns Http response of request status
   */
  updatePolicyGrants(policyId: string, params: IUpdatePolicyGrantsParams): Observable<HttpResponse<any>> {
    const url = `${env.coreEntityApiBase.url}/${this.POLICIES_RESOURCE}/${policyId}`;
    return this.http.put(url, params, { observe: 'response', headers: this.entityApiBaseService.getRequiredLoadingHttpHeader() });
  }

  /**
   * Update the policy's information
   * @param policyId The policy id
   * @param params Policy's information such as companyId, name, etc.
   * @returns Http response of request status
   */
  updatePolicyInfo(policyId: string, params: IUpdatePolicyInfoParams) {
    const url = `${env.coreEntityApiBase.url}/${this.POLICIES_RESOURCE}/${policyId}`;
    return this.http.patch(url, params, { observe: 'response', headers: this.entityApiBaseService.getRequiredLoadingHttpHeader() });
  }

  /**
   * Create the policy
   * @param policyId The policy id
   * @param params Policy parameters to replace
   * @returns Http response of request status
   */
  createPolicy(params: ICreatePolicyParams): Observable<HttpResponse<any>> {
    const url = `${env.coreEntityApiBase.url}/${this.POLICIES_RESOURCE}`;
    return this.http.post(url, params, { observe: 'response', headers: this.entityApiBaseService.getRequiredLoadingHttpHeader() });
  }

  /**
   * Add application to a policy
   * @param policyId The policy id
   * @param params The application id to be added
   * @returns Http response of request status
   */
  addApplicationToPolicy(policyId: string, params: string): Observable<HttpResponse<any>> {
    const url = `${env.coreEntityApiBase.url}/${this.POLICIES_RESOURCE}/${policyId}/${this.APPLICATION_RESOURCE}`;
    return this.http.post(url, params, { observe: 'response', headers: this.entityApiBaseService.getRequiredLoadingHttpHeader() });
  }

  /**
   * Remove application from a policy
   * @param policyId The policy id
   * @param applicationId The application id to be removed
   * @returns Http response of request status
   */
  removeApplicationOfPolicy(policyId: string, applicationId: string): Observable<HttpResponse<any>> {
    const url = `${env.coreEntityApiBase.url}/${this.POLICIES_RESOURCE}/${policyId}/${this.APPLICATION_RESOURCE}/${applicationId}`;
    return this.http.delete(url, { observe: 'response', headers: this.entityApiBaseService.getRequiredLoadingHttpHeader() });
  }

  /**
   * Add roles to a application of a policy
   * @param policyId The policy id
   * @param applicationId The application id
   * @param params The array of role's ids to be added to this group role array
   * @returns Http response of request status
   */
  addCapabilitiesToAppOfPolicy(policyId: string, applicationId: string, params: Array<string>): Observable<HttpResponse<any>> {
    const url = `${env.coreEntityApiBase.url}/${this.POLICIES_RESOURCE}/${policyId}/${this.APPLICATION_RESOURCE}/${applicationId}/${this.ROLES_RESOURCE}`;
    return this.http.post(url, params, { observe: 'response', headers: this.entityApiBaseService.getRequiredLoadingHttpHeader() });
  }

  /**
   * Remove roles from a application of a policy
   * @param policyId The policy id
   * @param applicationId The application id
   * @param params The array of role's ids to be removed to this group role array
   * @returns Http response of request status
   */
  removeCapabilitiesOfAppOfPolicy(policyId: string, applicationId: string, params: Array<string>): Observable<HttpResponse<any>> {
    const url = `${env.coreEntityApiBase.url}/${this.POLICIES_RESOURCE}/${policyId}/${this.APPLICATION_RESOURCE}/${applicationId}/${this.ROLES_RESOURCE}`;
    return this.http.delete(url, { observe: 'response', headers: this.entityApiBaseService.getRequiredLoadingHttpHeader(), body: params });
  }

  /**
   * Get all policy templates that matches query parameters
   * @param params Query parameters to search for policy templates
   * @returns Http response of policy template list in full model
   */
  getPolicyTemplates(params?: IGetPolicyTemplatesParams): Observable<HttpResponse<Array<IPolicyTemplate>>> {
    const url = `${env.coreEntityApiBase.url}/${this.POLICY_TEMPLATES_RESOURCE}`;
    return this.entityApiBaseService.getData(url, params);
  }

  /**
   * Create a policy template from parameters
   * @param params Policy template parameters to create
   * @returns Http response of policy template in full model
   */
  createPolicyTemplate(params: ICreatePolicyTemplateParams): Observable<HttpResponse<IPolicyTemplate>> {
    const url = `${env.coreEntityApiBase.url}/${this.POLICY_TEMPLATES_RESOURCE}`;
    return this.http.post(url, params, { observe: 'response', headers: this.entityApiBaseService.getRequiredLoadingHttpHeader() });
  }

  /**
   * Get policy template by querying the policy template id, return a full model
   * @param roleTemplateId The policy template id
   * @returns Http response of policy template in full model
   */
  getPolicyTemplateById(roleTemplateId: string): Observable<HttpResponse<IPolicyTemplate>> {
    const url = `${env.coreEntityApiBase.url}/${this.POLICY_TEMPLATES_RESOURCE}/${roleTemplateId}`;
    return this.entityApiBaseService.getData(url);
  }

  /**
   * Update a policy template with parameters
   * @param roleTemplateId The policy template id
   * @param params Policy template parameters to patch
   * @returns Http response of request status
   */
  updatePolicyTemplate(roleTemplateId: string, params: IUpdatePolicyTemplateParams): Observable<HttpResponse<any>> {
    const url = `${env.coreEntityApiBase.url}/${this.POLICY_TEMPLATES_RESOURCE}/${roleTemplateId}`;
    return this.http.patch(url, params, { observe: 'response', headers: this.entityApiBaseService.getRequiredLoadingHttpHeader() });
  }

  /**
   * Create a group with the use of policy template
   * @param roleTemplateId The policy template id
   * @param params The group parameters to create alongside with the policy template's params
   * @returns Http response of request status
   */
  createGroupFromPolicyTemplate(roleTemplateId: string, params: IPolicyTemplatePromoteParams): Observable<HttpResponse<any>> {
    const url = `${env.coreEntityApiBase.url}/${this.POLICY_TEMPLATES_RESOURCE}/${roleTemplateId}/${this.PROMOTE_RESOURCE}`;
    return this.http.post(url, params, { observe: 'response', headers: this.entityApiBaseService.getRequiredLoadingHttpHeader() });
  }
}
