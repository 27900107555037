import { Directive, EventEmitter, HostListener, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { first, throttleTime } from 'rxjs/operators';

@Directive({
  selector: '[appThrottledClick]'
})
export class ThrottledClickDirective implements OnInit, OnDestroy {
  @Input() throttleTime;
  @Output() throttledClick = new EventEmitter();

  clicks = new Subject();
  subscription: Subscription;

  constructor() { }

  ngOnInit() {
    this.subscription = this.clicks.pipe(
      this.throttleTime ? throttleTime(this.throttleTime) : first()
    ).subscribe(e => this.emitThrottledClick(e));
  }

  emitThrottledClick(e) {
    this.throttledClick.emit(e);
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  @HostListener('click', ['$event'])
  @HostListener('submit', ['$event'])
  clickEvent(event) {
    event.preventDefault();
    event.stopPropagation();
    this.clicks.next(event);
  }
}
