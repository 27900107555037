import { SettingApiService } from '@app/core/services/api/setting-api.service';
import { Injectable } from '@angular/core';
import { CompanySetting } from '@app/core/consts/company.const';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs/internal/Observable';
import { OwnerType, SettingArrayAction } from '@app/core/consts/setting.const';
import { SettingInfo } from '@app/core/models/setting.model';
import { HttpResponse } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class SettingsService {

  constructor(
    private settingApiService: SettingApiService
  ) { }

  isLegacyOperatorCompany(companyId: string): Observable<boolean> {
    const params = {
      ownerId: companyId,
      name: CompanySetting.LEGACY_OPERATOR,
      ownerType: OwnerType.COMPANY,
    };
    return this.settingApiService.getCompanySetting(params)
      .pipe(
        map((res: HttpResponse<Array<SettingInfo>>) => res.body),
        map((settings: Array<SettingInfo>) => {
          return settings.some(setting => setting?.value === 'true');
        })
      );
  }

  isAllowManagedCompany(companyId): Observable<boolean> {
    const params = {
      ownerId: companyId,
      name: CompanySetting.MANAGED,
      ownerType: OwnerType.COMPANY,
    };
    return this.settingApiService.getCompanySetting(params)
      .pipe(
        map((res: HttpResponse<Array<SettingInfo>>) => res.body),
        map((settings: Array<SettingInfo>) => {
          return settings.some(setting => setting?.value === 'true');
        })
      );
  }

  getCompanySetting(companyId: string, settingName: string): Observable<SettingInfo> {
    const params = {
      ownerId: companyId,
      name: settingName,
      ownerType: OwnerType.COMPANY,
    };
    return this.settingApiService.getCompanySetting(params).pipe(
      map(response => {
        return response.body[0];
      })
    );
  }



  modifySetting(target: Array<any>, action: string, name: string = null, data = null) {
    if (!target) {
      return null;
    }

    const index = target.findIndex((setting) => setting.name === name);
    if (index === -1) {
      return target;
    }

    switch (action) {
      case SettingArrayAction.REMOVE:
        target.splice(index, 1);
        break;
      case SettingArrayAction.UPDATE:
        if (typeof data === 'object'
          && !Array.isArray(data)) {
          Object.assign(target[index], data);
        }
        break;
      case SettingArrayAction.ATTACH:
        if (typeof data === 'object'
          && !Array.isArray(data)
          && data !== null) { // type of null is 'object'
          target.splice(index, 0, data);
        }
        break;
      default:
        break;
    }

    return target;
  }

  getCompanyMigrationStatus(companyId): Observable<string | null> {
    const params = {
      ownerId: companyId,
      name: CompanySetting.GROUP_POLICY_STATUS,
      ownerType: OwnerType.COMPANY,
    };
    return this.settingApiService.getCompanySetting(params)
      .pipe(
        map((res: HttpResponse<Array<SettingInfo>>) => res.body),
        map((settings: Array<SettingInfo>) => {
          const setting = settings?.length ? settings[0] : null;
          return setting?.value;
        })
      );
  }
}
