import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DefaultItemList, IconToggleType } from '@app/core/consts/preview-dialog.const';
import { Translations } from '@app/core/services/i18n/translations.service';
import { TranslateService } from '@zonar-ui/i18n';

@Component({
  selector: 'app-preview-dialog',
  templateUrl: './preview-dialog.component.html',
  styleUrls: ['./preview-dialog.component.scss']
})
export class PreviewDialogComponent implements OnInit {

  mapArrayDisplay: any = {};
  mapInitData: any = {};
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<PreviewDialogComponent>,
    public translateService: TranslateService,
    public translations: Translations
  ) { }

  ngOnInit(): void {
    if (this.data && this.data.valueChanged) {
      this.data.valueChanged = this.data.valueChanged.filter(valueChanged => valueChanged.values !== null);
      this.mapInitData = this.data.valueChanged.reduce((a, v) => ({ ...a, [v.fieldName]: v }), {});
      this.buildMapArrayDisplay();
    }
  }

  buildMapArrayDisplay() {
    this.data.valueChanged.forEach(item => {
      if (this.hasArray(item.values)) {
        this.mapArrayDisplay[item.fieldName] = item.values;
      } else if (this.hasArray(item.values?.value)) {
        this.mapArrayDisplay[item.fieldName] = item.values?.value;
      }
    });
  }

  onCancel() {
    this.dialogRef.close();
  }

  onConfirm() {
    this.dialogRef.close(true);
  }

  showMore(fieldName) {
    this.mapArrayDisplay[fieldName] = this.mapInitData[fieldName].values;
  }

  showLess(fieldName) {
    this.mapArrayDisplay[fieldName] = this.mapInitData[fieldName].values.slice(0, DefaultItemList.DEFAULT_NUMBER_OF_ITEMS);
  }

  typeOf(value) {
    return typeof value;
  }

  hasArray(value) {
    return Array.isArray(value);
  }

  get iconType() {
    return IconToggleType;
  }
}
