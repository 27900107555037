/* angular */
import { ICreateGroupParams, IGroup } from '@app/core/models/group.model';
import { createAction, props } from '@ngrx/store';

/* action types */
export enum ActionTypes {
  // common action types
  InitGroup = '[GROUP] InitGroup',
  ResetGroup = '[GROUP] ResetGroup',
  CancelRequest = '[GROUP] CancelRequest',

  GetGroupByCompanyId = '[GROUP] GetGroupByCompanyId',
  GetGroupByCompanyIdSuccess = '[GROUP] GetGroupByCompanyIdSuccess',
  GetGroupByCompanyIdFailure = '[GROUP] GetGroupByCompanyIdFailure',

  CreateGroup = '[GROUP] CreateGroup',
  CreateGroupSuccess = '[GROUP] CreateGroupSuccess',
  CreateGroupFailure = '[GROUP] CreateGroupFailure',
};

// common actions
export const initGroup = createAction(ActionTypes.InitGroup);
export const resetGroup = createAction(ActionTypes.ResetGroup);
export const cancelRequest = createAction(ActionTypes.CancelRequest);

export const getGroupByCompanyId = createAction(ActionTypes.GetGroupByCompanyId, props<{ payload: {params?: any} }>());
export const getGroupByCompanyIdSuccess = createAction(ActionTypes.GetGroupByCompanyIdSuccess, props<{ payload: { companyGroups: Array<IGroup> } }>());
export const getGroupByCompanyIdFailure = createAction(ActionTypes.GetGroupByCompanyIdFailure, props<{ payload: { error: any } }>());

export const createGroup = createAction(ActionTypes.CreateGroup, props<{ payload: ICreateGroupParams }>());
export const createGroupSuccess = createAction(ActionTypes.CreateGroupSuccess, props<{ payload: IGroup }>());
export const createGroupFailure = createAction(ActionTypes.CreateGroupFailure, props<{ payload: { error: any } }>());
