/* angular */
import { IAssetParams } from '@app/core/models/asset.model';
import { createReducer, on } from '@ngrx/store';

/* actions */
import * as AssetsDataStoreActions from './assets-data-store.actions';
import { BulkAssetsImportStatuses,  UpdateAssetsStatus,  ImportBulkAssets, ActiveAssetUpdateStatus, UpdateBulkAssets } from './assets-data-store.interface';
import { IDivision } from '@app/core/models/company.model';

/* state key */
export const featureKey = 'assets';

/* state interface */

export interface AssetsDataStore {
  isLoading?: boolean;
  assets?: IAssetParams[];
  totalAssets: number;
  activeAsset?: {
    data?: IAssetParams;
    homeLocation?: IDivision;
    isLoading?: boolean;
    error?: any;
    updateStatus?: ActiveAssetUpdateStatus;
    updateError?: string;
  };
  importAssets?: ImportBulkAssets;
  updateAssetsStatus?: UpdateAssetsStatus;
  combineAssetList?: any[];
  assetType?: any[];
  updateBulkAssets?: UpdateBulkAssets;
  filterParams?: {
    properties?: any;
    page?: number;
    perPage?: number;
  };
}


/* initial state */
export const initialState: AssetsDataStore = {
  isLoading: null,
  assets: [],
  totalAssets: null,
  activeAsset: {
    data: null,
    homeLocation: null,
    isLoading: null,
    error: null,
    updateStatus: null,
    updateError: null,
  },
  importAssets: null,
  updateAssetsStatus: null,
  combineAssetList: null,
  assetType: null,
  updateBulkAssets: {
    total: null,
    processed: null,
    succeedUpdated: null,
    failedUpdated: null,
  },
  filterParams: {
    properties: null,
    page: null,
    perPage: null,
  },
};

export const reducer = createReducer(
  initialState,
  on(AssetsDataStoreActions.resetSavedFilterParams, (state, action) => ({
    ...state,
    assets: null,
    isLoading: null,
    totalAssets: null,
  })),
  // Asset Filter Params
  on(AssetsDataStoreActions.setAssetFilterProperties, (state, action) => ({
    ...state,
    filterParams: {
      ...state.filterParams,
      properties: action.payload.properties,
    }
  })),
  on(AssetsDataStoreActions.setAssetFilterPage, (state, action) => ({
    ...state,
    filterParams: {
      ...state.filterParams,
      page: action.payload.page,
      perPage: action.payload.perPage,
    }
  })),

  on(AssetsDataStoreActions.bulkAssetProcessStart, (state, action) => ({
    ...state,
    updateBulkAssets: {
      ...state.updateBulkAssets,
      total: action.payload.totalAssets,
      processed: 0,
      succeedUpdated: [],
      failedUpdated: [],
    }
  })),
  on(AssetsDataStoreActions.bulkAssetProcessSuccess, (state, action) => {
    let count = state.updateBulkAssets.processed;
    count++;
    return {
      ...state,
      updateBulkAssets: {
        ...state.updateBulkAssets,
        processed: count,
        succeedUpdated: [...state.updateBulkAssets.succeedUpdated, action.payload.asset],
      }
    };
  }),
  on(AssetsDataStoreActions.bulkAssetProcessFail, (state, action) => {
    let count = state.updateBulkAssets.processed;
    count++;
    return {
      ...state,
      updateBulkAssets: {
        ...state.updateBulkAssets,
        processed: count,
        failedUpdated: [...state.updateBulkAssets.failedUpdated, action.payload.asset],
      }
    };
  }),
  on(AssetsDataStoreActions.bulkAssetProcessReset, (state, action) => ({
    ...state,
    updateBulkAssets: {
      ...state.updateBulkAssets,
      total: null,
      processed: null,
      succeedUpdated: null,
      failedUpdated: null,
    }
  })),

  on(AssetsDataStoreActions.getAssetDetail, (state) => ({
    ...state,
    activeAsset: {
      ...state.activeAsset,
      isLoading: true,
    }
  })),

  on(AssetsDataStoreActions.getAssetDetailSuccess, (state, action) => ({
    ...state,
    activeAsset: {
      ...state.activeAsset,
      data: action.payload.asset,
      homeLocation: action.payload.homeLocation,
      error: null,
      isLoading: false,
    }
  })),

  on(AssetsDataStoreActions.getAssetDetailFail, (state, action) => ({
    ...state,
    activeAsset: {
      ...state.activeAsset,
      error: action.payload.error,
      isLoading: false,
    }
  })),

  on(AssetsDataStoreActions.resetAssetDetail, (state, action) => ({
    ...state,
    activeAsset: {
      ...state.activeAsset,
      data: null,
      homeLocation: null,
      isLoading: null,
      error: null,
      updateStatus: null,
      updateError: null,
    }
  })),

  on(AssetsDataStoreActions.updateAssetCustomProperty, (state) => ({
    ...state,
    activeAsset: {
      ...state.activeAsset,
      updateStatus: ActiveAssetUpdateStatus.LOADING
    }
  })),

  on(AssetsDataStoreActions.updateAssetCustomPropertySuccess, (state) => ({
    ...state,
    activeAsset: {
      ...state.activeAsset,
      updateStatus: ActiveAssetUpdateStatus.SUCCESS
    }
  })),

  on(AssetsDataStoreActions.updateAssetCustomPropertyFail, (state) => ({
    ...state,
    activeAsset: {
      ...state.activeAsset,
      updateStatus: ActiveAssetUpdateStatus.ERROR
    }
  })),




  on(AssetsDataStoreActions.bulkAssetsCSVDataFileSelected, (state, action) => ({
    ...state,
    importAssets: {
      ...state.importAssets,
      status: BulkAssetsImportStatuses.VALIDATING_FILE,
      company: {
        id: action.payload.companyId,
        divisions: action.payload.divisions
      },
      uploadedData: {
        ...state.importAssets?.uploadedData,
        csvFile: {
          name: action.payload.csvFile?.name,
          size: action.payload.csvFile?.size,
          type: action.payload.csvFile?.type
        }
      },
      importedData: {
        ...state.importAssets?.importedData,
        processedRowsCount: 0,
        successfullyImportedAssets: [],
        erroredAssets: []
      }
    }
  })),

  on(AssetsDataStoreActions.bulkAssetsCSVDataValidated, (state, action) => ({
    ...state,
    importAssets: {
      ...state.importAssets,
      status: BulkAssetsImportStatuses.PARSING_DATA,
      uploadedData: {
        ...state.importAssets?.uploadedData,
        recordRows: action.payload.recordRows
      }
    }
  })),

  on(AssetsDataStoreActions.bulkAssetsCSVParsingStarted, (state, action) => ({
    ...state,
    importAssets: {
      ...state.importAssets,
      status: BulkAssetsImportStatuses.PARSING_DATA
    }
  })),

  on(AssetsDataStoreActions.bulkAssetCSVFileForInvalidDataGenerated, (state, action) => ({
    ...state,
    importAssets: {
      ...state.importAssets,
      status: BulkAssetsImportStatuses.DATA_LOADED,
      uploadedData: {
        ...state.importAssets?.uploadedData,
        invalidDataCsvFile: action.payload.csvFile
      }
    }
  })),

  on(AssetsDataStoreActions.bulkAssetsCSVRecordParsed, (state, action) => ({
    ...state,
    importAssets: {
      ...state.importAssets,
      uploadedData: {
        ...state.importAssets?.uploadedData,
        recordHashmap: action.payload.recordHashmap,
        headerArray: action.payload.headers
      }
    }
  })),
  on(AssetsDataStoreActions.importAssetSuccessful, (state, action) => {
    let count = state.importAssets.importedData.processedRowsCount;
    count++;
    return {
      ...state,
      importAssets: {
        ...state.importAssets,
        importedData: {
          ...state.importAssets?.importedData,
          processedRowsCount: count,
          successfullyImportedAssets: [...state.importAssets.importedData.successfullyImportedAssets, { id: action.payload.assetId }]
        },
      }
    };
  }),

  on(AssetsDataStoreActions.importAssetFailure, (state, action) => {
    return {
      ...state,
      importAssets: {
        ...state.importAssets,
        importedData: {
          ...state.importAssets?.importedData
        }
      }
    };
  }),
  on(AssetsDataStoreActions.importAsset, (state, action) => ({
    ...state,
    importAssets: {
      ...state.importAssets,
      status: BulkAssetsImportStatuses.IMPORTING_ASSETS
    }
  })),
  on(AssetsDataStoreActions.bulkAssetsImportCompleted, (state, action) => ({
    ...state,
    importAssets: {
      ...state.importAssets,
      status: BulkAssetsImportStatuses.IMPORTING_COMPLETED
    }
  })),
  on(AssetsDataStoreActions.resetImportAssets, (state, action) => ({
    ...state,
    importAssets: null
  })),
  on(AssetsDataStoreActions.saveError, (state, action) => {
    let count = state.importAssets.importedData.processedRowsCount;
    count++;
    let recordError = state.importAssets.uploadedData.recordHashmap[action.payload.index + 1];
    if (recordError) {
      const lackingColumnCount = state.importAssets.uploadedData.headerArray.length - recordError.length;
      if (lackingColumnCount > 0) {
        for (let i = 0; i < lackingColumnCount; i++) {
          recordError = [...recordError, ''];
        }
      }
      recordError = [...recordError, action.payload.message];
    }
    return {
      ...state,
      importAssets: {
        ...state.importAssets,
        importedData: {
          ...state.importAssets.importedData,
          processedRowsCount: count,
          erroredAssets: recordError ?
            [...state.importAssets.importedData.erroredAssets, recordError] : [...state.importAssets.importedData.erroredAssets]
        }
      }
    };
  }),

  on(AssetsDataStoreActions.resetAssetState, (state, action) => (initialState)),

  //#region Bulk Asset Activate/Deactivate Reducers
  on(AssetsDataStoreActions.bulkAssetStatusSelected, (state, action) => ({
    ...state,
    updateAssetsStatus: {
      ...state.updateAssetsStatus,
      successfullyUpdateStatusAssetIds: [],
      failureUpdateStatusAssets: [],
      processedAssetsCount: 0
    }
  })),
  on(AssetsDataStoreActions.updateAssetStatusFailure, (state, action) => {
    let count = state.updateAssetsStatus.processedAssetsCount;
    count++;
    return {
      ...state,
      updateAssetsStatus: {
        ...state.updateAssetsStatus,
        processedAssetsCount: count,
        failureUpdateStatusAssetIds: [
          ...state.updateAssetsStatus.failureUpdateStatusAssets,
          {
            assetId: action.payload.assetId,
            errorMessage: action.payload.errorMessage
          }
        ]
      }
    };
  }),
  on(AssetsDataStoreActions.updateAssetStatusSuccessful, (state, action) => {
    let count = state.updateAssetsStatus.processedAssetsCount;
    count++;
    return {
      ...state,
      updateAssetsStatus: {
        ...state.updateAssetsStatus,
        processedAssetsCount: count,
        successfullyUpdateStatusAssetIds: [
          ...state.updateAssetsStatus.successfullyUpdateStatusAssetIds,
          action.payload.assetId
        ]
      }
    };
  }),
  on(AssetsDataStoreActions.bulkAssetStatusReset, (state, action) => ({
    ...state,
    updateAssetsStatus: {
      ...state.updateAssetsStatus,
      successfullyUpdateStatusAssetIds: [],
      failureUpdateStatusAssets: [],
      processedAssetsCount: 0
    } 
  })),

  on(AssetsDataStoreActions.getAssets, (state, action) => ({
    ...state,
    isLoading: true
  })),

  on(AssetsDataStoreActions.getAssetsSuccessfully, (state, action) => ({
    ...state,
    assets: action.payload.assetList,
    isLoading: false
  })),

  //TODO handle error
  on(AssetsDataStoreActions.getAssetsFailure, (state, action) => ({
    ...state,
    isLoading: false
  })),

  on(AssetsDataStoreActions.searchAssetsWithProperties, (state, action) => ({
    ...state,
    isLoading: true
  })),

  on(AssetsDataStoreActions.searchAssetsWithPropertiesSuccess, (state, action) => ({
    ...state,
    assets: action.payload.assetList,
    totalAssets: action.payload.totalAssets,
    isLoading: false,
  })),

  on(AssetsDataStoreActions.searchAssetsWithPropertiesFail, (state, action) => ({
    ...state,
    assets: [],
    totalAssets: action.payload.totalAssets,
    isLoading: false,
  })),

  on(AssetsDataStoreActions.getTypeAssetsSuccessfully, (state, action) => ({
    ...state,
    assetType: action.payload.assetType
  })),
  
  //TODO handle error
  on(AssetsDataStoreActions.getTypeAssetsFailure, (state, action) => ({
    ...state,
  })),

  on(AssetsDataStoreActions.setCombineAssetList, (state, action) => ({
    ...state,
    combineAssetList: action.payload.combineAssetList
  })),
);


