<div class="list-management-container" fxLayout.lt-sm="column" fxLayoutAlign.lt-sm="center center"
  fxLayoutGap.lt-sm="0.5rem" fxLayout.gt-xs="row" fxLayoutAlign.gt-xs="start center">
  <ng-container *ngFor="let searchItem of searchList; let i = index">
    <div fxFlex.gt-xs="50" class="mr-10">
      <app-search class="app-search" [searchFormControl]="searchesFormGroup.controls[searchItem.controlName]"
        placeHolder="{{searchItem.searchPlaceHolder}}">
      </app-search>
    </div>
  </ng-container>

  <ng-container *ngFor="let buttonItem of buttonList; let i = index">
    <div fxFlex.gt-xs="50" class="button-area" fxLayout="row" fxLayoutAlign="end center">
      <ng-container *ngIf="buttonItem.permission; else nonPermission">
        <div *ngIf="buttonItem.hasMenu" [ngClass]="!!buttonItem?.menuList?.length ? '' : 'disabled'">
          <button mat-stroked-button *zonarPermission="buttonItem.permission" [disabled]="buttonItem.isDisabledButton"
            [matMenuTriggerFor]="menu" #trigger="matMenuTrigger">
            <span>{{buttonItem.menuName | titlecase}}</span>
            <mat-icon class="icon-button" *ngIf="!trigger.menuOpen">arrow_drop_down</mat-icon>
            <mat-icon class="icon-button" *ngIf="trigger.menuOpen">arrow_drop_up</mat-icon>
          </button>
          <mat-menu #menu="matMenu">
            <ng-container *ngFor="let menuItem of buttonItem.menuList">
              <button mat-menu-item (click)="menuItemClick(menuItem.action)">{{ menuItem.name | translate }}</button>
            </ng-container>
          </mat-menu>
        </div>
        <button class="default-button" mat-flat-button *zonarPermission="buttonItem.permission"
          (click)="onNavigate(buttonItem)" [disabled]="buttonItem.isDisabledButton || externallyManaged">
          {{buttonItem.buttonName | titlecase}}
        </button>

      </ng-container>
      <ng-template #nonPermission>
        <div *ngIf="buttonItem.hasMenu" fxLayout="row" [ngClass]="!!buttonItem?.menuList?.length ? '' : 'disabled'">
          <button mat-stroked-button [disabled]="buttonItem.isDisabledButton" [matMenuTriggerFor]="menu"
            #trigger="matMenuTrigger" class="button-item">
            <span>{{buttonItem.menuName | titlecase}}</span>
            <mat-icon class="icon-button" *ngIf="!trigger.menuOpen">arrow_drop_down</mat-icon>
            <mat-icon class="icon-button" *ngIf="trigger.menuOpen">arrow_drop_up</mat-icon>
          </button>
          <mat-menu #menu="matMenu" class="menu-button">
            <ng-container *ngFor="let menuItem of buttonItem.menuList">
              <div [matTooltip]="menuItem.disabledButtonTooltipText || ''" [matTooltipDisabled]="menuItem.disabledButtonTooltipText ? menuItem.hasDisabledDisabledButtonTooltip : true" matTooltipPosition="left"
              matTooltipClass="disabled-button-tooltip">
                <button mat-menu-item [disabled]="menuItem.hasDisabled" (click)="menuItemClick(menuItem.action)">{{
                  menuItem.name | translate }}</button>
              </div>
            </ng-container>
          </mat-menu>
        </div>

        <button *ngIf="buttonItem?.buttonName" class="default-button" mat-flat-button [disabled]="buttonItem.isDisabledButton || externallyManaged"
          (click)="onNavigate(buttonItem)">
          {{buttonItem.buttonName | titlecase}}
        </button>
      </ng-template>
    </div>
  </ng-container>
</div>
<div *ngIf="filterList.length" class="filter-group" fxLayout="row" fxLayoutAlign="start center">
  <div class="title" fxLayout="row" fxLayoutAlign="start center">
    <mat-icon class="icon">filter_list</mat-icon>
    <span class="text">{{'companyView.filterLabel' | translate}}</span>
  </div>
  <div class="filter-area" fxLayout="row" fxLayoutAlign="center center"
    *ngFor="let filterItem of filterList; let i = index">
    <zui-searchable-dropdown
      [options]="filterItem?.options ? filterItem.options : []"
      [label]="filterItem?.filterLabel ? filterItem.filterLabel : ''"
      [z_formGroup]="filtersFormGroup"
      [isMultiple]="filterItem?.isMultiple !== undefined ? filterItem.isMultiple : false"
      [selectOptionsControlName]="filterItem?.selectOptionsControlName"
      [sortByStringTitle]="filterItem?.sortByStringTitle !== undefined ? filterItem.sortByStringTitle : true">
    </zui-searchable-dropdown>
  </div>
</div>
<div 
  class="app-table-container" 
  [ngClass]="media.isActive('lt-md') ? 'mobile-view' : 'desktop-view'"
  fxLayout="column" fxFlexFill>
  <zui-table class="ss-table" [columns]="columns" [dataSource]="dataSource" (rowClick)="rowClick($event)"
    (selectedRows)="selectedRows($event)" [hasLoader]="true" [toolTipData]="toolTipData" [errorConfig]="errorConfig"
    [iconColors]="iconColors" [columnStyleConfig]="columnStyleConfig" [templateRef]="columnTemplateRef"
    [contentProjectionEnabled]="contentProjectionEnabled" [uniqueRowIdentifier]="uniqueRowIdentifier"
    [customCellPortals]="customCellPortals" [enableColumnPicker]="false" [enableMobileTable]="enableMobileTable" [initialPageSize]="initialPageSize">
      <ng-template #columnTemplateRef let-row>
        <ng-container *ngIf="contentProjectionEnabled">
          <app-nested-division
          [rootDivision]="row"
          [allDivisions]="dataList"
          [rowChanged]="rowClickEvent"
          (rowClick)="rowClick($event)"
          ></app-nested-division>
        </ng-container>
      </ng-template>
  </zui-table>
</div>
