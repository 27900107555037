import { FormControl, Validators } from '@angular/forms';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Translations } from '@app/core/services/i18n/translations.service';
import { TranslateService } from '@ngx-translate/core';
import { I18nService } from '@zonar-ui/i18n';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-caution-popup',
  templateUrl: './caution-popup.component.html',
  styleUrls: ['./caution-popup.component.scss']
})
export class CautionPopupComponent implements OnInit {
  private onDestroy$ = new Subject<void>();
  cautionPopupTranslated: any = {};
  translationsNotLoaded = !!this.translateService.store.translations;
  inputConfirm: string;
  confirmKeyword: FormControl;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<CautionPopupComponent>,
    public translateService: TranslateService,
    public translations: Translations,
    private i18nService: I18nService
  ) {
    if (this.translationsNotLoaded) {
      this.translateService
        .use(this.i18nService.getLanguageInUse()).pipe(takeUntil(this.onDestroy$)).subscribe(() => this.translationsNotLoaded = false);
    }
  }

  ngOnInit(): void {
    this.translateService.get([
      this.translations.deactivateCautionPopupDialog.deactivationActionText
    ]).subscribe(translated => {
      this.cautionPopupTranslated = translated;
      this.confirmKeyword = new FormControl('', Validators.pattern(
        this.cautionPopupTranslated[this.translations.deactivateCautionPopupDialog.deactivationActionText]
      ));
    });
  }

  onCancel() {
    this.dialogRef.close();
  }

  onConfirm() {
    if (this.inputConfirm === this.cautionPopupTranslated[this.translations.deactivateCautionPopupDialog.deactivationActionText]) {
      this.dialogRef.close(true);
    }
  }

  hasDisableButton() {
    if (this.inputConfirm) {
      return this.inputConfirm !== this.cautionPopupTranslated[this.translations.deactivateCautionPopupDialog.deactivationActionText];
    }

    return true;
  }
}
