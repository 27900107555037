import { ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, Output, SimpleChanges, OnDestroy } from '@angular/core';
import { FormArray, FormControl, FormGroup, ValidationErrors, ValidatorFn } from '@angular/forms';
import { IConfirmDialogText } from '@app/core/models/asset.model';
import { DialogService } from '@app/core/services/dialog.service';
import { Translations } from '@app/core/services/i18n/translations.service';
import { RegionParserService } from '@app/core/services/region-parser.service';
import { I18nService, TranslateService } from '@zonar-ui/i18n';
import { Subject, merge } from 'rxjs';
import { distinctUntilChanged, map, switchMap, takeUntil } from 'rxjs/operators';
import { MediaObserver } from "@angular/flex-layout";
import { Feature } from '@app/core/consts/app.const';
import { ENTIRE_COMPANY } from '@app/core/consts/division.const';
import { cloneDeep } from 'lodash';
import { DropdownOptions } from '@app/core/models/list-management';
import { ButtonSkeletonStyle, DropdownSkeletonStyle } from '@app/shared/config/skeleton-style.config';

@Component({
  selector: 'app-multi-dropdown-panel',
  templateUrl: './multi-dropdown-panel.component.html',
  styleUrls: ['./multi-dropdown-panel.component.scss']
})
export class MultiDropdownPanelComponent implements OnChanges, OnDestroy {
  @Input() primaryOptions: DropdownOptions[] = [];
  @Input() selectedPrimaryOptions: Array<string> = [];
  @Input() secondaryOptions: DropdownOptions[] = [];
  @Input() userId: string;
  @Input() dialogData: any = {};
  @Input() groups: FormArray;
  @Input() isZonarUser: boolean;
  @Input() isLoading: boolean = false;

  @Output() viewClickEvent = new EventEmitter();

  duplicateCollection = [];
  unselectedCollection = [];
  roleEmptyCollection = [];

  private onDestroy$ = new Subject<void>();
  translationsNotLoaded = !!this.translateService.store.translations;
  companyLevel: any[] = [];
  entireCompany;
  primaryOptionNotSelectedList: DropdownOptions[] = [];

  dropdownSkeletonStyle = DropdownSkeletonStyle;
  buttonSkeletonStyle = ButtonSkeletonStyle;

  constructor(
    public translations: Translations,
    public translateService: TranslateService,
    private i18nService: I18nService,
    private dialogService: DialogService,
    public parsingService: RegionParserService,
    protected changeDetectorRef: ChangeDetectorRef,
    public media: MediaObserver,
  ) {
    if (this.translationsNotLoaded) {
      this.translateService
        .use(this.i18nService.getLanguageInUse()).pipe(takeUntil(this.onDestroy$)).subscribe(() => this.translationsNotLoaded = false);
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.secondaryOptions?.currentValue && changes.secondaryOptions.currentValue !== changes.secondaryOptions.previousValue) {
      this.entireCompany = this.secondaryOptions.find(option => option.title === ENTIRE_COMPANY);
    }

    if (changes.primaryOptions?.currentValue && changes.primaryOptions.currentValue !== changes.primaryOptions.previousValue) {
      const companyLevelSuffix = this.translateService.instant(this.translations.user.editUser.companyLevelSuffix);
      this.companyLevel = this.primaryOptions?.filter(option => option.title?.includes(companyLevelSuffix));
      this.buildUniquePolicyOptions();
    }

    if (changes.groups?.currentValue && changes.groups.currentValue !== changes.groups.previousValue) {
      this.buildUniquePolicyOptions();
      this.groups?.addValidators([this.duplicateValidation(), this.unselectedValidation(), this.roleEmptyValidation()]);
      this.subscribeToValuesChange();
    }
  }

  buildUniquePolicyOptions() {
    // Build policyOptions for each form groups: primary - selected + selfSelected
    this.groups?.controls.forEach((formGroup: FormGroup) => {
      formGroup.get('policyOptions')?.setValue(
        this.getUniquePolicyOptions(this.selectedPrimaryOptions, cloneDeep(this.primaryOptions), formGroup),
        { emitEvent: true }
      );
    });
  }

  buildTenantOptions(originalOptions: DropdownOptions[], group: FormGroup) {
    const policyValue = group?.controls?.policy?.value || '';
    originalOptions.forEach(tenant => {
      tenant.isDisabled = false;
      if (tenant.value !== this.entireCompany?.value && this.companyLevel.find(({ value }) => value === policyValue)) {
        tenant.isDisabled = true;
      }
    });
    return originalOptions;
  }

  getUniquePolicyOptions(selectedOptions: Array<string>, originalOptions: DropdownOptions[], group: FormGroup) {
    if (selectedOptions.length) {
      const tenantValue = group?.controls?.tenant?.value || '';
      const policyValue = group?.controls?.policy?.value || '';
      originalOptions.forEach(policy => {
        policy.isDisabled = policy.isDisabled ? policy.isDisabled : false;
        if (tenantValue && tenantValue !== this.entireCompany?.value && this.companyLevel.find(({ value }) => value === policy.value)) {
          policy.isDisabled = true;
        }
      });
      const filterOptions = originalOptions.filter(option => !selectedOptions.includes(option?.value));
      const selfSelectedOption = originalOptions.find(option => option?.value === policyValue);
      if (selfSelectedOption) {
        filterOptions.push(selfSelectedOption);
      }
      return filterOptions;
    } else {
      return [...this.primaryOptions];
    }
  }

  openDropdown(group: FormGroup, mode = Feature.POLICY) {
    if (mode === Feature.POLICY) {
      group.get('policyOptions')?.setValue(
        this.getUniquePolicyOptions(this.selectedPrimaryOptions, cloneDeep(this.primaryOptions), group),
        { emitEvent: false }
      );
    } else {
      group.get('secondaryOptions')?.setValue(
        this.buildTenantOptions(cloneDeep(this.secondaryOptions), group),
      );
    }
  }

  subscribeToValuesChange() {
    this.groups?.valueChanges.pipe(
      takeUntil(this.onDestroy$),
      distinctUntilChanged(),
      switchMap(() => {
        this.setOptionsWithCompanyAdminRole();
        const groupObs = this.groups.controls.map((groupForm, index) => {
          return groupForm.valueChanges.pipe(
            map(groupValue => ({ groupValue, index })));
        });
        return merge(...groupObs);
      })
    ).subscribe((group) => {
      if (this.selectedPrimaryOptions[group.index] !== group?.groupValue?.policy) {
        this.selectedPrimaryOptions.splice(group.index, 1, group?.groupValue?.policy);
        this.buildUniquePolicyOptions();
      }
    });
  }

  addNewGroup() {
    this.groups.push(this.newGroup({ policy: '', tenant: '' }));
    this.changeDetectorRef.detectChanges(); // to avoid NG0100 error when adding new form on run time.
  }

  newGroup(data: any) {
    data = data || { policy: null, tenant: null };

    return new FormGroup({
      policy: new FormControl(data.policy),
      tenant: new FormControl(data.tenant),
      policyOptions: new FormControl(
        this.getUniquePolicyOptions(this.selectedPrimaryOptions, this.primaryOptions, data.policy)
      ),
      secondaryOptions: new FormControl(
        this.secondaryOptions
      ),
    });
  }

  duplicateValidation(): ValidatorFn {
    return (formArray: FormArray): { [key: string]: any } | null => {
      if (!!this.duplicateCollection.length) {
        for (var i = 0; i < this.duplicateCollection.length; i++) {
          let errors = this.groups.at(this.duplicateCollection[i])?.errors as Object || {};
          delete errors['duplicated'];
          this.groups.at(this.duplicateCollection[i])?.setErrors(
            Object.keys(errors as ValidationErrors)?.length ? (errors as ValidationErrors) : null
          );
        }
      }

      let dict = {};
      formArray.controls.forEach((fg: FormGroup, index) => {
        if (!!fg?.get('policy')?.value && !!fg?.get('tenant')?.value) {
          // To remove searchFormControlName of searchable-dropdown
          let tmpGroup = ((({ tenant, policy }) => ({ tenant, policy }))(fg.value));
          let flattenFormToKey = JSON.stringify(tmpGroup);
          dict[flattenFormToKey] = dict[flattenFormToKey] || [];
          dict[flattenFormToKey].push(index);
        }
      });
      let duplicates = [];
      for (var key in dict) {
        if (dict[key].length > 1) { // which mean there is duplicated selection.
          duplicates = duplicates.concat(dict[key]);
        }
      }

      this.duplicateCollection = duplicates;
      for (const index of duplicates) {
        formArray.at(+index)?.setErrors({ duplicated: true });
      }
      return duplicates.length > 0 ? { error: 'Has Duplicate !' } : null;
    };
  };

  unselectedValidation(): ValidatorFn {
    return (formArray: FormArray): { [key: string]: any } | null => {
      if (!!this.unselectedCollection.length) {
        for (var i = 0; i < this.unselectedCollection.length; i++) {
          let errors = this.groups.at(this.unselectedCollection[i])?.errors as Object || {};
          delete errors['unselected'];
          this.groups.at(this.unselectedCollection[i])?.setErrors(Object.keys(errors as ValidationErrors)?.length ? (errors as ValidationErrors) : null);
        }
      }

      let dict = {};
      formArray.controls.forEach((fg: FormGroup, index) => {
        if (this.companyLevel.find(({ value }) => (!fg?.get('policy')?.value || !fg?.get('tenant')?.value) && fg?.get('policy')?.value !== value)) {
          let flattenFormToKey = JSON.stringify(fg.value);
          dict[flattenFormToKey] = dict[flattenFormToKey] || [];
          dict[flattenFormToKey].push(index);
        }
      });

      let unselects = [];
      for (var key in dict) {
        if (dict[key].length > 0) { // which mean there is unselected.
          unselects = unselects.concat(dict[key]);
        }
      }
      this.unselectedCollection = unselects;

      for (const index of unselects) {
        formArray.at(+index)?.setErrors({ unselected: true });
      }

      return unselects.length > 0 ? { error: 'Has Unselected !' } : null;
    };
  };

  roleEmptyValidation(): ValidatorFn {
    return (formArray: FormArray): { [key: string]: any } | null => {
      if (!!this.roleEmptyCollection.length) {
        for (var i = 0; i < this.roleEmptyCollection.length; i++) {
          let errors = this.groups.at(this.roleEmptyCollection[i])?.errors as Object || {};
          delete errors['roleEmpty'];
          this.groups.at(this.roleEmptyCollection[i])?.setErrors(Object.keys(errors as ValidationErrors)?.length ? (errors as ValidationErrors) : null);
        }
      }

      this.roleEmptyCollection = [];
      formArray.controls.forEach((fg: FormGroup, index) => {
        if (this.primaryOptions.find(option => option.value === fg?.get('policy')?.value && option?.error)) {
          this.roleEmptyCollection.push(index);
          fg.setErrors({ roleEmpty: true });
        }
      });

      return this.roleEmptyCollection.length > 0 ? { error: 'Has roleEmpty !' } : null;
    };
  };

  onViewPolicyClick(policyValue) {
    this.viewClickEvent.emit({ value: policyValue, mode: Feature.POLICY });
  }

  deleteGroup(index, inputGroup) {
    if (this.hasDisabled(inputGroup)) {
      return;
    }

    if (index > -1) {
      const translationPath = this.translations.user;
      if (!!inputGroup.policy?.value && !!inputGroup.tenant?.value) {
        const dialogParams: IConfirmDialogText = {
          title: this.translateService.instant(translationPath.groupRemoveDialogTitle),
          messages: this.translateService.instant(translationPath.groupRemoveDialogMessages),
          textCancelButton: this.translateService.instant(translationPath.cancel),
          textConfirmButton: this.translateService.instant(translationPath.continue),
        };
        this.dialogService.showConfirmDialog(dialogParams, '36.875rem', '16.063rem')
          .subscribe((confirm: boolean) => {
            if (confirm) {
              this.groups.removeAt(index);
              this.selectedPrimaryOptions.splice(index, 1);
              this.buildUniquePolicyOptions();
            }
          });
      } else {
        this.groups.removeAt(index);
        this.selectedPrimaryOptions.splice(index, 1);
        this.buildUniquePolicyOptions();
      }

    }
  }

  hasDisabled(inputGroup) {
    return !this.isZonarUser && !!this.primaryOptions.find(option => option?.isHidden && option?.value === inputGroup?.policy?.value);
  }

  onTenantView(input) {
    this.viewClickEvent.emit({ value: input, mode: Feature.TENANT });
  }

  getFormGroups(): FormGroup[] {
    return this.groups?.controls as FormGroup[];
  }

  getParsedKeyWords() {
    return this.parsingService.getParsedKeyWords();
  }

  setOptionsWithCompanyAdminRole() {
    if (this.companyLevel?.length && this.entireCompany) {
      const groupControl = this.groups?.controls?.find((group: FormGroup) =>
        this.companyLevel.find(({ value }) => value === group?.get('policy')?.value) && group?.get('tenant')?.value !== this.entireCompany?.value);
      if (groupControl) {
        groupControl.get('tenant').patchValue(this.entireCompany?.value, { onlySelf: true });
        delete groupControl?.errors['unselected'];
      }
    }
  }

  ngOnDestroy() {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }
}
