import { createFeatureSelector, createSelector } from '@ngrx/store';
import { PolicyState } from './policy-data-store.interface';
import { featureKey } from './policy-data-store.reducer';

export const selectPolicyState = createFeatureSelector<PolicyState>(featureKey);

export const selectCompanyTotalPolicyCount = createSelector(selectPolicyState, state => state?.companyTotalPolicyCount);
export const selectPolicyLoadingState = createSelector(selectPolicyState, state => state?.isLoading);
export const selectPolicies = createSelector(selectPolicyState, state => state?.policies);
export const selectPolicy = createSelector(selectPolicyState, state => state?.policy);
export const selectPolicyTemplates = createSelector(selectPolicyState, state => state?.policyTemplates);
export const selectPolicyTemplate = createSelector(selectPolicyState, state => state?.policyTemplate);
export const selectPolicyDetail = createSelector(selectPolicyState, state => state?.policyDetail);
export const selectCreateUpdateSuccess = createSelector(selectPolicyState, state => ({ isSuccess: state?.createUpdateSuccess, error: state.createUpdateError }));