<div class="overlay-spinner-container">
  <mat-progress-spinner
    class="overlay-spinner"
    [ngStyle]="getCenterPositionForSpinner()"
    [mode]="mode"
    [diameter]="diameter"
  ></mat-progress-spinner>
  <div class="overlay-area"></div>
  
</div>
