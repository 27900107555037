/*import angular */
import { createReducer, on } from '@ngrx/store';
import * as GroupDataStoreActions from './group-data-store.action';
import { GroupState } from './group-data-store.interface';

/* state key */
export const featureKey = 'group';

/* initial state */
export const initialState: GroupState = {
  isLoading: false,
  companyGroups: null
};

export const reducer = createReducer(
  initialState,
  on(GroupDataStoreActions.initGroup, (state, action) => ({
    ...state
  })),

  on(GroupDataStoreActions.getGroupByCompanyId, (state, action) => ({
    ...state,
    isLoading: true
  })),
  on(GroupDataStoreActions.getGroupByCompanyIdSuccess, (state, action) => ({
    ...state,
    companyGroups: action.payload.companyGroups,
    isLoading: false
  })),
  on(GroupDataStoreActions.getGroupByCompanyIdFailure, (state, action) => ({
    ...state,
    isLoading: false
  })),
);
