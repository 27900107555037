/*import angular */
import { createReducer, on } from '@ngrx/store';
import { SettingDataStore } from './setting-data-store.interface';
import * as SettingDataStoreActions from './setting-data-store.action';

/* state key */
export const featureKey = 'setting';

/* initial state */
export const initialState: SettingDataStore = {
  isLoading: null,
  hasAllowManaged: null,
  hasLegacyOperator: null,
  setting: null
};

export const reducer = createReducer(
  initialState,
  on(SettingDataStoreActions.initSetting, (state, action) => ({
    ...state
  })),
  on(SettingDataStoreActions.getAllowManagedCompanySuccessfully, (state, action) => ({
    ...state,
    hasAllowManaged: action.payload.hasAllowManaged
  })),
  // TODO: handle error
  on(SettingDataStoreActions.getAllowManagedCompanyFailure, (state, action) => ({
    ...state,
  })),
  on(SettingDataStoreActions.resetAllowManagedCompany, (state, action) => ({
    ...state,
    hasAllowManaged: null
  })),

  on(SettingDataStoreActions.getGroupPolicySettingSuccess, (state, action) => ({
    ...state,
    setting: action.payload.setting
  })),

  on(SettingDataStoreActions.setGroupPolicySetting, (state, action) => ({
    ...state,
    setting: action.payload.setting
  })),

  on(SettingDataStoreActions.createGroupPolicySettingSuccess, (state, action) => ({
    ...state,
    setting: action.payload.setting
  })),
  // #region LEGACY
  on(SettingDataStoreActions.getLegacyOperatorSetting, (state, action) => ({
    ...state,
    hasLegacyOperator: null,
  })),
  on(SettingDataStoreActions.getLegacyOperatorSettingSuccess, (state, action) => ({
    ...state,
    hasLegacyOperator: action.payload.hasLegacyOperator,
  })),
  // #endregion LEGACY
);
