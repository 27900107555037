import { toTitleCase } from '@app/core/utils';
import { Component, OnInit } from '@angular/core';
import { Translations } from '@app/core/services/i18n/translations.service';
import { RegionParserService } from '@app/core/services/region-parser.service';
import { I18nService, TranslateService } from '@zonar-ui/i18n';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { SupportService } from '@app/core/services/support.service';
import { BulkImportRequirementsComponent } from '@app/shared/components/bulk-import-requirements-base/bulk-import-requirements-base';

@Component({
  selector: 'app-bulk-asset-import-requirements',
  templateUrl: './bulk-asset-import-requirements.component.html',
  styleUrls: ['./bulk-asset-import-requirements.component.scss']
})
export class BulkAssetImportRequirementsComponent extends BulkImportRequirementsComponent {

  constructor(
    public i18nService: I18nService,
    public translateService: TranslateService,
    public translations: Translations,
    public regionParserService: RegionParserService,
    public supportService: SupportService,
  ) {
    super(i18nService, translateService,translations, regionParserService, supportService);
  }

  initGuide() {
    const translateAssetFieldString = 'supportModule.importGuide.asset.fields';
    const translateAssetDescString = 'supportModule.importGuide.asset.guideDescription';
    this.translateService.get([
      translateAssetFieldString,
      translateAssetDescString
    ]).subscribe(translated => {
      const assetGuideTranslated: any = this.regionParserService.getParsedBucketKeyWords(translateAssetDescString, Object.keys(translated[translateAssetDescString]));
      this.guideDesc = {
        subDescPrefix: assetGuideTranslated.subDescPrefix,
        subDescSuffix: assetGuideTranslated.subDescSuffix
      };
      const fieldTranslated: any = this.regionParserService.getParsedBucketKeyWords(translateAssetFieldString, Object.keys(translated[translateAssetFieldString]));
      this.guideList.push(
        {
          fieldName: fieldTranslated.assetIdFieldName,
          requirement: fieldTranslated.assetIdRequirement,
          required: true
        },
        {
          fieldName: fieldTranslated.typeFieldName,
          requirement: fieldTranslated.typeRequirement,
          required: true
        },
        {
          fieldName: fieldTranslated.subTypeFieldName,
          requirement: fieldTranslated.subTypeRequirement,
          required: true
        },
        {
          fieldName: toTitleCase(fieldTranslated.divisionFieldName),
          requirement: fieldTranslated.divisionRequirement,
          required: true
        },
        {
          fieldName: fieldTranslated.isHazardousFieldName,
          requirement: fieldTranslated.isHazardousRequirement
        }
      );
    });
  }

  getFilePathDownload() {
    this.urlDownload = this.supportService.getFilePathDownloadAsset();
  }

}
