import { AfterViewInit, Component, HostListener, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { EntityType } from '@app/core/consts/setting.const';
import { MultiTagIdDialogParams } from '@app/core/models/user.model';
import { Translations } from '@app/core/services/i18n/translations.service';
import { SupportService } from '@app/core/services/support.service';

@Component({
  selector: 'app-multi-tagid-dialog',
  templateUrl: './multi-tagid-dialog.component.html',
  styleUrls: ['./multi-tagid-dialog.component.scss']
})
export class MultiTagidDialogComponent implements OnInit, AfterViewInit {
  displayedColumns: string[] = ['critical-icon', 'account-code', 'tag-id', 'pin'];
  gtcDriverUrl: string;
  compWindow = window;
  compDocument = document;
  isMainContentOverflowY: boolean = false;

  readonly DEFAULT_VALUE = '-';

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: MultiTagIdDialogParams,
    public dialogRef: MatDialogRef<MultiTagidDialogComponent>,
    public translations: Translations,
    private supportService: SupportService,
  ) {}

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.calculateOverflow();
    });
  }

  ngOnInit(): void {
    this.gtcDriverUrl = this.supportService.getEnvironmentBasedUrl(EntityType.GTCDRIVERMANAGEMENT);
  }

  calculateOverflow() {
    let dialogSize = (80 / 100) * (this.compWindow?.innerHeight || 0);
    let contentHeight = this.compDocument.getElementById('multiTagIdDialogContent')?.offsetHeight;
    let buttonHeight = this.compDocument.getElementById('multiTagIdDialogButton')?.offsetHeight;
    if (contentHeight && buttonHeight) {
      this.isMainContentOverflowY = contentHeight >= (dialogSize - buttonHeight - 1);
    }
  }

  onClose() {
    this.dialogRef.close();
  }

  onGoToClassicGTC() {
    this.compWindow.open(this.gtcDriverUrl, '_blank');
  }

  @HostListener('window:resize')
  onResize() {
    this.calculateOverflow();
  }
}
