<div class="announcement-container" fxLayout="column" fxLayoutAlign="space-between start">
  <div class="dialog-title" fxFill>
    <span>{{data.title}}</span>
  </div>
  <div class="dialog-body" fxLayout="row"
    fxLayoutAlign="space-evenly center" fxLayoutGap="0.5rem">
    <span fxFlex="40" class="head-text" [ngStyle]="{'opacity': !hasManagedUserId ? '1' : '0.5'}">{{ translations.announcement.companyEmail
      | translate
      }}</span>
    <mat-slide-toggle class="slide-toggle" [checked]="hasManagedUserId" (change)="toggleChange($event)">
    </mat-slide-toggle>
    <span fxFlex="40" [ngStyle]="{'opacity': hasManagedUserId ? '1' : '0.5'}">{{ translations.announcement.zonarIssuedId
      | translate:getParsedKeyWords()
      }}</span>
  </div>

  <div class="description" fxLayout="column" fxFill>
    <span class="title-description">{{data.titleDescription}}</span>
    <span class="body-description">{{data.description}}</span>
  </div>

  <div class="button-container" fxFill fxLayout="row" fxLayoutAlign="end end">
    <button *ngIf="data?.textCancelButton" mat-stroked-button type="button" (click)="onCancel()">
      {{data?.textCancelButton}}</button>
    <button class="continue-button" mat-raised-button type="submit"
      (click)="onConfirm()">{{data?.textContinueButton}}</button>
  </div>
</div>
