<div class="requirement-field-container" fxLayout="column" fxLayoutAlign="start">
  <span class="title">{{title}}</span>
  <span class="guide-sub-description" *ngIf="guideDescription">
    {{guideDescription.subDescPrefix}}
    <a [href]="urlDownload" download>{{translations.supportModule.importGuide.here | translate}}</a>
    {{guideDescription.subDescSuffix}}
  </span>
  <div class="fields-container" fxLayout="column" fxLayoutAlign="start">
    <div class="field-header" fxLayout="row" fxLayoutAlign="center center">
      <span fxFlex="30">{{translations.supportModule.importGuide.field | translate}}</span>
      <span fxFlex="70">{{translations.supportModule.importGuide.requirements | translate}}</span>
    </div>
    <div class="field-body" *ngFor="let guide of guideList" fxLayout="row" fxLayoutAlign="center center">
      <span fxFlex="30" [ngClass]="guide.required ? 'required' : ''">{{guide.fieldName}}</span>
      <span fxFlex="70">{{guide.requirement}}</span>
    </div>
  </div>
</div>
