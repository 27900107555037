<div [ngClass]="media.isActive('lt-sm') ? 'mobile-view' : ''" class="confirm-change-dialog" fxLayout="column" fxLayoutAlign="start start">
  <div fxLayout="row" fxLayoutAlign="start" class="title">
    <span>{{data?.title}}</span>
  </div>

  <div *ngIf="data?.disabledProducts?.length" class="warning-container" fxLayout="row" fxLayoutAlign="start start">
    <img [src]="'assets/icons/Severity-icons.svg'" alt="Severity icons">

    <span>{{data?.warningMessage}}</span>
  </div>

  <div *ngIf="data?.nameChanged" fxLayout="column" fxLayoutAlign="start start" class="company-name-container">
    <span class="company-name-label">{{translations.confirmChangeDialog.companyNameLabel | translate}}</span>
    <span class="company-name-data">{{data?.nameChanged}}</span>

    <mat-divider *ngIf="data?.nameChanged && (data?.enabledProducts?.length || data?.disabledProducts?.length)" class="divider"></mat-divider>
  </div>


  <div *ngIf="data?.enabledProducts?.length || data?.disabledProducts?.length" class="messages">
    <span>{{data?.messages}}</span>
  </div>

  <div class="product-change-container" fxLayout="column" fxLayoutAlign="start start">
    <div *ngIf="data?.enabledProducts?.length" class="product-change-type" fxLayout="column" fxLayoutAlign="start start">
      <span class="label">{{translations.confirmChangeDialog.enabled | translate}}</span>
      <span class="char" *ngFor="let item of data?.enabledProducts">{{item?.name}}</span>
    </div>

    <div *ngIf="data?.disabledProducts?.length" class="product-change-type" fxLayout="column" fxLayoutAlign="start start">
      <span class="label">{{translations.confirmChangeDialog.disabled | translate}}</span>
      <span class="char" *ngFor="let item of data?.disabledProducts">{{item?.name}}</span>
    </div>
  </div>

  <div class="dialog-button" fxLayout="row" fxLayoutAlign="end end">
    <button mat-stroked-button type="button" (click)="onCancel()" fxLayoutAlign="center center"> {{data?.textCancelButton}}</button>
    <button mat-raised-button type="submit" class="button-ml" fxLayoutAlign="center center" (click)="onConfirm()">{{data?.textConfirmButton}}</button>
  </div>
</div>