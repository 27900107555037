import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { InformationDialogParams } from '@app/core/models/asset.model';

@Component({
  selector: 'app-information-dialog',
  templateUrl: './information-dialog.component.html',
  styleUrls: ['./information-dialog.component.scss']
})
export class InformationDialogComponent {

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: InformationDialogParams,
    public dialogRef: MatDialogRef<InformationDialogComponent>,
  ) { }

  onClose() {
    this.dialogRef.close(true);
  }

}
