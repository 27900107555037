<div fxFlex fxLayout="row" fxLayoutAlign="center center">
  <mat-card class="page-container">
    <div class="page-content" fxLayout="column">
      <mat-card-title>
        <mat-icon>error_outline</mat-icon>
      </mat-card-title>

      <mat-card-content fxFlex fxLayout="column" class="card-content">
        <span class="title">{{translations.pageNotFound.title | translate }}</span>
        <span class="message">{{fourOhFourMode === FourOhFourMode.NO_PERMISSION ? (translations.pageNotFound.noPermissionMessage | translate) : (translations.pageNotFound.message | translate) }}</span>
        <div>
          <button *ngIf="fourOhFourMode === FourOhFourMode.DEFAULT" mat-flat-button type="button" class="home-page-btn" routerLink="/">
            {{translations.pageNotFound.homeButton | translate }}
          </button>
        </div>
      </mat-card-content>
    </div>
  </mat-card>
</div>
