/* angular */
import { IUser, IUserNavigation, IUserPolicy } from '@app/core/models/user.model';
import { createAction, props } from '@ngrx/store';
import { Observable } from 'rxjs';
import {
  BulkUserCSVFileForInvalidDataGeneratedPayload,
  BulkUserEditSelectedPayload,
  BulkUsersCSVDataFileSelectedPayload,
  BulkUsersImportCompletedPayload, GetUsersPayload, GetUsersSuccessfulPayload,
  ImportUserFailurePayload, ImportUserSuccessfulPayload,
  LoadUsersForBulkImportPayload,
  RowError
} from './users-data-store.interface';

/* action types */
export enum ActionTypes {
  InitUsers = '[USERS] InitUsers',
  GetUsers = '[USERS] GetUsers',
  GetUsersSuccessful = '[USERS] GetUsersSuccessful',
  LoadUsersForBulkImport = '[USERS] LoadUsersForBulkImport',
  /* Bulk User Import CSV Action Types */
  BulkUsersCSVDataFileSelected = '[USERS] BulkUsersCSVDataFileSelected',
  BulkUsersValidateDuplicate = '[USERS] BulkUsersValidateDuplicate',
  BulkUsersCSVDataValidated = '[USERS] BulkUsersCSVDataValidated',
  BulkUserCSVFileForInvalidDataGenerated = '[USERS] BulkUserCSVFileForInvalidDataGenerated',
  BulkUsersCSVRecordParsed = '[USERS] BulkUsersCSVRecordParsed',
  BulkUsersCSVParsingStarted = '[USERS] BulkUsersCSVParsingStarted',
  /* Bulk User Import Data Ingestion Action Types */
  BulkUserCSVDataUpload = '[USER] BulkUserCSVDataUpload',
  ImportValidatedBulkUsersData = '[USER] ImportBulkUsersData',
  ImportUser = '[USER] ImportUser',
  CreateProfileForExistingUser = '[USER] CreateProfileForExistingUser',
  ImportUserSuccessful = '[USER] ImportUserSuccessful',
  ImportUserFailure = '[USER] ImportUserFailure',
  BulkUsersImportCompleted = '[USER] BulkUsersImportCompleted',
  BulkUsersImportResetUploadedData = '[USER] BulkUsersImportResetUploadedData',
  SaveError = '[USER] SaveError',
  ResetUserState = '[USER] ResetUserState',
  /* Bulk User Edit Action Types */
  BulkUserEditSelected = '[BULK_USER] BulkUserEditSelected',
  BulkUserEditSubmit = '[BULK_USER] BulkUserEditSubmit',
  BulkUserEditSuccessResult = '[BULK_USER] BulkUserEditSuccessResult',
  BulkUserEditErrorResult = '[BULK_USER] BulkUserEditErrorResult',
  BulkUserEditReset = '[BULK_USER] BulkUserEditReset',

  /* Bulk Deactivate Users Action Types */
  BulkDeactivateUserProfile = '[BULK_USER] BulkDeactivateUserProfile',
  BulkDeactivateUserProfileSuccess = '[BULK_USER] BulkDeactivateUserProfileSuccess',
  BulkDeactivateUserProfileError = '[BULK_USER] BulkDeactivateUserProfileError',
  BulkDeactivateUsers = '[BULK_USER] BulkDeactivateUsers',

  /* Users List Action Types */
  GetAllUsers = '[USER] GetAllUsers',
  GetAllUsersSuccessful = '[USER] GetAllUsersSuccessful',
  GetAllUsersFailure = '[USER] GetAllUsersFailure',
  GetAllUserProfiles = '[USER] GetAllUserProfiles',
  GetAllUsersProfilesSuccessful = '[USER] GetAllUsersProfilesSuccessful',
  GetAllUsersProfilesFailure = '[USER] GetAllUsersProfilesFailure',
  SetCombineUserList = '[USER] SetCombineUserList',

  GetUserPolicies = '[USER] GetUserPolicies',
  GetUserPoliciesSuccess = '[USER] GetUserPoliciesSuccess',
  GetUserPoliciesFailure = '[USER] GetUserPoliciesFailure',

  GetUsersByCompanyId = '[USER] GetUsersByCompanyId',
  GetUsersByCompanyIdSuccessful = '[USER] GetUsersByCompanyIdSuccessful',
  GetUsersByCompanyIdFailure = '[USER] GetUsersByCompanyIdFailure',

  SetUser = '[USER] SetUser',
  GetUserById = '[USER] GetUserById',
  GetUserByIdSuccess = '[USER] GetUserByIdSuccess',
  GetUserByIdFail = '[USER] GetUserByIdFail',

  SetUserNavigation = '[USER] SetUserNavigation',
  
  ResetUserPolicy = '[USER] ResetUserPolicy',
}

/* actions */
export const initUsers = createAction(ActionTypes.InitUsers);
export const resetUserState = createAction(ActionTypes.ResetUserState);
export const getUsers = createAction(ActionTypes.GetUsers, props<{ payload: GetUsersPayload }>());
export const getUsersSuccessful = createAction(ActionTypes.GetUsersSuccessful, props<{ payload: GetUsersSuccessfulPayload }>());
export const loadUsersForBulkImport = createAction(ActionTypes.LoadUsersForBulkImport, props<{ payload: LoadUsersForBulkImportPayload }>());

//#region Bulk User Import CSV File And Data Validation Actions
export const bulkUsersCSVDataFileSelected = createAction(ActionTypes.BulkUsersCSVDataFileSelected,
  props<{ payload: BulkUsersCSVDataFileSelectedPayload }>());
export const bulkUsersValidateDuplicate = createAction(ActionTypes.BulkUsersValidateDuplicate,
  props<{ payload: any }>());
export const bulkUsersCSVDataValidated = createAction(ActionTypes.BulkUsersCSVDataValidated,
  props<{ payload: any }>());
export const bulkUserCSVFileForInvalidDataGenerated = createAction(ActionTypes.BulkUserCSVFileForInvalidDataGenerated,
  props<{ payload: BulkUserCSVFileForInvalidDataGeneratedPayload }>());
export const bulkUsersCSVRecordParsed = createAction(ActionTypes.BulkUsersCSVRecordParsed,
  props<{ payload: any }>());
export const bulkUsersCSVParsingStarted = createAction(ActionTypes.BulkUsersCSVParsingStarted,
  props<{ payload: any }>());
//#endregion

//#region Bulk User Import Data Ingestion Actions
export const bulkUserCSVDataUpload = createAction(ActionTypes.BulkUserCSVDataUpload,
  props<{ payload: any }>());
export const importValidatedBulkUsersData = createAction(ActionTypes.ImportValidatedBulkUsersData,
  props<{ payload: any }>());
export const importUser = createAction(ActionTypes.ImportUser, props<{ payload: any }>());
export const createProfileForExistingUser = createAction(ActionTypes.CreateProfileForExistingUser, props<{ payload: any }>());
export const importUserSuccessful = createAction(ActionTypes.ImportUserSuccessful,
  props<{ payload: ImportUserSuccessfulPayload }>());
export const importUserFailure = createAction(ActionTypes.ImportUserFailure,
  props<{ payload: ImportUserFailurePayload }>());
export const saveError = createAction(ActionTypes.SaveError,
  props<{ payload: RowError }>());
//#endregion

//#region Bulk User Import Completion Action
export const bulkUsersImportCompleted = createAction(ActionTypes.BulkUsersImportCompleted,
  props<{ payload: BulkUsersImportCompletedPayload }>());
export const bulkUsersImportResetUploadedData = createAction(ActionTypes.BulkUsersImportResetUploadedData);
//#endregion

//#region Bulk User Edit Action
export const bulkUserEditSelected = createAction(ActionTypes.BulkUserEditSelected,
  props<{ payload: BulkUserEditSelectedPayload }>());

export const bulkUserEditSubmit = createAction(ActionTypes.BulkUserEditSubmit,
  props<{ payload: Observable<any>[], user: any }>());

export const bulkUserEditSuccessResult = createAction(ActionTypes.BulkUserEditSuccessResult,
  props<{ payload: any }>());

export const bulkUserEditErrorResult = createAction(ActionTypes.BulkUserEditErrorResult,
  props<{ payload: any }>());


//#region Bulk Deactivate Users Action
export const bulkDeactivateUserProfile = createAction(ActionTypes.BulkDeactivateUserProfile,
  props<{ payload: Observable<any>[], user: any }>());

export const bulkDeactivateUserProfileError = createAction(ActionTypes.BulkDeactivateUserProfileError,
  props<{ payload: any }>());

export const bulkDeactivateUserProfileSuccess = createAction(ActionTypes.BulkDeactivateUserProfileSuccess,
  props<{ payload: any }>());

export const bulkDeactivateUsers = createAction(ActionTypes.BulkDeactivateUsers);

export const bulkUserEditReset = createAction(ActionTypes.BulkUserEditReset);
//#endregion

//#region Bulk Deactivate Users Action
export const getAllUsers = createAction(ActionTypes.GetAllUsers,
  props<{ payload: { companyId: string, sort: any } }>());
export const getAllUsersSuccessful = createAction(ActionTypes.GetAllUsersSuccessful,
  props<{ payload: { userList: IUser[] } }>());
export const getAllUsersFailure = createAction(ActionTypes.GetAllUsersFailure,
  props<{ payload: { error: any } }>());
export const getAllUsersProfiles = createAction(ActionTypes.GetAllUserProfiles,
  props<{ payload: { companyId: string, params: any } }>());
export const getAllUsersProfilesSuccessful = createAction(ActionTypes.GetAllUsersProfilesSuccessful,
  props<{ payload: { userProfileList: IUser[] } }>());
export const getAllUsersProfilesFailure = createAction(ActionTypes.GetAllUsersProfilesFailure,
  props<{ payload: { error: any } }>());
export const setCombineUserList = createAction(ActionTypes.SetCombineUserList,
  props<{ payload: { combineUserList: any[] } }>());

export const getUserPolicies = createAction(ActionTypes.GetUserPolicies,
  props<{ payload: { userId: string } }>());
export const getUserPoliciesSuccess = createAction(ActionTypes.GetUserPoliciesSuccess,
  props<{ payload: { userPolicies: IUserPolicy[] } }>());
export const getUserPoliciesFailure = createAction(ActionTypes.GetUserPoliciesFailure, props<{ payload: { error: any } }>());

export const getUsersByCompanyId = createAction(ActionTypes.GetUsersByCompanyId,
  props<{ payload: { companyId: string } }>());
export const getUsersByCompanyIdSuccessful = createAction(ActionTypes.GetUsersByCompanyIdSuccessful,
  props<{ payload: { userList: any[] } }>());
export const getUsersByCompanyIdFailure = createAction(ActionTypes.GetUsersByCompanyIdFailure,
  props<{ payload: { error: any } }>());

export const setUser = createAction(ActionTypes.SetUser,
  props<{ payload: { user: IUser } }>());

export const setUserNavigation = createAction(ActionTypes.SetUserNavigation,
  props<{ payload: { userInfo: IUserNavigation } }>());
//#endregion

//#region Common
export const getUserById = createAction(ActionTypes.GetUserById, props<{ payload: { userId: string } }>());
export const getUserByIdSuccess = createAction(ActionTypes.GetUserByIdSuccess, props<{ payload: { user: IUser } }>());
export const getUserByIdFail = createAction(ActionTypes.GetUserByIdFail, props<{ payload: { error: any } }>());
//#endregion

export const resetUserPolicy = createAction(ActionTypes.ResetUserPolicy);
