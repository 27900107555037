import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { fromEvent } from 'rxjs';
import { mergeMap, catchError, tap, switchMap } from 'rxjs/operators';

/* actions */
import * as AssetsDataStoreActions from './assets-data-store.actions';
import { SupportService } from '@app/core/services/support.service';
import { EntityApiBaseService } from '@app/core/services/api/entity-api-base.service';
import papa from 'papaparse';
import { toTitleCase } from '@app/core/utils';
import { BulkAssetImportService } from '@app/shared/services/bulk-asset-import.service';
import { CoreAssetApiService } from '@app/core/services/api/core-asset-api.service';
import { AssetStatus } from '@app/core/consts/asset.const';
import { IAssetParams } from '@app/core/models/asset.model';
import { HttpResponse } from '@angular/common/http';
import { CoreCompanyApiService } from '@app/core/services/api/core-company-api.service';

@Injectable()
export class AssetsDataStoreEffects {
  newLineReg = /\r\n|\n/;
  createAssetError = 'Create Asset error';
  getCompanyAssetError = 'Get Company Asset error';
  updateAssetStatusError = 'Update Asset Status error';

  constructor(
    private actions$: Actions,
    public supportService: SupportService,
    public entityApiBaseService: EntityApiBaseService,
    public bulkAssetImportService: BulkAssetImportService,
    public coreAssetApiService: CoreAssetApiService,
    private coreCompanyApiService: CoreCompanyApiService
  ) { }

  updateAssetCustomProperty$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AssetsDataStoreActions.updateAssetCustomProperty),
      mergeMap((action) => {
        const { assetId, propertyValueIds } = action.payload;
        return this.coreAssetApiService.updateAsset(assetId, { propertyValueIds }).pipe(
          mergeMap(res => {
            return [AssetsDataStoreActions.updateAssetCustomPropertySuccess({
              payload: {}
            })];
          }),
          catchError(error => {
            return [AssetsDataStoreActions.updateAssetCustomPropertyFail({
              payload: { error }
            })];
          })
        );
      })
    )
  );

  bulkAssetProcess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AssetsDataStoreActions.bulkAssetProcess),
      mergeMap((action) => {
        const { asset, propertyValueIds } = action.payload;
        return this.coreAssetApiService.updateAsset(asset.id, { propertyValueIds })
          .pipe(
            mergeMap(res => {
              return [AssetsDataStoreActions.bulkAssetProcessSuccess({
                payload: { asset }
              })];
            }),
            catchError(error => {
              return [AssetsDataStoreActions.bulkAssetProcessFail({
                payload: { asset }
              })];
            })
          );
      })
    )
  );

  getAssetDetail$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AssetsDataStoreActions.getAssetDetail),
      mergeMap((action) => {
        const { assetId } = action.payload;
        return this.coreAssetApiService.getAssetById(assetId).pipe(
          mergeMap((assetRes: HttpResponse<IAssetParams>) => {
            if (assetRes?.body?.divisions[0]) {
              return this.coreCompanyApiService.getDivisionById(assetRes?.body?.divisions[0]).pipe(
                mergeMap((division) => {
                  return [AssetsDataStoreActions.getAssetDetailSuccess({
                    payload: {
                      asset: assetRes.body,
                      homeLocation: division,
                    }
                  })];
                }),
                catchError((error: any) => {
                  return [AssetsDataStoreActions.getAssetDetailFail({
                    payload: { error },
                  })];
                })
              );
            } else {
              return [AssetsDataStoreActions.getAssetDetailSuccess({
                payload: {
                  asset: assetRes.body,
                  homeLocation: {},
                }
              })];
            }
          }),
          catchError((error: any) => {
            return [AssetsDataStoreActions.getAssetDetailFail({
              payload: { error },
            })];
          })
        );
      })
    ));

  validateBulkImportCSVFile$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AssetsDataStoreActions.bulkAssetsCSVDataFileSelected),
      mergeMap((action) => {
        /* parse and validate CSV file */
        const { companyId, divisions, fieldsCheckDup } = action.payload;
        const params = {
          companyId,
          divisions,
          fieldsCheckDup
        };
        return action.payload.csvFile ? [AssetsDataStoreActions.bulkAssetsCSVParsingStarted({
          payload: {
            csvFile: action.payload.csvFile,
            params
          }
        })] : [];
      })
    )
  );

  parseCSV$ = createEffect(() => {
    let tmpPayload: any = {};
    return this.actions$.pipe(
      ofType(AssetsDataStoreActions.bulkAssetsCSVParsingStarted),
      tap(action => tmpPayload = action.payload),
      switchMap((action: any) => {
        const reader = new FileReader();
        reader.readAsText(action.payload.csvFile);
        return fromEvent(reader, 'loadend');
      }),
      mergeMap((fileReader: any) => {
        /* parse and validate CSV file */
        return this.entityApiBaseService.getData(
          this.supportService.getFilePathDownloadAsset(), null, { responseType: 'text' })
          .pipe(mergeMap(fileData => {
            const headerSampleFile = papa.parse(fileData)?.data[0]?.map(item => item.trim());
            const resultRecords = [];
            const recordHashmap = {};
            const csvData = fileReader.target.result;
            const csvRecordsArray = papa.parse(csvData);
            const headers = csvRecordsArray?.data[0]?.map(item => item.trim());
            if (headers?.length !== headerSampleFile?.length) {
              return [AssetsDataStoreActions.bulkAssetsCSVRecordParsed({
                payload: {
                  resultRecords: null,
                  headers: null,
                  recordHashmap,
                  params: tmpPayload.params
                }
              })];
            }

            let emptyRow = 0;

            for (let record = 1; record < csvRecordsArray?.data?.length; record++) {
              const recordData = csvRecordsArray?.data[record];
              if (recordData[0] === '' && recordData?.length === 1) {
                emptyRow++;
              } else {
                recordHashmap[record - emptyRow] = recordData;

                const recordDataObject = {};
                for (let index = 0; index < headers?.length; index++) {
                  recordDataObject[headers[index]?.toLowerCase()] = recordData[index]?.trim();
                }
                resultRecords.push(recordDataObject);
              }
            }
            return [AssetsDataStoreActions.bulkAssetsCSVRecordParsed({
              payload: {
                resultRecords,
                headers,
                recordHashmap,
                params: tmpPayload.params
              }
            })];
          }));
      })
    );
  }
  );

  parsingRecordCSV$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AssetsDataStoreActions.bulkAssetsCSVRecordParsed),
      mergeMap((action) => {
        let recordParsed = [];
        if (action.payload.resultRecords) {
          action.payload.resultRecords.forEach(record => {
            recordParsed.push(this.bulkAssetImportService.parseRecord(record, action.payload.params));
          });
        } else {
          recordParsed = null;
        }
        return [
          AssetsDataStoreActions.bulkAssetsValidateDuplicate(
            {
              payload: {
                recordParsed,
                fieldsCheckDup: action.payload.params.fieldsCheckDup
              }
            }
          )
        ];
      })
    )
  );

  validateBulkImportCSVData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AssetsDataStoreActions.bulkAssetsValidateDuplicate),
      mergeMap((action) => {
        // Add DeDup field to recordParsed
        const { recordParsed, fieldsCheckDup } = action.payload;
        const recordRows = this.bulkAssetImportService.deDupRecord(recordParsed, fieldsCheckDup);
        return [AssetsDataStoreActions.bulkAssetsCSVDataValidated({
          payload: { recordRows }
        })];
      })
    )
  );


  bulkAssetCSVDataUpload$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AssetsDataStoreActions.bulkAssetCSVDataUpload),
      mergeMap((action) => {
        const { record, index, headerArray, prePopulateData, companyId } = action.payload;
        if (!record.hasOwnProperty('fieldDup') || !record.fieldDup.length) {
          if (this.bulkAssetImportService.getMisMatchColumnAmount(record, headerArray) < 0) {
            // Save error row to ErrorList
            return [AssetsDataStoreActions.saveError({
              payload: { index, message: this.bulkAssetImportService?.errorMessagesTranslated?.misMatchingColumnAmount }
            })];
          }

          const validatedRecord = this.bulkAssetImportService.validateRecord(record, prePopulateData);
          if (!validatedRecord.isValid) {
            // Save error row to ErrorList
            return [AssetsDataStoreActions.saveError({
              payload: { index, message: `${toTitleCase(validatedRecord.fieldName)} ${this.bulkAssetImportService?.errorMessagesTranslated?.validateErrorMessage}` }
            })];
          } else {
            return this.bulkAssetImportService.checkDupRecordAPI(validatedRecord.data, companyId).pipe(
              mergeMap((res: any) => {
                if (res.isDuplicateRecord) {
                  // Save error row to ErrorList
                  return [AssetsDataStoreActions.saveError({
                    payload: { index, message: `${this.bulkAssetImportService?.errorMessagesTranslated?.systemDuplicateMessage} ${toTitleCase(res.fieldName.join(' & '))}` }
                  })];
                } else {

                  return this.bulkAssetImportService.prePopulateAdditionalMetaData(validatedRecord.data).pipe(
                    mergeMap((response: any) => {
                      return [AssetsDataStoreActions.importAsset(
                        {
                          payload:
                          {
                            assetParams: response,
                            companyId: action.payload.companyId,
                            index: action.payload.index,
                          }
                        }
                      )];
                    })
                  );
                }
              }),
              catchError(err => {
                return [AssetsDataStoreActions.saveError({
                  payload: {
                    index,
                    message: err?.error?.message ? err.error.message : this.getCompanyAssetError
                  }
                })];
              })
            );
          }
        } else {
          // Save error row to ErrorList
          return [AssetsDataStoreActions.saveError({
            payload: { index, message: `${this.bulkAssetImportService?.errorMessagesTranslated?.csvDuplicateMessage} ${toTitleCase(record.fieldDup?.join(' & '))}` }
          })];
        }
      })
    )
  );

  importAsset$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AssetsDataStoreActions.importAsset),
      mergeMap((action) => {
        const { assetParams } = action.payload;
        return this.bulkAssetImportService.createRecord(assetParams).pipe(
          mergeMap((res: any) => {
            return [AssetsDataStoreActions.importAssetSuccessful(
              {
                payload: {
                  assetId: res?.body?.id
                }
              })];
          }),
          catchError(error => {
            // Save error row to ErrorList
            return [AssetsDataStoreActions.saveError({
              payload: {
                index: action.payload.index,
                message: error?.error?.message ? error.error.message : this.createAssetError
              }
            })];
          })
        );
      })
    ));

  //#region Bulk Asset Activate/Deactivate Effects
  updateAssetStatus$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AssetsDataStoreActions.updateAssetStatus),
      mergeMap((action) => {
        const { assetId, assetStatus } = action.payload;
        return this.coreAssetApiService.updateAsset(assetId, { status: assetStatus ? AssetStatus.ACTIVE : AssetStatus.INACTIVE }).pipe(
          mergeMap((res: any) => {
            return [AssetsDataStoreActions.updateAssetStatusSuccessful({
              payload: {
                assetId: assetId
              }
            })];
          }),
          catchError(error => {
            return [AssetsDataStoreActions.updateAssetStatusFailure({
              payload: {
                assetId: assetId,
                errorMessage: error?.error?.message ? error.error.message : this.updateAssetStatusError
              }
            })];
          })
        );
      })
    )
  );

  getAssets$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AssetsDataStoreActions.getAssets),
      mergeMap((action) => {
        const { companyId, params } = action.payload;
        return this.coreAssetApiService.getAssets(companyId, params).pipe(
          mergeMap((assets: IAssetParams[]) => {
            return [AssetsDataStoreActions.getAssetsSuccessfully({
              payload: { assetList: assets }
            })];
          }),
          catchError(error => {
            return [AssetsDataStoreActions.getAssetsFailure({
              payload: { error }
            })];
          })
        );
      })
    )
  );

  searchAssetsWithProperties$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AssetsDataStoreActions.searchAssetsWithProperties),
      mergeMap((action) => {
        const { queryParams, requestBody, isInit } = action.payload;
        return this.coreAssetApiService.searchAssetProperties(queryParams, requestBody, isInit).pipe(
          mergeMap(({assets, totalAssets} ) => {
            return [AssetsDataStoreActions.searchAssetsWithPropertiesSuccess({
              payload: { assetList: assets, totalAssets: totalAssets }
            })];
          }),
          catchError(error => {
            return [AssetsDataStoreActions.searchAssetsWithPropertiesFail({
              payload: { error , totalAssets: 0}
            })];
          })
        );
      })
    )
  );

  getTypeAssets$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AssetsDataStoreActions.getTypeAssets),
      mergeMap((action) => {
        return this.coreAssetApiService.getTypeAssets().pipe(
          mergeMap((res: any) => {
            const assetType = res.body;
            return [AssetsDataStoreActions.getTypeAssetsSuccessfully({
              payload: { assetType }
            })];
          }),
          catchError(error => {
            return [AssetsDataStoreActions.getTypeAssetsFailure({
              payload: { error }
            })];
          })
        );
      })
    )
  );
}
