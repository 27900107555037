export class TreeNode {
  id: string;
  name: string;
  level?: number;
  children: Array<any>; // children array should be TreeNode or string type
  expandable?: boolean;
  selected?: boolean;
  disabled?: boolean;
  parentId?: string;
}
