/* angular */
import { IAssetParams } from '@app/core/models/asset.model';
import { createAction, props } from '@ngrx/store';
import {
  BulkAssetDataUpload,
  BulkAssetsCSVPayload,
  BulkAssetsCSVRecordParsed,
  BulkAssetsImportCompletedPayload,
  BulkAssetsValidateDuplicate,
  ImportAssetFailurePayload,
  ImportAssetSuccessfulPayload,
  RowError,
  UpdateAssetStatusFailurePayload,
  UpdateAssetStatusPayload,
  UpdateAssetStatusSuccessfulPayload,
  UpdateBulkAssetsUpdated
} from './assets-data-store.interface';
import { IDivision } from '@app/core/models/company.model';

/* action types */
export enum ActionTypes {
  /* Bulk Asset Import CSV Action Types */
  BulkAssetsCSVDataFileSelected = '[ASSETS] BulkAssetsCSVDataFileSelected',
  BulkAssetsCSVParsingStarted = '[ASSETS] BulkAssetsCSVParsingStarted',
  BulkAssetsCSVRecordParsed = '[ASSETS] BulkAssetsCSVRecordParsed',
  BulkAssetsValidateDuplicate = '[ASSETS] BulkAssetsValidateDuplicate',
  BulkAssetsCSVDataValidated = '[ASSETS] BulkAssetsCSVDataValidated',
  BulkAssetCSVDataUpload = '[ASSETS] BulkAssetCSVDataUpload',
  SaveError = '[ASSETS] SaveError',
  ImportAsset = '[ASSETS] ImportAsset',
  ImportAssetSuccessful = '[ASSETS] ImportAssetSuccessful',
  BulkAssetsImportCompleted = '[ASSETS] BulkAssetsImportCompleted',
  ResetAssetState = '[ASSETS] ResetAssetState',
  ResetImportAssets = '[ASSETS] ResetImportAssets',
  BulkAssetCSVFileForInvalidDataGenerated = '[ASSETS] BulkAssetCSVFileForInvalidDataGenerated',
  ImportAssetFailure = '[ASSETS] ImportAssetFailure',
  /* Bulk Asset Activate/Deactivate Action Types */
  BulkAssetStatusSelected = '[ASSETS] BulkAssetStatusSelected',
  UpdateAssetStatus = '[ASSETS] UpdateAssetStatus',
  UpdateAssetStatusSuccessful = '[ASSETS] UpdateAssetStatusSuccessful',
  UpdateAssetStatusFailure = '[ASSETS] UpdateAssetStatusFailure',
  BulkAssetStatusReset = '[ASSETS] BulkAssetStatusReset',

  GetAssets = '[ASSETS] GetAssets',
  GetAssetsSuccessfully = '[ASSETS] GetAssetsSuccessfully',
  GetAssetsFailure = '[ASSETS] GetAssetsFailure',
  SetCombineAssetList = '[ASSETS] SetCombineAssetList',
  GetTypeAssets = '[ASSETS] GetTypeAssets',
  GetTypeAssetsSuccessfully = '[ASSETS] GetTypeAssetsSuccessfully',
  GetTypeAssetsFailure = '[ASSETS] GetTypeAssetsFailure',

  SearchAssetsWithProperties = '[ASSETS] SearchAssetsWithProperties',
  SearchAssetsWithPropertiesSuccess = '[ASSETS] SearchAssetsWithPropertiesSuccess',
  SearchAssetsWithPropertiesFail = '[ASSETS] SearchAssetsWithPropertiesFail',

  GetAssetDetail = '[ASSETS] GetAssetDetail',
  GetAssetDetailSuccess = '[ASSETS] GetAssetDetailSuccess',
  GetAssetDetailFail = '[ASSETS] GetAssetDetailFail',
  ResetAssetDetail = '[ASSETS] ResetAssetDetail',

  // Edit Asset
  UpdateAssetCustomProperty = '[ASSETS] UpdateAssetCustomProperty',
  UpdateAssetCustomPropertySuccess = '[ASSETS] UpdateAssetCustomPropertySuccess',
  UpdateAssetCustomPropertyFail = '[ASSETS] UpdateAssetCustomPropertyFail',

  // Bulk Edit Assets
  BulkAssetProcessStart = '[ASSETS] BulkAssetProcessStart',
  BulkAssetProcess = '[ASSETS] BulkAssetProcess',
  BulkAssetProcessSuccess = '[ASSETS] BulkAssetProcessSuccess',
  BulkAssetProcessFail = '[ASSETS] BulkAssetProcessFail',
  BulkAssetProcessReset = '[ASSETS] BulkAssetProcessReset',
}

//#region Bulk Asset Import CSV File And Data Validation Actions
export const bulkAssetsCSVDataFileSelected = createAction(ActionTypes.BulkAssetsCSVDataFileSelected,
  props<{ payload: Partial<BulkAssetsCSVPayload> }>());

export const bulkAssetsCSVParsingStarted = createAction(ActionTypes.BulkAssetsCSVParsingStarted,
  props<{ payload: BulkAssetsCSVPayload }>());

export const bulkAssetsCSVRecordParsed = createAction(ActionTypes.BulkAssetsCSVRecordParsed,
  props<{ payload: BulkAssetsCSVRecordParsed }>());

export const bulkAssetsValidateDuplicate = createAction(ActionTypes.BulkAssetsValidateDuplicate,
  props<{ payload: BulkAssetsValidateDuplicate }>());

export const bulkAssetsCSVDataValidated = createAction(ActionTypes.BulkAssetsCSVDataValidated,
  props<{ payload: BulkAssetsCSVRecordParsed }>());

export const bulkAssetCSVDataUpload = createAction(ActionTypes.BulkAssetCSVDataUpload,
  props<{ payload: BulkAssetDataUpload }>());

export const saveError = createAction(ActionTypes.SaveError,
  props<{ payload: RowError }>());

export const importAsset = createAction(ActionTypes.ImportAsset, props<{ payload: BulkAssetDataUpload }>());

export const importAssetSuccessful = createAction(ActionTypes.ImportAssetSuccessful,
  props<{ payload: ImportAssetSuccessfulPayload }>());

export const bulkAssetsImportCompleted = createAction(ActionTypes.BulkAssetsImportCompleted,
  props<{ payload: BulkAssetsImportCompletedPayload }>());

export const bulkAssetCSVFileForInvalidDataGenerated = createAction(ActionTypes.BulkAssetCSVFileForInvalidDataGenerated,
  props<{ payload: BulkAssetsCSVPayload }>());

export const importAssetFailure = createAction(ActionTypes.ImportAssetFailure,
  props<{ payload: ImportAssetFailurePayload }>());

export const resetAssetState = createAction(ActionTypes.ResetAssetState);
export const resetImportAssets = createAction(ActionTypes.ResetImportAssets);

//#region Bulk Asset Activate/Deactivate Actions
export const bulkAssetStatusSelected = createAction(ActionTypes.BulkAssetStatusSelected);
export const updateAssetStatus = createAction(ActionTypes.UpdateAssetStatus,
  props<{ payload: UpdateAssetStatusPayload }>());
export const updateAssetStatusSuccessful = createAction(ActionTypes.UpdateAssetStatusSuccessful,
  props<{ payload: UpdateAssetStatusSuccessfulPayload }>());
export const updateAssetStatusFailure = createAction(ActionTypes.UpdateAssetStatusFailure,
  props<{ payload: UpdateAssetStatusFailurePayload }>());
export const bulkAssetStatusReset = createAction(ActionTypes.BulkAssetStatusReset);

export const getAssets = createAction(ActionTypes.GetAssets,
  props<{ payload: { companyId: string, params?: any } }>());
export const getAssetsSuccessfully = createAction(ActionTypes.GetAssetsSuccessfully,
  props<{ payload: { assetList: IAssetParams[] } }>());
export const getAssetsFailure = createAction(ActionTypes.GetAssetsFailure,
  props<{ payload: { error: any } }>());

export const searchAssetsWithProperties = createAction(ActionTypes.SearchAssetsWithProperties,
  props<{ payload: { queryParams: any, requestBody: any, isInit: boolean } }>());
export const searchAssetsWithPropertiesSuccess = createAction(ActionTypes.SearchAssetsWithPropertiesSuccess,
  props<{ payload: { assetList: IAssetParams[], totalAssets: number } }>());
export const searchAssetsWithPropertiesFail = createAction(ActionTypes.SearchAssetsWithPropertiesFail,
  props<{ payload: { error: any, totalAssets: number } }>());

export const setCombineAssetList = createAction(ActionTypes.SetCombineAssetList,
  props<{ payload: { combineAssetList: any[] } }>());
export const getTypeAssets = createAction(ActionTypes.GetTypeAssets);
export const getTypeAssetsSuccessfully = createAction(ActionTypes.GetTypeAssetsSuccessfully, props<{ payload: { assetType: any } }>());
export const getTypeAssetsFailure = createAction(ActionTypes.GetTypeAssetsFailure, props<{ payload: { error: any } }>());

/**
 * Get asset's entity and also its home location based on this asset's 1st division.
 */
export const getAssetDetail = createAction(ActionTypes.GetAssetDetail, props<{ payload: { assetId: string } }>());
export const getAssetDetailSuccess = createAction(ActionTypes.GetAssetDetailSuccess, props<{ payload: { asset: IAssetParams, homeLocation: IDivision } }>());
export const getAssetDetailFail = createAction(ActionTypes.GetAssetDetailFail, props<{ payload: { error: any } }>());
export const resetAssetDetail = createAction(ActionTypes.ResetAssetDetail);

/* TODO: Mock for asset custom properties START */
// Edit Asset
export const updateAssetCustomProperty = createAction(ActionTypes.UpdateAssetCustomProperty, props<{ payload: { assetId: string, propertyValueIds: string[]; } }>());
export const updateAssetCustomPropertySuccess = createAction(ActionTypes.UpdateAssetCustomPropertySuccess, props<{ payload: any }>());
export const updateAssetCustomPropertyFail = createAction(ActionTypes.UpdateAssetCustomPropertyFail, props<{ payload: { error: any } }>());

// Bulk Edit Assets
export const bulkAssetProcessStart = createAction(ActionTypes.BulkAssetProcessStart, props<{ payload: { totalAssets: number } }>());
export const bulkAssetProcess = createAction(ActionTypes.BulkAssetProcess, props<{ payload: { asset: IAssetParams; propertyValueIds: string[]; } }>());
export const bulkAssetProcessSuccess = createAction(ActionTypes.BulkAssetProcessSuccess, props<{ payload: { asset: UpdateBulkAssetsUpdated } }>());
export const bulkAssetProcessFail = createAction(ActionTypes.BulkAssetProcessFail, props<{ payload: { asset: UpdateBulkAssetsUpdated; } }>());
export const bulkAssetProcessReset = createAction(ActionTypes.BulkAssetProcessReset);
/* TODO: Mock for asset custom properties END */
